import { Component, Input, OnInit } from '@angular/core';
import { AppEnums } from 'src/app/enums/app.enum';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

export interface IAudit {
  feduid?: string;
  message: any;
  name?: string;
  operation?: string;
  userid?: string;
  _date?: any;
  _timestamp?: number;
}

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss'],
})
export class AuditLogComponent implements OnInit {
  @Input() policyID: string;
  @Input() policyName: string;
  showAuditLog: boolean = false;
  auditLogs: IAudit[] = [];
  isLoading: boolean = false;
  constructor(public appService: AppService) { }

  ngOnInit(): void { }
  onShowAuditLog() {
    let data = {
      pipeline: [
        {
          $match: {
            _id: this.policyID,
          },
        },
        {
          $project: {
            audit: 1,
          },
        },
      ],
    };
    this.isLoading = true;
    switch (this.policyName) {
      case 'motor':
        this.appService.getMotorQuotesAndPolicies(data).subscribe((res) => {
          if (res.Status == 200) {
            this.auditLogs = res.Payload[0].audit;
            this.showAuditLog = true;
            this.isLoading = false;
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeInfo,
              '',
              res.Payload
            );
            this.isLoading = false;
            this.showAuditLog = false;
          }
        });
        break;
      case 'medical':
        this.appService
          .getMedicalAggregateQuotesAndPolicies(data)
          .subscribe((res) => {
            if (res.Status == 200) {
              this.auditLogs = res.Payload[0].audit;
              this.showAuditLog = true;
              this.isLoading = false;
            } else {
              this.appService.showToastMessage(
                AppEnums.ToastTypeInfo,
                '',
                res.Payload
              );
              this.isLoading = false;
              this.showAuditLog = false;
            }
          });
        break;
      case 'golf':
        this.appService
          .getGolfAggregateQuotesAndPolicies(data)
          .subscribe((res) => {
            if (res.Status == 200) {
              this.auditLogs = res.Payload[0].audit;
              this.showAuditLog = true;
              this.isLoading = false;
            } else {
              this.appService.showToastMessage(
                AppEnums.ToastTypeInfo,
                '',
                res.Payload
              );
              this.isLoading = false;
              this.showAuditLog = false;
            }
          });
        break;
      case 'personalaccident':
        this.appService
          .getPersonalAccidentAggregateQuotesAndPolicies(data)
          .subscribe((res) => {
            if (res.Status == 200) {
              this.auditLogs = res.Payload[0].audit;
              this.showAuditLog = true;
              this.isLoading = false;
            } else {
              this.appService.showToastMessage(
                AppEnums.ToastTypeInfo,
                '',
                res.Payload
              );
              this.isLoading = false;
              this.showAuditLog = false;
            }
          });
        break;
      case 'domestic':
        this.appService
          .getDomesticAggregateQuotesAndPolicies(data)
          .subscribe((res) => {
            if (res.Status == 200) {
              this.auditLogs = res.Payload[0].audit;
              this.showAuditLog = true;
              this.isLoading = false;
            } else {
              this.appService.showToastMessage(
                AppEnums.ToastTypeInfo,
                '',
                res.Payload
              );
              this.isLoading = false;
              this.showAuditLog = false;
            }
          });
        break;
      case 'evacuation':
        this.appService
          .getEvacuationAggregateQuotesAndPolicies(data)
          .subscribe((res) => {
            if (res.Status == 200) {
              this.auditLogs = res.Payload[0].audit;
              this.showAuditLog = true;
              this.isLoading = false;
            } else {
              this.appService.showToastMessage(
                AppEnums.ToastTypeInfo,
                '',
                res.Payload
              );
              this.isLoading = false;
              this.showAuditLog = false;
            }
          });
        break;
      case 'life':
        this.appService
          .getLifeAggregateQuotesAndPolicies(data)
          .subscribe((res) => {
            if (res.Status == 200) {
              this.auditLogs = res.Payload[0].audit;
              this.showAuditLog = true;
              this.isLoading = false;
            } else {
              this.appService.showToastMessage(
                AppEnums.ToastTypeInfo,
                '',
                res.Payload
              );
              this.isLoading = false;
              this.showAuditLog = false;
            }
          });
        break;
      case 'travel':
        this.appService
          .getTravelAggregateQuotesAndPolicies(data)
          .subscribe((res) => {
            if (res.Status == 200) {
              this.auditLogs = res.Payload[0].audit;
              this.showAuditLog = true;
              this.isLoading = false;
            } else {
              this.appService.showToastMessage(
                AppEnums.ToastTypeInfo,
                '',
                res.Payload
              );
              this.isLoading = false;
              this.showAuditLog = false;
            }
          });
        break;
      case 'unisure':
        this.appService
          .makePostRequest(
            `${environment.MEDICAL_API_URL}/unisureadminlistquote`,
            data
          )
          .subscribe((res) => {
            if (res.Status == 200) {
              this.auditLogs = res.Payload[0].audit;
              this.showAuditLog = true;
              this.isLoading = false;
            } else {
              this.appService.showToastMessage(
                AppEnums.ToastTypeInfo,
                '',
                res.Payload
              );
              this.isLoading = false;
              this.showAuditLog = false;
            }
          });
        break;
      case 'other':
        this.appService
          .makePostRequest(`${environment.PA_API_URL}/adminlistotherquote`, {
            _id: this.policyID,
          })
          .subscribe((res) => {
            if (res.Status == 200) {
              this.auditLogs = res.Payload[0]?.audit;
              this.showAuditLog = true;
              this.isLoading = false;
            } else {
              this.appService.showToastMessage(
                AppEnums.ToastTypeInfo,
                '',
                res.Payload
              );
              this.isLoading = false;
              this.showAuditLog = false;
            }
          });
        break;
      case 'lastexpense':
        this.appService
          .makePostRequest(
            `${environment.LIFE_API_URL}/adminlistlastexpensequote`,
            {
              _id: this.policyID,
            }
          )
          .subscribe((res) => {
            if (res.Status == 200) {
              this.auditLogs = res.Payload[0]?.audit;
              this.showAuditLog = true;
              this.isLoading = false;
            } else {
              this.appService.showToastMessage(
                AppEnums.ToastTypeInfo,
                '',
                res.Payload
              );
              this.isLoading = false;
              this.showAuditLog = false;
            }
          });
        break;
      case 'telemedicine':
        this.appService.getTelemedicineAggregateQuotesAndPolicies(data).subscribe((res) => {
          if (res.Status == 200) {
            this.auditLogs = res.Payload[0].audit;
            this.showAuditLog = true;
            this.isLoading = false;
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeInfo,
              '',
              res.Payload
            );
            this.isLoading = false;
            this.showAuditLog = false;
          }
        });
        break;
    }
  }

  hideAuditLog() {
    this.showAuditLog = false;
  }
}
