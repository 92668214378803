import {Component, OnInit, Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';



@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() skip;
  @Input() limit;
  @Input() dataLoading;
  @Input() dataLength;
  @Output() newSkip = new EventEmitter<number>();

  previousStatus:string = "disabled";
  nextStatus:string = "disabled";

  constructor() { }

  ngOnInit(): void {

  }

  goToPrevPage(skip, limit){
    if(skip >= limit){
      skip -= limit;
      this.newSkip.emit(skip);
    }
    if(skip === 0){
      skip -= limit;
      this.newSkip.emit(skip);
      this.previousStatus="disabled";
    }

  }

  goToNextPage(skip, limit){
    skip += limit;
    this.newSkip.emit(skip);
    if(skip > 0){
      this.previousStatus = "";
    }

    if(skip === 0){
      skip += limit;
      this.newSkip.emit(skip);
    }
  }

  

}
