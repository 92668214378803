<div *ngIf="totalPaid < paymentdetails?.totalPayable; else fullyPaid">
    <div class="alert alert-danger mt-2 text-center" role="alert">
        Total Paid is
        <strong>{{ totalPaid | currency: 'Ksh. ' }}</strong>.
        The balance is
        <strong>{{
            paymentdetails?.totalPayable - totalPaid | currency: 'Ksh. '
            }}</strong>
    </div>
    <div class="alert alert-warning mt-2 text-center" role="alert">
        The next installment of
        <strong>{{
            paymentdetails?.perUnitRepaymentAmount | currency: 'Ksh. '
            }}</strong>
        is expected on
        <strong>{{
            paymentdetails?.nextPaymentDate | date: 'fullDate'
            }}</strong>
    </div>
</div>
<ng-template #fullyPaid>
    <div class="alert alert-success mt-2 text-center" role="alert">
        This plan is fully paid. The balance is <strong>{{ paymentdetails?.totalPayable - totalPaid | currency: 'Ksh. '
            }}</strong>
    </div>
</ng-template>