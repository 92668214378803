import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppService } from '../services/app.service';

@Pipe({
  name: 'thirdParty',
})
export class ThirdPartyPipe implements PipeTransform {
  constructor(private appService: AppService) {}
  transform(thirdPartyId: string): Observable<string> {
    return this.appService.getThirdPartiesFromCdn?.pipe(
      map((tps) => {
        return tps.find((tp) => tp.thirdPartyId == thirdPartyId)?.name;
      })
    );
  }
}
