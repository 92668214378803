<h3 class="card-title text-uppercase mb-0 text-mtek">
  <i class="fa fa-handshake-o text-mtek"></i>
  Actions
</h3>
<hr />
<div class="row">
  <P>
    <button mat-button (click)="goToEditUserDetails()" *ngIf="appService.isAdmin()" class="text-success">
      <i class="vsm-icon pe-7s-pen h6"></i> Edit Details
    </button>
  </P>
</div>

<div class="row">
  <p>
    <button mat-button (click)="showChangePasswordDialog()" *ngIf="appService.isAdmin()" class="text-info">
      <i class="vsm-icon pe-7s-key h6"></i> Change Password
    </button>
  </p>
</div>

<div class="row">
  <p>
    <button mat-button (click)="showUpdateRoleDialog()" *ngIf="appService.isAdmin()" class="text-warning">
      <i class="vsm-icon pe-7s-repeat h6"></i> Update Role
    </button>
  </p>
</div>

<div class="row">
  <p>
    <button mat-button (click)="openAddNotification()" *ngIf="appService.isAdmin()" class="text-info">
      <i class="vsm-icon pe-7s-paper-plane h6"></i> Send Notification
    </button>
  </p>
</div>

<div class="row">
  <p>
    <button mat-button (click)="enableDisableAccount()" *ngIf="appService.isAdmin()" class="text-success">
      <div *ngIf="userDetails?.disabled; else enabled">
        <i class="vsm-icon pe-7s-check h6"></i> Enable Account
      </div>
      <ng-template #enabled>
        <i class="vsm-icon pe-7s-delete-user h6"></i>
        Disable Account
      </ng-template>
    </button>
  </p>
</div>