<mat-tab-group mat-align-tabs="start">
  <mat-tab label="User Details" *ngIf="appService.hasPermission('admin-view-client-details-user-details')">
    <div class="row">
      <div class="col-md-9">
        <div class="card section1">
          <div class="card-body">
            <h4 class="card-title text-uppercase mb-0 text-mtek">
              <i class="fa fa-address-card-o text-mtek h5"></i>
              User Details
            </h4>
            <hr />

            <div class="row">
              <div class="col-md-3">
                <h5 class="card-title text-uppercase mb-0 text-info">
                  Personal Details
                </h5>
                <hr />
                <p>
                  <strong>
                    <small class="d-block text-dark text-uppercase bold-text">Name</small>
                  </strong>
                  {{ userDetails?.userName }}
                </p>
                <p>
                  <small class="d-block text-dark text-uppercase bold-text">Email</small>
                  {{ userDetails?.email }}
                </p>
                <p>
                  <small class="d-block text-dark text-uppercase bold-text">Phone Number</small>
                  {{ userDetails?.phoneNumber }}
                </p>
                <p>
                  <small class="d-block text-dark text-uppercase bold-text">Birthday</small>
                  {{ userDetails?.dob }}
                </p>
                <p>
                  <small class="d-block text-dark text-uppercase bold-text">Occupation</small>
                  <span *ngIf="userDetails?.occupation; else principleProfile">
                    {{ userDetails?.occupation }}
                  </span>
                  <ng-template #principleProfile>
                    <span>{{
                      assessorDetails?.principleProfile?.occupation
                      }}</span>
                  </ng-template>
                </p>
                <p>
                  <small class="d-block text-dark text-uppercase bold-text">Employment Status</small>
                  {{ userDetails?.employmentStatus }}
                </p>

                <p>
                  <small class="d-block text-dark text-uppercase bold-text">Spouse or Kids</small>
                  <span *ngIf="userDetails?.spouseOrKids; else single">
                    {{ userDetails?.spouseOrKids }}
                  </span>

                  <ng-template #single>
                    {{ assessorDetails?.principleProfile?.maritalStatus }}
                  </ng-template>
                </p>

                <p>
                  <small class="d-block text-dark text-uppercase bold-text">Monthly Income</small>
                  {{ userDetails?.monthlyIncome }}
                </p>

                <p>
                  <small class="d-block text-dark text-uppercase bold-text">Own a Car</small>
                  {{ userDetails?.ownACar }}
                </p>
                <br />
                <br />
                <br />
              </div>
              <div class="col-md-3">
                <h5 class="card-title text-uppercase mb-0 text-info">
                  Identification Details
                </h5>
                <hr />
                <p>
                  <small class="d-block text-dark text-uppercase bold-text">ID Number</small>
                  <span *ngIf="userDetails?.idNumber; else nationalIdNo">
                    {{ userDetails?.idNumber }}
                  </span>

                  <ng-template #nationalIdNo>
                    {{ valuerDetails?.nationalIdNo }}
                  </ng-template>
                </p>
                <p>
                  <small class="d-block text-dark text-uppercase bold-text">Passport Number</small>
                  {{ userDetails?.passportNumber }}
                </p>
                <p>
                  <small class="d-block text-dark text-uppercase bold-text">KRA PIN</small>
                  <span *ngIf="userDetails?.kraPin; else valuerKra">
                    {{ userDetails?.kraPin }}
                  </span>
                  <ng-template #valuerKra>
                    {{ valuerDetails?.kraPin }}
                  </ng-template>
                </p>
                <p>
                  <small class="d-block text-dark text-uppercase bold-text">Nationality</small>
                  {{ userDetails?.nationality }}
                </p>
                <p>
                  <small class="d-block text-dark text-uppercase bold-text">Driving License</small>
                  {{ userDetails?.driverLicense }}
                </p>

                <p *ngIf="valuerDetails?.copNo">
                  <small class="d-block text-dark text-uppercase bold-text">COP Number</small>
                  {{ valuerDetails?.copNo }}
                </p>

                <p *ngIf="valuerDetails?.businessCertNo">
                  <small class="d-block text-dark text-uppercase bold-text">Business Certificate Number</small>
                  {{ valuerDetails?.businessCertNo }}
                </p>

                <h5 class="card-title text-uppercase mb-0 text-info" *ngIf="userDetails?.promo">
                  Referral Details
                </h5>
                <hr />
                <p *ngIf="userDetails?.promo && userDetails?.referralCode">
                  <small class="d-block text-dark text-uppercase bold-text">Referral Code</small>
                  {{ userDetails?.promo }}
                </p>
                <p *ngIf="userDetails?.referralCode">
                  <small class="d-block text-dark text-uppercase bold-text">Referred By</small>
                  {{ userDetails?.referralCode }}
                </p>
              </div>
              <div class="col-md-3">
                <h5 class="card-title text-uppercase mb-0 text-info">
                  Address Details
                </h5>
                <hr />
                <p>
                  <small class="d-block text-dark text-uppercase bold-text">Physical Address</small>
                  {{ userDetails?.physicalAddress }}
                </p>

                <p>
                  <small class="d-block text-dark text-uppercase bold-text">Postal Address</small>
                  {{ userDetails?.postalAddress }}
                </p>
                <p>
                  <small class="d-block text-dark text-uppercase bold-text">Postal Code</small>
                  {{ userDetails?.postalCode }}
                </p>

                <h5 class="card-title text-uppercase mb-0 text-info">
                  User Roles
                </h5>
                <hr />
                <p>
                  <span *ngFor="let arole of userRoles" class="badge text-muted mr-5">{{ arole }}</span>
                </p>
              </div>

              <div class="col-md-3">
                <h5 class="card-title text-uppercase mb-0 text-info">
                  Bima Profile
                </h5>
                <hr />
                <div *ngIf="userDetails?.bimaProfile; else noBimaProfile">
                  <p>
                    <small class="d-block text-dark text-uppercase bold-text">Referal Code</small>
                    {{ userDetails?.bimaProfile?.referralCode }}
                  </p>

                  <p>
                    <small class="d-block text-dark text-uppercase bold-text">Total Points</small>
                    {{ userDetails?.bimaProfile?.totalPoints }}
                  </p>

                  <p>
                    <small class="d-block text-dark text-uppercase bold-text">Balance Points</small>
                    {{ userDetails?.bimaProfile?.balance }}
                  </p>

                  <p>
                    <small class="d-block text-dark text-uppercase bold-text">Redeemed Points</small>
                    {{ userDetails?.bimaProfile?.redeemed }}
                  </p>

                  <p>
                    <small class="d-block text-dark text-uppercase bold-text">Referals</small>
                    {{ userDetails?.bimaProfile?.referrals }}
                  </p>

                  <p>
                    <small class="d-block text-dark text-uppercase bold-text">Referrer Code</small>
                    <span *ngIf="
                        userDetails?.bimaProfile?.referrerCode;
                        else noReferrerCode
                      ">
                      {{ userDetails?.bimaProfile?.referrerCode }}
                    </span>

                    <ng-template #noReferrerCode>
                      <div class="alert alert-info">No referrerCode</div>
                    </ng-template>
                  </p>
                </div>
              </div>
              <ng-template #noBimaProfile>
                <div class="alert alert-info" role="alert">
                  No Bima Profile Found.
                </div>
              </ng-template>
            </div>

            <div class="row">
              <!-- Only for Kyosk for now -->
              <div class="col-md-12" *ngIf="appService.isKyosk() && userDetails">
                <small class="d-block text-dark text-uppercase bold-text">Referral Link</small>
                {{getKyoskReferralLink}}
                <button class="btn btn-light btn-sm" [cdkCopyToClipboard]="getKyoskReferralLink"><i
                    class="fa fa-copy"></i> Copy</button>
              </div>

            </div>
            <hr />
            <div class="row">
              <div class="col-md-6">
                <small class="d-block text-dark text-uppercase bold-text">Created</small> {{
                userDetails?._timestamp | date: 'medium' }}
              </div>
              <div class="col-md-6">
                <small class="d-block text-dark text-uppercase bold-text">Updated</small> {{
                userDetails?._utimestamp | date: 'medium' }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="card section2">
          <div class="card-body">
            <div class="row rw-stat">
              <div class="col-7 col-md-8">
                <div class="n">
                  <h4 class="card-title text-uppercase mb-0 text-mtek">
                    <strong><i class="fa fa-download text-mtek"></i></strong>
                    Downloads
                  </h4>
                  <hr />

                  <div class="col-md-12">
                    <div class="row" *ngIf="userDetails?.signature">
                      <p>
                        <a href="javascript:void(0)" (click)="viewAttachment(userDetails?.signature)"
                          class="text-success bold-text">
                          <i class="vsm-icon pe-7s-cloud-download h5"></i>
                          <span class="aligned-with-icon h6"> Signature </span>
                        </a>
                      </p>
                    </div>
                    <div class="row" *ngIf="userDetails?.kraPhoto">
                      <p>
                        <a href="javascript:void(0)" (click)="viewAttachment(userDetails?.kraPhoto)"
                          class="text-success bold-text">
                          <i class="vsm-icon pe-7s-cloud-download h5"></i>
                          <span class="aligned-with-icon h6"> KRA</span>
                        </a>
                      </p>
                    </div>

                    <div class="row" *ngIf="valuerDetails?.kraPinImage">
                      <p>
                        <a href="javascript:void(0)" (click)="viewAttachment(valuerDetails?.kraPinImage)"
                          class="text-success bold-text">
                          <i class="vsm-icon pe-7s-cloud-download h5"></i>
                          <span class="aligned-with-icon h6"> KRA</span>
                        </a>
                      </p>
                    </div>

                    <div class="row" *ngIf="userDetails?.passportPhoto">
                      <p>
                        <a href="javascript:void(0)" (click)="viewAttachment(userDetails?.passportPhoto)"
                          class="text-success bold-text">
                          <i class="vsm-icon pe-7s-cloud-download h5"></i>
                          <span class="aligned-with-icon h6"> Passport </span>
                        </a>
                      </p>
                    </div>
                    <div class="row" *ngIf="userDetails?.idPhotoFront">
                      <p>
                        <a href="javascript:void(0)" (click)="viewAttachment(userDetails?.idPhotoFront)"
                          class="text-success bold-text">
                          <i class="vsm-icon pe-7s-cloud-download h5"></i>
                          <span class="aligned-with-icon h6"> ID - Front </span>
                        </a>
                      </p>
                    </div>
                    <div class="row" *ngIf="userDetails?.idPhotoBack">
                      <p>
                        <a href="javascript:void(0)" (click)="viewAttachment(userDetails?.idPhotoBack)"
                          class="text-success bold-text">
                          <i class="vsm-icon pe-7s-cloud-download h5"></i>
                          <span class="aligned-with-icon h6"> ID - Back </span>
                        </a>
                      </p>
                    </div>

                    <div class="row" *ngIf="userDetails?.dlphoto">
                      <p>
                        <a href="javascript:void(0)" (click)="viewAttachment(userDetails?.dlphoto)"
                          class="text-success bold-text">
                          <i class="vsm-icon pe-7s-cloud-download h5"></i>
                          <span class="aligned-with-icon h6">
                            Driving license
                          </span>
                        </a>
                      </p>
                    </div>

                    <div class="row" *ngIf="valuerDetails?.nationalIdImageFront">
                      <p>
                        <a href="javascript:void(0)" (click)="viewAttachment(userDetails?.dlphoto)"
                          class="text-success bold-text">
                          <i class="vsm-icon pe-7s-cloud-download h5"></i>
                          <span class="aligned-with-icon h6">
                            Driving license
                          </span>
                        </a>
                      </p>
                    </div>

                    <div class="row" *ngIf="valuerDetails?.nationalIdImageFront">
                      <p>
                        <a href="javascript:void(0)" (click)="
                            viewAttachment(valuerDetails?.nationalIdImageFront)
                          " class="text-success bold-text">
                          <i class="vsm-icon pe-7s-cloud-download h5"></i>
                          <span class="aligned-with-icon h6"> ID - Front </span>
                        </a>
                      </p>
                    </div>
                    <div class="row" *ngIf="valuerDetails?.nationalIdImageBack">
                      <p>
                        <a href="javascript:void(0)" (click)="
                            viewAttachment(valuerDetails?.nationalIdImageBack)
                          " class="text-success bold-text">
                          <i class="vsm-icon pe-7s-cloud-download h5"></i>
                          <span class="aligned-with-icon h6"> ID - Back </span>
                        </a>
                      </p>
                    </div>

                    <div class="row" *ngIf="userDetails?.logbookphoto">
                      <p>
                        <a href="javascript:void(0)" (click)="viewAttachment(userDetails?.logbookphoto)"
                          class="text-success bold-text">
                          <i class="vsm-icon pe-7s-cloud-download h5"></i>
                          <span class="aligned-with-icon h6"> Log Book </span>
                        </a>
                      </p>
                    </div>

                    <div class="row" *ngIf="valuerDetails?.businessCertImage">
                      <p>
                        <a href="javascript:void(0)" (click)="
                            viewAttachment(valuerDetails?.businessCertImage)
                          " class="text-success bold-text">
                          <i class="vsm-icon pe-7s-cloud-download h5"></i>
                          <span class="aligned-with-icon h6">
                            Business Certificate
                          </span>
                        </a>
                      </p>
                    </div>

                    <div class="row" *ngIf="valuerDetails?.copImage">
                      <p>
                        <a href="javascript:void(0)" (click)="viewAttachment(valuerDetails?.copImage)"
                          class="text-success bold-text">
                          <i class="vsm-icon pe-7s-cloud-download h5"></i>
                          <span class="aligned-with-icon h6"> COP Image </span>
                        </a>
                      </p>
                    </div>

                    <div class="row" *ngIf="assessorDetails?.principleProfile?.passportPhoto">
                      <p>
                        <a href="javascript:void(0)" (click)="
                            viewAttachment(
                              assessorDetails?.principleProfile?.passportPhoto
                            )
                          " class="text-success bold-text">
                          <i class="vsm-icon pe-7s-cloud-download h5"></i>
                          <span class="aligned-with-icon h6">
                            Passport Image
                          </span>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card section2" *ngIf="appService.isAdmin()">
          <div class="card-body">
            <app-user-actions [userDetails]="userDetails" (emitReloadUserDetails)="getUserDetailsByFeduid()">
            </app-user-actions>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Referrals" *ngIf="appService.hasPermission('admin-view-client-details-user-referrals')">
    <div class="card">
      <div class="card-body col-md-12">
        <h4 class="card-title text-uppercase mb-0 text-mtek d-inline">
          Referrals List
        </h4>
        <div class="row mt-2">
          <div class="col-md-12">
            <app-search [searchParams]="searchParams" (searchResults)="showSearchResults($event)"
              (InputEmpty)="resetData()"></app-search>
          </div>
        </div>
        <app-referrals-list [referralDetails]="referralDetails"></app-referrals-list>

        <app-pagination *ngIf="!isSearchData" [skip]="bPpagination.skip" [limit]="bPpagination.limit"
          [dataLength]="dataLength" [dataLoading]="dataLoading" (newSkip)="updateSkip($event)"></app-pagination>

        <app-pagination *ngIf="isSearchData" [skip]="pagination.skip" [limit]="pagination.limit"
          [dataLength]="dataLength" [dataLoading]="dataLoading" (newSkip)="updateSearchSkip($event)"></app-pagination>

        <div class="col-md-12 text-center">
          <app-loader *ngIf="allDataLoaded == false"></app-loader>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Wallet" *ngIf="appService.hasPermission('admin-view-client-details-user-wallet')">
    <app-client-wallet [feduid]="feduid"></app-client-wallet>
  </mat-tab>
</mat-tab-group>