export enum AppEnums {
  ToastTypeSuccess = 'success',
  ToastTypeInfo = 'info',
  ToastTypeError = 'error',
  ToastTypeWarning = 'warning',
  RoleAdmin = 'admin',
  RoleClient = 'client',
  RoleSalesAgent = 'salesagent',
  RoleLeadGenerator = 'leadgenerator',
  RolePartner = 'partner',
  RoleAssessor = 'assessor',
  RoleAgent = 'agent',
  RoleValuer = 'valuer',
  RolePrimePartner = 'primepartner',
  RoleConsultant = 'consultant',
  PartnerIdMUAKenya = 'mua-ke-14ac2-dc46-4edd-ae6c-cd0b4f4ae832',
  PartnerIdGA = 'ga-dbbcd171-aa7c-4405-ad9c-4f8ab9ee0003',
  PartnerIdMTEK = 'mtek-dbbcd171-aa7c-4405-ad9c-4f8ab9ee0007',
  PartnerIdMayfair = 'mayfair-dbbcd171-aa7c-4405-ad9c-4f8ab9ee0001',
  PartnerIdICEA = 'icea-ke-d171-aa7c-4405-ad9c-4f8ab9ee0023',
  PartnerIdMadison = 'madison-d171-aa7c-4405-ad9c-4f8ab9ee0034',
  PartnerIdJubilee = 'jubilee-d171-aa7c-4405-ad9c-4f8ab9ee0040',
  PartnerIdMonarch = 'monarch-ke-d171-aa7c-4405-ad9c-4f8ab9ee0025',
  PartnerIdResolution = 'resolution-d171-aa7c-4405-ad9c-4f8ab9ee0035',
  PartnerIdKorient = 'korient-d171-aa7c-4405-ad9c-4f8ab9ee0039',
  PartnerIdFirstAssurance = 'fassurance-d171-aa7c-4405-ad9c-4f8ab9ee0036',
  PartnerIdPioneer = 'pioneer-d171-aa7c-4405-ad9c-4f8ab9ee0054',
  PartnerIdBritam = 'britam-d171-aa7c-4405-ad9c-4f8ab9ee0036',
  PartnerIdOccidental = 'occidental-d171-aa7c-4405-ad9c-4f8ab9ee0037',
  PartnerIdCic = 'cic-d174-la7c-4t05-ad9c-al8ab9ee0044',
  MTEK_GUUID = 'mtek-dbbcd171-aa7c-4405-ad9c-4f8ab9ee0007',
  PDF = 'PDF',
  HTML = 'HTML',
  SVG = 'SVG',
  AddunderwriterProduct = 'AddunderwriterProduct',
  MXPNL_LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  MXPNL_LOGIN_FAILED = 'LOGIN_FAILED',
  MXPNL_FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  MXPNL_CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  MXPNL_LOGOUT = 'LOGOUT',
  MXPNL_ANONYMOUS_UID = '_ANONYMOUS_UID_',
  MTEK_PAYBILL_NUMBER = '4069593',
  PRE_ISSUED_POLICY_NUMBER = 'PRE_ISSUED_POLICY_NUMBER',
  PRODUCT_MOTOR = 'motor',
  PRODUCT_MEDICAL = 'medical',
  PRODUCT_HEALTH = 'health',
  PRODUCT_TRAVEL = 'travel',
  PRODUCT_PA = 'personalaccident',
  PRODUCT_GOLF = 'golf',
  PRODUCT_DOMESTIC = 'domestic',
  PRODUCT_EVACUATION = 'evacuation',
  PRODUCT_LASTEXPENSE = 'lastexpense',
  PRODUCT_TELEMEDICINE = 'telemedicine',
  PRODUCT_OTHER = 'other',
  PRODUCT_GADGET = 'gadget',
  PartnerTrustgro = 'trustgro-e1de-4920-89b2-a317f7628c4a',
  PartnerLuminous = 'luminous_matatu_sacco-49e9-b9ac-f3c9e3ff52a1',
  PartnerEmbassava = 'embassava_matatu_sacco-4dfa-8182-538b5aa204ee',
  PartnerAstrabel = 'astrabel_matatu_sacco-4520-9c82-e1c38f0a8e68',
  PartnerKyosk = 'kyosk-fdcf-43c5-b0a2-9e0ad6c2027b',
  PartnerkCB = 'kcb-6584-499f-b2a7-419c72398543',
}
