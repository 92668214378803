<app-date-range-picker-dashboard (dateRange)="updateRange($event)"></app-date-range-picker-dashboard>
<h4>{{selectedDateRange.start_date | date: 'fullDate'}} to {{selectedDateRange.end_date | date:'fullDate'}}</h4>
<div class="row stats-container" *ngIf="usersList.length > 0">
  <!-- clients count-->
  <div class="mr-2" *ngFor="let statistic of usersList">
    <div class="card card-stats stats-2" (click)="goToUsers(statistic?.category)">
      <div class="card-body">
        <div class="row rw-stat">
          <div class="col-5 col-md-4">
            <div class="icon-big text-center icon-danger">
              <i class="fa fa-users text-mtek"></i>
            </div>
          </div>
          <div class="col-7 col-md-8">
            <div class="n">
              <h5 class="card-title text-uppercase text-muted mb-0">
                {{ statistic?.displayName | uppercase }}
              </h5>
              <span class="h2 mb-0 text-mtek">{{ statistic?.value }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loader *ngIf="usersLoading"></app-loader>
<div *ngIf="usersList.length === 0 && usersLoading === false" class="alert alert-warning" role="alert">
  No new users for the selected period. Please try another date range!
</div>