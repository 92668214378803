<div class="card p-4" *ngIf="commissionShare">
  <h4 class="card-title text-uppercase mb-0 text-mtek">Commissions Share</h4>
  <div class="row mt-1">
    <div class="col-lg-4 card-cont">
      <div class="card tot-card-wrapper card-wrapper">
        <div class="icon-big icon-warning">
          <i class="vsm-icon pe-7s-credit text-mtek"></i>
        </div>
        <div class="text-center texts-container">
          <h5 class="card-title text-uppercase text-mtek mb-0">mTek</h5>
          <span *ngIf="!isLoading && commissionShare?.mtekCommission; else isNa" class="h5 mb-0">{{
            commissionShare?.mtekCommission | currency : 'Ksh. ' }}</span>
          <app-loader *ngIf="isLoading"></app-loader>
        </div>
      </div>
    </div>
    <div class="col-lg-4 card-cont">
      <div class="card tot-card-wrapper card-wrapper">
        <div class="icon-big icon-warning">
          <i class="vsm-icon pe-7s-credit text-mtek"></i>
        </div>
        <div class="text-center texts-container">
          <h5 class="card-title text-uppercase text-mtek mb-0">Patapesa</h5>
          <span *ngIf="!isLoading && commissionShare?.patapesaCommission; else isNa" class="h5 mb-0">{{
            commissionShare?.patapesaCommission | currency : 'Ksh. '
            }}</span>
          <app-loader *ngIf="isLoading"></app-loader>
        </div>
      </div>
    </div>
    <div class="col-lg-4 card-cont">
      <div class="card tot-card-wrapper card-wrapper">
        <div class="icon-big icon-warning">
          <i class="vsm-icon pe-7s-credit text-mtek"></i>
        </div>
        <div class="text-center texts-container">
          <h5 class="card-title text-uppercase text-mtek mb-0">
            County Representative
          </h5>
          <span *ngIf="
              !isLoading && commissionShare?.countyRepCommission;
              else isNa
            " class="h5 mb-0">{{
            commissionShare?.countyRepCommission | currency : 'Ksh. '
            }}</span>
          <app-loader *ngIf="isLoading"></app-loader>
        </div>
      </div>
    </div>
    <div class="col-lg-4 card-cont">
      <div class="card tot-card-wrapper card-wrapper">
        <div class="icon-big icon-warning">
          <i class="vsm-icon pe-7s-credit text-mtek"></i>
        </div>
        <div class="text-center texts-container">
          <h5 class="card-title text-uppercase text-mtek mb-0">Embedded</h5>
          <span *ngIf="!isLoading && commissionShare?.embeddedCommission; else isNa" class="h5 mb-0">{{
            commissionShare?.embeddedCommission | currency : 'Ksh. '
            }}</span>
          <app-loader *ngIf="isLoading"></app-loader>
        </div>
      </div>
    </div>
    <div class="col-lg-4 card-cont">
      <div class="card tot-card-wrapper card-wrapper">
        <div class="icon-big icon-warning">
          <i class="vsm-icon pe-7s-credit text-mtek"></i>
        </div>
        <div class="text-center texts-container">
          <h5 class="card-title text-uppercase text-mtek mb-0">Consultant</h5>
          <span *ngIf="
              !isLoading && commissionShare?.consultantCommission;
              else isNa
            " class="h5 mb-0">{{
            commissionShare?.consultantCommission | currency : 'Ksh. '
            }}</span>
          <app-loader *ngIf="isLoading"></app-loader>
        </div>
      </div>
    </div>
    <div class="col-lg-4 card-cont">
      <div class="card tot-card-wrapper card-wrapper">
        <div class="icon-big icon-warning">
          <i class="vsm-icon pe-7s-credit text-mtek"></i>
        </div>
        <div class="text-center texts-container">
          <h5 class="card-title text-uppercase text-mtek mb-0">
            Prime Partner
          </h5>
          <span *ngIf="
              !isLoading && commissionShare?.primePartnerCommission;
              else isNa
            " class="h5 mb-0">{{
            commissionShare?.primePartnerCommission | currency : 'Ksh. '
            }}</span>
          <app-loader *ngIf="isLoading"></app-loader>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <div class="row mt-1" *ngIf="commissionShare?.expectedCommission">
    <div class="col-md-3">
      <p class="h4 text-center">
        {{ commissionShare?.expectedCommission | currency : ' ' }}
        <small class="h6 d-block text-muted">Gross Commission</small>
      </p>
    </div>
    <div class="col-md-3">
      <p class="h4 text-center">
        {{ commissionShare?.whtTax | currency : ' ' }}
        <small class="h6 d-block text-muted">Tax (WHT)</small>
      </p>
    </div>
    <div class="col-md-3">
      <p class="h4 text-center">
        {{ commissionShare?.totalCommission | currency : ' ' }}
        <small class="h6 d-block text-muted">Expected Commission</small>
      </p>
    </div>
    <div class="col-md-3">
      <p class="h4 text-center">
        {{
        commissionShare?.totalCommission - totalPaidCommission
        | currency : ' '
        }}
        <small class="h6 d-block text-muted">Commission Balance</small>
      </p>
    </div>
  </div>

  <div class="row mt-1" *ngIf="commissionPayments.length > 0">
    <div class="col-md-12">
      <div class="table-responsive-sm">
        <table class="table table-striped table-responsive-sm">
          <thead>
            <tr>
              <th scope="col">Posted</th>
              <th scope="col">Amount</th>
              <th scope="col">Reference</th>
              <th scope="col">Received</th>
              <th scope="col">Mode</th>
              <th scope="col">Posted By</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let commissionPayment of commissionPayments">
              <td scope="col">
                {{ commissionPayment?._timestamp | date : 'mediumDate' }}
              </td>
              <td scope="col">
                {{ commissionPayment?.paid | currency : ' ' }}
              </td>
              <td scope="col">{{ commissionPayment?.reference }}</td>
              <td scope="col">{{ commissionPayment?.datePaid | date : 'mediumDate' }}</td>
              <td scope="col">{{ commissionPayment?.paymentMode }}</td>
              <td scope="col">{{ commissionPayment?.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <ng-template #isNa>
    <span>0.00</span>
  </ng-template>
</div>