import { Injectable } from '@angular/core';
import { DomesticListDetailsComponent } from '../domestic-policy/domestic-list-details/domestic-list-details.component';
import { DomesticListComponent } from '../domestic-policy/domestic-list/domestic-list.component';
import { DomesticPolicyComponent } from '../domestic-policy/domestic-policy.component';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  getMenu(): Array<any> {
    const menu = [
      {
        path: '',
        component: DomesticPolicyComponent,
        children: [
          { path: '', component: DomesticListComponent },
          { path: 'list', component: DomesticListComponent },
          {
            path: 'list/domestic-details/:_id',
            component: DomesticListDetailsComponent,
          },
        ],
      },
    ];

    return menu;
  }
}
