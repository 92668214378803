import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AppEnums } from 'src/app/enums/app.enum';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-generic-search',
  templateUrl: './generic-search.component.html',
  styleUrls: ['./generic-search.component.scss'],
})
export class GenericSearchComponent implements OnInit {
  @Input() searchParams;
  @Output() searchResults = new EventEmitter<string>();
  @Output() InputEmpty = new EventEmitter<boolean>();
  searchForm: FormGroup;
  optionalForm: FormGroup;
  isSearching: boolean = false;
  searchQuery: {} = {};

  name = new FormControl('');

  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService
  ) {
    this.searchForm = this.formBuilder.group({
      inputItem: ['', [Validators.required]],
    });
    this.optionalForm = this.formBuilder.group({
      name: [''],
    });
  }

  ngOnInit(): void {}
  search(searchParams: any) {
    this.isSearching = true;
    const { inputItem } = this.searchForm.value;

    let cleanInputItem: any = inputItem;

    if (inputItem == 'true') {
      cleanInputItem = true;
    }

    if (inputItem == 'false') {
      cleanInputItem = false;
    }

    if (!inputItem) {
      this.isSearching = false;
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        'Missing Input',
        'Please enter your search item!'
      );
      return;
    }

    const fields = searchParams.searchFields.map((item) => {
      if (this.optionalForm.value.name == 'salesAgentProfile.countyCode') {
        return { [item.value]: cleanInputItem };
      }
      return { [item.value]: { $regex: cleanInputItem, $options: 'i' } };
    });
    if (searchParams.customQuery.$or) {
      let andQuery = {
        $and: [{ $or: fields }, { $or: searchParams.customQuery.$or }],
      };

      delete searchParams.customQuery.$or;
      this.searchQuery = {
        ...andQuery,
        ...searchParams.customQuery,
      };
    } else {
      this.searchQuery = {
        $or: fields,
        ...searchParams.customQuery,
      };
    }

    try {
      this.appService
        .makeSearchRequest(searchParams.url, {
          ...this.searchQuery,
        })
        .subscribe(
          (apiResponse) => {
            this.isSearching = false;
            apiResponse.Status === 200
              ? apiResponse.Payload.length === 0
                ? (this.appService.showToastMessage(
                    AppEnums.ToastTypeWarning,
                    'No results!',
                    'No results found. Try searching other options'
                  ),
                  this.searchResults.emit(apiResponse.Payload))
                : this.searchResults.emit(apiResponse.Payload)
              : this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  'Something went wrong',
                  'Failed to get search results. Please try again'
                );
          },
          (err) => {
            this.isSearching = false;
            this.appService.showToastMessage(
              AppEnums.ToastTypeError,
              '',
              this.appService.processHttpErrors(err)
            );
          }
        );
    } catch (ex) {
      this.isSearching = false;
      this.appService.showToastMessage(
        AppEnums.ToastTypeError,
        'Something went wrong',
        'Please try again'
      );
      throw new Error(ex.toString);
    }
  }

  onInputChange() {
    const { inputItem } = this.searchForm.value;
    if (!inputItem) {
      this.InputEmpty.emit();
    }
  }
}
