<h1 mat-dialog-title>Download Report</h1>
<div mat-dialog-content>
  <form [formGroup]="formGroupDownloadReport" (ngSubmit)="submitInfo()">
    <app-loader *ngIf="isLoading"></app-loader>
    <mat-form-field *ngIf="appService.isAdmin()">
      <mat-label>Select Underwriter</mat-label>
      <mat-select formControlName="insurer">
        <mat-option value="all">All Underwriters</mat-option>
        <mat-option
          *ngFor="let uw of appService.getMotorUnderwriters | async"
          [value]="uw.insurer"
        >
          {{ uw.underwriter }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Select Report</mat-label>
      <mat-select formControlName="reportType">
        <mat-option *ngFor="let type of types" [value]="type.value">
          {{ type.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        [matDatepicker]="startDatePicker"
        placeholder="Start Date"
        formControlName="startDate"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="startDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        [matDatepicker]="endDatePicker"
        placeholder="End Date"
        formControlName="endDate"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="endDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button color="primary" (click)="submitInfo()">
    Download Report
  </button>
</div>
