<div>
  <div class="card mt-3">
    <div class="card-body">
      <div *ngIf="creditRatingResult; else noCreditRatingResult">
        <div class="d-flex justify-content-between">
          <h2 class="text-mtek col align-self-start">
            <i class="fa fa-users text-mtek"></i> Credit Rating Attempts
          </h2>
          <div class="d-flex justify-content-end">
            <button class="col align-self-end" (click)="popSaveCredingRating()" class="btn btn-outline-info mb-3 me-5">
              <i class="vsm-icon pe-7s-pen h6"></i> Review Rating
            </button>
          </div>
        </div>

        <div class="col-md-12 text-center">
          <app-loader *ngIf="isLoading"></app-loader>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="table-responsive-sm">
              <table class="table table-striped table-responsive-sm">
                <tr>
                  <th scope="col">ID No.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Net Income</th>
                  <th scope="col">Other Obligations</th>
                  <th scope="col">Expense</th>
                  <th scope="col">Description</th>
                  <th scope="col">Source</th>
                  <th scope="col">Live</th>
                </tr>

                <tbody *ngFor="let result of creditRatingResult">
                  <tr class="section-step">
                    <td class="p-3">{{ result.client.id_number }}</td>
                    <td class="p-3">
                      <small class="d-block">{{ result.client.name }}</small>
                    </td>
                    <td class="p-3">
                      <small class="d-block">{{ result.client.email }}</small>
                    </td>
                    <td class="p-3">
                      <small class="d-block">
                        {{ result.net_income | currency: 'Ksh. ' }}</small>
                    </td>
                    <td class="p-3">
                      <small class="d-block">{{
                        result.other_obligations
                        | currency: 'Ksh.
                        '
                        }}</small>
                    </td>
                    <td class="p-3">
                      <small class="d-block">
                        {{
                        result.total_expense
                        | currency: 'Ksh.
                        '
                        }}</small>
                    </td>
                    <td class="p-3">
                      <small class="d-block">{{
                        result.trustgro_response.description
                        }}</small>
                    </td>
                    <td class="p-3">
                      <small class="d-block">{{
                        result.trustgro_response.source
                        }}</small>
                    </td>
                    <td class="p-3">
                      <small class="d-block">{{
                        result.trustgro_response.live
                        }}</small>
                    </td>
                  </tr>
                </tbody>
              </table>
              <app-pagination [skip]="pagination.skip" [limit]="pagination.limit" [dataLength]="dataLength"
                [dataLoading]="isLoading" (newSkip)="updateSkip($event)"></app-pagination>
            </div>
          </div>

          <!-- <div class="col-md-3">
            <div class="card" *ngFor="let result of creditRatingResult">
              <div class="card-body">
                <h2 class="card-title text-mtek">Trusgro Response</h2>
                <hr />
                <p>
                  <b>Description -</b>
                  {{ result?.trustgro_response?.description }}
                </p>
                <p>
                  <b>Affordability -</b>
                  {{
                    result?.trustgro_response?.affordability | currency: 'Ksh. '
                  }}
                </p>
                <p>
                  <b>Score -</b>
                  {{ result?.trustgro_response?.score }}
                </p>
              </div>
            </div>
          </div> -->
        </div>
        <!-- <ng-template #noRecords>
        <div class="alert alert-warning" role="alert">
          There Are No Credit Rating Records For This client?.
        </div>
      </ng-template> -->
      </div>
      <ng-template #noCreditRatingResult>
        <div class="alert alert-warning" role="alert">
          There Are No Credit Rating Records For Quote.
        </div>
      </ng-template>
    </div>
  </div>

  <div class="card mt-3">
    <div class="card-body">
      <div *ngIf="creditingReviews.length > 0; else noCreditingReviews">
        <h2 class="text-mtek col align-self-start">
          <i class="fa fa-users text-mtek"></i> Credit Rating Reviews
        </h2>
        <div class="table-responsive-sm">
          <table class="table table-striped table-responsive-sm">
            <tr>
              <th scope="col">Date</th>
              <th scope="col">ID Number</th>
              <th scope="col">Name & Email</th>
              <th scope="col">Affordability</th>
              <th scope="col">Used</th>
              <th scope="col">Description</th>
              <th scope="col">Review By</th>
              <th scope="col"></th>
            </tr>
            <tbody>
              <tr class="section-step" *ngFor="let acreditingReviews of creditingReviews">
                <td class="p-3">
                  {{ acreditingReviews?._timestamp | date: 'medium' }}
                </td>
                <td class="p-3">{{ acreditingReviews?.id_number }}</td>
                <td class="p-3">
                  <small class="d-block">{{ acreditingReviews?.first_name }}
                    {{ acreditingReviews?.last_name }}</small>
                  <small class="d-block">{{ acreditingReviews?.email }}</small>
                </td>

                <td class="p-3">
                  {{ acreditingReviews?.affordability | currency: 'Ksh. ' }}
                </td>
                <td class="p-3">
                  <span *ngIf="acreditingReviews?.used; else notUsed">YES</span>
                  <ng-template #notUsed>NO</ng-template>
                </td>
                <td class="p-3">
                  {{ acreditingReviews?.description }}
                </td>
                <td class="p-3">
                  {{ acreditingReviews?.client?.name }}
                </td>

                <td class="p-3">
                  <button *ngIf="acreditingReviews?.used === false"
                    (click)="popDeleteCredingRating(acreditingReviews?._id)" class="btn btn-sm btn-outline-danger">
                    <i class="vsm-icon pe-7s-trash"></i> Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination [skip]="pagination.skip" [limit]="pagination.limit" [dataLength]="dataLength"
            [dataLoading]="isLoading" (newSkip)="updateSkip($event)"></app-pagination>
        </div>
      </div>
      <ng-template #noCreditingReviews>
        <div class="alert alert-warning" role="alert">
          There Are No Credit Rating Reviews For Quote.
        </div>
      </ng-template>
    </div>
  </div>
</div>