import { Component, Input, OnInit } from '@angular/core';
import { IPayment } from 'src/app/models/payment.interface';
import { AppService } from 'src/app/services/app.service';
import { IPaymentdetail } from '../payment-plan-partial/payment-plan-partial.component';

@Component({
  selector: 'app-repayment-status',
  templateUrl: './repayment-status.component.html',
  styleUrls: ['./repayment-status.component.scss'],
})
export class RepaymentStatusComponent implements OnInit {
  paymentLogs: IPayment[] = [];
  @Input() selectedRecordId: string;
  @Input() paymentdetails: IPaymentdetail;
  totalPaid: number = 0;
  totalPayable: number = 0;

  constructor(public appService: AppService) {}
  initiatePage() {
    if (this.paymentdetails.hasOwnProperty('payable')) {
      this.totalPayable = this.paymentdetails?.payable;
    } else {
      this.totalPayable = this.paymentdetails?.totalPayable;
    }
    this.appService
      .getPaymentsLogByQuoteId(this.selectedRecordId)
      .subscribe((res) => {
        this.paymentLogs = res;
        this.paymentLogs.forEach((aPayment) => {
          if (aPayment.paidAmount > 0) {
            this.totalPaid += aPayment.paidAmount;
          } else {
            this.totalPaid += aPayment.amount;
          }
        });
      });
  }

  ngOnInit(): void {
    this.initiatePage();
  }
}
