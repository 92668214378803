<div class="card">
  <div class="card-body">
    <div class="table-responsive-sm">
      <table class="table table-striped table-responsive-sm">
        <tr>
          <th scope="col">System Date</th>
          <th scope="col">Sales Date</th>
          <th scope="col">Client</th>
          <th scope="col">Premium Amount</th>
          <th scope="col">Insurer</th>
          <th scope="col">Policy</th>
          <th scope="col">Details</th>
        </tr>

        <tbody>
          <tr *ngFor="let policy of policies" (click)="goToPolicyDetails(policy._id)" class="section-step"
            [ngStyle]="highlightCancelledPolicies(policy)">
            <td class="p-3">
              <small *ngIf="policy?._utimestamp">
                <span class="bold-text"> Updated On: </span>
                {{ policy?._utimestamp | date: 'medium' }}
              </small>
              <br />
              <small>
                <span class="bold-text"> Created On: </span>
                {{ policy?._timestamp | date: 'medium' }}
              </small>
            </td>
            <td class="p-3">
              <small *ngIf="policy?.salesDateTimestamp; else noSalesDate">
                {{ policy?.salesDateTimestamp | date: 'medium' }}
              </small>
              <ng-template #noSalesDate>
                <small>NA</small>
              </ng-template>
              <br />
            </td>
            <td class="p-3">
              <small>
                <span *ngIf="
                    policy?.questions?.moreQuestions?.company?.name ||
                      policy?.questions?.moreQuestions?.client?.userName;
                    else isAppUserName
                  ">
                  {{
                  policy?.questions?.moreQuestions?.company?.name ||
                  policy?.questions?.moreQuestions?.client?.userName
                  }}
                </span>
                <ng-template #isAppUserName>
                  <span *ngIf="policy?.client?.name; else isUserName">
                    {{ policy?.client?.name }}
                  </span>
                  <ng-template #isUserName>
                    {{ policy?.client?.userName }}
                  </ng-template>
                </ng-template>

                <br />
                <span *ngIf="
                    policy?.questions?.moreQuestions?.company?.phoneNumber ||
                      policy?.questions?.moreQuestions?.client?.phoneNumber;
                    else isAppUserPhone
                  ">
                  {{
                  policy?.questions?.moreQuestions?.company?.phoneNumber ||
                  policy?.questions?.moreQuestions?.client?.phoneNumber
                  }}
                </span>
                <ng-template #isAppUserPhone>
                  {{ policy?.client?.phoneNumber }} {{ policy?.client?.phone }}
                </ng-template>
              </small>
            </td>

            <td class="p-3">
              <small>
                {{ policy?.amount | currency: 'Ksh. ' }}
              </small>
            </td>
            <td class="p-3">
              <small>
                {{ policy?.insurer | underwriter | async }}
              </small>
            </td>
            <td class="p-3">
              <small>
                {{ policy?.sme | smePolicyName: "Domestic"}}
              </small>
            </td>
            <td class="p-3">
              <i class="vsm-icon pe-7s-angle-right"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>