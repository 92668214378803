import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AppEnums } from "src/app/enums/app.enum";
import { AppService } from "src/app/services/app.service";
import { environment } from "src/environments/environment";
import { SaveCredingRatingComponent } from "../save-creding-rating/save-creding-rating.component";
import { DeleteCreditRatingComponent } from "../delete-credit-rating/delete-credit-rating.component";
import { IClient } from "src/app/models/client.interface";
import { IPagination } from "src/app/models/pagination.interface";
export interface ICreditRatingResult {
  _id: string;
  id_number: string;
  first_name: string;
  last_name: string;
  net_income: number;
  other_obligations: number;
  total_expense: number;
  middle_name: string;
  qid: string;
  cover: string;
  trustgro_response: {
    has_error: boolean;
    affordability: number;
    score: boolean;
    description: string;
    source: string;
    live: boolean;
  };
  _date: any;
  _timestamp: number;
  clientid: string;
  feduid: string;
  agentfeduid: string;
  agencyid: string;
  client: IClient;
  _udate: any;
  _utimestamp: number;
}
@Component({
  selector: "app-credit-rating",
  templateUrl: "./credit-rating.component.html",
  styleUrls: ["./credit-rating.component.scss"],
})
export class CreditRatingComponent implements OnInit {
  client: IClient;
  isLoading: boolean = false;
  score: boolean = true;
  creditingReviews: any[] = [];
  @Input() qid: string;
  creditRatingResult: ICreditRatingResult[];
  dataLength: number = 0;
  pagination: IPagination = {
    limit: 10,
    skip: 0,
    sort: {
      _timestamp: -1,
    },
  };

  constructor(private appService: AppService, public dialog: MatDialog) {}

  updateSkip(newSkip) {
    if (newSkip) {
      this.pagination.skip = newSkip;
      this.getCreditRatingByQuoteId(this.pagination);
    }
  }

  getCreditRatingByQuoteId(pagination: IPagination) {
    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.CORE_API_URL}/getcreditratingresult`, {
        qid: this.qid,
        pagination,
      })
      .subscribe(
        (res) => {
          this.isLoading = false;
          if (res.Status === 200) {
            if (res.Payload.length > 0) {
              this.creditRatingResult = res.Payload;
              this.dataLength = this.creditRatingResult.length;
              this.getListCreditingReview(this.creditRatingResult[0].id_number);
            }
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              "Something went wrong",
              "Unable to get the creding review list"
            );
          }
        },
        (error) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            "",
            this.appService.processHttpErrors(error)
          );
        }
      );
  }
  popSaveCredingRating() {
    let dialogRef = this.dialog.open(SaveCredingRatingComponent, {
      width: "50%",
      data: this.creditRatingResult[0],
      disableClose: false,
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.getListCreditingReview(this.creditRatingResult[0].id_number);
    });
  }

  popDeleteCredingRating(_id: string) {
    let dialogRef = this.dialog.open(DeleteCreditRatingComponent, {
      width: "50%",
      data: {
        _id: _id,
      },
      disableClose: false,
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.getListCreditingReview(this.creditRatingResult[0].id_number);
    });
  }

  getListCreditingReview(id_number: string) {
    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.CORE_API_URL}/listcreditratingreview`, {
        id_number: id_number,
        pagination: this.pagination,
      })
      .subscribe(
        (res) => {
          this.isLoading = false;
          if (res.Status === 200) {
            this.creditingReviews = res.Payload;
            this.dataLength = this.creditingReviews.length;
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              "Something went wrong",
              "Unable to get the creding review list"
            );
          }
        },
        (error) => {
          this.isLoading = false;
          this.appService.showToastMessage(AppEnums.ToastTypeError, "", error);
        }
      );
  }

  ngOnInit(): void {
    this.getCreditRatingByQuoteId(this.pagination);
  }
}
