import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AppEnums } from '../enums/app.enum';
import { ApiResponse, dataApiResponse } from '../models/api-response.interface';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { IUser } from '../models/user.interface';
import { IRole } from '../models/role.interface';
import { environment } from 'src/environments/environment';
import { INotificationPagination } from '../models/pagination.interface';
import { ICommissionStatement } from '../models/commission-statement.interface';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { IUnderwriter } from '../models/underwriter.interface';
import { IThirdParty } from '../models/third-parties.interface';
import { ISendNotification } from '../models/send-notification.interface';
import { EProduct } from '../enums/items.enum';

import { IPromoProduct } from 'src/app/models/promo-product.interface';
import { Router } from '@angular/router';
import { IRbacRole } from '../models/rbac-roles.interface';
import * as mixpanel from 'mixpanel-browser';
import { Apollo } from 'apollo-angular';
import { IMotorPolicyNumber } from '../models/motor-policy-number.interface';
import { IPayment } from '../models/payment.interface';
import { IValuer } from '../models/valuer.interface';
import { IDocumentType } from '../models/document-types.interface';
import { IUnderwriterContact } from '../models/underwriter-contact.interface';
import { ICounty } from '../models/counties.interface';
import { IAkiUnderwriter } from '../models/aki-underwriter.interface';
import * as XLSX from 'xlsx'

export interface IDashCard {
  displayName: string;
  category: string;
  value: number;
}

export interface INameValue {
  name: string;
  value: any;
}

export interface IdValue {
  id: string;
  value: any;
}
const CACHE_SIZE = 1;
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ModuleID: environment.ADMIN_MODULE_ID,
    apiKey: environment.ADMIN_API_KEY,
    SKEY: 'SKEY',
  }),
};
@Injectable({
  providedIn: 'root',
})
export class AppService {
  userData: Observable<firebase.User>;
  authUser: IUser;
  userStatisticsCache: IDashCard[] = [];
  sasTokenCache: string;
  underwriters: any;

  usersCache: IUser[] = [];

  private rbacRolesCache$: Observable<IRbacRole[]> | undefined;
  private underwritersCache$: Observable<IUnderwriter[]> | undefined;
  private akiUnderwritersCache$: Observable<IAkiUnderwriter[]> | undefined;
  private countiesCache$: Observable<ICounty[]> | undefined;
  private thirdPartiesCache$: Observable<IThirdParty[]> | undefined;
  private combinedUnderwritersThirdPartiesCache$:
    | Observable<IUnderwriter[]>
    | undefined;
  private documentType$: Observable<IDocumentType[]> | undefined;
  private policyNumberCache$: Observable<IMotorPolicyNumber[]> | undefined;

  constructor(
    private angularFireAuth: AngularFireAuth,
    private toastr: ToastrService,
    private http: HttpClient,
    private router: Router,
    private apollo: Apollo
  ) {
    this.userData = angularFireAuth.authState;
  }

  get collectionPrefixes(): string[] {
    return [
      'domestic',
      'evacuation',
      'golf',
      'life',
      'medical',
      'motor',
      'other_policies',
      'personalaccident',
      'travel',
      'unisure',
      //'lastexpense',
    ];
  }

  get getCurrentApplicationVersion() {
    return environment.appVersion;
  }

  fetchUnderwritercontacts(data: any): Observable<IUnderwriterContact[]> {
    return this.makePostRequest(
      `${environment.PREMIUM_API_URL}/getunderwritercontacts`,
      data
    ).pipe(
      map((res) => {
        return res.Payload;
      })
    );
  }

  get getVehicleUsages(): string[] {
    return [
      '',
      'PRIVATE',
      'COMMERCIAL',
      'PSV',
      'COMMERCIAL_OWN_GOODS',
      'COMMERCIAL_GENERAL_CARTAGE',
      'COMMERCIAL_HIRE',
      'PRIVATE_HIRE',
      'SELF_DRIVE_HIRE',
      'PRIME_MOVERS',
      'TANKERS',
      'TRACTORS',
    ];
  }

  get getPaymentChannels(): string[] {
    return [
      'M-PESA',
      'Bank Deposit',
      'Bank Transfer',
      'Cash',
      'Card',
      'Cheque',
      'Other',
      'Wallet',
    ];
  }

  getPolicyBaseUrl(cover: string): string {
    let url = '';
    switch (cover) {
      case 'motor':
        url = `${environment.MOTOR_API_URL}`;
        break;
      case 'medical':
        url = `${environment.MEDICAL_API_URL}`;
        break;
      case 'health':
        url = `${environment.MEDICAL_API_URL}`;
        break;
      case 'pa':
        url = `${environment.PA_API_URL}`;
        break;
      case 'personalaccident':
        url = `${environment.PA_API_URL}`;
        break;

      case 'travel':
        url = `${environment.TRAVEL_API_URL}`;
        break;
      case 'evacuation':
        url = `${environment.EVACUATION_API_URL}`;
        break;
      case 'domestic':
        url = `${environment.DOMESTIC_API_URL}`;
        break;
      case 'golf':
        url = `${environment.GOLF_API_URL}`;
        break;
      case 'life':
        url = `${environment.LIFE_API_URL}`;
        break;

      default:
        url = url = `${environment.PA_API_URL}`;
        break;
    }

    return url;
  }

  getPolicyUpdateUrl(cover: string): string {
    let url = '';
    switch (cover) {
      case 'motor':
        url = `${environment.MOTOR_API_URL}/adminupdatequote`;
        break;
      case 'medical':
        url = `${environment.MEDICAL_API_URL}/adminupdatequotes`;
        break;
      case 'health':
        url = `${environment.MEDICAL_API_URL}/adminupdatequotes`;
        break;
      case 'pa':
        url = `${environment.PA_API_URL}/adminupdatequote`;
        break;
      case 'personalaccident':
        url = `${environment.PA_API_URL}/adminupdatequote`;
        break;

      case 'travel':
        url = `${environment.TRAVEL_API_URL}/adminupdatequote`;
        break;
      case 'evacuation':
        url = `${environment.EVACUATION_API_URL}/adminupdatequote`;
        break;
      case 'domestic':
        url = `${environment.DOMESTIC_API_URL}/adminupdatequote`;
        break;
      case 'golf':
        url = `${environment.GOLF_API_URL}/adminupdatequote`;
        break;

      default:
        url = '';
        break;
    }

    return url;
  }

  loadActivePolicyNumbers(selectedRecord): Observable<IMotorPolicyNumber[]> {
    let activePolicyNumbers: IMotorPolicyNumber[] = [
      {
        _id: AppEnums.PRE_ISSUED_POLICY_NUMBER,
        insurer: selectedRecord?.insurer,
        vehicleType: null,
        coverType: null,
        vehicleUsage: null,
        consumed: false,
        policyNumber: AppEnums.PRE_ISSUED_POLICY_NUMBER,
        _date: '',
        _timestamp: 1635239856453,
      },
    ];
    //KORIENT WEEKLY LOGIC
    if (
      selectedRecord?.insurer === AppEnums.PartnerIdKorient &&
      selectedRecord?.policy.use === 'PRIVATE' &&
      selectedRecord?.policy.durationFlag === 'MONTH' &&
      selectedRecord?.policy.duration === 1 &&
      selectedRecord?.policy.policyType === 'THIRD_PARTY'
    ) {
      return this.getKorientWeeklyPolicyNumber.pipe(
        map((res) => {
          return activePolicyNumbers.concat(res);
        })
      );
    } else {
      let data = {
        $or: [{ consumed: false }, { reuse: true }],
        insurer: selectedRecord.insurer,
      };
      if (selectedRecord.motor.carType) {
        data['vehicleType'] = selectedRecord.motor.carType;
      }
      if (selectedRecord?.policy?.use) {
        data['vehicleUsage'] = selectedRecord.policy.use;
      }
      return this.getMotorPolicyNumbers(data).pipe(
        map((r) => {
          return activePolicyNumbers.concat(r);
        })
      );
    }
  }

  sendIceaRiskDeclaration(_id: string): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.MOTOR_API_URL}/declereicearisknote`,
      {
        _id: _id,
      }
    );
  }

  getApprovedValuersOfUnderwriter(insurer: string): Observable<IValuer[]> {
    let body = {
      fields: [
        'principleFeduid',
        'principle_id',
        'email',
        'name',
        'principleProfile.userName',
      ],
    };
    if (this.isPartner()) {
      body['underwriters'] = {
        $elemMatch: { id: this.getSessionUser().partner, approved: true },
      };
    } else {
      body['underwriters'] = {
        $elemMatch: { id: insurer, approved: true },
      };
    }

    return this.makePostRequest(
      `${environment.VALUATION_API_URL}/listvaluer`,
      body
    )
      .pipe(
        map((res) => {
          return res.Payload;
        })
      )
      .pipe(
        catchError((e) => {
          this.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.processHttpErrors(e)
          );
          return of([]);
        })
      );
  }

  notifyUnderwriterToApproveValuer(insurer: string, valuerId: string) {
    this.makePostRequest(
      `${environment.VALUATION_API_URL}/adminrequestunderwriterapproval`,
      {
        insurer: insurer,
        valuerid: valuerId,
      }
    ).subscribe(
      (res) => {
        if (res.Status === 200) {
          this.showToastMessage(
            AppEnums.ToastTypeSuccess,
            res.Message,
            'Aprroval request sent to underwriter'
          );
        } else {
          this.showToastMessage(AppEnums.ToastTypeWarning, '', res.Payload);
        }
      },
      (err) => {
        this.showToastMessage(
          AppEnums.ToastTypeError,
          '',
          this.processHttpErrors(err)
        );
      }
    );
  }

  notifyUnderwriterToApproveAssessor(insurer: string, assessorId: string) {
    this.makePostRequest(
      `${environment.CLAIMS_API_URL}/adminrequestunderwriterapproval`,
      {
        insurer: insurer,
        assessorid: assessorId,
      }
    ).subscribe(
      (res) => {
        if (res.Status === 200) {
          this.showToastMessage(
            AppEnums.ToastTypeSuccess,
            res.Message,
            'Aprroval request sent to underwriter'
          );
        } else {
          this.showToastMessage(AppEnums.ToastTypeWarning, '', res.Payload);
        }
      },
      (err) => {
        this.showToastMessage(
          AppEnums.ToastTypeError,
          '',
          this.processHttpErrors(err)
        );
      }
    );
  }

  notifyUnderwriterToApproveAgent(insurer: string, agencyid: string) {
    this.makePostRequest(
      `${environment.CORE_API_URL}/adminrequestunderwriterapproval`,
      {
        insurer: insurer,
        agencyid: agencyid,
      }
    ).subscribe(
      (res) => {
        if (res.Status === 200) {
          this.showToastMessage(
            AppEnums.ToastTypeSuccess,
            res.Message,
            'Aprroval request sent to underwriter'
          );
        } else {
          this.showToastMessage(AppEnums.ToastTypeWarning, '', res.Payload);
        }
      },
      (err) => {
        this.showToastMessage(
          AppEnums.ToastTypeError,
          '',
          this.processHttpErrors(err)
        );
      }
    );
  }

  getPaymentsLogByQuoteId(quoteId: string): Observable<IPayment[]> {
    return this.makePostRequest(
      `${environment.PAYMENTS_API_URL}/listpaymentlogs`,
      {
        received_underscore_id: quoteId,
        $or: [{ status: 'SUCCESS' }, { paid: true }],
        fields: [
          '_id',
          'service',
          'product',
          'amount',
          'insurer',
          'synced',
          'paid',
          'isFinanced',
          '_timestamp',
          'bimaRedemption',
          'details',
          'transactionType',
          'mtekPaymentRef',
          'mpesaPaybillNo',
          'isSettledToUnderwriter',
          '_utimestamp',
          'paidAmount',
          'broker',
        ],
      }
    ).pipe(
      map((res) => {
        return res.Payload;
      })
    );
  }

  getMotorPolicyNumbers(data): Observable<IMotorPolicyNumber[]> {
    return this.makePostRequest(
      `${environment.MOTOR_API_URL}/listpolicynoallocation`,
      data
    ).pipe(
      map((res) => {
        let r: IMotorPolicyNumber[] = res.Payload;
        if (Array.isArray(r)) {
          return r.sort((a: IMotorPolicyNumber, b: IMotorPolicyNumber) => {
            return a._timestamp - b._timestamp;
          });
        } else {
          return [];
        }
      })
    );
  }

  mixPanelInit(userToken: string): void {
    mixpanel.init(environment.MIXPANEL_PROJECT_TOKEN, {
      api_host: 'https://api.mixpanel.com',
    });
    mixpanel.identify(userToken);
  }

  mixpanelTrack(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }
  mixpanelSetProfile(profile: any = {}): void {
    mixpanel.people.set(profile);
  }

  get getCurrentTimeStamp() {
    return Date.now();
  }

  getTimestampFromDate(y: number, m: number, d: number): number {
    let newDate = new Date(y, m, d);
    return newDate.getTime();
  }

  formatDateSlashedDdMmYyyy(y: number, m: number, d: number): string {
    let mon = new String(m + 1).padStart(2, '0');
    let dd = new String(d).padStart(2, '0');
    return `${dd}/${mon}/${y}`;
  }

  formatDateAsMysqlDate(y: number, m: number, d: number) {
    let mon = new String(m + 1).padStart(2, '0');
    let dd = new String(d).padStart(2, '0');
    return `${y}-${mon}-${dd}`;
  }

  get getAuthedMenuFromCDN(): Observable<IUnderwriter> {
    return this.getAuthedUnderwriterAccount.pipe(
      map((res: IUnderwriter[]) => {
        if (this.isAdmin()) {
          return res[0];
        } else {
          let insurer = this.getSessionUser().insurer;
          return res.filter((unwrt) => {
            return unwrt.insurer === insurer;
          })[0];
        }
      })
    );
  }

  get getUnderwritersFromDB(): Observable<IUnderwriter[]> {
    return this.makePostRequest(
      `${environment.CORE_API_URL}/listInsuranceUnderwriters`,
      {}
    ).pipe(
      map((res) => {
        if (res.Status == 200) {
          let ut: IUnderwriter[] = res.Payload;
          return ut.sort((a, b) => a.name.localeCompare(b.name));
        } else {
          this.showToastMessage(AppEnums.ToastTypeError, '', res.Payload);
        }
      }),
      catchError((e) => {
        return throwError(e);
      })
    );
  }

  get getAuthedUnderwriterAccount(): Observable<IUnderwriter[]> {
    return this.getUnderwritersFromDB.pipe(
      map((res: IUnderwriter[]) => {
        if (this.isAdminOrThirdParty()) {
          return res;
        } else {
          let insurer = this.getSessionUser().insurer;
          let un = res.filter((unwrt) => {
            return unwrt._id === insurer;
          });
          return un;
        }
      })
    );
  }

  get getMotorUnderwriters(): Observable<IUnderwriter[]> {
    return this.getUnderwritersFromCdn.pipe(
      map((u) => {
        return u.filter((w) => {
          return (
            w.products.filter((p) => {
              return p.id == 'motor';
            }).length > 0
          );
        });
      })
    );
  }

  get getTelemedicineProviders(): Observable<IUnderwriter[]> {
    return this.getThirdPartiesFromCdn.pipe(
      map((u) => {
        return u.filter((w) => {
          return (
            w.products.filter((p) => {
              return p.id == 'telemedicine';
            }).length > 0
          );
        });
      })
    );
  }

  get getLifeUnderwritters(): Observable<IUnderwriter[]> {
    return this.getUnderwritersFromCdn.pipe(
      map((u) => {
        return u.filter((w) => {
          return (
            w.products.filter((p) => {
              return p.id == 'life';
            }).length > 0
          );
        });
      })
    );
  }

  get getKorientWeeklyPolicyNumber(): Observable<IMotorPolicyNumber[]> {
    let httpOpts = {
      headers: new HttpHeaders({
        Accept: '',
      }),
    };
    return this.http
      .get(`${environment.CDN_BASE_URL}/korientweekly.json`, httpOpts)
      .pipe(
        map((res: IMotorPolicyNumber[]) => {
          return res;
        })
      )
      .pipe(shareReplay(CACHE_SIZE));
  }

  get getCombinedUnderwriterThirdpartyFromCdn(): Observable<IUnderwriter[]> {
    if (!this.combinedUnderwritersThirdPartiesCache$) {
      this.combinedUnderwritersThirdPartiesCache$ = forkJoin({
        underwriters: this.getUnderwritersFromCdn,
        thirdParties: this.getThirdPartiesFromCdn,
      })
        .pipe(
          map((res) => {
            let combined: IUnderwriter[] = res.underwriters;
            let tps: IThirdParty[] = res.thirdParties;
            tps.forEach((tp) => {
              combined.push({
                logoUrl: tp.logoUrl,
                underwriter: tp.name,
                insurer: tp.thirdPartyId,
                products: tp.products,
              });
            });
            return [...new Set(combined.map((tp) => tp))].sort((a, b) =>
              a.underwriter.localeCompare(b.underwriter)
            );
          })
        )
        .pipe(shareReplay(CACHE_SIZE));
    }
    return this.combinedUnderwritersThirdPartiesCache$;
  }

  get getCountiesFromCdn(): Observable<ICounty[]> {
    let httpOpts = {
      headers: new HttpHeaders({
        Accept: '',
      }),
    };
    if (!this.countiesCache$) {
      this.countiesCache$ = this.http
        .get(`${environment.CDN_BASE_URL}/counties.json`, httpOpts)
        .pipe(
          map((res: ICounty[]) => {
            return res.sort((a, b) => a.name.localeCompare(b.name));
          })
        )
        .pipe(shareReplay(CACHE_SIZE));
      return this.countiesCache$;
    }
    return this.countiesCache$;
  }

  get getAkiUnderwritersFromCdn(): Observable<IAkiUnderwriter[]> {
    let httpOpts = {
      headers: new HttpHeaders({
        Accept: '',
      }),
    };
    if (!this.akiUnderwritersCache$) {
      this.akiUnderwritersCache$ = this.http
        .get(
          `${environment.CDN_BASE_URL}/aki_dmvic_underwriters_kcb.json`,
          httpOpts
        )
        .pipe(
          map((res: IAkiUnderwriter[]) => {
            return res.sort((a, b) => a.EntityName.localeCompare(b.EntityName));
          })
        )
        .pipe(shareReplay(CACHE_SIZE));
      return this.akiUnderwritersCache$;
    }
    return this.akiUnderwritersCache$;
  }

  get getUnderwritersFromCdn(): Observable<IUnderwriter[]> {
    let httpOpts = {
      headers: new HttpHeaders({
        Accept: '',
      }),
    };
    if (!this.underwritersCache$) {
      this.underwritersCache$ = this.http
        .get(`${environment.UNDERWRITERS_CDN_URL}`, httpOpts)
        .pipe(
          map((res: IUnderwriter[]) => {
            return res.sort((a, b) =>
              a.underwriter.localeCompare(b.underwriter)
            );
          })
        )
        .pipe(shareReplay(CACHE_SIZE));
      return this.underwritersCache$;
    }
    return this.underwritersCache$;
  }

  getUnderwriterByInsurerId(insurer: string): Observable<IUnderwriter> {
    return this.getUnderwritersFromCdn.pipe(
      map((u) => {
        return u.find((w) => {
          return w.insurer === insurer;
        });
      })
    );
  }

  get getPolicyDocNamesFromCdn(): Observable<IDocumentType[]> {
    let httpOpts = {
      headers: new HttpHeaders({
        Accept: '',
      }),
    };
    if (!this.documentType$) {
      this.documentType$ = this.http
        .get(`${environment.CDN_BASE_URL}/policydoctypes.json`, httpOpts)
        .pipe(
          map((res: IDocumentType[]) => {
            return res.sort((a, b) => a.viewValue.localeCompare(b.viewValue));
          })
        )
        .pipe(shareReplay(CACHE_SIZE));
    }
    return this.documentType$;
  }

  get getThirdPartiesFromCdn(): Observable<IThirdParty[]> {
    let httpOpts = {
      headers: new HttpHeaders({
        Accept: '',
      }),
    };
    if (!this.thirdPartiesCache$) {
      this.thirdPartiesCache$ = this.http
        .get(`${environment.THIRD_PARTIES_CDN_URL}`, httpOpts)
        .pipe(
          map((res: IThirdParty[]) => {
            return res.sort((a, b) => a.name.localeCompare(b.name));
          })
        )
        .pipe(shareReplay(CACHE_SIZE));
      return this.thirdPartiesCache$;
    }
    return this.thirdPartiesCache$;
  }

  get getAuthedThirdPartyListFromCDN(): Observable<IThirdParty[]> {
    return this.getThirdPartiesFromCdn.pipe(
      map((res: IThirdParty[]) => {
        if (this.isAdmin()) {
          return res;
        } else {
          let thirdPartyId = this.getSessionUser().thirdPartyId;
          return res.filter((unwrt) => {
            return unwrt.thirdPartyId === thirdPartyId;
          });
        }
      })
    );
  }

  get getAuthedThirdPartyFromCDN(): Observable<IThirdParty> {
    return this.getThirdPartiesFromCdn.pipe(
      map((res: IThirdParty[]) => {
        if (this.isAdmin()) {
          return res[0];
        } else {
          let thirdPartyId = this.getSessionUser().thirdPartyId;
          return res.filter((unwrt) => {
            return unwrt.thirdPartyId === thirdPartyId;
          })[0];
        }
      })
    );
  }

  getUserDetailsByFeduid(feduid: string, fields: any) {
    return this.makePostRequest(
      `${environment.CORE_API_URL}/getUserDetailsByFeduid`,
      {
        feduid: feduid,
        fields: fields,
      }
    ).pipe(
      map((res) => {
        return res.Payload;
      })
    );
  }

  get getRbacRoles() {
    if (!this.rbacRolesCache$) {
      this.rbacRolesCache$ = this.makePostRequest(
        `${environment.CORE_API_URL}/listrole`,
        {}
      )
        .pipe(
          map((res) => {
            return res.Payload;
          })
        )
        .pipe(shareReplay(CACHE_SIZE));
    }
    return this.rbacRolesCache$;
  }

  get shouldShowQuotes(): Observable<Boolean> {
    return this.getAuthedMenuFromCDN.pipe(
      map((res) => {
        return res.showQuotes;
      })
    );
  }

  setAuthUser(authUser: IUser) {
    this.authUser = authUser;
  }

  getAuthUser() {
    return this.authUser;
  }

  setSessionUser(authUser: IUser) {
    sessionStorage.setItem('user', JSON.stringify(authUser));
  }

  setPermissions(tasks: string[]) {
    sessionStorage.setItem('permissions', JSON.stringify(tasks));
  }
  get getPermissions(): string[] {
    let permString = sessionStorage.getItem('permissions');
    let tasks: string[] = JSON.parse(permString);
    if (tasks === null) {
      return [];
    }
    return tasks;
  }

  hasPermission(permissionName: string): boolean {
    return this.getPermissions.includes(permissionName);
  }
  getSessionUser(): IUser {
    let userString = sessionStorage.getItem('user');
    let sessionUser: IUser = JSON.parse(userString);
    return sessionUser;
  }

  getMotorQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.CORE_API_URL}/adminaggregate`,
      data
    );
  }
  getMedicalQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.MEDICAL_API_URL}/adminlistquote`,
      data
    );
  }

  getMedicalAggregateQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.CORE_API_URL}/adminaggregate`,
      data
    );
  }

  getTelemedicineAggregateQuotesAndPolicies(
    data: any
  ): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.MEDICAL_API_URL}/telemedicineadminaggregate`,
      data
    );
  }

  get getMedicalUnderwriters(): Observable<IUnderwriter[]> {
    return this.getUnderwritersFromCdn.pipe(
      map((u) => {
        return u.filter((w) => {
          return (
            w.products.filter((p) => {
              return p.id == 'medical';
            }).length > 0
          );
        });
      })
    );
  }

  getTravelQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.TRAVEL_API_URL}/adminlisttravelquote`,
      data
    );
  }

  getTravelAggregateQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.TRAVEL_API_URL}/adminaggregate`,
      data
    );
  }

  getLastExpenseAggregateQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.LIFE_API_URL}/adminaggregatelastexpense`,
      data
    );
  }

  getUnisureAggregateQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.MEDICAL_API_URL}/unisureadminlistquote`,
      data
    );
  }

  getOtherPoliciesAggregateQuotesAndPolicies(
    data: any
  ): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.PA_API_URL}/adminlistotherquote`,
      data
    );
  }

  getOtherPoliciesAggregate(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.PA_API_URL}/adminAggregateOtherPolicies`,
      data
    );
  }
  getOtherQuestions(questions_id: string): Observable<ApiResponse> {
    return this.makePostRequest(`${environment.PA_API_URL}/getotherquestions`, {
      _id: questions_id,
    });
  }

  get getTravelUnderwriters(): Observable<IUnderwriter[]> {
    return this.getUnderwritersFromCdn.pipe(
      map((u) => {
        return u.filter((w) => {
          return (
            w.products.filter((p) => {
              return p.id == 'travel';
            }).length > 0
          );
        });
      })
    );
  }

  getDomesticQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.DOMESTIC_API_URL}/adminlistdomesticquote`,
      data
    );
  }

  get getDomesticUnderwriters(): Observable<IUnderwriter[]> {
    return this.getUnderwritersFromCdn.pipe(
      map((u) => {
        return u.filter((w) => {
          return (
            w.products.filter((p) => {
              return p.id == 'domestic';
            }).length > 0
          );
        });
      })
    );
  }

  getDomesticAggregateQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.DOMESTIC_API_URL}/adminaggregate`,
      data
    );
  }
  getDomesticCommissionStatementReport(
    data: any
  ): Observable<ICommissionStatement[]> {
    return this.makePostRequest(
      `${environment.DOMESTIC_API_URL}/getcommissionstatementreport`,
      data
    ).pipe(
      map((res) => {
        return res.Payload?.quotes;
      })
    );
  }

  getGadgetQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.DOMESTIC_API_URL}/adminlistgadgetquote`,
      data
    );
  }

  get getGadgetUnderwriters(): Observable<IUnderwriter[]> {
    return this.getUnderwritersFromCdn.pipe(
      map((u) => {
        return u.filter((w) => {
          return (
            w.products.filter((p) => {
              return p.id == 'gadget';
            }).length > 0
          );
        });
      })
    );
  }

  getGadgetAggregateQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.DOMESTIC_API_URL}/admingadgetaggregate`,
      data
    );
  }

  getLifeCommissionStatementReport(
    data: any
  ): Observable<ICommissionStatement[]> {
    return this.makePostRequest(
      `${environment.LIFE_API_URL}/getcommissionstatementreport`,
      data
    ).pipe(
      map((res) => {
        return res.Payload?.quotes;
      })
    );
  }
  getGolfCommissionStatementReport(
    data: any
  ): Observable<ICommissionStatement[]> {
    return this.makePostRequest(
      `${environment.GOLF_API_URL}/getcommissionstatementreport`,
      data
    ).pipe(
      map((res) => {
        return res.Payload?.quotes;
      })
    );
  }
  getPaCommissionStatementReport(
    data: any
  ): Observable<ICommissionStatement[]> {
    return this.makePostRequest(
      `${environment.PA_API_URL}/getcommissionstatementreport`,
      data
    ).pipe(
      map((res) => {
        return res.Payload?.quotes;
      })
    );
  }
  getEvacuationCommissionStatementReport(
    data: any
  ): Observable<ICommissionStatement[]> {
    return this.makePostRequest(
      `${environment.EVACUATION_API_URL}/getcommissionstatementreport`,
      data
    ).pipe(
      map((res) => {
        return res.Payload?.quotes;
      })
    );
  }
  getMotorCommissionStatementReport(
    data: any
  ): Observable<ICommissionStatement[]> {
    return this.makePostRequest(
      `${environment.MOTOR_API_URL}/getcommissionstatementreport`,
      data
    ).pipe(
      map((res) => {
        return res.Payload?.quotes;
      })
    );
  }
  getMedicalCommissionStatementReport(
    data: any
  ): Observable<ICommissionStatement[]> {
    return this.makePostRequest(
      `${environment.MEDICAL_API_URL}/getcommissionstatementreport`,
      data
    ).pipe(
      map((res) => {
        return res.Payload?.quotes;
      })
    );
  }
  getTravelCommissionStatementReport(
    data: any
  ): Observable<ICommissionStatement[]> {
    return this.makePostRequest(
      `${environment.TRAVEL_API_URL}/getcommissionstatementreport`,
      data
    ).pipe(
      map((res) => {
        return res.Payload?.quotes;
      })
    );
  }

  getPersonalAccidentQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.PA_API_URL}/adminlistpaquote`,
      data
    );
  }

  getPersonalAccidentAggregateQuotesAndPolicies(
    data: any
  ): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.PA_API_URL}/adminaggregate`,
      data
    );
  }

  getLifeAggregateQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.LIFE_API_URL}/adminaggregatelife`,
      data
    );
  }

  get getPaUnderwriters(): Observable<IUnderwriter[]> {
    return this.getUnderwritersFromCdn.pipe(
      map((u) => {
        return u.filter((w) => {
          return (
            w.products.filter((p) => {
              return p.id == 'pa';
            }).length > 0
          );
        });
      })
    );
  }

  get getEvacuationUnderwriters(): Observable<IUnderwriter[]> {
    return this.getUnderwritersFromCdn.pipe(
      map((u) => {
        return u.filter((w) => {
          return (
            w.products.filter((p) => {
              return p.id == 'evacuation';
            }).length > 0
          );
        });
      })
    );
  }

  getEvacuationQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.EVACUATION_API_URL}/adminlistevacuationquote`,
      data
    );
  }

  getEvacuationAggregateQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.EVACUATION_API_URL}/adminaggregateevacuation`,
      data
    );
  }

  getGolfQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.GOLF_API_URL}/adminlistgolfquote`,
      data
    );
  }

  getGolfAggregateQuotesAndPolicies(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.GOLF_API_URL}/adminaggregategolf`,
      data
    );
  }
  getAllPayments(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.MPESA_API_URL}/api/listpaymentlogs`,
      data
    );
  }

  getAllNtsaPayments(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.MPESA_API_URL}/api/listNTSATokenPayment`,
      data
    );
  }

  getPaymentPlans(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.IPF_API_URL}/listAllFinancedPolicies`,
      data
    );
  }

  getReport(data: any): Observable<ApiResponse> {
    return this.makePostRequest(`${environment.MOTOR_API_URL}/getreport`, data);
  }

  getPaymentPlansReportData(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.MPESA_API_URL}/getPaymentsReport`,
      data
    );
  }

  getPaymentPlansReportFile(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.REPORTS_API_URL}/reports/paymentplan`,
      data
    );
  }
  getMtekPaymentPlansReportFile(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.REPORTS_API_URL}/reports/mtek/paymentplan`,
      data
    );
  }
  getReportsReferences(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.MPESA_API_URL}/getReportsReferences`,
      data
    );
  }

  updateReportReference(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.MPESA_API_URL}/updateReportReference`,
      data
    );
  }

  getCommisionStatementReportFile(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.REPORTS_API_URL}/reports/commissionstatement`,
      data
    );
  }

  getDeclarationReportFile(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.REPORTS_API_URL}/reports/declarationreport`,
      data
    );
  }

  getAllPaymentPlans(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.MPESA_API_URL}/aggregateFinancedPlanPolicies`,
      data
    );
  }

  getMotorClaims(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.CLAIMS_API_URL}/listMotorClaim`,
      data
    );
  }

  getGadgetClaims(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.DOMESTIC_API_URL}/adminListGadgetClaims`,
      data
    );
  }

  getDomesticClaims(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.DOMESTIC_API_URL}/adminListClaims`,
      data
    );
  }

  saveNtsaPayments(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.MPESA_API_URL}/api/stageNTSATokenPayment`,
      data
    );
  }

  calcNtsaTokens(data: any): Observable<ApiResponse> {
    return this.makePostRequest(
      `${environment.MPESA_API_URL}/api/calcNtsaTokens`,
      data
    );
  }
  processHttpErrors(err) {
    let e = err.error;

    if (err.status == 543) {
      this.SignOut();
      return `Session Expired. Log in afresh`;
    } else if (e.hasOwnProperty('Payload')) {
      if (typeof e.Payload === 'string') {
        return e.Message;
      }
    } else {
      if (e?.code) {
        return `Status (${e?.code}) : ${e?.message} ${e.error}`;
      } else {
        return `Status (${err.status}) : ${err.statusText}`;
      }
    }
  }

  sendNotificationToUser(notification: ISendNotification) {
    this.makePostRequest(
      `${environment.CORE_API_URL}/pushnotification`,
      notification
    ).subscribe(
      (apiResponse) => {
        if (apiResponse.Status == 200) {
          this.showToastMessage(
            AppEnums.ToastTypeSuccess,
            'Notification Sent',
            apiResponse.Message
          );
        } else {
          this.showToastMessage(
            AppEnums.ToastTypeWarning,
            'Status ' + apiResponse.Status,
            apiResponse.Message
          );
        }
      },
      (err) => {
        this.showToastMessage(
          AppEnums.ToastTypeError,
          '',
          this.processHttpErrors(err)
        );
      }
    );
  }

  allLocalRolesObject(): IRole[] {
    let allRole = [
      {
        roleName: 'All',
        name: 'all',
        value: false,
        allowedRoles: [],
        requireunderwriter: false,
      },
    ];

    let systemRoles = [
      {
        roleName: 'Client',
        name: AppEnums.RoleClient,
        value: false,
        allowedRoles: [AppEnums.RoleAdmin, AppEnums.RolePartner],
        requireunderwriter: false,
      },
      {
        roleName: 'Administrator',
        name: AppEnums.RoleAdmin,
        value: false,
        allowedRoles: [AppEnums.RoleAdmin],
        requireunderwriter: false,
      },
      {
        roleName: 'Agent',
        name: AppEnums.RoleAgent,
        value: false,
        allowedRoles: [AppEnums.RolePartner],
        requireunderwriter: true,
      },
      {
        roleName: 'Valuer',
        name: AppEnums.RoleValuer,
        value: false,
        allowedRoles: [AppEnums.RolePartner],
        requireunderwriter: true,
      },
      {
        roleName: 'Assessor',
        name: AppEnums.RoleAssessor,
        value: false,
        allowedRoles: [AppEnums.RolePartner],
        requireunderwriter: true,
      },
      {
        roleName: 'Partner',
        name: AppEnums.RolePartner,
        value: false,
        requireunderwriter: true,
        allowedRoles: [AppEnums.RoleAdmin],
      },
      {
        roleName: 'Sales Agent',
        name: AppEnums.RoleSalesAgent,
        value: false,
        requireunderwriter: true,
        allowedRoles: [AppEnums.RoleAdmin],
      },
      {
        roleName: 'Lead Generator',
        name: AppEnums.RoleLeadGenerator,
        value: false,
        requireunderwriter: false,
        allowedRoles: [AppEnums.RoleAdmin, AppEnums.RoleLeadGenerator],
      },
      {
        roleName: 'Prime Partner',
        name: AppEnums.RolePrimePartner,
        value: false,
        requireunderwriter: false,
        allowedRoles: [AppEnums.RoleAdmin],
      },
      {
        roleName: 'Consultant',
        name: AppEnums.RoleConsultant,
        value: false,
        requireunderwriter: false,
        allowedRoles: [AppEnums.RoleAdmin],
      },
    ].sort((a, b) => a.roleName.localeCompare(b.roleName));
    this.makeGetRequest;
    return allRole.concat(systemRoles);
  }

  getRolesByUser(rolesArray): string[] {
    let roles = [];
    if (rolesArray) {
      rolesArray.forEach((role) => {
        if (role.hasOwnProperty(AppEnums.RoleAgent)) {
          if (role.agent) {
            roles.push(AppEnums.RoleAgent);
          }
        }
        if (role.hasOwnProperty(AppEnums.RoleValuer)) {
          if (role.valuer) {
            roles.push(AppEnums.RoleValuer);
          }
        }
        if (role.hasOwnProperty(AppEnums.RoleAssessor)) {
          if (role.assessor) {
            roles.push(AppEnums.RoleAssessor);
          }
        }
        if (role.hasOwnProperty(AppEnums.RoleAdmin)) {
          if (role.admin) {
            roles.push(AppEnums.RoleAdmin);
          }
        }
        if (role.hasOwnProperty(AppEnums.RolePartner)) {
          if (role.partner) {
            roles.push(AppEnums.RolePartner);
          }
        }

        if (role.hasOwnProperty(AppEnums.RoleClient)) {
          if (role.client) {
            roles.push(AppEnums.RoleClient);
          }
        }
        if (role.hasOwnProperty(AppEnums.RoleSalesAgent)) {
          if (role.salesagent) {
            roles.push(AppEnums.RoleSalesAgent);
          }
        }
        if (role.hasOwnProperty(AppEnums.RoleLeadGenerator)) {
          if (role.leadgenerator) {
            roles.push(AppEnums.RoleLeadGenerator);
          }
        }
        if (role.hasOwnProperty(AppEnums.RolePrimePartner)) {
          if (role.primepartner) {
            roles.push(AppEnums.RolePrimePartner);
          }
        }
      });
    }

    return roles;
  }

  getRoles(rolesArr?: string[]): string[] {
    if (this.getSessionUser() === null || this.getSessionUser() == undefined) {
      return [];
    }
    let rolesArray = [];
    if (rolesArr) {
      rolesArray = rolesArr;
    } else {
      rolesArray = this.getSessionUser().roles;
    }

    let roles = [];
    rolesArray.forEach((role) => {
      if (role.hasOwnProperty(AppEnums.RoleAgent)) {
        if (role.agent) {
          roles.push(AppEnums.RoleAgent);
        }
      }
      if (role.hasOwnProperty(AppEnums.RoleValuer)) {
        if (role.valuer) {
          roles.push(AppEnums.RoleValuer);
        }
      }
      if (role.hasOwnProperty(AppEnums.RoleAssessor)) {
        if (role.assessor) {
          roles.push(AppEnums.RoleAssessor);
        }
      }
      if (role.hasOwnProperty(AppEnums.RoleAdmin)) {
        if (role.admin) {
          roles.push(AppEnums.RoleAdmin);
        }
      }
      if (role.hasOwnProperty(AppEnums.RolePartner)) {
        if (role.partner) {
          roles.push(AppEnums.RolePartner);
        }
      }

      if (role.hasOwnProperty(AppEnums.RoleClient)) {
        if (role.client) {
          roles.push(AppEnums.RoleClient);
        }
      }
      if (role.hasOwnProperty(AppEnums.RoleSalesAgent)) {
        if (role.salesagent) {
          roles.push(AppEnums.RoleSalesAgent);
        }
      }
      if (role.hasOwnProperty(AppEnums.RoleLeadGenerator)) {
        if (role.leadgenerator) {
          roles.push(AppEnums.RoleLeadGenerator);
        }
      }
      if (role.hasOwnProperty(AppEnums.RolePrimePartner)) {
        if (role.primepartner) {
          roles.push(AppEnums.RolePrimePartner);
        }
      }
    });

    return roles;
  }

  isTrustGro() {
    return this.getSessionUser()?.thirdPartyId === AppEnums.PartnerTrustgro;
  }

  isKyosk() {
    return this.getSessionUser()?.thirdPartyId === AppEnums.PartnerKyosk;
  }

  isAdminOrTrustGro() {
    return this.isAdmin() || this.isTrustGro();
  }

  isThirdParty() {
    return this.getRoles().includes(AppEnums.RoleLeadGenerator);
  }

  isAdminOrThirdParty() {
    return [AppEnums.RoleAdmin, AppEnums.RoleLeadGenerator].some((role) =>
      this.getRoles().includes(role)
    );
  }
  isAdminOrThirdPartyOrPrimePartner() {
    return [
      AppEnums.RoleAdmin,
      AppEnums.RoleLeadGenerator,
      AppEnums.RolePrimePartner,
    ].some((role) => this.getRoles().includes(role));
  }
  isAdmin() {
    return this.getRoles().includes(AppEnums.RoleAdmin);
  }

  isPrimePartner() {
    return this.getRoles().includes(AppEnums.RolePrimePartner);
  }

  isPartner() {
    return this.getRoles().includes(AppEnums.RolePartner);
  }

  isAdminOrPartner() {
    return [AppEnums.RoleAdmin, AppEnums.RolePartner].some((role) =>
      this.getRoles().includes(role)
    );
  }

  isAstrabelMember() {
    return this.getSessionUser()?.thirdPartyId === AppEnums.PartnerAstrabel;
  }

  isEmbassavaMember() {
    return this.getSessionUser()?.thirdPartyId === AppEnums.PartnerEmbassava;
  }

  isLuminousMember() {
    return this.getSessionUser()?.thirdPartyId === AppEnums.PartnerLuminous;
  }

  SignUp(email: string, password: string) {
    this.angularFireAuth.createUserWithEmailAndPassword(email, password);
  }

  signin(email: string, password: string) {
    return this.angularFireAuth.signInWithEmailAndPassword(email, password);
  }

  resetPassword(email: string) {
    return this.angularFireAuth.sendPasswordResetEmail(email);
  }

  SignOut() {
    this.mixPanelInit(this.getSessionUser()?.feduid);
    this.mixpanelTrack(AppEnums.MXPNL_FORGOT_PASSWORD);
    this.angularFireAuth.signOut();
    sessionStorage.clear();
    this.router.navigate(['auth/signin']);
  }
  getUserData() {
    return this.angularFireAuth.authState;
  }
  getFirebaseUser() {
    return this.angularFireAuth.currentUser;
  }

  public showToastMessage(
    alertType: AppEnums,
    alertTitle: string,
    alertMessage: string
  ) {
    switch (alertType) {
      case AppEnums.ToastTypeSuccess:
        return this.toastr.success(alertMessage, alertTitle);
        break;
      case AppEnums.ToastTypeInfo:
        return this.toastr.info(alertMessage, alertTitle);
        break;
      case AppEnums.ToastTypeWarning:
        return this.toastr.warning(alertMessage, alertTitle);
        break;
      case AppEnums.ToastTypeError:
        return this.toastr.error(alertMessage, alertTitle);
        break;
      default:
        break;
    }
  }

  makePostRequest(fullUrl: string, data: any): Observable<ApiResponse> {
    if (this.getAuthUser()) {
      httpOptions.headers = httpOptions.headers.set(
        'SKEY',
        this.getAuthUser().idToken
      );
    } else {
      httpOptions.headers = httpOptions.headers.set(
        'SKEY',
        this.getSessionUser().idToken
      );
    }
    return this.http.post<ApiResponse>(fullUrl, data, httpOptions);
  }

  makeGetRequest(fullUrl: string): Observable<ApiResponse> {
    if (this.getAuthUser()) {
      httpOptions.headers = httpOptions.headers.set(
        'SKEY',
        this.getAuthUser().idToken
      );
    } else {
      httpOptions.headers = httpOptions.headers.set(
        'SKEY',
        this.getSessionUser().idToken
      );
    }
    return this.http.get<ApiResponse>(fullUrl, httpOptions);
  }

  makeAuthGetRequest(
    fullUrl: string,
    idToken: string
  ): Observable<ApiResponse> {
    httpOptions.headers = httpOptions.headers.set('SKEY', idToken);
    return this.http.get<ApiResponse>(fullUrl, httpOptions);
  }

  makeAuthPostRequest(
    fullUrl: string,
    data: any,
    idToken: string
  ): Observable<ApiResponse> {
    httpOptions.headers = httpOptions.headers.set('SKEY', idToken);
    return this.http.post<ApiResponse>(fullUrl, data, httpOptions);
  }

  makeDataGetRequest(fullUrl: string): Observable<dataApiResponse> {
    return this.http.get<dataApiResponse>(fullUrl);
  }

  makeAnyDataGetRequest(fullUrl: string): Observable<any> {
    return this.http.get<any>(fullUrl);
  }

  makeDataPostRequest(fullUrl: string, data: any): Observable<any> {
    return this.http.post<any>(fullUrl, data);
  }

  makeDeleteRequest(fullUrl: string, data: any): Observable<ApiResponse> {
    if (this.getAuthUser()) {
      httpOptions.headers = httpOptions.headers.set(
        'SKEY',
        this.getAuthUser().idToken
      );
    } else {
      httpOptions.headers = httpOptions.headers.set(
        'SKEY',
        this.getSessionUser().idToken
      );
    }
    return this.http.delete<ApiResponse>(fullUrl, httpOptions);
  }
  makeUpdateRequest(fullUrl: string, data: any): Observable<ApiResponse> {
    if (this.getAuthUser()) {
      httpOptions.headers = httpOptions.headers.set(
        'SKEY',
        this.getAuthUser().idToken
      );
    } else {
      httpOptions.headers = httpOptions.headers.set(
        'SKEY',
        this.getSessionUser().idToken
      );
    }
    return this.http.put<ApiResponse>(fullUrl, data, httpOptions);
  }

  makeSearchRequest(fullUrl: string, data: any): Observable<ApiResponse> {
    return this.makePostRequest(fullUrl, data);
  }

  getNotifications(
    pagination: INotificationPagination
  ): Observable<ApiResponse> {
    let data = {
      read: false,
      pagination,
    };
    return this.makePostRequest(
      `${environment.CORE_API_URL}/listnotifications`,
      data
    );
  }

  getUserStatistics(data): Observable<IDashCard[]> {
    if (this.userStatisticsCache.length > 0) {
      this.userStatisticsCache = [];
    }
    return this.makePostRequest(
      `${environment.CORE_API_URL}/getUserStats`,
      data
    ).pipe(
      map((res) => {
        let rs: INameValue[] = res.Payload;
        rs.forEach((el) => {
          switch (el.name) {
            case 'all':
              this.userStatisticsCache.push({
                displayName: 'All Users',
                category: el.name,
                value: el.value,
              });
              break;
            case 'client':
              this.userStatisticsCache.push({
                displayName: 'Clients',
                category: el.name,
                value: el.value,
              });
              break;
            case 'admin':
              this.userStatisticsCache.push({
                displayName: 'Admins',
                category: el.name,
                value: el.value,
              });
              break;
            case 'agent':
              this.userStatisticsCache.push({
                displayName: 'Agents',
                category: el.name,
                value: el.value,
              });
              break;
            case 'assessor':
              this.userStatisticsCache.push({
                displayName: 'Assessor',
                category: el.name,
                value: el.value,
              });
              break;
            case 'partner':
              this.userStatisticsCache.push({
                displayName: 'Partners',
                category: el.name,
                value: el.value,
              });
              break;
            case 'valuer':
              this.userStatisticsCache.push({
                displayName: 'Valuers',
                category: el.name,
                value: el.value,
              });
              break;

            default:
              break;
          }
        });
        return this.userStatisticsCache;
      })
    );
  }
  isNumber(value: string | number): boolean {
    return value != null && value !== '' && !isNaN(Number(value.toString()));
  }

  isEmptyString(str) {
    return !str || /^\s*$/.test(str);
  }

  getSasToken(): Observable<string> {
    if (this.sasTokenCache) {
      return of(this.sasTokenCache);
    } else {
      return this.makeGetRequest(`${environment.CORE_API_URL}/getSASkey`).pipe(
        map((res) => {
          if (res.Status == 200) {
            this.sasTokenCache = res.Payload;
            return this.sasTokenCache;
          } else {
            this.showToastMessage(
              AppEnums.ToastTypeWarning,
              `${res.Status}`,
              res.Payload
            );
            return '';
          }
        })
      );
    }
  }

  getAsanaWorkspaces() {
    return this.makeGetRequest(`${environment.CORE_API_URL}/asanaworkspaces`);
  }

  getAsanaProjects() {
    return this.makeGetRequest(`${environment.CORE_API_URL}/asanaprojects`);
  }

  getAsanaUsers() {
    return this.makeGetRequest(`${environment.CORE_API_URL}/asanausers`);
  }

  uploadAzureImages(
    url: string,
    file: File,
    formFileName: string,
    contentType: string
  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append(formFileName, file);
    var blob = new Blob([file], { type: contentType });
    const req = new HttpRequest('PUT', url, blob, {
      headers: new HttpHeaders({
        'x-ms-blob-type': 'BlockBlob',
        'Content-Type': contentType,
      }),
      reportProgress: true,
      responseType: 'json',
    });
    return this.http.request(req);
  }

  getPromoProducts(): IPromoProduct[] {
    return [
      { value: EProduct.insurance_motor, name: 'Motor cover' },
      { value: EProduct.insurance_traumacare, name: 'Traumacare' },
      { value: EProduct.insurance_travel, name: 'Travel cover' },
      { value: EProduct.extreme_clean, name: 'Extreme clean' },
      { value: EProduct.insurance_cancer, name: 'Cancer cover' },
      { value: EProduct.insurance_domestic, name: 'Domestic cover' },
      { value: EProduct.insurance_golf, name: 'Golf cover' },
      { value: EProduct.insurance_health, name: 'Health cover' },
      {
        value: EProduct.insurance_personalaccident,
        name: 'Personal Accident (PA) cover',
      },
      { value: EProduct.insurance_lastexpense, name: 'Last Expense Cover' },
    ];
  }

  getInsuranceProducts(): IPromoProduct[] {
    return [
      { value: EProduct.insurance_motor, name: 'Motor cover' },
      { value: EProduct.insurance_traumacare, name: 'Traumacare' },
      { value: EProduct.insurance_travel, name: 'Travel cover' },
      { value: EProduct.insurance_cancer, name: 'Cancer cover' },
      { value: EProduct.insurance_domestic, name: 'Domestic cover' },
      { value: EProduct.insurance_golf, name: 'Golf cover' },
      { value: EProduct.insurance_health, name: 'Health cover' },
      {
        value: EProduct.insurance_personalaccident,
        name: 'Personal Accident (PA) cover',
      },
    ];
  }

  makeDownloadPostRequest(fullUrl: string, data: any): Observable<Blob> {
    const hds = new HttpHeaders({
      'Content-Type':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      SKEY: this.getSessionUser().idToken,
    });
    return this.http.post<Blob>(fullUrl, data, {
      headers: hds,
      responseType: 'blob' as 'json',
    });
  }

  makePdfDownloadPostRequest(fullUrl: string, data: any): Observable<Blob> {
    const hds = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      SKEY: this.getSessionUser().idToken,
    });
    return this.http.post<Blob>(fullUrl, data, {
      headers: hds,
      responseType: 'blob' as 'json',
    });
  }

  fetchAllUsers(): Observable<IUser[]> {
    if (this.usersCache.length > 0) {
      return of(this.usersCache);
    } else {
      return this.makePostRequest(`${environment.CORE_API_URL}/listUsers`, {
        fields: [
          '_timestamp',
          '_id',
          'feduid',
          'userName',
          'email',
          'phoneNumber',
          'idNumber',
          'kraPin',
          'roles',
        ],
      }).pipe(
        map((res) => {
          this.usersCache = res.Payload;
          return this.usersCache;
        })
      );
    }
  }

  isValidEmail(email: string) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  isValidPhoneNumber(phoneNumber: string) {
    var phoneNumberPattern = /^\+\d{12}$/;
    if (phoneNumber.match(phoneNumberPattern)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Get bearer token
   */
  public getBearerToken(): string {
    let idToken = null;

    if (this.getAuthUser()) {
      idToken = this.getAuthUser().idToken;
    } else {
      idToken = this.getSessionUser().idToken;
    }

    return idToken;
  }

  /**
   * query graphQL
   */
  public query(query: any, variables?: any) {
    //set headers
    let httpHeaders = {
      Accept: `application/json`,
      'Content-Type': `application/json`,
      Authorization: `Bearer ${this.getBearerToken()}`,
    };

    //graphQL
    if (variables)
      return this.apollo.watchQuery<any>({
        query: query,
        variables: variables,
      }).valueChanges;

    return this.apollo.watchQuery<any>({
      query: query,
      context: { headers: httpHeaders },
    }).valueChanges;
  }

  /**
   * GraphQL mutation
   */
  public mutate(mutation: any, variables: any) {
    //set headers
    let httpHeaders = {
      Accept: `application/json`,
      'Content-Type': `application/json`,
      Authorization: `Bearer ${this.getBearerToken()}`,
    };

    return this.apollo.mutate<any>({
      mutation: mutation,
      variables: variables,
      context: { headers: httpHeaders },
    });
  }

  exportToExcell({ data, fileName }: { data: any[]; fileName: string }) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

    // Create a header row and set the style
    const header = Object.keys(data[0]);
    header.forEach((key, index) => {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });
      if (!ws[cellAddress]) {
        ws[cellAddress] = { v: key };
      }
    });

    // Set the style for the header row
    ws['!cols'] = ws['!cols'] || [];
    header.forEach(() => {
      ws['!cols']!.push({ width: 20 }); // Adjust the width as needed
    });
    // Create a workbook and add the worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Save the file
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  getEndTimestamp(endDate: Date): number {
    return new Date(
      Date.UTC(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        23,
        59,
        59,
        0
      )
    ).getTime();
  }
  getStartTimestamp(startDate: Date): number {
    return new Date(
      Date.UTC(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        0,
        0,
        0,
        0
      )
    ).getTime();
  }

  formatDate(date: Date) {
    // Extract the date components
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    // Extract the date components
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Create a formatted date string
    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
  }


}
