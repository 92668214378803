<div class="container">
  <h1 mat-dialog-title>
    Reversed Payment
    <mat-progress-bar mode="indeterminate" *ngIf="!dataSent"></mat-progress-bar>
  </h1>
  <form [formGroup]="editPaymentForm" autocomplete="off">
    <div class="row">
      <mat-form-field>
        <input
          type="text"
          formControlName="reversalReference"
          matInput
          placeholder="Reversal Reference"
          required
        />
      </mat-form-field>
      <mat-form-field>
        <input
          type="text"
          matInput
          formControlName="reversalReason"
          placeholder="Reversal Reason"
          required
        />
      </mat-form-field>
    </div>

    <div class="row">
      <div class="col-md-5"></div>
      <div class="col-md-2">
        <button mat-raised-button color="primary" (click)="updatePayment()">
          Submit
        </button>
      </div>
      <div class="col-md-5"></div>
    </div>
  </form>
</div>
