import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { IMotor } from 'src/app/models/motor.interface';
import { Input } from '@angular/core';

@Component({
  selector: 'app-motor-list-table',
  templateUrl: './motor-list-table.component.html',
  styleUrls: ['./motor-list-table.component.scss'],
})
export class MotorListTableComponent implements OnInit {
  @Input() policies: IMotor[] = [];
  previousDisabled: boolean = true;
  isLoading: boolean = false;
  tableFiltersFormGroup: FormGroup;
  @Input() dataLoaded: boolean;
  @Input() feduid: string;

  filteredRows: any;

  constructor(private formBuilder: FormBuilder, private router: Router) {
    this.tableFiltersFormGroup = this.formBuilder.group({
      searchFilter: '',
    });
    this.filterSearchTable();
  }

  ngOnInit(): void {
    this.filteredRows = this.policies;
  }

  filterSearchTable() {
    this.tableFiltersFormGroup
      .get('searchFilter')
      .valueChanges.subscribe((val) => {
        this.filteredRows = this.policies.filter((record) => {
          return Object.keys(record).reduce((acc, curr) => {
            return (
              acc ||
              JSON.stringify(record[curr])
                .toLowerCase()
                .includes(val.toLowerCase())
            );
          }, false);
        });
      });
  }

  goToPolicyDetails(_id: string) {
    this.router.navigate(['/motor/list/motor-details', _id]);
  }

  highlightCancelledPolicies(policy: any) {
    if (policy.hasOwnProperty('cancellation')) {
      return {
        'background-color': '#DB3A34',
        color: '#fff',
      };
    }
  }
}
