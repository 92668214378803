<app-date-range-picker-dashboard (dateRange)="updateRange($event)"></app-date-range-picker-dashboard>
<h4>{{selectedDateRange.start_date | date: 'fullDate'}} to {{selectedDateRange.end_date | date:'fullDate'}}</h4>
<div *ngIf="!noPayments; else noPaymentsFound">
  <div class="row">
    <div class="col-lg-4 card-container">
      <div class="card card-stats">
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase mb-0 text-warning">
                Total payments
              </h5>
              <span *ngIf="paymentDataLoaded" class="mb-2">Ksh </span><span *ngIf="paymentDataLoaded" class="h5 mb-0">{{
                totalPayments | currency: ' '
                }}</span>
              <app-loader *ngIf="!paymentDataLoaded"></app-loader>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-gradient-warning text-white rounded-circle shadow">
                <i class="fa fa-check-circle"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 card-container">
      <div class="card card-stats">
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h5 class="card-title text-u percase mb-0 text-success">
                Mpesa Payments
              </h5>
              <span *ngIf="paymentDataLoaded" class="mb-2">Ksh </span><span *ngIf="paymentDataLoaded" class="h5 mb-0">{{
                totalMpesaPayments | currency: ' '
                }}</span>
              <app-loader *ngIf="!paymentDataLoaded"></app-loader>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-gradient-success text-white rounded-circle shadow">
                <i class="fa fa-check-circle"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 card-container">
      <div class="card card-stats">
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase mb-0 text-mtek">
                Card Payments
              </h5>
              <span *ngIf="paymentDataLoaded" class="mb-2">Ksh </span><span *ngIf="paymentDataLoaded" class="h5 mb-0">{{
                totalCardPayments | currency: ' '
                }}</span>
              <app-loader *ngIf="!paymentDataLoaded"></app-loader>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-gradient-info text-white rounded-circle shadow">
                <i class="fa fa-check-circle"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">

    <div class="chart-container col-lg-7">
      <div class="card chart-wrapper">
        <app-loader *ngIf="!paymentChartsDataLoaded"></app-loader>
        <apx-chart *ngIf="paymentChartsDataLoaded" [series]="chartOptions.series" [chart]="chartOptions.chart"
          [responsive]="chartOptions.responsive" [plotOptons]="chartOptions.plotOptions" [title]="chartOptions.title"
          [xaxis]="chartOptions.xaxis" [legend]="chartOptions.legend" [fill]="chartOptions.fill">
        </apx-chart>
      </div>
    </div>
    <div class="chart-container col-lg-5">
      <div class="card chart-wrapper">
        <app-loader *ngIf="!paymentChartsDataLoaded"></app-loader>
        <apx-chart *ngIf="paymentChartsDataLoaded" [series]="chartOptions2.series" [chart]="chartOptions2.chart"
          [plotOptions]="chartOptions2.plotOptions" [labels]="chartOptions2.labels" [title]="chartOptions2.title"
          [legend]="chartOptions2.legend"></apx-chart>
      </div>
    </div>
  </div>
</div>

<ng-template #noPaymentsFound>
  <div class="alert alert-warning" role="alert">
    No new payments for the selected period. Please try another date range!
  </div>
</ng-template>