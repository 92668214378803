export enum EProduct {
  all = "All",
  insurance_motor = "insurance_motor",
  insurance_traumacare = "insurance_traumacare",
  insurance_travel = "insurance_travel",
  extreme_clean = "extreme_clean",
  insurance_cancer = "insurance_cancer",
  insurance_health = "insurance_health",
  insurance_golf = "insurance_golf",
  insurance_domestic = "insurance_domestic",
  insurance_personalaccident = "insurance_personalaccident",
  insurance_lastexpense = "insurance_lastexpense"
}

export enum EUnit {
  percentage = "percentage",
  amount = "amount",
}
