<div>
    <div>
      <div style="display: block">
        <canvas baseChart
          [data]="doughnutChartData"
          [labels]="doughnutChartLabels"
          [options]="options"
          [chartType]="doughnutChartType">
        </canvas>
      </div>
    </div>
  </div>
  