import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'smePolicyName',
})
export class SmePolicyNamePipe implements PipeTransform {
  transform(isSme: boolean, policyName: string): unknown {
    if (isSme) {
      return policyName + ' SME';
    }
    return policyName;
  }
}
