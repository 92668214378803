import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AppEnums } from 'src/app/enums/app.enum';
import { ApiResponse } from 'src/app/models/api-response.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import { DisplayClientDetailsComponent } from '../display-client-details/display-client-details.component';

@Component({
  selector: 'app-update-company-details',
  templateUrl: './update-company-details.component.html',
  styleUrls: ['./update-company-details.component.scss'],
})
export class UpdateCompanyDetailsComponent implements OnInit {
  companyFormGroup: FormGroup;
  companyMultiUploadFormGroup: FormGroup;
  allowedFileExtentions = ['jpg', 'jpeg', 'png', 'pdf'];
  azureFileLocation: string = 'manual-sales';
  activeFileName: string = '';
  activeFile: any;
  _tstamp: number = 0;
  currentRecord: any;
  moreQuestions: any;
  companyFiles: any[] = [
    { name: 'KRA PIN Document', value: 'kraPhoto', suffix: '_KRA_PIN' },
    {
      name: 'Incorporation Certificate',
      value: 'incorporationCert',
      suffix: '_INCORPORATION_CERT',
    },
  ];
  isLoading: boolean = false;
  isMoreQuestionsObjectAvailable: boolean = false;

  constructor(
    private _formBuiler: FormBuilder,
    private appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DisplayClientDetailsComponent>
  ) {
    this._tstamp = Date.now();
    this.companyFormGroup = _formBuiler.group({
      emailAddress: ['', Validators.email],
      name: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      kraPin: ['', Validators.required],
      KraDoc: ['', Validators.required],
      incorporationCert: ['', Validators.required],
      physicaladdress: ['', Validators.required],
      postalAddress: ['', Validators.required],
      postalCode: ['', Validators.required],
    });
    this.companyMultiUploadFormGroup = _formBuiler.group({
      fileObj: ['', Validators.required],
    });
  }

  onFileObjChange(idx: number) {
    this.activeFile = this.data.clientFiles[idx];
    this.activeFileName = this._tstamp + this.activeFile.suffix;
  }

  onCompanyFileObjChange(idx: number) {
    this.activeFile = this.companyFiles[idx];
    this.activeFileName = this._tstamp + this.activeFile.suffix;
  }

  uploadCompanyDoc($event) {
    switch (this.activeFile.value) {
      case 'kraPhoto':
        this.companyFormGroup.patchValue({
          KraDoc: $event,
        });
        break;
      case 'incorporationCert':
        this.companyFormGroup.patchValue({
          incorporationCert: $event,
        });
        break;
      default:
        break;
    }
  }

  setCompanyInformation() {
    if (this.data?.company) {
      this.companyFormGroup = this._formBuiler.group({
        emailAddress: [this.data?.company?.emailAddress, Validators.email],
        name: [this.data?.company?.name, Validators.required],
        phoneNumber: [this.data?.company?.phoneNumber, Validators.required],
        kraPin: [this.data?.company?.kraPin, Validators.required],
        KraDoc: [this.data?.company?.KraDoc, Validators.required],
        incorporationCert: [
          this.data?.company?.incorporationCert,
          Validators.required,
        ],
        physicaladdress: [
          this.data?.company?.physicaladdress,
          Validators.required,
        ],
        postalAddress: [this.data?.company?.postalAddress, Validators.required],
        postalCode: [this.data?.company?.postalCode, Validators.required],
      });
    }
  }

  getQuoteQuestions(
    res: ApiResponse,
    microService: string
  ): Observable<ApiResponse> {
    let q = res.Payload[0].questions_id;

    return this.appService.makePostRequest(
      `${microService}/admingetquestions`,
      {
        _id: q,
      }
    );
  }

  ngOnInit(): void {
    this.setCompanyInformation();
    this.getMoreQuestions();
  }

  getMoreQuestions() {
    this.isMoreQuestionsObjectAvailable = true;
    switch (this.data?.cover) {
      case 'personalaccident':
        this.appService
          .makePostRequest(`${environment.PA_API_URL}/getquestions`, {
            _id: this.data?.selectedRecordQuestionsId,
          })
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'motor':
        this.appService
          .makePostRequest(`${environment.MOTOR_API_URL}/getquestions`, {
            _id: this.data?.selectedRecordQuestionsId,
          })
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload;
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'medical':
        this.appService
          .makePostRequest(`${environment.MEDICAL_API_URL}/admingetquestions`, {
            _id: this.data?.selectedRecordQuestionsId,
          })
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'travel':
        this.appService
          .makePostRequest(`${environment.TRAVEL_API_URL}/admingetquestions`, {
            _id: this.data?.selectedRecordQuestionsId,
          })
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'domestic':
        this.appService
          .makePostRequest(
            `${environment.DOMESTIC_API_URL}/getdomesticquotequestions`,
            {
              _id: this.data?.selectedRecordQuestionsId,
            }
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'evacuation':
        this.appService
          .makePostRequest(`${environment.EVACUATION_API_URL}/getQuestions`, {
            _id: this.data?.selectedRecordQuestionsId,
          })
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'golf':
        this.appService
          .makePostRequest(`${environment.GOLF_API_URL}/getQuestions`, {
            _id: this.data?.selectedRecordQuestionsId,
          })
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'life':
        this.appService
          .makePostRequest(`${environment.LIFE_API_URL}/getQuestions`, {
            _id: this.data?.selectedRecordQuestionsId,
          })
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'other':
        this.appService
          .makePostRequest(`${environment.PA_API_URL}/getotherquestions`, {
            _id: this.data?.selectedRecordQuestionsId,
          })
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload;
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'unisure':
        this.appService
          .makePostRequest(
            `${environment.MEDICAL_API_URL}/unisureadmingetquestions`,
            {
              _id: this.data?.selectedRecordQuestionsId,
            }
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
    }
  }

  updateCompanyInfo() {
    this.isLoading = true;
    let company = this.companyFormGroup.value;
    let body = {
      _id: this.data.selectedRecordQuestionsId,
      update: {
        moreQuestions: {
          ...this.moreQuestions?.moreQuestions,
          company: {
            ...this.moreQuestions?.moreQuestions?.company,
            ...company,
          },
        },
      },
    };

    switch (this.data.cover) {
      case 'personalaccident':
        this.appService
          .makePostRequest(
            `${environment.PA_API_URL}/adminUpdateQuestionsGeneral`,
            body
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeSuccess,
                  'Update successful',
                  'Success'
                );
                this.dialogRef.close();
              } else {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'Update unsuccessful',
                  'Something went wrong'
                );
              }
            },
            (error) => {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                this.appService.processHttpErrors(error),
                'Error'
              );
            }
          );
        break;
      case 'medical':
        this.appService
          .makePostRequest(
            `${environment.MEDICAL_API_URL}/adminUpdateQuestionsGeneral`,
            body
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeSuccess,
                  'Update successful',
                  'Success'
                );
                this.dialogRef.close();
              } else {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'Update unsuccessful',
                  'Something went wrong'
                );
              }
            },
            (error) => {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                this.appService.processHttpErrors(error),
                'Error'
              );
            }
          );
        break;
      case 'motor':
        this.appService
          .makePostRequest(
            `${environment.MOTOR_API_URL}/adminUpdateQuestionsGeneral`,
            body
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeSuccess,
                  'Update successful',
                  'Success'
                );
                this.dialogRef.close();
              } else {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'Update unsuccessful',
                  'Something went wrong'
                );
              }
            },
            (error) => {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                this.appService.processHttpErrors(error),
                'Error'
              );
            }
          );
        break;
      case 'travel':
        this.appService
          .makePostRequest(
            `${environment.TRAVEL_API_URL}/adminUpdateQuestionsGeneral`,
            body
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeSuccess,
                  'Update successful',
                  'Success'
                );
                this.dialogRef.close();
              } else {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'Update unsuccessful',
                  'Something went wrong'
                );
              }
            },
            (error) => {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                this.appService.processHttpErrors(error),
                'Error'
              );
            }
          );
        break;
      case 'other':
        this.appService
          .makePostRequest(
            `${environment.PA_API_URL}/adminUpdateOtherGeneralQuestions`,
            body
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeSuccess,
                  'Update successful',
                  'Success'
                );
                this.dialogRef.close();
              } else {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'Update unsuccessful',
                  'Something went wrong'
                );
              }
            },
            (error) => {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                this.appService.processHttpErrors(error),
                'Error'
              );
            }
          );
        break;
      case 'unisure':
        this.appService
          .makePostRequest(
            `${environment.MEDICAL_API_URL}/adminUpdateUnisureGeneralQuestions`,
            body
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeSuccess,
                  'Update successful',
                  'Success'
                );
                this.dialogRef.close();
              } else {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'Update unsuccessful',
                  'Something went wrong'
                );
              }
            },
            (error) => {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                this.appService.processHttpErrors(error),
                'Error'
              );
            }
          );
        break;
    }
  }
}
