import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppEnums } from 'src/app/enums/app.enum';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-referral-link-selector',
  templateUrl: './referral-link-selector.component.html',
  styleUrls: ['./referral-link-selector.component.scss'],
})
export class ReferralLinkSelectorComponent implements OnInit {
  referralLinks: string[] = [];
  @Output() referralLinkEventEmitter: EventEmitter<string> =
    new EventEmitter<string>();
  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.getTPLinks();
  }

  getTPLinks() {
    this.appService.getAuthedThirdPartyFromCDN.subscribe(
      (res) => {
        this.referralLinks = res.links;
      },
      (err) => {
        this.appService.showToastMessage(
          AppEnums.ToastTypeError,
          '',
          'Failed to load refarral links'
        );
      }
    );
  }

  setReferralLink(e) {
    if (e.target.value) {
      this.referralLinkEventEmitter.emit(e.target.value);
    }
  }
}
