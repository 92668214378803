<nav aria-label="Page navigation example">
    <div class="loader-container" >
        <app-loader *ngIf="dataLoading"></app-loader>
    </div>
    <ul class="pagination justify-content-end">
      <li *ngIf="!dataLoading" class="page-item {{previousStatus}}">
        <a
          class="page-link"
          href="javascript:void(0)"
          (click)="goToPrevPage(skip, limit)"
          tabindex="-1"
          >Previous</a
        >
      </li>
      <li *ngIf="!dataLoading && dataLength === limit" class="page-item">
        <a
          class="page-link"
          href="javascript:void(0)"
          (click)="goToNextPage(skip, limit)"
          >Next</a
        >
      </li>
    </ul>
  </nav>