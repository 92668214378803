import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppEnums } from 'src/app/enums/app.enum';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import { PolicyOptionsComponent } from '../policy-options/policy-options.component';

@Component({
  selector: 'app-add-card-membership-infor-partial',
  templateUrl: './add-card-membership-infor-partial.component.html',
  styleUrls: ['./add-card-membership-infor-partial.component.scss'],
})
export class AddCardMembershipInforPartialComponent implements OnInit {
  cardFormGroup: FormGroup;

  isLoading: boolean = false;
  cardMembersDetails: any[] = [];
  allCardMembers: any[] = [];
  questionsId: string = '';

  constructor(
    private _formBuilder: FormBuilder,
    private appService: AppService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PolicyOptionsComponent>
  ) {
    this.cardFormGroup = _formBuilder.group({
      member: [''],
      name: ['', Validators.required],
      memberNo: ['', Validators.required],
      schemeName: ['', Validators.required],
      dateIssued: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.prepareCardMembersInfo();
  }

  convertDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  prepareCardMembersInfo() {
    if (this.data.hasOwnProperty('policy')) {
      const policy = this.data.policy;
      if (policy.hasOwnProperty('questions_id')) {
        this.questionsId = policy?.questions_id;
      }
      if (policy?.questions?.moreQuestions) {
        const moreQuestions = policy?.questions?.moreQuestions;
        if (moreQuestions?.company) {
          if (moreQuestions?.company.hasOwnProperty('name')) {
            const principal = {
              name: moreQuestions?.company?.name,
              relationship: 'PRINCIPAL',
            };
            this.cardMembersDetails.push(principal);
          }
        } else if (moreQuestions?.client) {
          if (moreQuestions?.client.hasOwnProperty('userName')) {
            const principal = {
              name: moreQuestions?.client?.userName,
              relationship: 'PRINCIPAL',
            };
            this.cardMembersDetails.push(principal);
          }
        }

        if (moreQuestions.hasOwnProperty('dependents')) {
          if (moreQuestions?.dependents?.length > 0) {
            moreQuestions?.dependents.forEach((dep) => {
              let dependentObj = {
                name: dep.name,
                relationship: 'DEPENDANT',
              };
              this.cardMembersDetails.push(dependentObj);
            });
          }
        }
      }
    }
  }

  addMember() {
    if (!this.cardFormGroup.valid) {
      const controls = this.cardFormGroup.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            'Field Required',
            name
          );
        }
      }
      return;
    }
    let name = this.cardFormGroup.get('name').value;
    let schemeName = this.cardFormGroup.get('schemeName').value;
    let memberNo = this.cardFormGroup.get('memberNo').value;
    let dateIssued = this.cardFormGroup.get('dateIssued').value;

    let obj = {
      name: name,
      schemeName: schemeName,
      memberNo: memberNo,
      dateIssued: dateIssued,
    };

    this.allCardMembers.push(obj);
    this.cardFormGroup.reset();
  }

  removeMember(idx: number) {
    if (idx !== -1) {
      this.allCardMembers.splice(idx, 1);
    }
  }

  memberChange(event: string) {
    this.cardFormGroup = this._formBuilder.group({
      member: [''],
      schemeName: [''],
      name: [event],
      memberNo: [''],
      dateIssued: [''],
    });
  }

  validateMember() {
    this.isLoading = true;
    let cardMemberPayload: any;
    let dependantsList: any[] = [];
    let pCard: any;
    let dCard: any;
    this.allCardMembers.forEach((member) => {
      this.cardMembersDetails.forEach((cMember) => {
        if (member.name == cMember.name) {
          if (cMember.relationship == 'PRINCIPAL') {
            pCard = {
              principal: {
                schemeName: member.schemeName,
                name: member.name,
                memberNo: member.memberNo,
                dateIssued: this.convertDate(member.dateIssued),
              },
            };
          } else if (cMember.relationship == 'DEPENDANT') {
            let dependant = {
              schemeName: member.schemeName,
              name: member.name,
              memberNo: member.memberNo,
              dateIssued: this.convertDate(member.dateIssued),
            };
            dependantsList.push(dependant);
            dCard = {
              dependants: dependantsList,
            };
          }
        }
      });
    });
    cardMemberPayload = { ...pCard, ...dCard };
    if (JSON.stringify(cardMemberPayload) === '{}') {
      this.appService.showToastMessage(
        AppEnums.ToastTypeInfo,
        'Payload in empty',
        'Please add card member information'
      );
      this.isLoading = false;
    } else {
      this.appService
        .makePostRequest(
          `${environment.MEDICAL_API_URL}/adminUpdateGeneralQuestions`,
          {
            _id: this.questionsId,
            update: {
              moreQuestions: {
                card: cardMemberPayload,
              },
            },
          }
        )
        .subscribe(
          (res) => {
            this.isLoading = false;
            if (res.Status == 200) {
              this.appService.showToastMessage(
                AppEnums.ToastTypeSuccess,
                'Success',
                ''
              );
              this.dialogRef.close();
            } else {
              this.appService.showToastMessage(
                AppEnums.ToastTypeSuccess,
                `${res.Message}`,
                'Warning'
              );
            }
          },
          (error) => {
            this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              this.appService.processHttpErrors(error),
              'Error'
            );
          }
        );
    }
  }
}
