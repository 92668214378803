import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppEnums } from 'src/app/enums/app.enum';
import { AppService } from 'src/app/services/app.service';

export interface IBulkCommissionPaymentResponse {
  reference: string;
  received: number;
  disbursed: number;
  quotes: string[];
}

@Component({
  selector: 'app-bulk-pay-commission-dialog',
  templateUrl: './bulk-pay-commission-dialog.component.html',
  styleUrls: ['./bulk-pay-commission-dialog.component.scss'],
})
export class BulkPayCommissionDialogComponent implements OnInit {
  isLoading: boolean = false;
  agencyId: string;
  formGroupAddBulkCommission: FormGroup;
  bulkCommissionPaymentResponse: IBulkCommissionPaymentResponse = {
    reference: 'NA',
    received: 0,
    disbursed: 0,
    quotes: [],
  };
  constructor(
    public appService: AppService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<BulkPayCommissionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.formGroupAddBulkCommission = this.formBuilder.group({
      underwriter: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      reference: new FormControl('', Validators.compose([Validators.required])),
      received: new FormControl('', Validators.compose([Validators.required])),
    });
  }

  ngOnInit(): void {}
  submitCommission() {
    if (!this.formGroupAddBulkCommission.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Fill form correctly'
      );
      return;
    }
    if (
      !this.appService.isNumber(
        this.formGroupAddBulkCommission.get('received').value
      )
    ) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Fill form correctly'
      );
      return;
    }
    let data = this.formGroupAddBulkCommission.value;
    let url = this.appService.getPolicyBaseUrl(this.data.product);
    this.isLoading = true;
    this.appService
      .makePostRequest(`${url}/bulkrecordcommissionpayment`, data)
      .subscribe(
        (res) => {
          this.bulkCommissionPaymentResponse = res.Payload;

          if (res.Status === 200) {
            this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              '',
              'Commissions recorded successfully'
            );
            this.formGroupAddBulkCommission.reset();
          } else {
            if (this.bulkCommissionPaymentResponse.quotes.length === 0) {
              this.appService.showToastMessage(
                AppEnums.ToastTypeInfo,
                '',
                'The commission could not be disbursed. No commission balances exist'
              );
            } else {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                res.Message
              );
            }
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
        }
      );
  }
}
