import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppEnums } from 'src/app/enums/app.enum';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit {
  @Input() azureFileLocation: string;
  @Input() azureFileName: string;
  isLoading: boolean = false;
  fileLink: string = './assets/upload.png';
  @Output()
  emitFileUploadedEvent: EventEmitter<string> = new EventEmitter<string>();
  imagesFormGroup: FormGroup;
  aFile: File;
  contentType: string;
  fileExtension: string;
  finalFileName: string;
  sasToken: string;
  @Input() allowedFileExtentions: string[];

  constructor(private appService: AppService) {
    this.appService
      .makeGetRequest(`${environment.CORE_API_URL}/getSASkey`)
      ?.subscribe((sasToken) => {
        this.sasToken = sasToken.Payload;
      });
  }

  ngOnInit(): void {}
  onFileSelected(e) {
    this.aFile = e.target.files[0];
    this.contentType = this.aFile?.type;
    this.fileExtension = this.getFileExtension(this.aFile?.name);
    if (!this.allowedFileExtentions.includes(this.fileExtension)) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeError,
        'Invalid File',
        `Only the following files are allowed:  ${this.allowedFileExtentions.toString()}`
      );
      return;
    }
    this.isLoading = true;
    let fileName = `${this.azureFileName}.${this.fileExtension}`;
    let url = `${environment.AZURE_BLOB_MTEK_UPLOADS_URL}/${this.azureFileLocation}/${fileName}`;
    let fullUrl = `${url}${this.sasToken}`;
    this.appService
      .uploadAzureImages(fullUrl, this.aFile, fileName, this.contentType)
      ?.subscribe(
        (event) => {
          if (event instanceof HttpResponse) {
            let res = event.status;
            if (res == 201) {
              this.isLoading = false;
              this.emitFileUploadedEvent.emit(url);
              this.fileLink = './assets/checked.png';
              this.appService.showToastMessage(
                AppEnums.ToastTypeInfo,
                '',
                `File uploaded successfully!`
              );
            } else {
              this.isLoading = false;
              this.fileLink = './assets/cancel.png';
              this.appService.showToastMessage(
                AppEnums.ToastTypeWarning,
                '',
                `Operation return status ${event.status}`
              );
            }
          }
        },
        (err) => {
          this.isLoading = true;
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            `Could not upload the file!`
          );
        }
      );
  }
  getFileExtension(fileName: string): string {
    let arr = fileName.split('.');
    return arr[arr.length - 1];
  }
}
