<div *ngIf="isLife">
  <hr />
  <h3>Payment logs</h3>
  <hr />

  <div
    *ngIf="selectedRecord._nextPaymentTimestamp && selectedRecord.paidamount < paymentdetails?.payable; else fullyPaid">
    <div class="alert alert-danger mt-2 text-center" role="alert">
      Total Paid is
      <strong>{{ selectedRecord.paidamount | currency: 'Ksh. ' }}</strong>.
      The balance is
      <strong>{{
        paymentdetails?.payable - selectedRecord.paidamount | currency: 'Ksh. '
        }}</strong>
    </div>
    <div class="alert alert-warning mt-2 text-center" role="alert">
      The next installment of
      <strong>{{
        paymentdetails?.payable | currency: 'Ksh. '
        }}</strong>
      is expected on
      <strong>{{
        selectedRecord?._nextPaymentTimestamp | date: 'fullDate'
        }}</strong>
    </div>
  </div>

  <div class="list-group" *ngIf="paymentLogs.length > 0; else noPaymentLogs">
    <div *ngFor="let aPaymentLog of paymentLogs" class="list-group-item flex-column align-items-start">
      <div class="row">
        <p class="col-md">
          <span class="d-block text-info">Transaction Date </span>
          {{ aPaymentLog?._timestamp | date: 'medium' }}
        </p>
        <p class="col-md">
          <span class="d-block text-info">Payment Mode </span>
          {{ aPaymentLog?.transactionType }}
        </p>
        <p class="col-md">
          <span class="d-block text-info">Amount </span>
          <span *ngIf="aPaymentLog.hasOwnProperty('paidAmount') ; else noPaidAmount">
            {{ aPaymentLog?.paidAmount | currency: 'Ksh. ' }}
          </span>
          <ng-template #noPaidAmount>
            {{ aPaymentLog?.amount | currency: 'Ksh. ' }}
          </ng-template>
        </p>
        <p class="col-md" *ngIf="aPaymentLog?.details?.hasOwnProperty('mpesaReference'); else isTransactionRef">
          <span class="d-block text-info">Payment Ref </span>
          {{ aPaymentLog?.details?.mpesaReference }}
        </p>
        <ng-template #isTransactionRef>
          <p class="col-md">
            <span class="d-block text-info">Payment REF </span>
            {{ aPaymentLog?.details?.transactionRef }}
          </p>
        </ng-template>
        <p class="col-md">
          <span class="d-block text-info">MTEK REF </span>
          {{ aPaymentLog?.mtekPaymentRef }}
        </p>
        <div>
          <p class="col-md">
            <span class="d-block text-info">Paid To </span>
            {{ (aPaymentLog?.broker | underwriter| async)}}

          </p>
        </div>

      </div>
      <div *ngIf="aPaymentLog?.hasOwnProperty('service')">
        <p *ngIf="aPaymentLog?.service?.hasOwnProperty('transactionDescription')">
          <span class="d-block text-info">Payment Description </span>
          {{ aPaymentLog?.service?.transactionDescription }}
        </p>
      </div>
    </div>
  </div>
  <hr />
  <h3>Payments Schedule</h3>
  <hr />

  <div *ngIf="paymentdetails.hasOwnProperty('paymentsSchedule'); else noPaymentSchedule">
    <div class="list-group" *ngIf="paymentdetails?.paymentsSchedule.length > 0; else noPaymentSchedule">
      <div *ngFor="let paymentSchedule of paymentdetails?.paymentsSchedule"
        class="list-group-item flex-column align-items-start">
        <div class="row">
          <p class="col-md">
            <span class="d-block text-info">Transaction Date </span>
            {{ paymentSchedule?._timestamp | date: 'medium' }}
          </p>
          <p class="col-md">
            <span class="d-block text-info">Amount </span>
            <span>
              {{ paymentSchedule?.amount | currency: 'Ksh. ' }}
            </span>
          </p>
          <p class="col-md">
            <span class="d-block text-info">Payment Ref </span>
            {{ paymentSchedule?.transactionReference }}
          </p>

          <p class="col-md">
            <span class="d-block text-info">Month </span>
            {{ months[paymentSchedule?.month - 1] }}
          </p>

          <p class="col-md">
            <span class="d-block text-info">Paid </span>
            {{ paymentSchedule?.paid }}
          </p>

        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isLife">
  <div class="list-group" *ngIf="paymentLogs.length > 0; else noPaymentLogs">
    <div *ngFor="let aPaymentLog of paymentLogs" class="list-group-item flex-column align-items-start">
      <div class="row">
        <p class="col-md">
          <span class="d-block text-info">Transaction Date </span>
          {{ aPaymentLog?._timestamp | date: 'medium' }}
        </p>
        <p class="col-md">
          <span class="d-block text-info">Payment Mode </span>
          {{ aPaymentLog?.transactionType }}
        </p>
        <p class="col-md">
          <span class="d-block text-info">Amount </span>
          <span *ngIf="aPaymentLog.hasOwnProperty('paidAmount') ; else noPaidAmount">
            {{ aPaymentLog?.paidAmount | currency: 'Ksh. ' }}
          </span>
          <ng-template #noPaidAmount>
            {{ aPaymentLog?.amount | currency: 'Ksh. ' }}
          </ng-template>
        </p>
        <p class="col-md" *ngIf="aPaymentLog?.details?.hasOwnProperty('mpesaReference'); else isTransactionRef">
          <span class="d-block text-info">Payment Ref </span>
          {{ aPaymentLog?.details?.mpesaReference }}
        </p>
        <ng-template #isTransactionRef>
          <p class="col-md">
            <span class="d-block text-info">Payment REF </span>
            {{ aPaymentLog?.details?.transactionRef }}
          </p>
        </ng-template>
        <p class="col-md">
          <span class="d-block text-info">MTEK REF </span>
          {{ aPaymentLog?.mtekPaymentRef }}
        </p>
        <div>
          <p class="col-md">
            <span class="d-block text-info">Paid To </span>
            {{ (aPaymentLog?.broker | underwriter| async)}}

          </p>
        </div>

      </div>
      <div *ngIf="aPaymentLog?.hasOwnProperty('service')">
        <p *ngIf="aPaymentLog?.service?.hasOwnProperty('transactionDescription')">
          <span class="d-block text-info">Payment Description </span>
          {{ aPaymentLog?.service?.transactionDescription }}
        </p>
      </div>
    </div>
  </div>

</div>

<ng-template #noPaymentLogs>
  <div class="alert alert-danger">
    There are no successful payments recorded for this policy record.
  </div>
</ng-template>
<ng-template #noPaymentSchedule>
  <div class="alert alert-danger">
    There are no payments schedule recorded for this policy record.
  </div>
</ng-template>