import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import { ECovers } from 'src/app/enums/covers.enum';

import {
  ApexNonAxisChartSeries,
  ApexAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
  ApexTitleSubtitle,
} from 'ng-apexcharts';
import { AppEnums } from 'src/app/enums/app.enum';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
  labels: any;
  colors: string[];
};

export interface ITheme {
  bg: string;
  textColor: string;
}

interface Irange {
  end_date: number;
  start_date: number;
}

@Component({
  selector: 'app-policy-stats',
  templateUrl: './policy-stats.component.html',
  styleUrls: ['./policy-stats.component.scss'],
})
export class PolicyStatsComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  polTotal = 0;
  quoteTotal = 0;
  quotesData = [];
  policyData = [];
  polArr = [];
  quotesArr = [];

  quotesLoaded: boolean = false;
  policyLoaded: boolean = false;

  ECovers = ECovers;

  themes: ITheme[] = [
    { bg: 'bg-gradient-info', textColor: 'text-info' },
    { bg: 'bg-gradient-success', textColor: 'text-success' },
    { bg: 'bg-gradient-danger', textColor: 'text-danger' },
    { bg: 'bg-gradient-warning', textColor: 'text-warning' },
    { bg: 'bg-gradient-blue', textColor: 'text-violet' },
    { bg: 'bg-gradient-dark', textColor: 'text-dark' },
    { bg: 'bg-gradient-indigo', textColor: 'text-indigo' },
    { bg: 'bg-gradient-info', textColor: 'text-info' },
    { bg: 'bg-gradient-info', textColor: 'text-info' },
    { bg: 'bg-gradient-info', textColor: 'text-info' },
    { bg: 'bg-gradient-info', textColor: 'text-info' },
    { bg: 'bg-gradient-info', textColor: 'text-info' },
  ];


  totalQuotesArray: any[] = [];
  totalPoliciesArray: any[] = [];

  selectedDateRange: Irange;

  showLoader: boolean = true;
  chartsLoaded: boolean = false;

  constructor(private appService: AppService) {
    let todayStamp = Date.now();
    let today = new Date(todayStamp);
    let last30Days = new Date(Date.now() - 2592000000);

    let startDate =
      last30Days.getFullYear() +
      '-' +
      (last30Days.getMonth() + 1) +
      '-' +
      last30Days.getDate();

    let endDate =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate();

    this.selectedDateRange = {
      end_date: Date.parse(endDate) + 86340000,
      start_date: Date.parse(startDate),
    };
  }

  ngOnInit(): void {
    this.getPolicySummary();
    this.getQuotesSummary();
  }

  updateRange(range) {
    this.selectedDateRange.start_date = Date.parse(range.start_date);
    this.selectedDateRange.end_date = Date.parse(range.end_date) + 86340000;
    this.quoteTotal = 0;
    this.polTotal = 0;
    this.polArr = [];
    this.quotesArr = [];
    this.totalPoliciesArray = [];
    this.totalQuotesArray = [];
    this.quotesLoaded = false;
    this.policyLoaded = false;
    this.chartsLoaded = false;
    this.getPolicySummary();
    this.getQuotesSummary();
  }

  getPolicySummary() {
    this.appService
      .makePostRequest(`${environment.CORE_API_URL}/policystats`, {
        match: {
          salesDateTimestamp: {
            $gte: this.selectedDateRange.start_date,
            $lte: this.selectedDateRange.end_date,
          },
          paidamount: {
            $gt: 0,
          },
          ModuleID: AppEnums.PartnerkCB,
          insurer: {
            $exists: true,
          },
          // product: AppEnums.PRODUCT_MOTOR,
        },
        idFields: ['insurerName', 'insurer'],
      })
      .subscribe((apiResponse: any) => {
        this.policyData = apiResponse.Payload;
        let totalPol: any = {
          count: 0,
          cover: AppEnums.PRODUCT_MOTOR,
        };
        this.policyData?.forEach((item: any) => {
          let pol: any = {};
          pol.policy = item._id.policy;
          pol.records = [];
          totalPol.count +=
            item.single_policy_count +
            item.group_sales -
            item.group_policy_count;
        });
        this.polArr.push(totalPol);
        this.polArr?.sort((a, b) => {
          return b.count - a.count;
        });

        this.polTotal = totalPol.count;
        this.policyLoaded = true;
        this.showLoader = false;

        if (this.quotesLoaded) {
          this.displayChart();
        }
      });
  }

  getQuotesSummary() {
    this.appService
      .makePostRequest(`${environment.CORE_API_URL}/policystats`, {
        match: {
          _timestamp: {
            $gte: this.selectedDateRange.start_date,
            $lte: this.selectedDateRange.end_date,
          },
          $or: [
            {
              paidamount: {
                $lte: 0,
              },
            },
            {
              paidamount: {
                $exists: false,
              },
            },
          ],
          ModuleID: AppEnums.PartnerkCB,
          insurer: {
            $exists: true,
          },
          // product: AppEnums.PRODUCT_MOTOR,
        },
        idFields: ['insurerName', 'insurer'],
      })
      .subscribe((apiResponse: any) => {
        this.quotesData = apiResponse.Payload;
        let totalQuotes: any = {
          count: 0,
          cover: AppEnums.PRODUCT_MOTOR,
        };
        this.quotesData?.forEach((item: any) => {
          let quote: any = {};
          quote.policy = item._id.policy;
          quote.records = [];
          totalQuotes.count +=
            item.single_policy_count +
            item.group_sales -
            item.group_policy_count;
        });
        this.quoteTotal = totalQuotes.count;
        this.quotesArr.push(totalQuotes);

        this.quotesArr?.sort((a, b) => {
          return b.count - a.count;
        });

        this.quotesLoaded = true;

        this.showLoader = false;

        if (this.policyLoaded) {
          this.displayChart();
        }
      });
  }
  displayChart() {

    this.polArr.forEach(val => {
      this.totalPoliciesArray.push({
        x: val.cover,
        y: val.count
      });
    })

    this.quotesArr.forEach(val => {
      this.totalQuotesArray.push({
        x: val.cover,
        y: val.count
      });
    })

    this.chartOptions = {
      series: [
        {
          name: 'Policies',
          data: this.totalPoliciesArray
        },
        {
          name: 'Quotes',
          data: this.totalQuotesArray
        }
      ],
      colors: [
        '#00e396',
        '#f65740',
        '#fa8440',
        '#7367e5',
        '#212429',
        '#7703ac',
      ],
      chart: {
        type: 'bar',
        height: 450,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          //endingShape: "rounded"
        },
      },
      title: {
        text: 'Policies vs Quotes per Cover',
        align: 'center',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        type: 'category'
      },
      yaxis: {
        title: {
          text: 'Total Number',
        },
      },
      fill: {
        opacity: 1,
      },
      labels: [],
      tooltip: {
        y: {
          formatter: function (val) {
            return ' ' + val;
          },
        },
      },
    };

    this.chartsLoaded = true;
  }


}
