<form [formGroup]="formGroupDownloadReport">
  <app-loader *ngIf="isLoading"></app-loader>
  <h1 mat-dialog-title *ngIf="data?.referralCode">Download Detailed Report</h1>
  <mat-form-field>
    <input
      matInput
      [matDatepicker]="startDatePicker"
      placeholder="Start Date"
      formControlName="startDate"
    />
    <mat-datepicker-toggle
      matSuffix
      [for]="startDatePicker"
    ></mat-datepicker-toggle>
    <mat-datepicker #startDatePicker></mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <input
      matInput
      [matDatepicker]="endDatePicker"
      placeholder="End Date"
      formControlName="endDate"
    />
    <mat-datepicker-toggle
      matSuffix
      [for]="endDatePicker"
    ></mat-datepicker-toggle>
    <mat-datepicker #endDatePicker></mat-datepicker>
  </mat-form-field>
</form>
<div class="buttons">
  <button mat-button mat-dialog-close *ngIf="data?.referralCode">Cancel</button>
  <button mat-button color="primary" (click)="submitData()">
    Download Report
  </button>
</div>
