<h3>INTEGRATED PRODUCTS</h3>
<div class="badges-wrapper">
  <div *ngFor="let product of underwriterProducts; index as indexOfelement"
    class="badges-container {{themes[indexOfelement].bg}}">
    <span>{{product.policyName}}</span>
  </div>
</div>
<br /><app-date-range-picker-dashboard (dateRange)="updateRange($event)"></app-date-range-picker-dashboard>
<div class="row">
  <div class="cover-card-container col-lg-4">
    <div class="card cover-card-wrapper bg-white">
      <h3 class="text-center text-black">TOTAL</h3>
      <div class="row row-wrapper">
        <div>
          <div class="icon-container bg-gradient-dark col-lg-5">
            <i class="fa fa-users text-white"></i>
          </div>
        </div>
        <span class="col-lg-4 r-label">Claims</span>
        <span class="col-lg-4 val text-center">{{totalClaims}}</span>
      </div>
    </div>
  </div>
  <div *ngFor="let cover of policySummeries; index as indexOfelement" class="cover-card-container col-lg-4">
    <div class="card cover-card-wrapper bg-white">
      <h3 class="text-center {{ themes[indexOfelement].textColor }}">{{ cover.displayName }}</h3>
      <div class="row row-wrapper">
        <div>
          <div class="icon-container {{ themes[indexOfelement].bg }} col-lg-5">
            <i class="fa fa-users text-white"></i>
          </div>
        </div>
        <span class="col-lg-4 r-label">Claims</span>
        <span class="col-lg-4 val text-center">{{cover.claims}}</span>
      </div>
    </div>
  </div>
</div>
<br>
<div class="card chart-container">
  <app-loader *ngIf="!chartDataLoaded"></app-loader>
  <apx-chart *ngIf="chartDataLoaded" [series]="chartOptions.series" [chart]="chartOptions.chart"
    [colors]="chartOptions.colors" [plotOptions]="chartOptions.plotOptions" [dataLabels]="chartOptions.dataLabels"
    [legend]="chartOptions.legend" [grid]="chartOptions.grid" [xaxis]="chartOptions.xaxis"
    [title]="chartOptions.title"></apx-chart>
</div>

<br>