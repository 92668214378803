import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppEnums } from 'src/app/enums/app.enum';
import { AppService } from 'src/app/services/app.service';
import { ApiService } from 'src/app/services/ntsa_services/api.service';
import { environment } from 'src/environments/environment';
export interface ICommissionReportRef {
  _id: string;
  refNo: string;
  policy: string;
  _date: any;
  _timestamp: number;
  userid: string;
  feduid: string;
  name: string;
  _udate: any;
  _utimestamp: number;
}
@Component({
  selector: 'app-download-commission-statement-dialog',
  templateUrl: './download-commission-statement-dialog.component.html',
  styleUrls: ['./download-commission-statement-dialog.component.scss'],
})
export class DownloadCommissionStatementDialogComponent implements OnInit {
  isLoading: boolean = false;
  agencyId: string;
  formGroupCommStmt: FormGroup;
  downloadedFilename: string = 'COMMISSION_STATEMENT';
  reportReferenceNumbers: ICommissionReportRef[] = [];

  /**
   * Store the report file link return
   * after excel file is created
   */
  reportLink: string;

  constructor(
    public appService: AppService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DownloadCommissionStatementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.formGroupCommStmt = this.formBuilder.group({
      underwriter: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),

      startDate: new FormControl('', Validators.compose([Validators.required])),
      endDate: new FormControl('', Validators.compose([Validators.required])),
    });
  }

  ngOnInit(): void {
    this.fetchPreviousStatements();
  }
  downloadOldStatement(refNo: string) {
    this.isLoading = true;
    this.downloadedFilename = `${refNo}.pdf`;
    let url = this.appService.getPolicyBaseUrl(this.data.product);
    this.downloadPdf(`${url}/getcommissionstatement`, { refNo: refNo });
  }

  fetchPreviousStatements() {
    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.CORE_API_URL}/commissionreportrefs`, {
        policy: this.data.product,
      })
      .subscribe(
        (data) => {
          this.reportReferenceNumbers = data.Payload;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
        }
      );
  }

  downloadStatement() {
    if (!this.formGroupCommStmt.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeInfo,
        '',
        'Select all fields'
      );
      return;
    }
    let startDate = this.formGroupCommStmt.value.startDate;
    let endDate = this.formGroupCommStmt.value.endDate;
    if (endDate.getTime() < startDate.getTime()) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Invalid Start/End Date Selection'
      );
      return;
    }
    let data = {
      fields: {
        stage: 1,
        _id: 1,
        quoteNo: 1,
        insurer: 1,
        insurerName: 1,
        questions: 1,
        certificate: 1,
        paymentdetails: 1,
        amount: 1,
        commission: 1,
      },
    };
    data['underwriter'] = this.formGroupCommStmt.get('underwriter').value;
    data['isReporting'] = true;
    if (endDate.getTime() == startDate.getTime()) {
      data['startdate'] = new Date(startDate).getTime();
    } else {
      data['startdate'] = new Date(startDate).getTime();
      data['enddate'] = new Date(endDate).getTime();
    }

    this.isLoading = true;
    let url = this.appService.getPolicyBaseUrl(this.data.product);
    this.exportPdf(`${url}/getcommissionstatementreport`, data);
  }

  exportPdf(url: string, data: any) {
    this.appService.makePostRequest(url, data).subscribe(
      (res) => {
        if (res.Status == 200) {
          this.getReportsReferences(res.Payload);
        } else {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            'Error in retrieving report data'
          );
        }
      },
      (err) => {
        this.isLoading = false;
        this.appService.showToastMessage(
          AppEnums.ToastTypeError,
          '',
          this.appService.processHttpErrors(err)
        );
      }
    );
  }

  downloadPdf(url: string, data: any) {
    this.appService.makePdfDownloadPostRequest(url, data).subscribe(
      (data) => {
        if (data.size < 100) {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            'No records found'
          );
        } else {
          this.downloadReport(data);
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        this.appService.showToastMessage(
          AppEnums.ToastTypeError,
          '',
          this.appService.processHttpErrors(err)
        );
      }
    );
  }

  /**
   * Get previous report ref with outstanding balance
   * @param data Report query data
   */
  public getReportsReferences(data: any) {
    this.isLoading = true;
    this.appService.getReportsReferences(data).subscribe((res) => {
      if (res.Status == 200) {
        let reportData = {
          data: data,
          refs: res.Payload,
        };

        //this.getExportFile(reportData);
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.appService.showToastMessage(
          AppEnums.ToastTypeWarning,
          `${res.Status}`,
          res.Message
        );
      }
      (err) => {
        this.isLoading = false;
        this.appService.showToastMessage(
          AppEnums.ToastTypeError,
          '',
          this.appService.processHttpErrors(err)
        );
      };
    });
  }

  /**
   * Export commission statement to pdf
   * @param exportData The payment plan export data
   */
  public getExportFile(exportData: any) {
    let filters = {
      policy: this.data.product,
      startDate: this.formGroupCommStmt.value.startDate,
      endDate: this.formGroupCommStmt.value.endDate,
    };

    let data = {
      reportType: 'PDF',
      reportName: 'commission_statement_report',
      reportData: exportData,
      generatedBy: this.appService.getSessionUser()?.email,
      name: this.appService.getSessionUser()?.userName,
      temp: 0,
      filters: filters,
    };

    if (this.appService.isAdminOrThirdParty()) {
      this.isLoading = true;

      this.appService.getCommisionStatementReportFile(data).subscribe((res) => {
        if (res.Status == 200) {
          this.appService.getSasToken().subscribe((sasToken) => {
            if (res.Payload.hasOwnProperty('url')) {
              this.reportLink = `${res.Payload.url}${sasToken}`;

              //TODO: get refNo

              /*let data = {
                "refNo": "",
                "url": res.Payload.url
              }
              */

              //update/upload report
              //this.updateReportReference(data);

              this.appService.showToastMessage(
                AppEnums.ToastTypeSuccess,
                '',
                'Report Generated Successfully'
              );
            }
            this.isLoading = false;
          });
        } else {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            `${res.Status}`,
            res.Message
          );
        }
        (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
        };
      });
    }
  }

  /**
   * Update refNo and upload report url
   * @param data Request data
   */
  public updateReportReference(data: any) {
    this.isLoading = true;
    this.appService.updateReportReference(data).subscribe((res) => {
      if (res.Status == 200) {
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.appService.showToastMessage(
          AppEnums.ToastTypeWarning,
          `${res.Status}`,
          res.Message
        );
      }
      (err) => {
        this.isLoading = false;
        this.appService.showToastMessage(
          AppEnums.ToastTypeError,
          '',
          this.appService.processHttpErrors(err)
        );
      };
    });
  }

  downloadReport(data: any) {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(
      new Blob([data], {
        type: 'application/octet-stream',
      })
    );
    a.setAttribute('target', '_blank');
    a.setAttribute('download', this.downloadedFilename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
