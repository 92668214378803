import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AppEnums } from 'src/app/enums/app.enum';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-domestic-declaration-report',
  templateUrl: './domestic-declaration-report.component.html',
  styleUrls: ['./domestic-declaration-report.component.scss'],
})
export class DomesticDeclarationReportComponent implements OnInit {
  isLoading: boolean = false;
  formGroupDownloadReport: FormGroup;
  types = [];
  constructor(
    public appService: AppService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<DomesticDeclarationReportComponent>
  ) {
    this.formGroupDownloadReport = this.formBuilder.group({
      reportType: ['', Validators.compose([Validators.required])],
      startDate: ['', Validators.compose([Validators.required])],
      endDate: ['', Validators.compose([Validators.required])],
      insurer: [''],
    });

    this.types = [
      { name: 'Generated Quotes', value: 'QUOTE' },
      { name: 'Policies Issued', value: 'POLICY' },
    ].filter((aReport) => {
      if (!this.appService.isAdmin()) {
        return aReport.value === 'POLICY';
      } else {
        return true;
      }
    });
  }

  ngOnInit(): void {}
  submitInfo() {
    if (!this.formGroupDownloadReport.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeInfo,
        '',
        'Select all fields'
      );
      return;
    }
    let startDate = this.formGroupDownloadReport.value.startDate;
    let endDate = this.formGroupDownloadReport.value.endDate;
    let reportType = this.formGroupDownloadReport.value.reportType;
    if (endDate.getTime() < startDate.getTime()) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Invalid Start/End Date Selection'
      );
      return;
    }
    let payload: any;
    if (endDate.getTime() == startDate.getTime()) {
      let startTimestamp = new Date(startDate).getTime();
      let endTimestamp = startTimestamp + 86400000;
      payload = {
        startDate: startTimestamp,
        endDate: endTimestamp,
      };
    } else {
      let startTimestamp = new Date(startDate).getTime();
      let endTimestamp = new Date(endDate).getTime() + 86400000;
      payload = {
        startDate: startTimestamp,
        endDate: endTimestamp,
      };
    }

    if (this.appService.isPartner()) {
      this.getPartnerReport('domestic_uw_report', payload);
    } else if (this.appService.isThirdParty()) {
      this.getThirdPartyReport('domestic_tp_report', payload);
    } else if (this.appService.isAdmin) {
      if (reportType === 'POLICY') {
        this.getAdminReport('domestic_uw_report', payload);
      } else if (reportType === 'QUOTE') {
        this.getAdminReport('domestic_quote_uw_report', payload);
      }
    }
  }

  getAdminReport(reportUrl: string, payload: any) {
    let insurer = this.formGroupDownloadReport.get('insurer').value;
    let sendTo = this.appService.getSessionUser()?.email;
    const finalPayload = {
      startDate: payload?.startDate,
      endDate: payload?.endDate,
      underwriter: insurer,
      sendTo: sendTo,
    };
    this.downloadReport(reportUrl, finalPayload);
  }

  getPartnerReport(reportUrl: string, payload: any) {
    let insurer = this.appService.getSessionUser().partner;
    let sendTo = this.appService.getSessionUser()?.email;
    const finalPayload = {
      startDate: payload?.startDate,
      endDate: payload?.endDate,
      underwriter: insurer,
      sendTo: sendTo,
    };
    this.downloadReport(reportUrl, finalPayload);
  }

  getThirdPartyReport(reportUrl: string, payload: any) {
    let ModuleID = this.appService.getSessionUser().thirdPartyId;
    let sendTo = this.appService.getSessionUser()?.email;
    const finalPayload = {
      startDate: payload?.startDate,
      endDate: payload?.endDate,
      ModuleID: ModuleID,
      sendTo: sendTo,
    };
    this.downloadReport(reportUrl, finalPayload);
  }

  downloadReport(reportUrl, payload) {
    this.isLoading = true;
    this.appService
      .makePostRequest(
        `${environment.DATA_ANALYTICS_API_URL}/${reportUrl}`,
        payload
      )
      .subscribe(
        (res) => {
          this.isLoading = false;
          if (res.Status == 200) {
            this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              `Email sent to ${payload?.sendTo}`,
              'Success'
            );
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              res.Message,
              'Warning'
            );
          }
        },
        (error) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            this.appService.processHttpErrors(error),
            'Error'
          );
        }
      );
  }
}
