import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AppEnums } from 'src/app/enums/app.enum';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-policy-post-commission',
  templateUrl: './policy-post-commission.component.html',
  styleUrls: ['./policy-post-commission.component.scss'],
})
export class PolicyPostCommissionComponent implements OnInit {
  isLoading: boolean = false;
  @Input() selectedRecordId: string;
  formGroupPostCommission: FormGroup;
  @Output() emitSaveCommission = new EventEmitter<string>();

  constructor(public appService: AppService, private formBuilder: FormBuilder) {
    this.formGroupPostCommission = this.formBuilder.group({
      reference: new FormControl('', Validators.compose([Validators.required])),
      paymentMode: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      paymentDate: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      amount: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.min(100)])
      ),
    });
  }

  ngOnInit(): void {}

  submitCommission() {
    if (this.formGroupPostCommission.invalid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeError,
        '',
        'All fields in the form are required'
      );
      return;
    }

    let nowDate = new Date();
    let paymentDate = new Date();
    paymentDate = this.formGroupPostCommission.value.paymentDate;
    if (paymentDate.getTime() > nowDate.getTime()) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Invalid payment date'
      );
      return;
    }
    let amount = this.formGroupPostCommission.value.amount;
    if (amount <= 0) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Invalid commission amount'
      );
      return;
    }
    let data = {
      datePaid: paymentDate.getTime(),
      paymentMode: this.formGroupPostCommission.value.paymentMode,
      reference: this.formGroupPostCommission.value.reference,
      amount: amount,
      query: {
        $or: [
          {
            quoteID: this.selectedRecordId,
          },
        ],
      },
    };
    this.isLoading = true;
    this.appService
      .makePostRequest(
        `${environment.MPESA_API_URL}/bulkUpdateCommissions`,
        data
      )
      .subscribe(
        (res) => {
          if (res.Status == 200) {
            this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              res.Message,
              res.Payload
            );
            this.emitSaveCommission.emit(this.selectedRecordId);
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeError,
              '',
              res.Payload
            );
          }
          this.isLoading = false;
        },
        (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
          this.isLoading = false;
        }
      );
  }
}
