import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppEnums } from 'src/app/enums/app.enum';
import { IMedicalInterface } from 'src/app/models/medical.inteface';
import { IMotor } from 'src/app/models/motor.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
export interface ICancelObject {
  cover: string;
  policy: IMotor | IMedicalInterface;
}

@Component({
  selector: 'app-cancel-policy',
  templateUrl: './cancel-policy.component.html',
  styleUrls: ['./cancel-policy.component.scss'],
})
export class CancelPolicyComponent implements OnInit {
  cancelPolicyFormGroup: FormGroup;
  isLoading: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: ICancelObject,
    public dialogRef: MatDialogRef<CancelPolicyComponent>
  ) {
    this.cancelPolicyFormGroup = this.formBuilder.group({
      reason: new FormControl('', Validators.compose([Validators.required])),
    });
  }

  ngOnInit(): void { }

  getCancellationUrl(cover: string) {
    let url = '';
    switch (cover) {
      case 'motor':
        url = `${environment.MOTOR_API_URL}/adminupdatequote`;
        break;
      case 'medical':
        url = `${environment.MEDICAL_API_URL}/adminupdatequotes`;
        break;
      case 'personalaccident':
        url = `${environment.PA_API_URL}/adminupdatequote`;
        break;
      case 'travel':
        url = `${environment.TRAVEL_API_URL}/adminupdatequote`;
        break;
      case 'evacuation':
        url = `${environment.EVACUATION_API_URL}/adminupdatequote`;
        break;
      case 'domestic':
        url = `${environment.DOMESTIC_API_URL}/adminupdatequote`;
        break;
      case 'golf':
        url = `${environment.GOLF_API_URL}/adminupdatequote`;
        break;
      case 'life':
        url = `${environment.LIFE_API_URL}/adminupdatequote`;
        break;
      case 'lastexpense':
        url = `${environment.LIFE_API_URL}/adminupdatequotelastexpense`;
        break;
      case 'telemedicine':
        url = `${environment.MEDICAL_API_URL}/telemedicineadminupdatequotes`;
        break;

      default:
        url = '';
        break;
    }

    return url;
  }
  submitCancellation() {
    if (!this.cancelPolicyFormGroup.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Cancellation reason is required'
      );
      return;
    }
    this.isLoading = true;
    let cancellation = {
      reason: this.cancelPolicyFormGroup.get('reason').value,
      feduid: this.appService.getSessionUser()?.feduid,
      _timestamp: new Date().getTime(),
    };
    let data = {
      _id: this.data.policy._id,
      update: {
        cancellation: cancellation,
      },
    };
    let url = this.getCancellationUrl(this.data.cover);

    this.appService.makePostRequest(url, data).subscribe(
      (res) => {
        this.isLoading = false;
        if (res.Status == 200) {
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            'Success',
            'Policy Cancelled Successfully'
          );
          this.dialogRef.close(this.data.policy._id);
        } else {
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            'Failed',
            'Policy cancellation failed'
          );
        }
      },

      (err) => {
        this.isLoading = false;
        this.appService.showToastMessage(
          AppEnums.ToastTypeWarning,
          '',
          this.appService.processHttpErrors(err)
        );
      }
    );
  }
}
