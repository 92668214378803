import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppService } from '../services/app.service';

@Pipe({
  name: 'underwriter',
})
export class UnderwriterPipe implements PipeTransform {
  constructor(private appService: AppService) {}
  transform(insurerId: string): Observable<string> {
    return this.appService.getCombinedUnderwriterThirdpartyFromCdn?.pipe(
      map((underwriters) => {
        return underwriters.find(
          (underwriter) => underwriter.insurer == insurerId
        )?.underwriter;
      })
    );
  }
}
