<h1 mat-dialog-title>Update Finance Plan</h1>
<div mat-dialog-content>
  <app-loader *ngIf="isLoading"></app-loader>
  <form [formGroup]="updatePlanFormGroup" (submit)="updatePlan()">
    <div class="col-sm-12">
      <mat-form-field appearance="fill">
        <mat-label>Amount</mat-label>
        <input matInput placeholder="1000" formControlName="amount" required />
      </mat-form-field>
    </div>
    <div class="col-sm-12">
      <mat-form-field appearance="fill">
        <mat-label>Next Payment Date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="date" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="closeDialog()">Close</button>
  <button mat-button color="primary" (click)="updatePlan()">Submit</button>
</div>
