export let relationships = [
  { name: 'AUNT', value: 'Aunt' },
  { name: 'BROTHER', value: 'Brother' },
  { name: 'COUSIN', value: 'Cousin' },
  { name: 'DAUGHTER', value: 'Daughter' },
  { name: 'FATHER', value: 'Father' },
  { name: 'GRANDFATHER', value: 'Grandfather' },
  { name: 'GRANDMOTHER', value: 'Grandmother' },
  { name: 'MOTHER', value: 'Mother' },
  { name: 'NIECE', value: 'Niece' },
  { name: 'NEPHEW', value: 'Nephew' },
  { name: 'SISTER', value: 'Sister' },
  { name: 'SON', value: 'Son' },
  { name: 'SPOUSE', value: 'Spouse' },
  { name: 'UNCLE', value: 'Uncle' },
  { name: 'N/A', value: 'N/A' },
];

export let occupationsList = [
  'Accountant',
  'Accounts clerk or bookkeeper',
  'Actor or actress',
  'Actuary',
  'Acupuncturist (qualified Aust registered)',
  'Advertising executive (degree qualified)',
  'Agricultural contractor',
  'Agronomist',
  'Air conditioning installer',
  'Alarm installer',
  'Ambulance officer or driver',
  'Amusement parlour or centre',
  'Amway salesperson – full time',
  'Amway salesperson – part time',
  'Anaesthetist',
  'Animal breeder',
  'Animal groomer',
  'Animal shooter',
  'Animal trainer',
  'Announcer – radio or television',
  'Antenna erector',
  'Antique dealer – delivery',
  'Antique dealer – restoration',
  'Antique dealer – sales and office',
  'Apiarist or beekeeper',
  'Aquarium shop worker',
  'Archaeologist – field work',
  'Archaeologist – office only',
  'Architect (qualified)',
  'Armed Services – All ranks(no special hazards)',
  'Armed Services – Navy divers(no special hazards',
  'Armed Services – Navy submariners',
  'Armoured van driver',
  'Art dealer',
  'Artist – commercial only',
  'Artist – painter or sculptor',
  'Artist supplies shop worker',
  'Asbestos worker',
  'Asphalt layers',
  'Assessor – insurance',
  'Astronomer',
  'Auctioneer',
  'Audiologist, Audiometrist',
  'Auditor (tertiary qualified)',
  'Author',
  'Auto electrician',
  'Aviation industry air traffic controller',
  'Aviation industry aircraft maintenance –\nrefueller, security, unskilled worker',
  'Aviation industry aircraft maintenance –\nqualified, skilled worker',
  'Aviation industry ground staff – baggage\nhandler or porter',
  'Aviation industry ground staff – cleaner',
  'Aviation industry ground staff –\nmanagement and clerical',
  'Aviation, agriculture or cattle mustering',
  'Aviation or balloonists – professionals',
  'Aviation or charter (professional pilot)',
  'Aviation instructor',
  'Aviation military pilots and crew',
  'Aviation parachutists – instructors',
  'Aviation parachutists – military\n(not special air services)',
  'Aviation regular public transport\n(pilots and crew)',
  'Aviation test pilots',
  'Avon sales person',
  'Backhoe, bobcat, bulldozer – others',
  'Backhoe, bobcat, bulldozer –\nowner or operator est. 3 years',
  'Bailiff',
  'Baker',
  'Bakery - supervisor',
  'Bank, building society, credit union –\nsecurity staff',
  'Bank, building society, credit union –\nmanager, clerk, teller',
  'Barber',
  'Barrister',
  'White collar',
  'Battery fitting',
  'Beach inspector',
  'Beautician – salon only',
  'Beautician – working from home or mobile',
  'Bedding store worker',
  'Beekeeper or apiarist',
  'Bicycle store worker',
  'Biochemist',
  'Biologist (no field work)',
  'Biologist, Marine (field work, no diving)',
  'Blacksmith or farrier',
  'Blaster or explosives handler',
  'Blind, awning, screen –\nmanufacturer or installer',
  'Blind, awning, screen – supervising, quoting\n(up to 20% light manual only)',
  'Boarding house owner',
  'Boat builder',
  'Boating equipment supply or chandler',
  'Bobcat, backhoe or bulldozer – other',
  'Bobcat, backhoe or bulldozer –\nowner operator established 3 yrs',
  'Boilermaker',
  'Book shop worker',
  'Bookbinder',
  'Bookkeeper',
  'Bookmaker (licensed)',
  'Bootmaker',
  'Botanist',
  'Bottle dealer',
  'Brassware shop worker',
  'Bread or cake shop worker',
  'Brewing industry – chemist',
  'Brewery – labourer',
  'Brewery – supervisor',
  'Bricklayer',
  'Bricklayer’s labourer',
  'Brickworks employee – unskilled',
  'Builder (qualified)',
  'Builder’s labourer',
  'Building industry – air conditioning\ninstaller',
  'Building industry – antenna erector',
  'Building industry – architect',
  'Building industry – backhoe, bobcat,\nbulldozer others',
  'Building industry – backhoe, bobcat,\nbulldozer owner, operator',
  'Building industry – bricklayer',
  'Building industry – bricklayer’s labourer',
  'Building industry – builder’s labourer',
  'Building industry – cabinet maker –\nqualified',
  'Building industry – carpenter – qualified',
  'Building industry – ceiling fixer',
  'Building industry – cement renderer',
  'Building industry – concrete cutter',
  'Building industry – concrete worker',
  'Building industry – construction supervisor',
  'Building industry – crane operator',
  'Building industry – decorator',
  'Building industry – demolition worker\n(no explosives)',
  'Building industry – draftsperson (qualified)',
  'Building industry – drainer',
  'Building industry – earthmovers – others',
  'Building industry – earthmovers –\nowner operator est. 3 yrs',
  'Building industry – electrician',
  'Building industry – elevator mechanic',
  'Building industry – foreman, supervisor\n(no manual work)',
  'Building industry – glazier',
  'Building industry – handyman',
  'Building industry – house stumper',
  'Building industry – joiner – qualified',
  'Building industry – labourer',
  'Building industry – lift erector or mechanic',
  'Building industry – painter',
  'Building industry – paver',
  'Building industry – plasterer',
  'Building industry – plumber – other',
  'Building industry – plumber – roof',
  'Building industry – riggers',
  'Building industry – roof worker-tiler, plumber, repairer',
  'Building industry – steel erector or fixer',
  'Building industry – tiler-floors and walls',
  'Building industry – tiler-roof',
  'Bus driver or conductor – interstate',
  'Bus driver or conductor – local',
  'Business executive (degree qualified, earning net min. $100,000)',
  'Butcher – qualified (no slaughtering)',
  'Butler',
  'Cabinet maker – qualified',
  'Cafe – owner or worker',
  'Cameraman – aviation',
  'Cameraman – other',
  'Cameraman – studio',
  'Cane shop worker',
  'Canvas goods manufacturer',
  'Caravan park owner',
  'Card shop worker',
  'Cardiologist',
  'Caretaker',
  'Carnival – owner or employee',
  'Carpenter – qualified',
  'Carpenter – not qualified, less than 5 years',
  'Carpet layer',
  'Carpet, floor coverings store – sales only',
  'Cartographer',
  'Cashier – financial institution',
  'Cashier – supermarket',
  'Casino (government licensed) – senior management, clerical, cashier',
  'Casino (government licensed) – bar staff, security staff',
  'Casino (government licensed) – croupier',
  'Casino (government licensed) – waiter',
  'Caterer',
  'Ceiling fixer',
  'Chauffeur',
  'Chemist – industrial, non hazardous',
  'Chemist – other',
  'Chemist – pharmacy',
  'Chemist shop – pharmacist, shop assistant',
  'Child or day care centre – not working at home – qualified',
  'Child or day care centre – other',
  'China and glassware shop',
  'Chiropodist',
  'Chiropractor – government registered',
  'Chiropractor – other',
  'Circus worker or performer',
  'Cleaner – brick, street, window – city or industrial',
  'Cleaner – cleaner, office, factory or school – carpet suburban home, window – suburban home only',
  'Clergyman',
  'Clerk',
  'Clothing industry – fashion designer (not working at home), management (no manual work), salesperson (no deliveries)',
  'Clothing industry – patternmaker, tailor or dressmaker (shop department store)',
  'Clothing industry – process worker or machinist, tailor or dressmaker working at home',
  'Club employees – bar staff, security staff',
  'Club employees – clerical and senior management',
  'Club employees – waiter',
  'Coffee lounge',
  'Commercial artist',
  'Commercial traveller',
  'Commodity broker',
  'Compositor',
  'Computer industry – analyst, consultant, programmer (degree qualified)',
  'Computer industry - keyboard operator, sales, systems operator/administrator, technician',
  'Computer shop',
  'Concrete worker',
  'Confectionery shop',
  'Cook',
  'Conveyancer',
  'Conveyancer (degree qualified)',
  'Coroner',
  'Correctional Officer',
  'Courier – driver – other',
  'Courier – driver-owner establish 2 years',
  'Courier – motorcycle or bicycle',
  'Crane operator',
  'Crayfisherman',
  'Curator (museum, art gallery, library)',
  'Customs agent – clerical',
  'Customs agent (other than clerical)',
  'Customs officer',
  'Customs officer (field work)',
  'Dance instructor',
  'Debt collector – not repossessions',
  'Debt collector – repossessions',
  'Delicatessen',
  'Demolition worker (no explosives)',
  'Dental hygienist or therapist',
  'Dental nurse',
  'Dental or orthodontic technician',
  'Dentist',
  'Department store – cleaner',
  'Department store – clerical, sales, senior management',
  'Department store – deliveries, storeman',
  'Dermatologist',
  'Diamond cutter, polisher, setter',
  'Die maker',
  'Dietician (qualified and registered)',
  'Disposal store – sales',
  'Diving – construction, bridge, dams, dock, pier',
  'Diving – instructor (professional)',
  'Diving – professional deep sea exploration',
  'Diving – professional – abalone',
  'Diving – professional, military (no bomb disposal) or police',
  'Dock worker or stevedore',
  'Doctor',
  'Draftperson – qualified',
  'Drainer',
  'Drillers (water and mineral sample, not offshore)',
  'Driver – ambulance',
  'Driver – armoured car',
  'Driver – bus – local',
  'Driver – bus – long distance or interstate',
  'Driver – chauffeur',
  'Driver – courier – car, van other',
  'Driver – courier – car, van owner established 2 years',
  'Driver – courier – motorcycle or bicycle',
  'Driver – crane driver',
  'Driver – dairy deliveryman or milkman',
  'Driver – driving instructors',
  'Driver – explosive',
  'Driver – forklift',
  'Driver – garbage collections',
  'Driver – hire car owner or driver',
  'Driver – logging',
  'Driver – petrol or petroleum products',
  'Driver – removalists',
  'Driver – taxi other',
  'Driver – taxi owner driver establish 2 years',
  'Driver – tow truck',
  'Driver – truck and van – local establish 2 years',
  'Driver – truck and van – local other',
  'Driver – truck and van – long distance or interstate',
  'Dry cleaning',
  'Duty free shop worker',
  'Earthmovers – others',
  'Earthmovers – owner operator established 3 years',
  'Economist',
  'Editor – films, newspapers (etc)',
  'Electrical goods retailer – delivery',
  'Electrical goods retailer – light manual',
  'Electrical goods retailer – sales',
  'Electrician',
  'Electronic supply store – sales',
  'Electroplater',
  'Elevator mechanic',
  'Embalmer',
  'Endocrinologist',
  'Engineer (qualified) – offshore or underground inspections',
  'Engineer (qualified) – consulting, office duties minimal site work, no manual work',
  'Engraver',
  'ENT Surgeon',
  'Entertainer',
  'Equipment and plant hire',
  'Estate agent',
  'Explosives worker',
  'Factory hand or worker',
  'Farm employee or labourer',
  'Farm manager',
  'Farm owner (established 2 years) – beef cattle, dairy, grape grower, mixed farming, oyster, poultry, sheep, sugar cane, wheat',
  'Farm owner (established 2 years) – fruit grower, orchardist, market gardener',
  'Farm owner (established 2 years) – harvesting contractor',
  'Farrier or blacksmith',
  'Fast food or take away',
  'Fencing contractor',
  'Fibre glass moulder',
  'Film and TV industry – actor or actress',
  'Film and TV industry – cameraman – aviation',
  'Film and TV industry – cameraman – mainly studio',
  'Film and TV industry – cameraman – other',
  'Film and TV industry – producer, director, editor',
  'Financial consultant or adviser',
  'Financial planner, adviser, agent (tertiary qualified minimum earnings $100,000 net per annum)',
  'Firemen – airport, city, forestry',
  'Firemen – fire chiefs and senior officers',
  'Firemen – oil and natural gas',
  'Fish and chip shop worker',
  'Fisherman',
  'Fishmonger',
  'Fitter',
  'Floor covering fixer',
  'Floor sander',
  'Florist – delivery',
  'Florist – shopkeeper',
  'Foundry',
  'French polisher',
  'Fruit grower or orchardist',
  'Fruit picker',
  'Funeral director',
  'Furnishings retailer (cushions, fabrics, not furniture)',
  'Furniture removalist',
  'Furniture retailer – new – deliveries',
  'Furniture retailer – new – light manual',
  'Furniture retailer – new – sales only',
  'Furniture retailer – used',
  'Furrier',
  'Garbage contractor',
  'Garden shop worker',
  'Gardener – domestic or landscape other',
  'Gas industry – fitter',
  'Gas industry – inspector, meter reader or tester',
  'Gas industry – pipe laying',
  'Gastroenterologist',
  'General surgeon',
  'Geologist – (consultant and office only)',
  'Geologist – mainly field work, including underground or offshore',
  'Gift shop worker',
  'Glazier',
  'Goldsmith, silversmith',
  'Golf instructor (not professional player)',
  'Grape grower',
  'Graphic designer',
  'Greengrocer',
  'Greenkeeper',
  'Grocer',
  'Gunsmith',
  'Gymnasium instructor',
  'Gynaecologist, obstetrician',
  'Haberdasher',
  'Hairdresser',
  'Handyman',
  'Hardware retailer',
  'Health food store worker',
  'Home maintenance contractor',
  'Homemaker',
  'Horse breeder',
  'Horse riding – jockey – flat or harness',
  'Horse riding – jockey – steeple or rodeo (professional)',
  'Horse riding instructor',
  'Horse strapper',
  'Horse trainer',
  'Hospital staff – doctor or laboratory technician',
  'Hospital staff – nurse’s aide',
  'Hospital staff – nurse or nursing sister',
  'Hospital staff – porter or wardsman',
  'Hotel or motel industry – bar staff',
  'Hotel or motel industry – head waiters',
  'Hotel or motel industry – housekeepers or chambermaids',
  'Hotel or motel industry – security staff',
  'Hotel or motel industry – waiters or waitresses',
  'Hotel or motel industry – managers – admin only',
  'Hotel or motel industry – managers – other',
  'House restumper',
  'Hypnotherapist – well established',
  'Ice cream parlour',
  'Importer or exporter – clerical',
  'Inspector (health, buildings etc)',
  'Instrument maker',
  'Insulation installer',
  'Insurance consultant',
  'Interior decorator – consulting only',
  'Interior decorator – other',
  'Interpreter',
  'Investor',
  'Jackeroo',
  'Janitor',
  'Jeweller – manufacture',
  'Jeweller – retail',
  'Jockey – flat or harness',
  'Jockey – steeple',
  'Joiner – other',
  'Joiner – qualified',
  'Journalist (no unusual hazards)',
  'Judge, Magistrate',
  'Kennel proprietor',
  'Lab technician – non hazardous',
  'Labourer – farm labourers',
  'Labourer – others in regular employment',
  'Landbroker',
  'Landscape architect (degree qualified, no manual work)',
  'Landscape gardener – other',
  'Lawnmower – sales or sales and service',
  'Lawnmowing contractor',
  'Lawyer',
  'Lecturer, Professor, Tutor - full time in university',
  'Librarian',
  'Lift erector or mechanic',
  'Light fittings store worker',
  'Linesman – telephone or electrical',
  'Liquor store worker',
  'Livestock broker, buyer, dealer',
  'Locksmith',
  'Logging – in mill (permanent mill only)',
  'Logging – mobile mill',
  'Logging – tree feller',
  'Logging – tree surgeon',
  'Loss adjuster',
  'Lumber (yard work)',
  'Machinery or equipment hire',
  'Machinist – metal or wood – qualified',
  'Machinist – supervisor (no manual work)',
  'Mail contractor',
  'Management consultant',
  'Manicurist – not working at home',
  'Marina owner',
  'Marine engineer (seaman)',
  'Market gardener',
  'Martial arts instructor',
  'Masseur – other',
  'Masseur – salon only',
  'Meat industry – inspector',
  'Meat industry – supervisor (up to 10% manual work)',
  'Meat packer',
  'Mechanic',
  'Medical practitioner',
  'Merchant banker (degree qualified)',
  'Merchant marine',
  'Metal dealer (scrap)',
  'Metallurgist (office duties only)',
  'Meteorologist',
  'Meter reader (gas or electricity)',
  'Milk bar',
  'Milk vendor',
  'Mining – clerical or executive manager',
  'Mining – explosives handler (surface and underground workers)',
  'Mining – surface or underground worker – other (no explosives)',
  'Mining – surface worker – qualified (no explosives)',
  'Model',
  'Motor cycle – mechanic',
  'Motor cycle – salesman',
  'Motor vehicle – accessories and spare parts sales',
  'Motor vehicle – assembly worker',
  'Motor vehicle – detailer',
  'Motor vehicle – mechanic',
  'Motor vehicle – salesman',
  'Motor vehicle – trimmer',
  'Motor vehicle – wrecker',
  'Moulder',
  'Museum or gallery proprietor',
  'Music teacher – in school, university or college',
  'Musical instrument sales – lifting or deliveries',
  'Musical instrument sales – sales only',
  'Musician',
  'N/A',
  'Nanny',
  'Naturopath',
  'Neurologist',
  'Neurosurgeon',
  'Newsagent',
  'Newspaper staff – editor, journalist or reporter (no unusual hazards), office worker',
  'Newspaper staff – photographer or printer',
  'Nurse – enrolled nurse or nurse’s aide',
  'Nurse – intellectual disability',
  'Nurse – mothercraft',
  'Nurse – registered or nursing sister',
  'Nurse – registered',
  'Nurseryman',
  'Occupational health and safety inspector',
  'Occupational therapist',
  'Office equipment – salesman',
  'Office equipment – serviceman',
  'Office supplies or stationer',
  'Office worker',
  'Oil or gas – onshore or offshore worker – other',
  'Oil or gas – onshore or offshore workers – geologists, engineers or lab technician',
  'Oncologist',
  'Opthalmic surgeon',
  'Ophthalmologist',
  'Optical dispenser or mechanic',
  'Optician, optometrist',
  'Orchardist or fruit grower',
  'Orthodontist',
  'Orthopaedic surgeon',
  'Osteopath (well established)',
  'Outdoor supplies retailer (tents, BBQ)',
  'Paediatrician',
  'Paint or wallpaper store',
  'Painter',
  'Panel beater',
  'Park ranger',
  'Parking station attendant',
  'Parking warden',
  'Pastry cook',
  'Pathologist',
  'Patternmaker (industrial eg foundry)',
  'Paver',
  'Pawnbroker',
  'Payroll guard',
  'Periodontist',
  'Personnel consultant',
  'Pest exterminator',
  'Pet shop worker',
  'Pharmacist',
  'Photoengraver',
  'Photographer – aviation',
  'Photographer – mainly studio',
  'Photographer – other',
  'Photographic store worker',
  'Physician',
  'Physicist',
  'Physiotherapist',
  'Piano tuner',
  'Picture framer',
  'Pilot – harbour',
  'Plasterer',
  'Plumber – other',
  'Plumber – roof',
  'Podiatrist',
  'Police – airwing or bomb disposal',
  'Police – other – officers including motorcycle police or special operations group',
  'Police – special operations group',
  'Police – dog squad',
  'Police – superintendents',
  'Post office – counter clerk or manager',
  'Post office – postman or sorter',
  'Potter',
  'Poultry farmer',
  'Printer',
  'Prison warder',
  'Private investigator',
  'Process worker – clothing industry, hazardous industries (eg munitions, acids)',
  'Produce merchant',
  'Professional sportsman',
  'Professor (university)',
  'Psychiatrist',
  'Psychologist',
  'Pulp and paper mill employees (in mill or yard only)',
  'Quantity surveyor',
  'Quarry worker (no explosives)',
  'Radio and television announcer',
  'Radiographer',
  'Radiologist',
  'Railway worker – driver or guard, signalman, station assistant or station master',
  'Railway worker – railway police, shunter, track laying or maintenance',
  'Railway worker – labourer',
  'Real estate',
  'Receptionist',
  'Record shop worker',
  'Refrigeration mechanic',
  'Rehabilitation consultant (medically qualified)',
  'Removalist',
  'Renal physician',
  'Repairman – office equipment or household appliances',
  'Reporter (no unusual hazards)',
  'Restaurant employees – chef',
  'Restaurant employees – cook or waiter',
  'Restaurant owner – administration only, no cooking or waiting',
  'Restaurant owner – other',
  'Rigger',
  'Roof worker – tiler, fixer or repairer',
  'Rubbish collector',
  'Saddlemaker',
  'Sailmaker',
  'Sales representative, deliveries',
  'Sandblaster',
  'Sawmill worker (permanently located mill only)',
  'Scaffold erector',
  'School principal, Headmaster, Head Teacher',
  'Scrap dealer (metal)',
  'Seaman – navy',
  'Second hand dealer',
  'Secretary',
  'Security guard',
  'Service station – cashier or console operator, mechanic or proprietor',
  'Shearer',
  'Sheet metal worker',
  'Shipwright',
  'Shoe shop (retail sales or not repair)',
  'Shoemaker or repairer',
  'Shopfitter',
  'Shopkeeper – adult books and merchandise',
  'Shopkeeper – antique dealer – delivery',
  'Shopkeeper – antique dealer – restoration',
  'Shopkeeper – antique dealer – sales and office',
  'Shopkeeper – aquarium shop',
  'Shopkeeper – artist supplies',
  'Shopkeeper – baby shop',
  'Shopkeeper – bedding store',
  'Shopkeeper – bicycle store',
  'Shopkeeper – boating equipment supply or chandler',
  'Shopkeeper – bookshop',
  'Shopkeeper – bootmaker',
  'Shopkeeper – brassware shop',
  'Shopkeeper – bread or cake shop',
  'Shopkeeper – butcher – no slaughtering',
  'Shopkeeper – butcher – slaughtering',
  'Shopkeeper – cafe',
  'Shopkeeper – cane shop',
  'Shopkeeper – card shop',
  'Shopkeeper – carpet, floor coverings store – sales only',
  'Shopkeeper – chemist shop – pharmacist',
  'Shopkeeper – chemist shop – shop assistant',
  'Shopkeeper – china and glassware shop',
  'Shopkeeper – clothing store',
  'Shopkeeper – coffee lounge',
  'Shopkeeper – computer shop',
  'Shopkeeper – confectionery shop',
  'Shopkeeper – delicatessen',
  'Shopkeeper – department store – cleaner',
  'Shopkeeper – department store – clerical sales only',
  'Shopkeeper – department store – deliveries, storeman',
  'Shopkeeper – department store – senior management',
  'Shopkeeper – disposal store',
  'Shopkeeper – duty free shop',
  'Shopkeeper – electrical goods retailer – deliveries',
  'Shopkeeper – electrical goods retailer – light manual',
  'Shopkeeper – electrical goods retailer – sales only',
  'Shopkeeper – electronic supply store – sales',
  'Shopkeeper – fast food or take away',
  'Shopkeeper – fish and chip shop',
  'Shopkeeper – florist',
  'Shopkeeper – florist – delivery',
  'Shopkeeper – furnishings retailer (cushions or fabric not furniture)',
  'Shopkeeper – furniture retailer – new – deliveries',
  'Shopkeeper – furniture retailer – new – light manual',
  'Shopkeeper – furniture retailer – new – sales only',
  'Shopkeeper – furniture retailer – used',
  'Shopkeeper – garden shop',
  'Shopkeeper – gift shop',
  'Shopkeeper – greengrocer',
  'Shopkeeper – grocer',
  'Shopkeeper – gunsmith',
  'Shopkeeper – haberdasher White collar',
  'Shopkeeper – hardware retailer',
  'Shopkeeper – health food store',
  'Shopkeeper – ice cream parlour',
  'Shopkeeper – jewellery store',
  'Shopkeeper – light fittings store',
  'Shopkeeper – liquor store',
  'Shopkeeper – locksmith',
  'Shopkeeper – milk bar',
  'Shopkeeper – motor vehicle accessories and spare parts',
  'Shopkeeper – musical instruments – sales only',
  'Shopkeeper – musical instruments sales – lifting or deliveries',
  'Shopkeeper – newsagent',
  'Shopkeeper – nursery or garden shop',
  'Shopkeeper – office supplies or stationer',
  'Shopkeeper – outdoor supplies retailer (tents, BBQ)',
  'Shopkeeper – paint or wallpaper store',
  'Shopkeeper – pawnbroker',
  'Shopkeeper – pet shop',
  'Shopkeeper – photographic store',
  'Shopkeeper – produce merchant',
  'Shopkeeper – record shop',
  'Shopkeeper – second hand goods',
  'Shopkeeper – shoe shop (retail sales not repair)',
  'Shopkeeper – shoemaker or repairer',
  'Shopkeeper – sporting goods store',
  'Shopkeeper – stationer',
  'Shopkeeper – supermarket – administration only',
  'Shopkeeper – supermarket – cashier',
  'Shopkeeper – supermarket – other',
  'Shopkeeper – swimming pool supplies',
  'Shopkeeper – take away food',
  'Shopkeeper – tobacconist',
  'Shopkeeper – toy shop',
  'Shopkeeper – video shop proprietor',
  'Showman',
  'Signwriter',
  'Singer',
  'Skiing – professional',
  'Skylight fitter',
  'Social worker',
  'Solicitor',
  'Speech therapist, pathologist',
  'Sporting goods store',
  'Sports person – professional',
  'Spray painter',
  'Squash court proprietor',
  'Stationer',
  'Statistician',
  'Steel erector or fixer',
  'Steeplejack',
  'Stevedore',
  'Steward (airline)',
  'Stock and station agent – no manual duties',
  'Stockbroker (degree qualified)',
  'Stockman',
  'Stonemason',
  'Storeman',
  'Student',
  'Stuntman',
  'Sugar cane farmer owner established 2 years',
  'Supermarket – administration only',
  'Supermarket – cashier',
  'Supermarket - manager (manual duties',
  'Supermarket – other',
  'Surfboard shaper',
  'Surveyor – field or office only, quantity',
  'Surveyor – marine or mines',
  'Swimming instructor',
  'Swimming pool builder',
  'Swimming pool supplies worker',
  'TAB agent',
  'Take away food shop worker',
  'Tanner',
  'Tax consultant (tertiary qualified)',
  'Taxi – other',
  'Taxi – owner driver established 2 years',
  'Taxidermist',
  'Teacher’s aide',
  'Teacher (school, university or college)',
  'Telephone technician',
  'Telephonist',
  'Tennis coach (not professional player)',
  'Theatre or cinema – confectionery seller, manager, ticket seller, usher or projectionist',
  'Tiler – floors and walls',
  'Tiler – roof',
  'Timber merchant',
  'Tobacconist',
  'Toolmaker',
  'Tour guide',
  'Tow truck driver',
  'Town planner',
  'Toy shop worker',
  'Train driver or guard',
  'Travel agent or consultant',
  'Tree feller or tree lopper',
  'Tree surgeon',
  'Tugboat crew (not deep sea)',
  'Tupperware salesperson',
  'TV technician',
  'Typesetter',
  'Typist',
  'Tyre fitter and repairs',
  'Tyre sales worker',
  'Undertaker',
  'Upholsterer',
  'Urologist',
  'Valuer',
  'Vending machine serviceman',
  'Veterinary nurse',
  'Veterinary surgeon',
  'Video shop proprietor',
  'Vigneron',
  'Waiter, waitress (restaurant)',
  'Wallpaper hanger',
  'Watchmaker',
  'Water proofer',
  'Welder',
  'Welfare officer',
  'Wharf labourer',
  'Window cleaner – city or industrial',
  'Window cleaner – suburban home only',
  'Window dresser',
  'Window tinter – motor vehicle, home or office',
  'Wine merchant – wholesale and retail',
  'Wine maker – qualified (no manual labour)',
  'Wine maker – unqualified (no manual labour)',
  'Wine maker – other',
  'Wool broker or buyer',
  'Wool classer',
  'Wrecker or demolisher (no explosives)',
  'X-ray machine operator or technician',
  'Zoo worker',
  'Zoologist',
];
