import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AppEnums } from "src/app/enums/app.enum";
import { AppService } from "src/app/services/app.service";
import { environment } from "src/environments/environment";
import { WalletTransactionsDetailsComponent } from "../wallet-transactions-details/wallet-transactions-details.component";

@Component({
  selector: "app-add-payment",
  templateUrl: "./add-payment.component.html",
  styleUrls: ["./add-payment.component.scss"],
})
export class AddPaymentComponent implements OnInit {
  isLoading: boolean = false;
  addPaymentFormGroup: FormGroup;

  constructor(
    public appService: AppService,
    public _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WalletTransactionsDetailsComponent>
  ) {
    this.addPaymentFormGroup = _formBuilder.group({
      amount: ["", Validators.required],
      referenceNo: ["", Validators.required],
    });
  }

  ngOnInit(): void {}

  addPayment() {
    const feduid = this.data?.feduid;
    if (!this.addPaymentFormGroup.valid) {
      const controls = this.addPaymentFormGroup.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            "Field Required",
            name
          );
        }
      }
      return;
    }
    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.MPESA_API_URL}/adminMakeOfflineDeposit`, {
        amount: Number(this.addPaymentFormGroup.get("amount").value),
        feduid: feduid,
        MpesaReceiptNumber: this.addPaymentFormGroup.get("referenceNo").value,
        walletType: "BIMA_SAVINGS",
      })
      .subscribe(
        (res) => {
          this.isLoading = false;
          if (res.Status == 200) {
            this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              "",
              "Success"
            );
            this.dialogRef.close();
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              `${res.Payload}`,
              "Warning"
            );
          }
        },
        (error) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            this.appService.processHttpErrors(error),
            "Error"
          );
        }
      );
  }
}
