import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppEnums } from 'src/app/enums/app.enum';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import {
  IAssessor,
  IAssessorUnderwriter,
} from 'src/app/models/assessor.interface';
import { IUser } from 'src/app/models/user.interface';

@Component({
  selector: 'app-assessor-partial',
  templateUrl: './assessor-partial.component.html',
  styleUrls: ['./assessor-partial.component.scss']
})
export class AssessorPartialComponent implements OnInit {

  feduid: string;
  assessor: IAssessor;
  user: IUser;
  searchForm: FormGroup;
  underwriterList: IAssessorUnderwriter[] = [];
  isLoading: boolean = false;
  sasToken: string;
  userRoles: string[] = [];
  approvedUnderwriterCount: number = 0;
  approveAssessorForm: FormGroup;

  constructor(
    private activeRoute: ActivatedRoute,
    public appService: AppService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.activeRoute.params?.subscribe((p) => {
      this.feduid = p['feduid'];
      this.getAssessor(this.feduid);
      this.getPrincipleProfile(this.feduid);
      this.approveAssessorForm = this.formBuilder.group({
        commission: [
          '',
          Validators.compose([
            Validators.required,
            Validators.min(1),
            Validators.max(100),
          ]),
        ],
      });
    });
  }

  ngOnInit(): void {
    this.appService.getSasToken()?.subscribe((sas) => {
      this.sasToken = sas;
    });
  }

  goToUserProfile() {
    this.router.navigate(['/users/list-client-details', this.feduid]);
  }

  get isMtekApproved() {
    return this.userRoles.includes(AppEnums.RoleAssessor);
  }

  viewAttachment(attachmentUrl: string) {
    if (attachmentUrl) {
      let url = `${attachmentUrl}${this.sasToken}`;
      window.open(url, '_blank');
    }
  }

  requestUnderwriterApproval(insurer: string) {
    this.appService.notifyUnderwriterToApproveAssessor(
      insurer,
      this.assessor._id
    );
  }

  mtekApproveAssessor() {
    this.isLoading = true;
    let data = {
      _id: this.assessor.principle_id,
      roles: this.prepareRoles(),
    };
    this.appService
      .makeUpdateRequest(`${environment.CORE_API_URL}/updateRole`, data)
      ?.subscribe(
        (apiResponse) => {
          this.isLoading = false;
          if (apiResponse.Status === 200) {
            this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              apiResponse.Status + '',
              apiResponse.Message
            );
            this.getPrincipleProfile(this.assessor.principleFeduid);
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeError,
              apiResponse.Status + '',
              apiResponse.Payload
            );
          }
        },
        (err) => {
          this.isLoading = true;
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            err.status,
            this.appService.processHttpErrors(err)
          );
        }
      );
  }

  prepareRoles() {
    let allRoles = [
      {
        name: AppEnums.RoleClient,
        value: true,
      },
      {
        name: AppEnums.RoleValuer,
        value: false,
      },
      {
        name: AppEnums.RolePartner,
        value: false,
      },
      {
        name: AppEnums.RoleAdmin,
        value: false,
      },
      {
        name: AppEnums.RoleSalesAgent,
        value: false,
      },
    ];

    if (this.isMtekApproved) {
      allRoles.push({
        name: AppEnums.RoleAssessor,
        value: false,
      });
    } else {
      allRoles.push({
        name: AppEnums.RoleAssessor,
        value: true,
      });
      this.underwriterList
        .filter((underwriter) => {
          return (
            underwriter.approved == false ||
            underwriter.approved === undefined ||
            underwriter.approved === null
          );
        })
        .forEach((anUnderwriter) => {
          this.requestUnderwriterApproval(anUnderwriter.id);
        });
    }

    return allRoles;
  }

  getAssessor(principleFeduid: string) {
    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.CLAIMS_API_URL}/listassessor`, {
        principleFeduid: principleFeduid,
      })
      ?.subscribe(
        (data) => {
          this.isLoading = false;

          if (data.Status == 200) {
            this.assessor = data.Payload[0];
            this.approvedUnderwriterCount = this.assessor?.underwriters?.filter(
              (underwriter) => {
                return underwriter.approved;
              }
            ).length;

            if (this.appService.isPartner()) {
              this.underwriterList = this.assessor.underwriters.filter(
                (underwriter) => {
                  return (
                    underwriter.id === this.appService.getSessionUser().partner
                  );
                }
              );
            } else {
              this.underwriterList = this.assessor.underwriters;
            }
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeError,
              'Failed',
              data.Payload
            );
          }
        },
        (err) => {
          this.isLoading = false;

          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
        }
      );
  }

  getPrincipleProfile(feduid: string) {
    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.CORE_API_URL}/getUserDetailsByFeduid`, {
        feduid: feduid,
        fields: ['userName', 'phone', 'email', 'roles'],
      })
      ?.subscribe((apiResponse) => {
        this.isLoading = false;
        this.user = apiResponse.Payload;
        this.userRoles = this.appService.getRoles(this.user?.roles);
      });
  }

  underwriterCancelAssessorApproval(underwriter: IAssessorUnderwriter) {
    this.submitCommission({
      commission: 0.1,
      approved: false,
      underwriterID: underwriter.id,
      assessorID: this.assessor._id,
    });
  }
  underwriterApproveAssessor(underwriter: IAssessorUnderwriter) {
    if (
      this.approveAssessorForm.get('commission').value === 0 ||
      isNaN(this.approveAssessorForm.get('commission').value)
    ) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeError,
        'Commission invalid',
        'Make sure you enter a valid commission'
      );
      return;
    }
    let comm = parseFloat(this.approveAssessorForm.get('commission').value);
    this.submitCommission({
      commission: comm,
      approved: true,
      underwriterID: underwriter.id,
      assessorID: this.assessor._id,
    });
  }

  submitCommission(data: any) {
    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.CLAIMS_API_URL}/approveassessor`, data)
      ?.subscribe(
        (response) => {
          this.isLoading = false;
          if (response.Status == 200) {
            this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              response.Status + '',
              response.Message
            );
            this.getAssessor(this.assessor.principleFeduid);
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeError,
              'Failed',
              response.Payload
            );
          }
        },
        (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
        }
      );
  }
}