import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppEnums } from 'src/app/enums/app.enum';
import { ApiResponse } from 'src/app/models/api-response.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-client-info-partial',
  templateUrl: './client-info-partial.component.html',
  styleUrls: ['./client-info-partial.component.scss'],
})
export class ClientInfoPartialComponent implements OnInit {
  @Input() receivedId: string;
  @Input() selectedQuoteId: string;
  @Input() product: string;
  @Input() currentRecordQuestionsId: string;
  @Output() clientInfoEvent: EventEmitter<any> = new EventEmitter<any>();
  clientFormGroup: FormGroup;
  multiUploadFormGroup: FormGroup;
  allowedFileExtentions = ['jpg', 'jpeg', 'png', 'pdf'];
  azureFileLocation: string = 'manual-sales';
  activeFileName: string = '';
  activeFile: any;
  clientFiles: any[] = [
    { name: 'ID Card (Front)', value: 'idPhotoFront', suffix: '_ID_FRONT' },
    { name: 'ID Card (Back)', value: 'idPhotoBack', suffix: '_ID_BACK' },
    { name: 'KRA PIN Document', value: 'kraPhoto', suffix: '_KRA_PIN' },
    { name: 'Passport Document', value: 'passportPhoto', suffix: '_PASSPORT' },
  ];
  genders: any[] = [
    { name: 'Female', value: 'female' },
    { name: 'Male', value: 'male' },
  ];
  _tstamp: number = 0;
  currentRecord: any;
  questionsId: string;
  capturedGender: string;
  isLoading: boolean = false;

  constructor(
    private _formBuiler: FormBuilder,
    private appService: AppService,
    private router: Router
  ) {
    this._tstamp = Date.now();
    this.clientFormGroup = _formBuiler.group({
      email: ['', Validators.email],
      userName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      kraPin: ['', Validators.required],
      kraPhoto: ['', Validators.required],
      gender: ['', Validators.required],
      idNumber: ['', Validators.required],
      passportNumber: [''],
      passportPhoto: [''],
      idPhotoBack: ['', Validators.required],
      idPhotoFront: ['', Validators.required],
      dlphoto: [''],
      physicaladdress: ['', Validators.required],
      postaladdress: ['', Validators.required],
      postalcode: ['', Validators.required],
    });
    this.multiUploadFormGroup = this._formBuiler.group({
      fileObj: ['', Validators.required],
    });
  }

  onFileObjChange(idx: number) {
    this.activeFile = this.clientFiles[idx];
    this.activeFileName = this._tstamp + this.activeFile.suffix;
  }

  uploadEvent($event) {
    switch (this.activeFile.value) {
      case 'idPhotoFront':
        this.clientFormGroup.patchValue({
          idPhotoFront: $event,
        });
        break;
      case 'idPhotoBack':
        this.clientFormGroup.patchValue({
          idPhotoBack: $event,
        });
        break;
      case 'kraPhoto':
        this.clientFormGroup.patchValue({
          kraPhoto: $event,
        });
        break;
      case 'passportPhoto':
        this.clientFormGroup.patchValue({
          passportPhoto: $event,
        });
        break;
      case 'dlphoto':
        this.clientFormGroup.patchValue({
          dlphoto: $event,
        });
        break;
      default:
        break;
    }
  }

  validateClient() {
    if (!this.clientFormGroup.valid) {
      const controls = this.clientFormGroup.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            'Field Required',
            name
          );
        }
      }
      return;
    }
    this.clientInfoEvent.emit(this.clientFormGroup.value);
  }

  updateClient() {
    this.isLoading = true;

    let moreQuestions = {
      _id: this.currentRecordQuestionsId,
      update: {
        moreQuestions: {
          client: this.clientFormGroup.value,
        },
      },
    };

    this.appService
      .makePostRequest(
        `${environment.MOTOR_API_URL}/adminUpdateQuestionsGeneral`,
        moreQuestions
      )
      .subscribe(
        (apiResponse) => {
          this.isLoading = false;
          if (apiResponse.Status === 200) {
            this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              'Success!',
              'Quote saved successfully!.'
            );
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              'Failed to save Quote!',
              apiResponse.Payload
            );
          }
        },
        (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
        }
      );
    this.router.navigate(['/motor/list/motor-details', this.selectedQuoteId]);
  }

  setClientInformation() {
    this.convertGenderValue();
    if (this.currentRecord != null) {
      if (this.currentRecord?.hasOwnProperty('moreQuestions')) {
        if (this.currentRecord.moreQuestions?.client != null) {
          this.clientFormGroup = this._formBuiler.group({
            email: [
              this.currentRecord.moreQuestions?.client?.email ||
                this.currentRecord?.email,
              Validators.email,
            ],
            userName: [
              this.currentRecord.moreQuestions?.client?.userName ||
                this.currentRecord?.name ||
                this.currentRecord.moreQuestions?.client?.name,
              Validators.required,
            ],
            phoneNumber: [
              this.currentRecord.moreQuestions?.client?.phoneNumber ||
                this.currentRecord?.phoneNumber,
              Validators.required,
            ],
            kraPin: [
              this.currentRecord.moreQuestions?.client?.kraPin,
              Validators.required,
            ],
            kraPhoto: [
              this.currentRecord.moreQuestions?.client?.kraPhoto,
              Validators.required,
            ],
            gender: [
              this.capturedGender ||
                this.currentRecord.moreQuestions?.client?.gender,
              Validators.required,
            ],
            idNumber: [
              this.currentRecord.moreQuestions?.client?.idNumber,
              Validators.required,
            ],
            passportNumber: [
              this.currentRecord.moreQuestions?.client?.passportNumber,
            ],
            passportPhoto: [
              this.currentRecord.moreQuestions?.client?.passportPhoto,
            ],
            idPhotoBack: [
              this.currentRecord.moreQuestions?.client?.idPhotoBack,
              Validators.required,
            ],
            idPhotoFront: [
              this.currentRecord.moreQuestions?.client?.idPhotoFront,
              Validators.required,
            ],
            dlphoto: [
              this.currentRecord.moreQuestions?.client?.dlphoto,
              Validators.required,
            ],
            physicaladdress: [
              this.currentRecord.moreQuestions?.client?.physicaladdress,
              Validators.required,
            ],
            postaladdress: [
              this.currentRecord.moreQuestions?.client?.postaladdress,
              Validators.required,
            ],
            postalcode: [
              this.currentRecord.moreQuestions?.client?.postaladdress,
              Validators.required,
            ],
          });
        }
      }
    }
  }

  setGetQuoteUrl() {
    let microService = '';
    if (this.product) {
      switch (this.product) {
        case 'motor':
          microService = `${environment.MOTOR_API_URL}`;
          this.getQuoteInformation('adminlistmotorquote', microService);
          this.clientFiles = [
            {
              name: 'ID Card (Front)',
              value: 'idPhotoFront',
              suffix: '_ID_FRONT',
            },
            {
              name: 'ID Card (Back)',
              value: 'idPhotoBack',
              suffix: '_ID_BACK',
            },
            { name: 'KRA PIN Document', value: 'kraPhoto', suffix: '_KRA_PIN' },
            {
              name: 'Passport Document',
              value: 'passportPhoto',
              suffix: '_PASSPORT',
            },
            { name: 'Driving License', value: 'dlphoto', suffix: '_LICENSE' },
          ];
          break;
        case 'health':
          microService = `${environment.MEDICAL_API_URL}`;
          this.getQuoteInformation('adminlistquote', microService);
          break;
        default:
          return;
      }
    }
  }

  convertGenderValue() {
    let gender = this.currentRecord.moreQuestions?.client?.gender;
    if (gender == 'Male') {
      this.capturedGender = 'male';
    } else if (gender == 'Female') {
      this.capturedGender = 'female';
    }
  }

  getQuoteInformation(endpoint: string, microService: string) {
    if (this.selectedQuoteId != null) {
      this.appService
        .makePostRequest(`${microService}/${endpoint}`, {
          _id: this.selectedQuoteId,
        })
        .pipe(switchMap((res) => this.getQuoteQuestions(res, microService)))
        .subscribe((apiResponse) => {
          if (apiResponse.Status === 200) {
            this.currentRecord = apiResponse.Payload;
            this.setClientInformation();
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              apiResponse.Payload,
              "Could not process this quote's information"
            );
          }
          (error) => {
            this.appService.showToastMessage(
              AppEnums.ToastTypeError,
              '',
              this.appService.processHttpErrors(error)
            );
          };
        });
    }
  }

  getQuoteQuestions(
    res: ApiResponse,
    microService: string
  ): Observable<ApiResponse> {
    let q = res.Payload[0].questions_id;

    return this.appService.makePostRequest(
      `${microService}/admingetquestions`,
      {
        _id: q,
      }
    );
  }

  ngOnInit(): void {
    this.setGetQuoteUrl();
  }
}
