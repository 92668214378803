import { Component, OnInit } from '@angular/core';
import { AppEnums } from 'src/app/enums/app.enum';
import { IMotor } from 'src/app/models/motor.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-valuation-summary',
  templateUrl: './valuation-summary.component.html',
  styleUrls: ['./valuation-summary.component.scss'],
})
export class ValuationSummaryComponent implements OnInit {
  valuations: IMotor[] = [];

  showLoader: boolean = true;
  dataLoaded: Boolean = false;

  cumTotalValuationsArray: number[] = [];
  totalSubmittedValuationsArray: number[] = [];
  totalValuations: number = 0;
  totalPendingValuations: number = 0;
  totalSubmittedValuations: number = 0;

  constructor(private appService: AppService) { }

  ngOnInit(): void {
    this.fetchValuations();
  }

  fetchValuations() {
    this.dataLoaded = false;
    let body = {
      pipeline: [
        {
          $match: {
            // This is the query to the quotes you want to display
            $or: [{ stage: 'PAY' }, { stage: 'VALUATION' }],
            amount: {
              $gt: 40,
            },
            paidamount: {
              $gt: 0,
            },
            // _id: this.selectedPolicyId,
            'policy.policyType': 'COMPREHENSIVE',
          },
        },
        {
          $lookup: {
            from: 'cover_questions',
            localField: 'questions_id',
            foreignField: '_id',
            as: 'questions',
          },
        },
        {
          $addFields: {
            questions: {
              $first: '$questions',
            },
          },
        },
        {
          $project: {
            // Limits the fields you want. To get everything remove the section.
            feduid: 1,
            _id: 1,
            stage: 1,
            client: 1,
            amount: 1,
            insurer: 1,
            policy: 1,
            _timestamp: 1,
            questions: 1,
            paidamount: 1,
            valuation: 1,
            valuer: 1,
            valuerdetails: 1,
          },
        },
      ],
    };

    this.appService
      .makePostRequest(`${environment.MOTOR_API_URL}/adminaggregatemotor`, body)
      .subscribe(
        (res) => {
          this.valuations = res.Payload;
          this.dataLoaded = true;

          this.totalValuations = this.valuations.length;

          this.valuations.forEach((valuation) => {
            if (valuation?.valuation?.status?.submitted == true) {
              this.totalSubmittedValuations +=
                valuation.valuation.status.submitted;
            } else {
              this.totalPendingValuations =
                this.totalValuations - this.totalSubmittedValuations;
            }
          });

          this.showLoader = false;
        },
        (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
        }
      );
  }
}
