import { Component, OnInit, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AppEnums } from 'src/app/enums/app.enum';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss'],
})
export class DocumentUploadComponent implements OnInit {
  @Input() _id: string;
  @Input() azureFileLocation: string;
  @Input() azureFileName: string;
  @Input() allowedFileExtentions: string[];
  @Input() uploadEndpoint: string;

  uploadForm: FormGroup;
  allDataLoaded: boolean = true;
  showUploadForm: boolean = false;

  constructor(public appService: AppService, private formBuilder: FormBuilder) {
    this.uploadForm = this.formBuilder.group({
      docName: new FormControl({}, Validators.required),
      description: new FormControl('', Validators.required),
      docUrl: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    this.azureFileName = this.azureFileName + '-' + Date.now();
  }

  submitDocument() {
    if (this.uploadForm.invalid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Fill all fields'
      );
      return;
    }

    let selectedDoc = this.uploadForm.value.docName.value;
    let selectedDocName = this.uploadForm.value.docName.viewValue;
    let description = this.uploadForm.value.description;
    let docUrl = this.uploadForm.value.docUrl;

    this.appService
      .makePostRequest(this.uploadEndpoint, {
        _id: this._id,
        [selectedDoc]: {
          documentName: selectedDocName,
          docUrl: docUrl,
          description,
          uploadedBy: this.appService.getSessionUser().userName,
          uploaderFeduid: this.appService.getSessionUser().feduid,
          _timestamp: Date.now(),
        },
      })
      .subscribe(
        (apiResponse) => {
          if (apiResponse.Status === 200) {
            this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              `${apiResponse.Status}`,
              apiResponse.Message
            );
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              `${apiResponse.Status}`,
              apiResponse.Message
            );
          }
        },
        (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
        }
      );
  }

  documentUploadedEvent(evt: any) {
    this.uploadForm.patchValue({ docUrl: evt });
  }
  get hasDocUrl() {
    return this.appService.isEmptyString(this.uploadForm.get('docUrl').value);
  }

  toggleUploadForm() {
    this.showUploadForm = !this.showUploadForm;
  }
}
