import { Component, OnInit, ViewChild, ɵConsole } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import {
  IseriesItem,
  IunderwriterPayments,
} from 'src/app/models/payment.interface';
import { IpaymentStats } from 'src/app/models/payment.interface';

import {
  ApexNonAxisChartSeries,
  ApexAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
  ApexTitleSubtitle,
} from 'ng-apexcharts';
import { AppEnums } from 'src/app/enums/app.enum';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
  colors: string[];
};

export type ChartOptions3 = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  stroke: ApexStroke;
  fill: ApexFill;
  title: ApexTitleSubtitle;
  colors: string[];
  legend: ApexLegend;
};

interface Irange {
  end_date: number;
  start_date: number;
}

@Component({
  selector: 'app-payment-summary',
  templateUrl: './payment-summary.component.html',
  styleUrls: ['./payment-summary.component.scss'],
})
export class PaymentSummaryComponent implements OnInit {
  allDataLoaded: boolean = false;
  chartsDataLoaded: boolean = false;
  payments: any[];
  underwritersList: [] = [];
  UnderwriterPayments: IunderwriterPayments[] = [];

  underwriterPayments: IpaymentStats[];

  totalMpesaPayments: number = 0;
  totalCardPayments: number = 0;
  totalOfflinePayments: number = 0;
  totalPayments: number = 0;

  coversArray: any[] = [];
  totalPaymentSeries: IseriesItem[] = [];
  cardPaymentSeries: IseriesItem[] = [];
  mpesaPaymentSeries: IseriesItem[] = [];
  offlinePaymentSeries: IseriesItem[] = [];

  selectedDateRange: Irange;

  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public chartOptions4: Partial<ChartOptions3>;

  constructor(public appService: AppService) {
    let todayStamp = Date.now();
    let today = new Date(todayStamp);
    let last30Days = new Date(Date.now() - 2592000000);

    let startDate =
      last30Days.getFullYear() +
      '-' +
      (last30Days.getMonth() + 1) +
      '-' +
      last30Days.getDate();

    let endDate =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate();

    this.selectedDateRange = {
      end_date: Date.parse(endDate),
      start_date: Date.parse(startDate),
    };
  }

  ngOnInit(): void {
    this.getPaymentsInfo();
  }

  updateRange(range) {
    this.selectedDateRange.start_date = Date.parse(range.start_date);
    this.selectedDateRange.end_date = Date.parse(range.end_date);
    this.allDataLoaded = false;
    this.chartsDataLoaded = false;
    this.underwritersList = [];
    this.UnderwriterPayments = [];

    this.totalMpesaPayments = 0;
    this.totalCardPayments = 0;
    this.totalOfflinePayments = 0;
    this.totalPayments = 0;

    this.coversArray = [];
    this.totalPaymentSeries = [];
    this.cardPaymentSeries = [];
    this.mpesaPaymentSeries = [];
    this.offlinePaymentSeries = [];
    this.getPaymentsInfo();
  }

  getPaymentsInfo() {
    this.appService
      .makePostRequest(`${environment.PAYMENTS_API_URL}/paymentlogstats`, {
        pipeline: [
          {
            $match: {
              product: {
                $exists: true,
                // $eq: AppEnums.PRODUCT_MOTOR,
              },
              status: 'SUCCESS',
              $and: [
                { _timestamp: { $gte: this.selectedDateRange.start_date } },
                { _timestamp: { $lte: this.selectedDateRange.end_date } },
              ],
            },
          },
          {
            $lookup: {
              from: 'cover_policy',
              localField: 'received_underscore_id',
              foreignField: '_id',
              as: 'policies',
            },
          },
          {
            $match: {
              policies: {
                $elemMatch: {
                  ModuleID: AppEnums.PartnerkCB,
                },
              },
            },
          },
          {
            $group: {
              _id: {
                tenantUUID: '$tenantUUID',
                product: '$product',
              },
              count: {
                $sum: 1,
              },
              total: {
                $sum: '$amount',
              },
              mpesa: {
                $sum: {
                  $cond: [
                    {
                      $eq: ['$transactionType', 'MPESA'],
                    },
                    '$amount',
                    0,
                  ],
                },
              },
              card: {
                $sum: {
                  $cond: [
                    {
                      $eq: ['$transactionType', 'CARD'],
                    },
                    '$amount',
                    0,
                  ],
                },
              },
              offline: {
                $sum: {
                  $cond: [
                    {
                      $eq: ['$transactionType', 'OFFLINE'],
                    },
                    '$amount',
                    0,
                  ],
                },
              },
            },
          },
          {
            $match: {
              _id: {
                $ne: 'None',
              },
            },
          },
          {
            $group: {
              _id: '$_id.tenantUUID',
              policies: {
                $push: {
                  policy: '$_id.product',
                  mpesaPayments: {
                    $sum: '$mpesa',
                  },
                  cardPayments: {
                    $sum: '$card',
                  },
                  offlinePayments: {
                    $sum: '$offline',
                  },
                  total: {
                    $sum: '$total',
                  },
                },
              },
              totalMpesaPayments: {
                $sum: '$mpesa',
              },
              totalCardPayments: {
                $sum: '$card',
              },
              totalOfflinePayments: {
                $sum: '$offline',
              },
              totalPayments: {
                $sum: '$total',
              },
            },
          },
        ],
      })
      ?.subscribe(
        (apiResponse) => {
          this.underwriterPayments = apiResponse.Payload;
          this.underwriterPayments.forEach((underwriter) => {
            this.totalPayments += underwriter.totalPayments;
            this.totalCardPayments += underwriter.totalCardPayments;
            this.totalMpesaPayments += underwriter.totalMpesaPayments;
            this.totalOfflinePayments += underwriter.totalOfflinePayments;

            underwriter.policies.forEach((policy) => {
              this.coversArray.push({
                policyName: policy.policy,
                total: policy.total,
                totalMpesa: policy.mpesaPayments,
                totalCard: policy.cardPayments,
                totalOffine: policy.offlinePayments,
              });
            });
          });

          this.totalPaymentSeries = Array.from(
            this.coversArray.reduce(
              (m, { policyName, total }) =>
                m.set(policyName, (m.get(policyName) || 0) + total),
              new Map()
            ),
            ([policyName, total]) => ({ x: policyName, y: total })
          );

          this.cardPaymentSeries = Array.from(
            this.coversArray.reduce(
              (m, { policyName, totalCard }) =>
                m.set(policyName, (m.get(policyName) || 0) + totalCard),
              new Map()
            ),
            ([policyName, totalCard]) => ({ x: policyName, y: totalCard })
          );

          this.mpesaPaymentSeries = Array.from(
            this.coversArray.reduce(
              (m, { policyName, totalMpesa }) =>
                m.set(policyName, (m.get(policyName) || 0) + totalMpesa),
              new Map()
            ),
            ([policyName, totalMpesa]) => ({ x: policyName, y: totalMpesa })
          );

          this.offlinePaymentSeries = Array.from(
            this.coversArray.reduce(
              (m, { policyName, totalOffine }) =>
                m.set(policyName, (m.get(policyName) || 0) + totalOffine),
              new Map()
            ),
            ([policyName, totalOffine]) => ({ x: policyName, y: totalOffine })
          );

          this.displayCharts();
        },
        (err) => {
          this.allDataLoaded = true;
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
        }
      );
  }

  displayCharts() {
    this.chartOptions = {
      series: [
        {
          name: 'Total',
          data: this.totalPaymentSeries,
        },
        {
          name: 'Mpesa',
          data: this.mpesaPaymentSeries,
        },
        {
          name: 'Card',
          data: this.cardPaymentSeries,
        },
        {
          name: 'Offline',
          data: this.offlinePaymentSeries,
        },
      ],
      colors: [
        '#32c2db',
        '#00e396',
        '#fa8440',
        '#f65740',
        '#008ffb',
        '#7367e5',
        '#212429',
        '#7703ac',
      ],
      chart: {
        type: 'bar',
        height: 450,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          //endingShape: "rounded",
        },
      },
      title: {
        text: 'Payment comparison per Policy',
        align: 'center',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        type: 'category',
      },
      yaxis: {
        title: {
          text: 'Amount in Kenya Shillings',
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return 'Ksh. ' + val;
          },
        },
      },
    };

    this.chartOptions4 = {
      series: [
        this.totalMpesaPayments,
        this.totalCardPayments,
        this.totalOfflinePayments,
      ],
      colors: [
        '#00e396',
        '#fa8440',
        '#f65740',
        '#7367e5',
        '#212429',
        '#7703ac',
      ],
      chart: {
        type: 'donut',
        width: 350,
        offsetX: -30,
      },
      legend: {
        position: 'bottom',
        width: 500,
      },
      stroke: {
        colors: ['#fff'],
      },
      title: {
        text: 'Payment methods comparison',
        align: 'center',
      },
      fill: {
        opacity: 0.8,
      },
      labels: ['Mpesa', 'Card', 'Offline'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };

    this.chartsDataLoaded = true;
    this.allDataLoaded = true;
  }
}
