import { Component, Inject, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppEnums } from 'src/app/enums/app.enum';
import { ApiResponse } from 'src/app/models/api-response.interface';
import { IUnderwriterContact } from 'src/app/models/underwriter-contact.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import {
  IPADeclarationReportPayload,
  IPADeclarationReportResponse,
} from '../../models/pa-declarationReportReponse.interface';
import {
  IMotorDeclarationReportResponse,
  IMotorDeclarationReportPayload,
} from '../../models/motorDeclarationReportResponse.interface';
import { IMedicalDeclarationReportResponse } from 'src/app/models/medical-declaration-report.interface';
import { ITravelDeclarationReportResponse } from 'src/app/models/travel-declaration-report.interface';
import { IDomesticDeclarationReportResponse } from 'src/app/models/domestic-declaration-report.interface';
import { IEvacuationDeclarationReportResponse } from 'src/app/models/evacuation-declaration-report.interface';

export interface IDeclarationReportPayload {
  reportType: string;
  reportName: string;
  contacts: any;
  name: string;
  temp: boolean;
  reportData: IMotorDeclarationReportResponse;
}

@Component({
  selector: 'app-send-declaration-report-dialog',
  templateUrl: './send-declaration-report-dialog.component.html',
  styleUrls: ['./send-declaration-report-dialog.component.scss'],
})
export class SendDeclarationReportDialogComponent implements OnInit {
  isLoading: boolean = false;
  policyDetail: { _id: string; emails: string };
  agencyId: string;
  formGroupSendDeclarationReport: FormGroup;
  /**
   * Store the report file link return
   * after excel file is created
   */
  reportLink: string;
  /**
   * Generated report status, true if emailed
   */
  emailed: boolean = false;
  constructor(
    public appService: AppService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SendDeclarationReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      product: string;
      _id: string;
      insurer: string;
    }
  ) {
    this.formGroupSendDeclarationReport = this.formBuilder.group({
      _id: new FormControl('', Validators.compose([Validators.required])),
      to: new FormControl('', Validators.compose([Validators.required])),
    });
  }
  ngOnInit(): void {
    this.getUnderwriterContacts();
    this.formGroupSendDeclarationReport.patchValue({
      _id: this.data._id,
    });
  }
  requestPolicyReportData(
    _id: string,
    product: string
  ): Observable<
    | IPADeclarationReportResponse
    | IMotorDeclarationReportResponse
    | IMedicalDeclarationReportResponse
    | ITravelDeclarationReportResponse
    | IDomesticDeclarationReportResponse
    | IEvacuationDeclarationReportResponse
    | string
  > {
    let policyPipeline = [];
    policyPipeline.push({ $match: { _id: _id } });
    switch (product) {
      case AppEnums.PRODUCT_MOTOR:
        policyPipeline.push({
          $lookup: {
            from: 'cover_questions',
            localField: 'questions_id',
            foreignField: '_id',
            as: 'moreQuestions',
          },
        });
        policyPipeline.push({ $unwind: '$moreQuestions' });
        policyPipeline.push({
          $project: {
            salesDateTimestamp: 1,
            clientName: '$moreQuestions.moreQuestions.client.userName',
            clientKraPin: '$moreQuestions.moreQuestions.client.kraPin',
            clientKraPhoto: '$moreQuestions.moreQuestions.client.kraPhoto',
            clientIdNo: '$moreQuestions.moreQuestions.client.idNumber',
            clientPhone: '$moreQuestions.moreQuestions.client.phoneNumber',
            clientEmail: '$moreQuestions.moreQuestions.client.email',
            clientPhysicaladdress:
              '$moreQuestions.moreQuestions.client.physicaladdress',
            clientPostaladdress:
              '$moreQuestions.moreQuestions.client.postaladdress',
            clientPostalCode: '$moreQuestions.moreQuestions.client.postalcode',
            clientPassportNumber:
              '$moreQuestions.moreQuestions.client.passportNumber',
            passportPhoto: '$moreQuestions.moreQuestions.client.passportPhoto',
            idPhotoFront: '$moreQuestions.moreQuestions.client.idPhotoFront',
            idPhotoBack: '$moreQuestions.moreQuestions.client.idPhotoBack',
            dlphoto: '$moreQuestions.moreQuestions.client.dlphoto',
            clientGender: '$moreQuestions.moreQuestions.client.gender',
            clientDob: '$moreQuestions.moreQuestions.client.dob',
            companyName: '$moreQuestions.moreQuestions.company.name',
            companyKraPin: '$moreQuestions.moreQuestions.company.kraPin',
            companyKraPhoto: '$moreQuestions.moreQuestions.company.KraDoc',
            companyPhone: '$moreQuestions.moreQuestions.company.phoneNumber',
            companyEmail: '$moreQuestions.moreQuestions.company.emailAddress',
            incorporationCert:
              '$moreQuestions.moreQuestions.company.incorporationCert',
            companyPhysicaladdress:
              '$moreQuestions.moreQuestions.company.physicaladdress',
            companyPostalAddress:
              '$moreQuestions.moreQuestions.company.postalAddress',
            amount: 1,
            'premium.basicPremium': 1,
            'premium.statutoryPremium': 1,
            'policy.addons': 1,
            'policy.use': 1,
            'policy.policyType': 1,
            insurerName: 1,
            registrationNumber:
              '$moreQuestions.moreQuestions.vehicle.registrationNumber',
            yom: '$moreQuestions.moreQuestions.vehicle.yom',
            make: '$moreQuestions.moreQuestions.vehicle.make',
            bodyType: '$moreQuestions.moreQuestions.vehicle.bodyType',
            model: '$moreQuestions.moreQuestions.vehicle.model',
            chassisNumber: '$moreQuestions.moreQuestions.vehicle.chassisNumber',
            value: '$motor.value',
            logbookDocument:
              '$moreQuestions.moreQuestions.vehicle.logbookDocument',
            'remittance.received': 1,
            'remittance.payments': 1,
            'paymentdetails.transactionRef': 1,
            'certificate.certificateNumber': 1,
            'certificate.policyNumber': 1,
            'certificate.dmvicTimestamp': 1,
            'certificate.validFromTimestamp': 1,
            'certificate.validToTimestamp': 1,
          },
        });
        return this.appService
          .getMotorQuotesAndPolicies({
            pipeline: policyPipeline,
          })
          .pipe(
            map((res) => {
              return res.Payload[0];
            })
          );

      case AppEnums.PRODUCT_PA:
        policyPipeline.push({
          $lookup: {
            from: 'personalaccident_questions',
            localField: 'questions_id',
            foreignField: '_id',
            as: 'moreQuestions',
          },
        });
        policyPipeline.push({ $unwind: '$moreQuestions' });
        policyPipeline.push({
          $project: {
            salesDateTimestamp: 1,
            clientName: '$moreQuestions.moreQuestions.client.userName',
            clientKraPin: '$moreQuestions.moreQuestions.client.kraPin',
            clientKraPhoto: '$moreQuestions.moreQuestions.client.kraPhoto',
            clientIdNo: '$moreQuestions.moreQuestions.client.idNumber',
            clientPhone: '$moreQuestions.moreQuestions.client.phoneNumber',
            clientEmail: '$moreQuestions.moreQuestions.client.email',
            clientPhysicaladdress:
              '$moreQuestions.moreQuestions.client.physicaladdress',
            clientPostaladdress:
              '$moreQuestions.moreQuestions.client.postaladdress',
            clientPostalCode: '$moreQuestions.moreQuestions.client.postalcode',
            clientPassportNumber:
              '$moreQuestions.moreQuestions.client.passportNumber',
            passportPhoto: '$moreQuestions.moreQuestions.client.passportPhoto',
            idPhotoFront: '$moreQuestions.moreQuestions.client.idPhotoFront',
            idPhotoBack: '$moreQuestions.moreQuestions.client.idPhotoBack',
            clientGender: '$moreQuestions.moreQuestions.client.gender',
            clientDob: '$moreQuestions.moreQuestions.client.dob',
            companyName: '$moreQuestions.moreQuestions.company.name',
            companyKraPin: '$moreQuestions.moreQuestions.company.kraPin',
            companyKraPhoto: '$moreQuestions.moreQuestions.company.KraDoc',
            companyPhone: '$moreQuestions.moreQuestions.company.phoneNumber',
            companyEmail: '$moreQuestions.moreQuestions.company.emailAddress',
            incorporationCert:
              '$moreQuestions.moreQuestions.company.incorporationCert',
            companyPhysicaladdress:
              '$moreQuestions.moreQuestions.company.physicaladdress',
            companyPostalAddress:
              '$moreQuestions.moreQuestions.company.postalAddress',
            amount: 1,
            'premium.basicPremium': 1,
            'premium.statutoryPremium': 1,
            addons: 1,
            'quotation.addons': 1,
            insurerName: 1,
            nokName: '$moreQuestions.moreQuestions.client.nokName',
            nokLocation: '$moreQuestions.moreQuestions.client.nokLocation',
            nokRelationship:
              '$moreQuestions.moreQuestions.client.nokRelationship',
            nokPhoneNo: '$moreQuestions.moreQuestions.client.nokPhoneNo',
            nokPostalAddress:
              '$moreQuestions.moreQuestions.client.nokPostalAddress',
            nokPostalCode: '$moreQuestions.moreQuestions.client.nokPostalCode',
            nokId_Passport:
              '$moreQuestions.moreQuestions.client.nokId_Passport',
            'quotation.insurerName': 1,
            'remittance.received': 1,
            'remittance.payments': 1,
            'paymentdetails.transactionRef': 1,
            'group.members': 1,
            policyNumber: 1,
            'policy.coverType': 1,
            'policy.occupation': 1,
            'policy.periodName': 1,
            endDate: '$moreQuestions.moreQuestions.endDate',
            startDate: '$moreQuestions.moreQuestions.startDate',
          },
        });

        return this.appService
          .getPersonalAccidentAggregateQuotesAndPolicies({
            pipeline: policyPipeline,
          })
          .pipe(
            map((res) => {
              return res.Payload[0];
            })
          );

      case AppEnums.PRODUCT_MEDICAL:
        policyPipeline.push({
          $lookup: {
            from: 'cover_questions',
            localField: 'questions_id',
            foreignField: '_id',
            as: 'moreQuestions',
          },
        });
        policyPipeline.push({ $unwind: '$moreQuestions' });
        policyPipeline.push({
          $project: {
            salesDateTimestamp: 1,
            clientName: '$moreQuestions.moreQuestions.client.userName',
            clientKraPin: '$moreQuestions.moreQuestions.client.kraPin',
            clientKraPhoto: '$moreQuestions.moreQuestions.client.kraPhoto',
            clientIdNo: '$moreQuestions.moreQuestions.client.idNumber',
            clientPhone: '$moreQuestions.moreQuestions.client.phoneNumber',
            clientEmail: '$moreQuestions.moreQuestions.client.email',
            clientPhysicaladdress:
              '$moreQuestions.moreQuestions.client.physicaladdress',
            clientPostaladdress:
              '$moreQuestions.moreQuestions.client.postaladdress',
            clientPostalCode: '$moreQuestions.moreQuestions.client.postalcode',
            clientPassportNumber:
              '$moreQuestions.moreQuestions.client.passportNumber',
            passportPhoto: '$moreQuestions.moreQuestions.client.passportPhoto',
            idPhotoFront: '$moreQuestions.moreQuestions.client.idPhotoFront',
            idPhotoBack: '$moreQuestions.moreQuestions.client.idPhotoBack',
            signature: '$moreQuestions.moreQuestions.client.signature',
            headshot: '$moreQuestions.moreQuestions.client.profileImage',
            clientGender: '$moreQuestions.moreQuestions.client.gender',
            clientDob: '$moreQuestions.moreQuestions.client.dob',
            companyName: '$moreQuestions.moreQuestions.company.name',
            companyKraPin: '$moreQuestions.moreQuestions.company.kraPin',
            companyKraPhoto: '$moreQuestions.moreQuestions.company.KraDoc',
            companyPhone: '$moreQuestions.moreQuestions.company.phoneNumber',
            companyEmail: '$moreQuestions.moreQuestions.company.emailAddress',
            incorporationCert:
              '$moreQuestions.moreQuestions.company.incorporationCert',
            companyPhysicaladdress:
              '$moreQuestions.moreQuestions.company.physicaladdress',
            companyPostalAddress:
              '$moreQuestions.moreQuestions.company.postalAddress',
            amount: 1,
            'premium.basicPremium': 1,
            'premium.statutoryPremium': 1,
            'aggregatedPremiums.basicPremium': 1,
            'aggregatedPremiums.statutoryPremiums': 1,
            addons: 1,
            'quotation.addons': 1,
            insurerName: 1,
            'quotation.insurerName': 1,
            nokName: '$moreQuestions.moreQuestions.client.nokName',
            nokLocation: '$moreQuestions.moreQuestions.client.nokLocation',
            nokRelationship:
              '$moreQuestions.moreQuestions.client.nokRelationship',
            nokPhoneNo: '$moreQuestions.moreQuestions.client.nokPhoneNo',
            nokPostalAddress:
              '$moreQuestions.moreQuestions.client.nokPostalAddress',
            nokPostalCode: '$moreQuestions.moreQuestions.client.nokPostalCode',
            nokId_Passport:
              '$moreQuestions.moreQuestions.client.nokId_Passport',
            'remittance.received': 1,
            'remittance.payments': 1,
            'paymentdetails.transactionRef': 1,
            dependents: '$moreQuestions.moreQuestions.dependents',
            'group.members': 1,
          },
        });
        return this.appService
          .getMedicalAggregateQuotesAndPolicies({
            pipeline: policyPipeline,
          })
          .pipe(
            map((res) => {
              return res.Payload[0];
            })
          );
      // break;
      case AppEnums.PRODUCT_TRAVEL:
        policyPipeline.push({
          $lookup: {
            from: 'travel_questions',
            localField: 'questions_id',
            foreignField: '_id',
            as: 'moreQuestions',
          },
        });
        policyPipeline.push({ $unwind: '$moreQuestions' });
        policyPipeline.push({
          $project: {
            salesDateTimestamp: 1,
            clientName: '$moreQuestions.moreQuestions.client.userName',
            clientKraPin: '$moreQuestions.moreQuestions.client.kraPin',
            clientKraPhoto: '$moreQuestions.moreQuestions.client.kraPhoto',
            clientIdNo: '$moreQuestions.moreQuestions.client.idNumber',
            clientPhone: '$moreQuestions.moreQuestions.client.phoneNumber',
            clientEmail: '$moreQuestions.moreQuestions.client.email',
            clientPhysicaladdress:
              '$moreQuestions.moreQuestions.client.physicaladdress',
            clientPostaladdress:
              '$moreQuestions.moreQuestions.client.postaladdress',
            clientPostalCode: '$moreQuestions.moreQuestions.client.postalcode',
            clientPassportNumber:
              '$moreQuestions.moreQuestions.client.passportNumber',
            passportPhoto: '$moreQuestions.moreQuestions.client.passportPhoto',
            idPhotoFront: '$moreQuestions.moreQuestions.client.idPhotoFront',
            idPhotoBack: '$moreQuestions.moreQuestions.client.idPhotoBack',
            clientGender: '$moreQuestions.moreQuestions.client.gender',
            clientDob: '$moreQuestions.moreQuestions.client.dob',
            companyName: '$moreQuestions.moreQuestions.company.name',
            companyKraPin: '$moreQuestions.moreQuestions.company.kraPin',
            companyKraPhoto: '$moreQuestions.moreQuestions.company.KraDoc',
            companyPhone: '$moreQuestions.moreQuestions.company.phoneNumber',
            companyEmail: '$moreQuestions.moreQuestions.company.emailAddress',
            incorporationCert:
              '$moreQuestions.moreQuestions.company.incorporationCert',
            companyPhysicaladdress:
              '$moreQuestions.moreQuestions.company.physicaladdress',
            companyPostalAddress:
              '$moreQuestions.moreQuestions.company.postalAddress',
            amount: 1,
            'premium.basicPremium': 1,
            'premium.statutoryPremium': 1,
            addons: 1,
            'quotation.addons': 1,
            insurerName: 1,
            nokName: '$moreQuestions.moreQuestions.client.nokName',
            nokLocation: '$moreQuestions.moreQuestions.client.nokLocation',
            nokRelationship:
              '$moreQuestions.moreQuestions.client.nokRelationship',
            nokPhoneNo: '$moreQuestions.moreQuestions.client.nokPhoneNo',
            nokPostalAddress:
              '$moreQuestions.moreQuestions.client.nokPostalAddress',
            nokPostalCode: '$moreQuestions.moreQuestions.client.nokPostalCode',
            nokId_Passport:
              '$moreQuestions.moreQuestions.client.nokId_Passport',
            'quotation.insurerName': 1,
            'remittance.received': 1,
            'remittance.payments': 1,
            'paymentdetails.transactionRef': 1,
            dependents: '$moreQuestions.moreQuestions.dependents',
            'policy.startYear': 1,
            'policy.endYear': 1,
            'policy.plan': 1,
            'policy.departure.country': 1,
            'policy.destination.country': 1,
            'policy.reason': 1,
            'policy.coverDays': 1,
            group: 1,
          },
        });
        return this.appService
          .getTravelAggregateQuotesAndPolicies({
            pipeline: policyPipeline,
          })
          .pipe(
            map((res) => {
              return res.Payload[0];
            })
          );
      case AppEnums.PRODUCT_DOMESTIC:
        policyPipeline.push({
          $lookup: {
            from: 'domestic_questions',
            localField: 'questions_id',
            foreignField: '_id',
            as: 'moreQuestions',
          },
        });
        policyPipeline.push({ $unwind: '$moreQuestions' });
        policyPipeline.push({
          $project: {
            salesDateTimestamp: 1,
            clientName: '$moreQuestions.moreQuestions.client.userName',
            clientKraPin: '$moreQuestions.moreQuestions.client.kraPin',
            clientKraPhoto: '$moreQuestions.moreQuestions.client.kraPhoto',
            clientIdNo: '$moreQuestions.moreQuestions.client.idNumber',
            clientPhone: '$moreQuestions.moreQuestions.client.phoneNumber',
            clientEmail: '$moreQuestions.moreQuestions.client.email',
            clientPhysicaladdress:
              '$moreQuestions.moreQuestions.client.physicaladdress',
            clientPostaladdress:
              '$moreQuestions.moreQuestions.client.postaladdress',
            clientPostalCode: '$moreQuestions.moreQuestions.client.postalcode',
            clientPassportNumber:
              '$moreQuestions.moreQuestions.client.passportNumber',
            passportPhoto: '$moreQuestions.moreQuestions.client.passportPhoto',
            idPhotoFront: '$moreQuestions.moreQuestions.client.idPhotoFront',
            idPhotoBack: '$moreQuestions.moreQuestions.client.idPhotoBack',
            clientGender: '$moreQuestions.moreQuestions.client.gender',
            clientDob: '$moreQuestions.moreQuestions.client.dob',
            companyName: '$moreQuestions.moreQuestions.company.name',
            companyKraPin: '$moreQuestions.moreQuestions.company.kraPin',
            companyKraPhoto: '$moreQuestions.moreQuestions.company.KraDoc',
            companyPhone: '$moreQuestions.moreQuestions.company.phoneNumber',
            companyEmail: '$moreQuestions.moreQuestions.company.emailAddress',
            incorporationCert:
              '$moreQuestions.moreQuestions.company.incorporationCert',
            companyPhysicaladdress:
              '$moreQuestions.moreQuestions.company.physicaladdress',
            companyPostalAddress:
              '$moreQuestions.moreQuestions.company.postalAddress',
            amount: 1,
            'premium.totalPremiumAmount': 1,
            'premium.statutoryPremium': 1,
            addons: 1,
            'quotation.addons': 1,
            insurerName: 1,
            backImage: '$moreQuestions.moreQuestions.domesticImages.backImage',
            frontImage:
              '$moreQuestions.moreQuestions.domesticImages.frontImage',
            leftSideImage:
              '$moreQuestions.moreQuestions.domesticImages.leftSideImage',
            rightSideImage:
              '$moreQuestions.moreQuestions.domesticImages.rightSideImage',
            'quotation.insurerName': 1,
            'remittance.received': 1,
            'remittance.payments': 1,
            'paymentdetails.transactionRef': 1,
            'group.members': 1,
            'policy.contentsSumValue': 1,
            'policy.contents': 1,
            endDate: '$moreQuestions.moreQuestions.endDate',
            startDate: '$moreQuestions.moreQuestions.startDate',
          },
        });
        return this.appService
          .getDomesticAggregateQuotesAndPolicies({
            pipeline: policyPipeline,
          })
          .pipe(
            map((res) => {
              return res.Payload[0];
            })
          );
      case AppEnums.PRODUCT_EVACUATION:
        policyPipeline.push({
          $lookup: {
            from: 'evacuation_questions',
            localField: 'questions_id',
            foreignField: '_id',
            as: 'moreQuestions',
          },
        });
        policyPipeline.push({ $unwind: '$moreQuestions' });
        policyPipeline.push({
          $project: {
            salesDateTimestamp: 1,
            clientName: '$moreQuestions.moreQuestions.client.userName',
            clientKraPin: '$moreQuestions.moreQuestions.client.kraPin',
            clientKraPhoto: '$moreQuestions.moreQuestions.client.kraPhoto',
            clientIdNo: '$moreQuestions.moreQuestions.client.idNumber',
            clientPhone: '$moreQuestions.moreQuestions.client.phoneNumber',
            clientEmail: '$moreQuestions.moreQuestions.client.email',
            clientPhysicaladdress:
              '$moreQuestions.moreQuestions.client.physicaladdress',
            clientPostaladdress:
              '$moreQuestions.moreQuestions.client.postaladdress',
            clientPostalCode: '$moreQuestions.moreQuestions.client.postalcode',
            clientPassportNumber:
              '$moreQuestions.moreQuestions.client.passportNumber',
            passportPhoto: '$moreQuestions.moreQuestions.client.passportPhoto',
            idPhotoFront: '$moreQuestions.moreQuestions.client.idPhotoFront',
            idPhotoBack: '$moreQuestions.moreQuestions.client.idPhotoBack',
            clientGender: '$moreQuestions.moreQuestions.client.gender',
            clientDob: '$moreQuestions.moreQuestions.client.dob',
            companyName: '$moreQuestions.moreQuestions.company.name',
            companyKraPin: '$moreQuestions.moreQuestions.company.kraPin',
            companyKraPhoto: '$moreQuestions.moreQuestions.company.KraDoc',
            companyPhone: '$moreQuestions.moreQuestions.company.phoneNumber',
            companyEmail: '$moreQuestions.moreQuestions.company.emailAddress',
            incorporationCert:
              '$moreQuestions.moreQuestions.company.incorporationCert',
            companyPhysicaladdress:
              '$moreQuestions.moreQuestions.company.physicaladdress',
            companyPostalAddress:
              '$moreQuestions.moreQuestions.company.postalAddress',
            amount: 1,
            'premium.basicPremium': 1,
            'premium.statutoryPremium': 1,
            addons: 1,
            'quotation.addons': 1,
            insurerName: 1,
            dependents: '$moreQuestions.moreQuestions.dependents',
            'quotation.insurerName': 1,
            'remittance.received': 1,
            'remittance.payments': 1,
            'paymentdetails.transactionRef': 1,
            'group.members': 1,
            'policy.plan': 1,
            'policy.startYear': 1,
            'policy.endYear': 1,
            'policy.coverDays': 1,
          },
        });
        return this.appService
          .getEvacuationAggregateQuotesAndPolicies({
            pipeline: policyPipeline,
          })
          .pipe(
            map((res) => {
              return res.Payload[0];
            })
          );
      case AppEnums.PRODUCT_OTHER:
        policyPipeline.push({
          $lookup: {
            from: 'other_policies_questions',
            localField: 'questions_id',
            foreignField: '_id',
            as: 'moreQuestions',
          },
        });
        policyPipeline.push({ $unwind: '$moreQuestions' });
        policyPipeline.push({
          $project: {
            salesDateTimestamp: 1,
            clientName: '$moreQuestions.moreQuestions.client.userName',
            clientKraPin: '$moreQuestions.moreQuestions.client.kraPin',
            clientKraPhoto: '$moreQuestions.moreQuestions.client.kraPhoto',
            clientIdNo: '$moreQuestions.moreQuestions.client.idNumber',
            clientPhone: '$moreQuestions.moreQuestions.client.phoneNumber',
            clientEmail: '$moreQuestions.moreQuestions.client.email',
            clientPhysicaladdress:
              '$moreQuestions.moreQuestions.client.physicaladdress',
            clientPostaladdress:
              '$moreQuestions.moreQuestions.client.postaladdress',
            clientPostalCode: '$moreQuestions.moreQuestions.client.postalcode',
            clientPassportNumber:
              '$moreQuestions.moreQuestions.client.passportNumber',
            passportPhoto: '$moreQuestions.moreQuestions.client.passportPhoto',
            idPhotoFront: '$moreQuestions.moreQuestions.client.idPhotoFront',
            idPhotoBack: '$moreQuestions.moreQuestions.client.idPhotoBack',
            clientGender: '$moreQuestions.moreQuestions.client.gender',
            clientDob: '$moreQuestions.moreQuestions.client.dob',
            companyName: '$moreQuestions.moreQuestions.company.name',
            companyKraPin: '$moreQuestions.moreQuestions.company.kraPin',
            companyKraPhoto: '$moreQuestions.moreQuestions.company.KraDoc',
            companyPhone: '$moreQuestions.moreQuestions.company.phoneNumber',
            companyEmail: '$moreQuestions.moreQuestions.company.emailAddress',
            incorporationCert:
              '$moreQuestions.moreQuestions.company.incorporationCert',
            companyPhysicaladdress:
              '$moreQuestions.moreQuestions.company.physicaladdress',
            companyPostalAddress:
              '$moreQuestions.moreQuestions.company.postalAddress',
            amount: 1,
            'premium.basicPremium': 1,
            'premium.statutoryPremium': 1,
            addons: 1,
            'quotation.addons': 1,
            insurerName: 1,
            dependents: '$moreQuestions.moreQuestions.dependents',
            'quotation.insurerName': 1,
            'remittance.received': 1,
            'remittance.payments': 1,
            'paymentdetails.transactionRef': 1,
            'group.members': 1,
            policy: 1,
            policy_type: 1,
          },
        });
      default:
        this.appService.showToastMessage(
          AppEnums.ToastTypeWarning,
          '',
          `Reports for ${product} coming soon ...`
        );
        this.isLoading = false;
    }
  }
  generateReport() {
    if (!this.formGroupSendDeclarationReport.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Fill form correctly'
      );
      return;
    }
    this.policyDetail = {
      _id: this.formGroupSendDeclarationReport.get('_id').value,
      emails: this.formGroupSendDeclarationReport.get('to').value,
    };
    this.isLoading = true;
    if (
      this.data.product == AppEnums.PRODUCT_GOLF ||
      this.data.product == AppEnums.PRODUCT_LASTEXPENSE ||
      this.data.product == AppEnums.PRODUCT_TELEMEDICINE ||
      this.data.product == AppEnums.PRODUCT_OTHER ||
      this.data.product == AppEnums.PRODUCT_GADGET
    ) {
      if (this.data.product == AppEnums.PRODUCT_GOLF) {
        this.getGolfDeclarationReport(this.data._id);
      } else if (this.data.product == AppEnums.PRODUCT_LASTEXPENSE) {
        this.getLastExpenseDeclarationReport(this.data._id);
      } else if (this.data.product == AppEnums.PRODUCT_TELEMEDICINE) {
        this.getTelemedicineDeclarationReport(this.data._id);
      } else if (this.data.product == AppEnums.PRODUCT_OTHER) {
        this.getOtherPoliciesDeclarationReport(this.data._id);
      } else if (this.data.product == AppEnums.PRODUCT_GADGET) {
        this.getGadgetPoliciesDeclarationReport(this.data._id);
      }
    } else {
      this.requestPolicyReportData(this.policyDetail._id, this.data.product)
        .pipe(
          switchMap((d) => this.callDeclarationReport(d, this.data.product))
        )
        .subscribe(
          (res) => {
            this.isLoading = false;
            this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              '',
              res?.Message
            );
            this.dialogRef.close();
          },
          (err) => {
            this.isLoading = false;
            this.appService.showToastMessage(
              AppEnums.ToastTypeError,
              '',
              this.appService.processHttpErrors(err)
            );
          }
        );
    }
  }
  prepareContacts(): string[] {
    let ems: string[] = this.policyDetail.emails.split(',');
    return ems.filter(
      (item, index) =>
        ems.indexOf(item) === index && this.appService.isValidEmail(item)
    );
  }
  callDeclarationReport(
    declarationReportData,
    product
  ): Observable<ApiResponse> {
    switch (product) {
      case AppEnums.PRODUCT_MOTOR:
        let motor: IMotorDeclarationReportPayload = {
          reportType: 'PDF',
          reportName: 'motor_declaration_report',
          contacts: this.prepareContacts(),
          name: 'Sales Report',
          temp: true,
          reportData: declarationReportData,
        };
        return this.appService.makePostRequest(
          `${environment.REPORTS_API_URL}/motor-declaration-report`,
          motor
        );
      case AppEnums.PRODUCT_PA:
        let pa: IPADeclarationReportPayload = {
          reportType: 'PDF',
          reportName: 'pa_declaration_report',
          contacts: this.prepareContacts(),
          name: 'Sales Report',
          temp: true,
          reportData: declarationReportData,
        };
        return this.appService.makePostRequest(
          `${environment.REPORTS_API_URL}/pa-declaration-report`,
          pa
        );
      case AppEnums.PRODUCT_MEDICAL:
        let medicalPayload: IDeclarationReportPayload = {
          reportType: 'PDF',
          reportName: 'medical_declaration_report',
          contacts: this.prepareContacts(),
          name: 'Sales Report',
          temp: true,
          reportData: declarationReportData,
        };
        return this.appService.makePostRequest(
          `${environment.REPORTS_API_URL}/medical-declaration-report`,
          medicalPayload
        );
      case AppEnums.PRODUCT_TRAVEL:
        let travelPayload: IDeclarationReportPayload = {
          reportType: 'PDF',
          reportName: 'travel_declaration_report',
          contacts: this.prepareContacts(),
          name: 'Sales Report',
          temp: true,
          reportData: declarationReportData,
        };
        return this.appService.makePostRequest(
          `${environment.REPORTS_API_URL}/travel-declaration-report`,
          travelPayload
        );
      case AppEnums.PRODUCT_DOMESTIC:
        let domesticPayload: IDeclarationReportPayload = {
          reportType: 'PDF',
          reportName: 'domestic_declaration_report',
          contacts: this.prepareContacts(),
          name: 'Sales Report',
          temp: true,
          reportData: declarationReportData,
        };
        return this.appService.makePostRequest(
          `${environment.REPORTS_API_URL}/domestic-declaration-report`,
          domesticPayload
        );
      case AppEnums.PRODUCT_EVACUATION:
        let evacuationPayload: IDeclarationReportPayload = {
          reportType: 'PDF',
          reportName: 'evacuation_declaration_report',
          contacts: this.prepareContacts(),
          name: 'Sales Report',
          temp: true,
          reportData: declarationReportData,
        };
        return this.appService.makePostRequest(
          `${environment.REPORTS_API_URL}/evacuation-declaration-report`,
          evacuationPayload
        );
      case AppEnums.PRODUCT_OTHER:
        let otherPayload: IDeclarationReportPayload = {
          reportType: 'PDF',
          reportName: 'other_policies_declaration_report',
          contacts: this.prepareContacts(),
          name: 'Sales Report',
          temp: true,
          reportData: declarationReportData,
        };
        return this.appService.makePostRequest(
          `${environment.REPORTS_API_URL}/other-policies-report`,
          otherPayload
        );
    }
  }
  getUnderwriterContacts() {
    this.isLoading = true;
    let filter = {
      product: this.data.product,
      id: this.data.insurer,
    };
    this.appService
      .fetchUnderwritercontacts(filter)
      .subscribe((underwriterContacts) => {
        let allContactEmails = '';
        let myEmail = this.appService.getSessionUser().email;
        if (myEmail) {
          allContactEmails += myEmail;
        }
        if (underwriterContacts.length > 0) {
          for (let index = 0; index < underwriterContacts.length; index++) {
            const aContact: IUnderwriterContact = underwriterContacts[index];
            if (this.appService.isValidEmail(aContact.email)) {
              allContactEmails += ',' + aContact.email;
            }
            if (this.appService.isValidEmail(aContact.cc)) {
              allContactEmails += ',' + aContact.cc;
            }
          }
        }
        let ems: string[] = allContactEmails.split(',');
        let emails = ems.filter((item, index) => ems.indexOf(item) === index);
        if (emails.length > 0) {
          this.formGroupSendDeclarationReport.patchValue({
            to: emails.toString(),
          });
        }
        this.isLoading = false;
      });
  }

  getGolfDeclarationReport(_id) {
    this.isLoading = true;
    this.appService
      .makePostRequest(
        `${environment.DATA_ANALYTICS_API_URL}/golf_declaration_report`,
        {
          _id: _id,
          sendTo: this.appService.getSessionUser().email,
        }
      )
      .subscribe(
        (res) => {
          this.isLoading = false;
          if (res.Status == 200) {
            return this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              res.Message,
              'Success'
            );
          } else {
            return this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              res.Message,
              'Warning'
            );
          }
        },
        (error) => {
          return this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            this.appService.processHttpErrors(error),
            'Error'
          );
        }
      );
  }

  getTelemedicineDeclarationReport(_id) {
    this.isLoading = true;
    this.appService
      .makePostRequest(
        `${environment.DATA_ANALYTICS_API_URL}/telemedicine_declaration_report`,
        {
          _id: _id,
          sendTo: this.appService.getSessionUser().email,
        }
      )
      .subscribe(
        (res) => {
          this.isLoading = false;
          if (res.Status == 200) {
            return this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              res.Message,
              'Success'
            );
          } else {
            return this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              res.Message,
              'Warning'
            );
          }
        },
        (error) => {
          return this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            this.appService.processHttpErrors(error),
            'Error'
          );
        }
      );
  }

  getLastExpenseDeclarationReport(_id) {
    this.isLoading = true;
    this.appService
      .makePostRequest(
        `${environment.DATA_ANALYTICS_API_URL}/lastexpense_declaration_report`,
        {
          _id: _id,
          sendTo: this.appService.getSessionUser().email,
        }
      )
      .subscribe(
        (res) => {
          this.isLoading = false;
          if (res.Status == 200) {
            return this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              res.Message,
              'Success'
            );
          } else {
            return this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              res.Message,
              'Warning'
            );
          }
        },
        (error) => {
          return this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            this.appService.processHttpErrors(error),
            'Error'
          );
        }
      );
  }

  getOtherPoliciesDeclarationReport(_id) {
    this.isLoading = true;
    this.appService
      .makePostRequest(
        `${environment.DATA_ANALYTICS_API_URL}/other_policies_declaration_report`,
        {
          _id: _id,
          sendTo: this.appService.getSessionUser().email,
        }
      )
      .subscribe(
        (res) => {
          this.isLoading = false;
          if (res.Status == 200) {
            return this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              res.Payload,
              res.Message
            );
          } else {
            return this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              res.Payload,
              res.Message
            );
          }
        },
        (error) => {
          return this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            this.appService.processHttpErrors(error),
            'Error'
          );
        }
      );
  }

  getGadgetPoliciesDeclarationReport(_id) {
    this.isLoading = true;
    this.appService
      .makePostRequest(
        `${environment.DATA_ANALYTICS_API_URL}/gadget_declaration_report`,
        {
          _id: _id,
          sendTo: this.appService.getSessionUser().email,
        }
      )
      .subscribe(
        (res) => {
          this.isLoading = false;
          if (res.Status == 200) {
            return this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              res.Payload,
              res.Message
            );
          } else {
            return this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              res.Payload,
              res.Message
            );
          }
        },
        (error) => {
          return this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            this.appService.processHttpErrors(error),
            'Error'
          );
        }
      );
  }
}
