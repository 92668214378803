<div class="table-responsive-sm">
  <table class="table table-striped table-responsive-sm">
    <tr>
      <th scope="col">Date</th>
      <th scope="col">Client</th>
      <th scope="col">Underwriter</th>
      <th scope="col">Policy</th>
      <th scope="col">Amount</th>
      <th scope="col">Mpesa Ref</th>
      <th scope="col">Status</th>
      <th scope="col">Description</th>
    </tr>
    <tbody>
      <tr
        *ngFor="let mpesa of payments"
        class="section-step"
        (click)="goToPaymentDetails(mpesa._id)"
      >
        <td class="p-3">{{ mpesa?._timestamp | date: 'medium' }}</td>
        <td class="p-3">
          <span *ngIf="mpesa?.policyHolderDetails; else nopolicyHolderDetails">
            {{ mpesa?.policyHolderDetails?.userName }}
            <br />
            <span class="small">
              {{ mpesa?.policyHolderDetails?.phoneNumber }}</span
            >
          </span>
          <ng-template #nopolicyHolderDetails>
            {{ mpesa?.client?.userName }} {{ mpesa?.client?.name }}
            <br />
            <span class="small"> {{ mpesa?.details?.mobileNumber }}</span>
          </ng-template>
        </td>
        <td class="p-3">
          {{ mpesa?.broker | underwriter | async }}
        </td>
        <td class="p-3">{{ mpesa?.service?.transactionDescription }}</td>
        <td class="p-3">{{ mpesa?.amount | currency: 'Ksh. ' }}</td>
        <td class="p-3">
          <span *ngIf="mpesa?.details?.mpesaReference; else noRef">{{
            mpesa?.details?.mpesaReference
          }}</span>
          <span *ngIf="mpesa?.details?.mpesaReference == null">NA</span>
        </td>

        <td class="p-3">
          <span
            *ngIf="mpesa?.status == 'SUCCESS'; else successReversed"
            class="text-success"
            >{{ mpesa?.status }}</span
          >

          <span
            *ngIf="mpesa?.status == 'SUCCESS_REVERSED'; else pending"
            class="text-success"
            >{{ mpesa?.status }}</span
          >
          <span
            *ngIf="mpesa?.status == 'PENDING'; else failed"
            class="text-warning"
          >
            {{ mpesa?.status }}<br />
            <button
              class="btn btn-sm btn-info"
              (click)="queryTransaction(mpesa?._id)"
            >
              Retry
            </button>
          </span>

          <span
            *ngIf="mpesa?.status == 'FAILED'; else noStatus"
            class="text-danger"
          >
            {{ mpesa?.status }}</span
          >

          <span *ngIf="mpesa?.status == null">NA</span>
        </td>

        <td class="p-3">
          <span *ngIf="mpesa?.details?.ResultDesc; else noDesc">
            {{ mpesa?.details?.ResultDesc }}</span
          >
          <span *ngIf="mpesa?.details?.ResultDesc == null">NA</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
