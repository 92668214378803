<div class="alert alert-info d-block mb-1" role="alert">
    <span class="d-block">Your JSON file should be formatted as follows:</span>
    <pre class="d-block">{{sampleJsonFormat}}</pre>
</div>
<div class="container-fluid">
    <div class="col-md-6">
        <app-loader *ngIf="isLoading"></app-loader>
        <img src="https://img.icons8.com/color/48/000000/json.png" />
        <span class="text-muted" style="font-size: 16px;">Import JSON</span>
        <div class="form-group">
            <input class="form-control" accept=".json" id="json" type="file" (change)="onFileSelect($event.target)"
                name="myfile">
        </div>
    </div>
</div>