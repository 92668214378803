<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<h1 mat-dialog-title>Password Reset for {{ data.userName }}</h1>
<div mat-dialog-content>
  <form [formGroup]="changPasswordForm" (ngSubmit)="submit()">
    <mat-form-field>
      <mat-label>Reset Password</mat-label>
      <input matInput [type]="visibility" formControlName="password" />
      <button
        mat-button
        type="button"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="togglePasswordVisibility()"
      >
        <i class="vsm-icon pe-7s-look"></i>
      </button>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Confirm Reset Password</mat-label>
      <input matInput [type]="visibility" formControlName="confirmPassword" />
      <button
        mat-button
        type="button"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="togglePasswordVisibility()"
      >
        <i class="vsm-icon pe-7s-look"></i>
      </button>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button color="primary" (click)="submit()">Submit</button>
</div>
