import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppEnums } from 'src/app/enums/app.enum';
import { IPagination } from 'src/app/models/pagination.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import { BulkPayCommissionDialogComponent } from '../bulk-pay-commission-dialog/bulk-pay-commission-dialog.component';
import { DownloadCommissionStatementDialogComponent } from '../download-commission-statement-dialog/download-commission-statement-dialog.component';

@Component({
  selector: 'app-policy-commissions-list',
  templateUrl: './policy-commissions-list.component.html',
  styleUrls: ['./policy-commissions-list.component.scss'],
})
export class PolicyCommissionsListComponent implements OnInit {
  @Input() product: string;
  productName: string;
  allCommissionDataLoaded: boolean = false;
  commissionDataLoading: boolean = false;
  allCommissions: any[] = [];
  commissionRows: any[] = [];
  isCommissionSearchData: boolean = false;
  dataCommissionLength: number = 0;
  CommissionPagination: IPagination = {
    limit: 10,
    skip: 0,
    sort: {
      _timestamp: -1,
    },
  };
  searchParamsCommissions: any;
  searchItem: string = '';
  constructor(
    private appService: AppService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getAllCommissions();
    this.convertProductName();
    this.searchParamsCommissions = {
      url: `${environment.MPESA_API_URL}/getComissions`,
      searchFields: [
        '_id',
        'xusr.userName',
        'xusr.email',
        'totalCommission',
        'mtekCommission',
        'patapesaCommission',
        'countyRepCommission',
        'embeddedCommission',
        'consultantCommission',
        'primePartnerCommission',
      ],
      fields: {
        _id: 1,
        'xusr.userName': 1,
        'xusr.email': 1,
        totalCommission: 1,
        mtekCommission: 1,
        patapesaCommission: 1,
        countyRepCommission: 1,
        embeddedCommission: 1,
        consultantCommission: 1,
        primePartnerCommission: 1,
      },
      pagination: [
        { $sort: this.CommissionPagination.sort },
        { $skip: this.CommissionPagination.skip },
        { $limit: this.CommissionPagination.limit },
      ],
      customQuery: [{ product: this.product }],
    };
  }

  convertProductName() {
    switch (this.product) {
      case 'health':
        this.productName = 'medical';
        break;
      case 'lastexpense':
        this.productName = 'last expense';
        break;
      case 'personalaccident':
        this.productName = 'personal accident';
        break;
      default:
        break;
    }
  }

  getAllCommissions() {
    this.commissionDataLoading = true;
    const pipeline = {
      pipeline: [
        {
          $match: {
            product: this.product,
          },
        },
        {
          $sort: this.CommissionPagination.sort,
        },
        {
          $skip: this.CommissionPagination.skip,
        },
        {
          $limit: this.CommissionPagination.limit,
        },
        {
          $project: {
            _id: 1,
            quoteID: 1,
            xusr: 1,
            totalCommission: 1,
            mtekCommission: 1,
            patapesaCommission: 1,
            countyRepCommission: 1,
            embeddedCommission: 1,
            consultantCommission: 1,
            primePartnerCommission: 1,
            _timestamp: 1,
          },
        },
      ],
    };
    this.appService
      .makePostRequest(`${environment.MPESA_API_URL}/getComissions`, pipeline)
      .subscribe(
        (res) => {
          this.commissionDataLoading = false;
          this.allCommissionDataLoaded = true;
          if (res.Status == 200) {
            const response = res.Payload;
            this.allCommissions = response;
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              res.Message,
              'Warning!'
            );
          }
        },
        (error) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            this.appService.processHttpErrors(error),
            'Warning!'
          );
        }
      );
  }

  goToPolicyDetails(_id: string) {
    switch (this.product) {
      case 'domestic':
        this.router.navigate(['/domestic/list/domestic-details', _id]);
        break;
      case 'evacuation':
        this.router.navigate(['/evacuation/list/evacuation-details', _id]);
        break;
      case 'golf':
        this.router.navigate(['/golf/list/golf-details', _id]);
        break;
      case 'lastexpense':
        this.router.navigate(['/lastexpense/list/lastexpense-details', _id]);
        break;
      case 'life':
        this.router.navigate(['/life/list/life-details', _id]);
        break;
      case 'health':
        this.router.navigate(['/medical/list/medical-details', _id]);
        break;
      case 'motor':
        this.router.navigate(['/motor/list/motor-details', _id]);
        break;
      case 'personalaccident':
        this.router.navigate(['/personal-accident/list/pa-details', _id]);
        break;
      case 'telemedicine':
        this.router.navigate(['/telemedicine/list/telemedicine-details', _id]);
        break;
      case 'travel':
        this.router.navigate(['/travel/list/travel-details', _id]);
        break;
      default:
        break;
    }
  }

  resetCommissionData() {
    this.getAllCommissions();
  }

  updateCommissionSkip(newSkip) {
    if (newSkip) {
      if (newSkip === -10) {
        this.CommissionPagination.skip = 0;
      } else {
        this.CommissionPagination.skip = newSkip;
      }
      this.getAllCommissions();
    }
  }

  popCommissionReportDialog() {
    this.dialog.open(DownloadCommissionStatementDialogComponent, {
      width: '50%',
      disableClose: true,
      data: {
        product: AppEnums.PRODUCT_MOTOR,
      },
    });
  }

  updateSearchInput(item) {
    this.searchItem = item;
  }

  showCommissionSearchResults(results) {
    this.isCommissionSearchData = true;
    this.dataCommissionLength = results.length;
    this.allCommissions = results;
  }
}
