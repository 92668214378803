<form>
    <div class="mb-1"></div>
    <div class="image-upload  text-center">
        <label for="file-input">
            <img class="img-holder text-center" src="{{fileLink}}" />
        </label>
        <input id="file-input" type="file" (change)="onFileSelected($event)" />
    </div>
    <div class="text-center">
        <app-loader *ngIf="isLoading"></app-loader>
    </div>
</form>