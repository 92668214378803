import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppEnums } from 'src/app/enums/app.enum';
import { relationships } from 'src/app/models/arrays';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import { DisplayNokDetailsComponent } from '../display-nok-details/display-nok-details.component';

@Component({
  selector: 'app-update-nok-details',
  templateUrl: './update-nok-details.component.html',
  styleUrls: ['./update-nok-details.component.scss'],
})
export class UpdateNokDetailsComponent implements OnInit {
  nextOfKinFormGroup: FormGroup;
  capturedGender: string;
  isLoading: boolean = false;
  moreQuestions: any;
  relationships = relationships;
  evacuationNextOfKinFormGroup: FormGroup;
  isMoreQuestionsObjectAvailable: boolean = false;
  constructor(
    private appService: AppService,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DisplayNokDetailsComponent>
  ) {
    this.nextOfKinFormGroup = _formBuilder.group({
      nokName: ['', Validators.required],
      nokPhoneNo: ['', Validators.required],
      nokId_Passport: ['', Validators.required],
      nokRelationship: ['', Validators.required],
      nokLocation: [''],
      nokPostalCode: [''],
      nokPostalAddress: [''],
    });
    this.evacuationNextOfKinFormGroup = _formBuilder.group({
      nokName: ['', Validators.required],
      nokPhoneNo: ['', Validators.required],
      nokId_Passport: ['', Validators.required],
      nokRelationship: ['', Validators.required],
      nokLocation: [''],
      nokPostalCode: [''],
      nokPostalAddress: [''],
      emergencyContactLocation: [''],
      emergencyContactName: [''],
      emergencyContactRelationship: [''],
      emergencyContactPhoneNo: [''],
    });
  }

  ngOnInit(): void {
    this.setNokInformation();
    this.getMoreQuestions();
  }

  getMoreQuestions() {
    this.isMoreQuestionsObjectAvailable = true;
    switch (this.data?.cover) {
      case 'personalaccident':
        this.appService
          .makePostRequest(`${environment.PA_API_URL}/getquestions`, {
            _id: this.data?.selectedRecordQuestionsId,
          })
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'motor':
        this.appService
          .makePostRequest(`${environment.MOTOR_API_URL}/getquestions`, {
            _id: this.data?.selectedRecordQuestionsId,
          })
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'medical':
        this.appService
          .makePostRequest(`${environment.MEDICAL_API_URL}/admingetquestions`, {
            _id: this.data?.selectedRecordQuestionsId,
          })
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'travel':
        this.appService
          .makePostRequest(`${environment.TRAVEL_API_URL}/admingetquestions`, {
            _id: this.data?.selectedRecordQuestionsId,
          })
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'domestic':
        this.appService
          .makePostRequest(
            `${environment.DOMESTIC_API_URL}/getdomesticquotequestions`,
            {
              _id: this.data?.selectedRecordQuestionsId,
            }
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'evacuation':
        this.appService
          .makePostRequest(`${environment.EVACUATION_API_URL}/getQuestions`, {
            _id: this.data?.selectedRecordQuestionsId,
          })
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'golf':
        this.appService
          .makePostRequest(`${environment.GOLF_API_URL}/getQuestions`, {
            _id: this.data?.selectedRecordQuestionsId,
          })
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'life':
        this.appService
          .makePostRequest(`${environment.LIFE_API_URL}/getQuestions`, {
            _id: this.data?.selectedRecordQuestionsId,
          })
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;

        case 'telemedicine':
        this.appService
          .makePostRequest(`${environment.MEDICAL_API_URL}/telemedicineadmingetquestions`, {
            _id: this.data?.selectedRecordQuestionsId,
          })
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
    }
  }

  convertGenderValue() {
    let gender = this.data.client?.gender;
    if (gender == 'Male') {
      this.capturedGender = 'male';
    } else if (gender == 'Female') {
      this.capturedGender = 'female';
    }
  }

  setNokInformation() {
    this.convertGenderValue();
    this.nextOfKinFormGroup = this._formBuilder.group({
      nokLocation: [this.data.client?.nokLocation],
      nokName: [this.data.client?.nokName],
      nokPhoneNo: [this.data.client?.nokPhoneNo],
      nokId_Passport: [this.data.client?.nokId_Passport],
      nokPostalAddress: [this.data.client?.nokPostalAddress],
      nokPostalCode: [this.data.client?.nokPostalCode],
      nokRelationship: [this.data.client?.nokRelationship],
    });
    this.evacuationNextOfKinFormGroup = this._formBuilder.group({
      nokLocation: [this.data.client?.nokLocation],
      nokName: [this.data.client?.nokName],
      nokPhoneNo: [this.data.client?.nokPhoneNo],
      nokId_Passport: [this.data.client?.nokId_Passport],
      nokPostalAddress: [this.data.client?.nokPostalAddress],
      nokPostalCode: [this.data.client?.nokPostalCode],
      nokRelationship: [this.data.client?.nokRelationship],
      emergencyContactLocation: [this.data.client?.emergencyContactLocation],
      emergencyContactName: [this.data.client?.emergencyContactName],
      emergencyContactRelationship: [
        this.data.client?.emergencyContactRelationship,
      ],
      emergencyContactPhoneNo: [this.data.client?.emergencyContactPhoneNo],
    });
  }

  updateNokInfo() {
    this.isLoading = true;
    let nok = this.nextOfKinFormGroup.value;
    let evacuationNok = this.evacuationNextOfKinFormGroup.value;
    let body = {
      _id: this.data.selectedRecordQuestionsId,
      update: {
        moreQuestions: {
          client: {
            ...this.moreQuestions?.moreQuestions?.client,
            ...nok,
          },
        },
      },
    };
    switch (this.data.cover) {
      case 'personalaccident':
        this.appService
          .makePostRequest(
            `${environment.PA_API_URL}/adminUpdateGeneralQuestions`,
            body
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeSuccess,
                  'Update successful',
                  'Success'
                );
                this.dialogRef.close();
              } else {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'Update unsuccessful',
                  'Something went wrong'
                );
              }
            },
            (error) => {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                this.appService.processHttpErrors(error),
                'Error'
              );
            }
          );
        break;
      case 'medical':
        this.appService
          .makePostRequest(
            `${environment.MEDICAL_API_URL}/adminUpdateGeneralQuestions`,
            body
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeSuccess,
                  'Update successful',
                  'Success'
                );
                this.dialogRef.close();
              } else {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'Update unsuccessful',
                  'Something went wrong'
                );
              }
            },
            (error) => {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                this.appService.processHttpErrors(error),
                'Error'
              );
            }
          );
        break;
      case 'travel':
        this.appService
          .makePostRequest(
            `${environment.TRAVEL_API_URL}/adminUpdateGeneralQuestions`,
            body
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeSuccess,
                  'Update successful',
                  'Success'
                );
                this.dialogRef.close();
              } else {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'Update unsuccessful',
                  'Something went wrong'
                );
              }
            },
            (error) => {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                this.appService.processHttpErrors(error),
                'Error'
              );
            }
          );
        break;
      case 'domestic':
        this.appService
          .makePostRequest(
            `${environment.DOMESTIC_API_URL}/adminUpdateGeneralQuestions`,
            body
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeSuccess,
                  'Update successful',
                  'Success'
                );
                this.dialogRef.close();
              } else {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'Update unsuccessful',
                  'Something went wrong'
                );
              }
            },
            (error) => {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                this.appService.processHttpErrors(error),
                'Error'
              );
            }
          );
        break;
      case 'evacuation':
        let evacuationbody = {
          _id: this.data.selectedRecordQuestionsId,
          update: {
            moreQuestions: {
              client: {
                ...this.moreQuestions?.moreQuestions?.client,
                ...evacuationNok,
              },
            },
          },
        };
        this.appService
          .makePostRequest(
            `${environment.EVACUATION_API_URL}/adminUpdateGeneralQuestions`,
            evacuationbody
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeSuccess,
                  'Update successful',
                  'Success'
                );
                this.dialogRef.close();
              } else {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'Update unsuccessful',
                  'Something went wrong'
                );
              }
            },
            (error) => {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                this.appService.processHttpErrors(error),
                'Error'
              );
            }
          );
        break;
      case 'telemedicine':
        this.appService
          .makePostRequest(
            `${environment.MEDICAL_API_URL}/telemedicineadminUpdateQuestions`,
            body
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeSuccess,
                  'Update successful',
                  'Success'
                );
                this.dialogRef.close();
              } else {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'Update unsuccessful',
                  'Something went wrong'
                );
              }
            },
            (error) => {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                this.appService.processHttpErrors(error),
                'Error'
              );
            }
          );
        break;
    }
  }
}
