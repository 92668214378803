<div class="container">
  <div class="p-2">
    <h1 mat-dialog-title class="text-center text-mtek">
      <i class="fa fa-users"></i> Upload Group Members
    </h1>
    <div mat-dialog-content>
      <app-group-info-partial
        [receivedId]="receivedId"
        (groupInfoEvent)="getGroupInfo($event)"
      >
      </app-group-info-partial>
    </div>
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close>Close</button>
    </div>
  </div>
</div>
