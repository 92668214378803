import { Component, OnInit, Input } from '@angular/core';
import { AppEnums } from 'src/app/enums/app.enum';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-list-documents',
  templateUrl: './list-documents.component.html',
  styleUrls: ['./list-documents.component.scss'],
})
export class ListDocumentsComponent implements OnInit {
  pdfSrc = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';

  @Input() _id: string;
  @Input() endpoint: string;
  docsLoading: boolean = false;
  documents: any[] = [];
  fullUrl: string;
  fileType: string = '';
  isLoading: boolean = false;
  showDocument: boolean = false;
  selectedIndex: number = null;

  constructor(public appService: AppService) {}

  ngOnInit(): void {
    this.getQuestions();
  }

  getQuestions() {
    this.docsLoading = true;
    this.appService
      .makePostRequest(this.endpoint, {
        _id: this._id,
      })
      .subscribe(
        (apiResponse) => {
          try {
            if (apiResponse.Status === 200) {
              if (typeof apiResponse.Payload.adminReports !== 'undefined') {
                Object.entries(apiResponse.Payload.adminReports).forEach(
                  ([key, value]) => {
                    if (key !== '_id') {
                      this.documents.push(value);
                    }
                  }
                );
                this.docsLoading = false;
              } else if (
                typeof apiResponse.Payload[0].adminReports !== 'undefined'
              ) {
                Object.entries(apiResponse.Payload[0].adminReports).forEach(
                  ([key, value]) => {
                    if (key !== '_id') {
                      this.documents.push(value);
                    }
                  }
                );
                this.docsLoading = false;
              } else {
                this.docsLoading = false;
              }
            } else {
              this.docsLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeWarning,
                apiResponse.Message,
                apiResponse.Payload
              );
            }
          } catch (err) {
            this.docsLoading = false;
          }
        },
        (err) => {
          this.docsLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
        }
      );
  }

  previewDocument(docUrl: string, i: number) {
    this.fullUrl = null;
    this.selectedIndex = i;
    this.showDocument = !this.showDocument;
    this.isLoading = true;
    this.appService.getSasToken().subscribe(
      (token) => {
        this.isLoading = false;
        this.fullUrl = docUrl + token;
        if (this.fullUrl.includes('.pdf')) {
          this.fileType = 'pdf';
          this.pdfSrc = this.fullUrl;
        }
      },
      (err) => {
        this.isLoading = false;
        this.appService.showToastMessage(
          AppEnums.ToastTypeError,
          '',
          this.appService.processHttpErrors(err)
        );
      }
    );
  }
}
