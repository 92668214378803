<app-loader *ngIf="isLoading"></app-loader>
<div class="container">
  <div class="row">
    <div class="col-md-12 text-center">
      <h1 class="text-mtek">Wallet Balance</h1>
      <span class="text-muted">{{
        userWalletBalance | currency: 'Ksh. '
      }}</span>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="text-mtek text-center">Wallet Transactions</div>
      <div *ngIf="userWalletTransactions.length > 0">
        <div class="table-responsive-sm">
          <table class="table table-striped table-responsive-sm">
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Transaction Type</th>
              <th scope="col">Amount</th>
              <th scope="col">Status</th>
              <th scope="col">Transaction Type</th>
            </tr>

            <tbody>
              <tr *ngFor="let transaction of userWalletTransactions">
                <td class="p-3">
                  {{ transaction?._timestamp | date }}
                </td>
                <td class="p-3">
                  {{ transaction?.transactionType }}
                </td>
                <td class="p-3">
                  {{ transaction?.amount }}
                </td>
                <td class="p-3">
                  {{ transaction?.status }}
                </td>
                <td class="p-3">
                  {{ transaction?.transactionDirection }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
