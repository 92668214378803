import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

interface Irange {
  start_date: number;
  end_date: number;
}

@Component({
  selector: 'app-user-stats',
  templateUrl: './user-stats.component.html',
  styleUrls: ['./user-stats.component.scss'],
})
export class UserStatsComponent implements OnInit {
  stats$: Observable<any[]>;
  userStatistics$: Observable<any[]>;
  selectedDateRange: Irange;
  dataLoaded = false;
  roles: any[];
  usersLoading: boolean = true;
  usersList: any[] = [];

  constructor(private appService: AppService, private router: Router) {
    let todayStamp = Date.now();
    let today = new Date(todayStamp);
    let last30Days = new Date(Date.now() - 2592000000);

    let startDate =
      last30Days.getFullYear() +
      '-' +
      (last30Days.getMonth() + 1) +
      '-' +
      last30Days.getDate();

    let endDate =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate();

    this.selectedDateRange = {
      end_date: Date.parse(endDate),
      start_date: Date.parse(startDate),
    };

    this.userStatistics$ = this.appService.getUserStatistics({
      $and: [
        { _timestamp: { $gte: this.selectedDateRange.start_date } },
        { _timestamp: { $lte: this.selectedDateRange.end_date } },
      ],
    });
  }

  updateRange(range) {
    this.usersLoading = true;
    this.usersList = [];
    this.selectedDateRange = range;
    this.userStatistics$ = this.appService.getUserStatistics({
      $and: [
        { _timestamp: { $gte: Date.parse(range.start_date) } },
        { _timestamp: { $lte: Date.parse(range.end_date) } },
      ],
    });

    this.userStatistics$.subscribe((data) => {
      data.forEach((s) => {
        if (s.value > 0) {
          this.usersList.push(s);
        }
      });
      this.usersLoading = false;
    });
  }

  ngOnInit(): void {
    this.userStatistics$.subscribe((data) => {
      data.forEach((s) => {
        if (s.value > 0) {
          this.usersList.push(s);
        }
      });
      this.usersLoading = false;
    });
  }

  goToUsers(role: string) {
    this.router.navigate(['/users/list-users', role]);
  }
}
