<div class="card card-stats">
  <div class="card-body">
    <div *ngIf="members.length > 0" class="">
      <h4 class="card-title text-uppercase mb-0 text-mtek">
        Group policy members: {{ members.length }}
      </h4>
      <br />
      <div
        class="d-flex justify-content-between mb-10 ben-card ben-container"
        *ngFor="let member of members; let i = index"
      >
        <div>
          <small class="d-block text-dark text-uppercase bold-text">
            Name
          </small>
          <span>
            {{ member?.name }}
          </span>
        </div>
        <div>
          <small class="d-block text-dark text-uppercase bold-text">
            Relationship
          </small>
          <span>
            {{ member?.relationship }}
          </span>
        </div>
        <div>
          <small class="d-block text-dark text-uppercase bold-text">
            Age
          </small>
          <span>
            {{ member?.age }}
          </span>
        </div>
        <div>
          <small class="d-block text-dark text-uppercase bold-text">
            DOB
          </small>
          <span>
            {{ member?.dayOfBirth }}/{{ member?.monthOfBirth }}/{{
              member?.yearOfBirth
            }}
          </span>
        </div>
        <div>
          <small class="d-block text-dark text-uppercase bold-text">
            ID Number
          </small>
          <span>
            {{ member?.idNo }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
