<div class="row" *ngIf="!allDataLoaded">
  <div class="col-md-12 text-center">
    <app-loader></app-loader>
  </div>
</div>
<form>
  <div class="mb-1"></div>
  <div class="custom-file">
    <input type="file" class="custom-file-input" id="customFile" (change)="onFileSelected($event)" />
    <label class="custom-file-label" for="customFile">Choose Image or File</label>
  </div>
  <div>
    <button mat-raised-button type="submit" class="d-inline btn-info section2" (click)="upload()">
      Upload
    </button>
  </div>
</form>