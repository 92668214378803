import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppEnums } from 'src/app/enums/app.enum';
import { ApiResponse } from 'src/app/models/api-response.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-company-info-update-partial',
  templateUrl: './company-info-update-partial.component.html',
  styleUrls: ['./company-info-update-partial.component.scss']
})
export class CompanyInfoUpdatePartialComponent implements OnInit {
  @ViewChild('stepper') private myStepper: MatStepper;
  @Input() receivedId: string;
  @Input() selectedQuoteId: string;
  @Input() product: string;
  @Input() startDate: any
  @Input() dependents: any
  @Input() dependantsAsIsInQuote: any
  @Input() checkoutUpdate: any[]
  @Input() cardMemberPayload: any[]
  @Output() companyInfoEvent: EventEmitter<any> = new EventEmitter<any>();
  companyFormGroup: FormGroup;
  companyMultiUploadFormGroup: FormGroup;
  allowedFileExtentions = ['jpg', 'jpeg', 'png', 'pdf'];
  azureFileLocation: string = 'manual-sales';
  activeFileName: string = '';
  activeFile: any;
  _tstamp: number = 0;
  currentRecord: any;
  companyFiles: any[] = [
    { name: 'KRA PIN Document', value: 'kraPhoto', suffix: '_KRA_PIN' },
    {
      name: 'Incorporation Certificate',
      value: 'incorporationCert',
      suffix: '_INCORPORATION_CERT',
    },
  ];
  isLoading: boolean = false;
  constructor(
    private _formBuilder: FormBuilder,
    private appService: AppService
  ) {
    this._tstamp = Date.now();
    this.companyFormGroup = this._formBuilder.group({
      emailAddress: ['', Validators.email],
      name: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      kraPin: ['', Validators.required],
      KraDoc: ['', Validators.required],
      incorporationCert: ['', Validators.required],
      physicaladdress: ['', Validators.required],
      postalAddress: ['', Validators.required],
      postalCode: ['', Validators.required],
    });
    this.companyMultiUploadFormGroup = _formBuilder.group({
      fileObj: ['', Validators.required],
    });
  }

  onCompanyFileObjChange(idx: number) {
    this.activeFile = this.companyFiles[idx];
    this.activeFileName = this._tstamp + this.activeFile.suffix;
  }

  uploadCompanyDoc($event) {
    switch (this.activeFile.value) {
      case 'kraPhoto':
        this.companyFormGroup.patchValue({
          KraDoc: $event,
        });
        break;
      case 'incorporationCert':
        this.companyFormGroup.patchValue({
          incorporationCert: $event,
        });
        break;
      default:
        break;
    }
  }

  validateCompany() {
    if (!this.companyFormGroup.valid) {
      const controls = this.companyFormGroup.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            'Field Required',
            name
          );
        }
      }
      return;
    }
    this.companyInfoEvent.emit(this.companyFormGroup.value);
  }

  setCompanyInformation() {
    if (this.currentRecord?.moreQuestions?.company != null) {
      this.companyFormGroup = this._formBuilder.group({
        emailAddress: [
          this.currentRecord?.moreQuestions?.company?.emailAddress,
          Validators.email,
        ],
        name: [
          this.currentRecord?.moreQuestions?.company?.name,
          Validators.required,
        ],
        phoneNumber: [
          this.currentRecord?.moreQuestions?.company?.phoneNumber,
          Validators.required,
        ],
        kraPin: [
          this.currentRecord?.moreQuestions?.company?.kraPin,
          Validators.required,
        ],
        KraDoc: [
          this.currentRecord?.moreQuestions?.company?.KraDoc,
          Validators.required,
        ],
        incorporationCert: [
          this.currentRecord?.moreQuestions?.company?.incorporationCert,
          Validators.required,
        ],
        physicaladdress: [
          this.currentRecord?.moreQuestions?.company?.physicaladdress,
          Validators.required,
        ],
        postalAddress: [
          this.currentRecord?.moreQuestions?.company?.postalAddress,
          Validators.required,
        ],
        postalCode: [
          this.currentRecord?.moreQuestions?.postalCode,
          Validators.required,
        ],
      });
    }
  }

  setGetQuoteUrl() {
    let microService = '';
    switch (this.product) {
      case 'motor':
        microService = `${environment.MOTOR_API_URL}`;
        this.getQuoteInformation('adminlistmotorquote', microService);
        break;
      case 'health':
        microService = `${environment.MEDICAL_API_URL}`;
        this.getQuoteInformation('adminlistquote', microService);
        break;
      default:
        return;
    }
  }

  getQuoteInformation(endpoint: string, microService: string) {
    if (this.selectedQuoteId != null) {
      this.appService
        .makePostRequest(`${microService}/${endpoint}`, {
          _id: this.selectedQuoteId,
        })
        .pipe(switchMap((res) => this.getQuoteQuestions(res, microService)))
        .subscribe((apiResponse) => {
          if (apiResponse.Status === 200) {
            this.currentRecord = apiResponse.Payload;
            this.setCompanyInformation();
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              apiResponse.Payload,
              "Could not process this quote's information"
            );
          }
          (error) => {
            this.appService.showToastMessage(
              AppEnums.ToastTypeError,
              '',
              this.appService.processHttpErrors(error)
            );
          };
        });
    }
  }

  getQuoteQuestions(
    res: ApiResponse,
    microService: string
  ): Observable<ApiResponse> {
    let q = res.Payload[0].questions_id;
    return this.appService.makePostRequest(
      `${microService}/admingetquestions`,
      {
        _id: q,
      }
    );
  }

  ngOnInit(): void {
    this.setGetQuoteUrl();
  }

  updateCompanyInfo() {
    this.isLoading = true;
    let body = {
      _id: this.currentRecord?._id,
      update: {
        moreQuestions: {
          company: this.companyFormGroup.value,
          startDate: this.startDate,
          checkoutUpdate: this.checkoutUpdate,
          dependents: this.dependents,
          dependantsAsIsInQuote: this.dependantsAsIsInQuote,
          card: this.cardMemberPayload
        }
      }
    }
    this.appService.makePostRequest(`${environment.MEDICAL_API_URL}/adminUpdateQuestionsGeneral`, body
    ).subscribe(res => {
      if (res.Status == 200) {
        this.isLoading = false;
        this.appService.showToastMessage(
          AppEnums.ToastTypeSuccess,
          'Update successful',
          'Success'
        )
      } else {
        this.isLoading = false;
        this.appService.showToastMessage(
          AppEnums.ToastTypeWarning,
          'Update unsuccessful',
          'Something went wrong'
        )
      }
    }, error => {
      this.isLoading = false;
      this.appService.showToastMessage(
        AppEnums.ToastTypeError,
        this.appService.processHttpErrors(error),
        'Error'
      )
    })
  }
}
