import { Component, Input, OnInit } from '@angular/core';
import { IPaymentLog } from 'src/app/models/payment-log.interface';
import { IPaymentPlanSchedule } from 'src/app/models/payment-plan-schedule.interface';

@Component({
  selector: 'app-payment-schedule-table',
  templateUrl: './payment-schedule-table.component.html',
  styleUrls: ['./payment-schedule-table.component.scss'],
})
export class PaymentScheduleTableComponent implements OnInit {
  @Input() sheduledPaymentPlans: IPaymentPlanSchedule[] = [];
  @Input() receivedId: string;

  constructor() {}

  ngOnInit(): void {}
}
