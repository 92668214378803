<div class="list-group">
  <div
    class="list-group-item flex-column align-items-start"
    *ngFor="let mQuestion of moreQuestions"
  >
    <p class="mb-1">
      {{ mQuestion.question }}
    </p>
    <strong class="text-info">{{ mQuestion.answer }}</strong>
  </div>
</div>
