import { Component, OnInit } from '@angular/core';
import { ThemeOptions } from '../../base-layout/theme-options';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
  styleUrls: ['./user-box.component.scss'],
})
export class UserBoxComponent implements OnInit {
  constructor(public globals: ThemeOptions, public appService: AppService) {}

  ngOnInit() {}

  toggleDrawer() {
    this.globals.toggleDrawer = !this.globals.toggleDrawer;
  }

  logout() {
    this.appService.SignOut();
  }
}
