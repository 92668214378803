import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentPlanNew',
})
export class PaymentPlanNewPipe implements PipeTransform {
  plan: string = '';
  transform(value: number): string {
    switch (value) {
      case 1:
        this.plan = 'One-off';
        break;
      case 2:
        this.plan = 'Days';
        break;
      case 3:
        this.plan = 'Weeks';
        break;
      case 4:
        this.plan = 'Months';
        break;
    }
    return this.plan;
  }
}
