import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AppEnums } from 'src/app/enums/app.enum';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-jsonfile-to-json',
  templateUrl: './jsonfile-to-json.component.html',
  styleUrls: ['./jsonfile-to-json.component.scss'],
})
export class JsonfileToJsonComponent implements OnInit {
  isLoading: boolean = false;
  //jsonContent: string;
  items: Array<any> = [];
  properties: any = '';
  flag: boolean = false;
  @Output() itemsEvent: EventEmitter<any> = new EventEmitter<any>();
  optionsFormGroup: FormGroup;
  @Input() fields: string[] = [];
  @Input() sampleJson: any;

  constructor(
    public appService: AppService,
    private _formBuilder: FormBuilder
  ) {
    this.optionsFormGroup = this._formBuilder.group({
      startRow: [''],
      endRow: [''],
      startCol: [''],
      endCol: [''],
    });
  }

  ngOnInit(): void {}

  get fieldStrings() {
    return this.fields.toString();
  }

  get sampleJsonFormat() {
    return JSON.stringify(this.sampleJson, undefined, 2);
  }

  onFileLoad = (fileLoadedEvent) => {
    try {
      const textFromFileLoaded = fileLoadedEvent.target.result;
      let jsonContent = textFromFileLoaded;
      let o = JSON.parse(jsonContent);
      if (o && typeof o === 'object') {
        this.itemsEvent.emit(o);
        this.isLoading = false;
      }
    } catch (e) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeSuccess,
        'Invalid file formatting!',
        'Your file contents must be a valid JSON Array'
      );
      return;
    }
  };

  onFileSelect(input: HTMLInputElement) {
    const files = input.files;
    var fileTypes = ['json']; //acceptable file types

    if (files && files.length) {
      var extension = input.files[0].name.split('.').pop().toLowerCase(), //file extension from input file
        isSuccess = fileTypes.indexOf(extension) > -1; //is extension in acceptable types
      if (isSuccess) {
        const fileToRead = files[0];

        const fileReader = new FileReader();
        fileReader.onload = this.onFileLoad;

        fileReader.readAsText(fileToRead, 'UTF-8');
      } else {
        this.appService.showToastMessage(
          AppEnums.ToastTypeSuccess,
          'Invalid file type!',
          'Failed!'
        );
      }
    }
  }
}
