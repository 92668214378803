<ul class="todo-list-wrapper list-group list-group-flush" *ngIf="allImages.length > 0; else noImages">
    <li class="list-group-item" *ngFor="let image of imagesArray" (click)="popSlideShow(content6)">
        <div class="todo-indicator bg-info"></div>
        <div class="widget-content p-0">
            <div class="widget-content-wrapper">
                <div class="widget-content-left mr-2">

                </div>
                <div class="widget-content-left mr-3">
                    <div class="widget-content-left">
                        <img width="42" class="rounded" src="{{image.url}}" alt="">
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">{{image.imageName}}</div>
                    <div class="widget-subheading"></div>
                </div>
                <div class="widget-content-left ml-1" *ngIf="image.isValidImage === false">
                    <i class="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i>
                </div>
                <div class="widget-content-left ml-1" *ngIf="image.isValidImage === true">
                    <i class="fa fa-check text-success" aria-hidden="true"></i>
                </div>
                <div class="widget-content-right">
                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </div>
            </div>
        </div>
    </li>

</ul>
<ng-template #noImages>
    <div class="alert alert-info" role="alert">
        There are no images.
    </div>
</ng-template>

<ng-template #content6 let-c="close" let-d="dismiss">
    <ngb-carousel *ngIf="imagesArray">
        <ng-template ngbSlide *ngFor="let image of imagesArray">
            <img src="{{image.url }}" width="100%" alt="{{image.imageName}}">
            <div class="carousel-caption">
                <h3>{{image.imageName}}</h3>
            </div>
        </ng-template>
    </ngb-carousel>
</ng-template>