<h2 mat-dalog-title class="text-mtek">Confirm Delete</h2>
<div mat-dialog-content>
  <h3>
    <strong>you sure you want to delete?</strong>
  </h3>
  <button class="btn btn-danger" (click)="delete()" mat-dialog-close>
    Yes
  </button>
  <button class="btn btn-primary ml-2" mat-dialog-close>No</button>
</div>
