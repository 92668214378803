import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppEnums } from 'src/app/enums/app.enum';
import { ApiResponse } from 'src/app/models/api-response.interface';
import { IDomestic } from 'src/app/models/domestic.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-cover-details',
  templateUrl: './update-cover-details.component.html',
  styleUrls: ['./update-cover-details.component.scss'],
})
export class UpdateCoverDetailsComponent implements OnInit {
  @Input() policy: IDomestic;
  @Input() cover: string;
  @Output() reloadPolicyEvent = new EventEmitter();
  @Output() setEditVehicleEvent = new EventEmitter<boolean>();
  coverDetailsFormGroup: FormGroup;
  isLoading: boolean = false;
  showTaxesForm: boolean = false;
  showPolicyStartDateForm: boolean = false;
  checked: boolean = false;
  taxes: any = {};
  addons: any = [];
  constructor(
    public appService: AppService,
    private _formBuilder: FormBuilder
  ) {
    this.coverDetailsFormGroup = this._formBuilder.group({
      basicPremium: ['', Validators.required],
      paidamount: ['', Validators.required],
      amount: ['', Validators.required],
      assured: ['', Validators.required],
      check: [this.checked],
    });
  }

  ngOnInit(): void {
    this.coverDetailsFormGroup.patchValue({
      basicPremium: this.policy?.premium?.totalPremiumAmount,
      paidamount: this.policy?.paidamount,
      amount: this.policy?.amount,
      assured: this.policy?.premium?.assured,
    });
  }
  onShowTaxesForm() {
    this.showTaxesForm = true;
  }
  onHideTaxesForm() {
    this.showTaxesForm = false;
  }
  onReceiveTaxes($event) {
    this.showTaxesForm = $event;
  }
  onShowPolicyStartDateForm() {
    this.showPolicyStartDateForm = true;
  }
  onHidePolicyStartDateForm() {
    this.showPolicyStartDateForm = false;
  }
  onReceivePolicyUpdateStatus($event) {
    this.showPolicyStartDateForm = $event;
  }
  submitCoverDetails() {
    if (!this.coverDetailsFormGroup.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Fill all required fields'
      );
      return;
    }
    this.isLoading = true;
    let amount: number = Number(this.coverDetailsFormGroup.get('amount').value);
    let paidamount: number = Number(
      this.coverDetailsFormGroup.get('paidamount').value
    );
    let basicPremium: number = Number(
      this.coverDetailsFormGroup.get('basicPremium').value
    );
    let assured: number = Number(
      this.coverDetailsFormGroup.get('assured').value
    );
    let isFinance = this.coverDetailsFormGroup.value.check;
    let body: any = {
      _id: this.policy?._id,
      update: {
        premium: {
          ...this.policy?.premium,
          basicPremium: basicPremium,
          assured: assured,
        },
        remittance: {
          ...this.policy?.remittance,
          amount: amount,
        },
        paidamount: paidamount,
        amount: amount,
      },
    };
    this.isTheCoverFinancePlan(isFinance, body).subscribe(
      (res) => {
        if (res.Status == 200) {
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            '',
            res.Message
          );
          this.isLoading = false;
          this.cancelEditMode();
        } else {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            res.Payload
          );
          this.isLoading = false;
        }
      },
      (err) => {
        this.appService.showToastMessage(AppEnums.ToastTypeError, '', err);
        this.isLoading = false;
      }
    );
  }

  isTheCoverFinancePlan(
    isFinanced: boolean,
    body: any
  ): Observable<ApiResponse> {
    if (isFinanced) {
      return this.appService
        .makePostRequest(
          `${environment.DOMESTIC_API_URL}/adminupdatequote`,
          body
        )
        .pipe(switchMap((res) => this.updateFinancePlan(res)));
    }
    return this.appService.makePostRequest(
      `${environment.DOMESTIC_API_URL}/adminupdatequote`,
      body
    );
  }
  updateFinancePlan(res: ApiResponse): Observable<ApiResponse> {
    this.isLoading = true;
    let data = {
      amount: res.Payload?.amount,
      cover: this.cover,
      quoteID: this.policy?._id,
    };
    return this.appService.makePostRequest(
      `${environment.IPF_API_URL}/adminRecalculatePaymentPlanViaTrustGro`,
      data
    );
  }
  cancelEditMode() {
    this.setEditVehicleEvent.emit(false);
    this.reloadPolicyEvent.emit(this.policy?._id);
  }
}
