<form [formGroup]="registerPaymentFormGroup">
  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
        <label for="paymentChannel">Payment Channel</label>
        <select
          class="custom-select"
          formControlName="paymentChannel"
          id="paymentChannel"
          required
          (change)="onPaymentChannelChange($event.target.value)"
        >
          <option></option>
          <option *ngFor="let c of appService.getPaymentChannels">
            {{ c }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-md-4" *ngIf="!isWalletTransaction">
      <div class="form-group">
        <label for="paidTo">Paid To</label>
        <select
          class="custom-select"
          formControlName="paidTo"
          id="paidTo"
          required
        >
          <option
            *ngFor="let underwriter of underwriters"
            [value]="underwriter?.insurer"
          >
            {{ underwriter?.underwriter }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-md-4" *ngIf="!isWalletTransaction">
      <div class="form-group">
        <label for="totalPaid">Total Paid Amount</label>
        <input
          type="number"
          min="1"
          class="form-control"
          formControlName="totalPaid"
          id="totalPaid"
          required
        />
      </div>
    </div>
    <div class="col-md-4" *ngIf="!isWalletTransaction">
      <div class="form-group">
        <label for="reference">Payment Reference</label>
        <input
          type="text"
          class="form-control"
          formControlName="reference"
          id="reference"
          required
        />
      </div>
    </div>
    <div class="col-md-4" *ngIf="!isWalletTransaction">
      <div class="form-group">
        <label for="description">Payment Description</label>
        <input
          type="text"
          class="form-control"
          formControlName="description"
          id="description"
          required
        />
      </div>
    </div>
    <div
      class="col-md-4"
      *ngIf="showMpesaPhoneNumberField && !isWalletTransaction"
    >
      <div class="form-group">
        <label for="mpesaNumber">M-PESA Phone Number</label>
        <input
          type="tel"
          class="form-control"
          formControlName="mpesaNumber"
          id="mpesaNumber"
        />
      </div>
    </div>
    <div class="col-md-4" *ngIf="!isWalletTransaction">
      <div class="form-group">
        <label for="_date">Projected Payment End Date</label>
        <div class="input-group">
          <input
            matInput
            class="form-control"
            formControlName="projectedEndPaymentTimeStamp"
            [matDatepicker]="pickerp"
          />
          <div class="input-group-append">
            <button
              (click)="pickerp.open()"
              class="btn btn-outline-secondary"
              type="button"
            >
              <span class="fa fa-calendar"></span>
            </button>
          </div>
        </div>
        <mat-datepicker #pickerp></mat-datepicker>
      </div>
    </div>
  </div>
  <app-loader *ngIf="isLoading"></app-loader>
  <button
    *ngIf="!isLoading"
    class="btn btn-outline-primary"
    (click)="registerPayment()"
  >
    Submit
  </button>
</form>
