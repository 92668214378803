<!-- breadcrumb -->
<div class="col">
  <ol class="breadcrumb">
    <li
      class="breadcrumb-item"
      *ngFor="let item of breadcrumbList; let i = index"
      [class.active]="i === breadcrumbList.length - 1"
    >
      <a [routerLink]="item.path" *ngIf="i !== breadcrumbList.length - 1">
        {{ item.name }}
      </a>
      <span *ngIf="i === breadcrumbList.length - 1">{{ item.name }}</span>
    </li>
  </ol>
</div>
