import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppEnums } from 'src/app/enums/app.enum';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-policy-start-date',
  templateUrl: './update-policy-start-date.component.html',
  styleUrls: ['./update-policy-start-date.component.scss'],
})
export class UpdatePolicyStartDateComponent implements OnInit {
  @Input() cover: string;
  @Input() questions_id: string;
  @Output() statusUpdate = new EventEmitter<boolean>();
  startDateForm: FormGroup;
  isLoading: boolean = false;
  constructor(
    private appService: AppService,
    private formBuilder: FormBuilder
  ) {
    this.startDateForm = this.formBuilder.group({
      date: ['', Validators.required],
    });
  }

  ngOnInit(): void {}
  updatePolicyStartDate() {
    let body = {};
    if (this.isFormValid() == true) {
      this.isLoading = true;
      let dateSelected = new Date(this.startDateForm.get('date').value);
      let day = dateSelected.getDate();
      let month = dateSelected.getMonth() + 1;
      let year = dateSelected.getFullYear();
      let fullDate = `${day}/${month}/${year}`;
      body = {
        _id: this.questions_id,
        update: {
          moreQuestions: {
            startDate: fullDate,
          },
        },
      };
      switch (this.cover) {
        case 'motor':
          this.appService
            .makePostRequest(
              `${environment.MOTOR_API_URL}/adminUpdateGeneralQuestions`,
              body
            )
            .subscribe(
              (res) => {
                if (res.Status == 200) {
                  this.isLoading = false;
                  this.appService.showToastMessage(
                    AppEnums.ToastTypeSuccess,
                    'Update successful',
                    'Success'
                  );
                  this.statusUpdate.emit(false);
                } else {
                  this.isLoading = false;
                  this.appService.showToastMessage(
                    AppEnums.ToastTypeWarning,
                    'Update unsuccessful',
                    'Something went wrong'
                  );
                }
              },
              (error) => {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  this.appService.processHttpErrors(error)
                );
              }
            );
          break;
        case 'domestic':
          this.appService
            .makePostRequest(
              `${environment.DOMESTIC_API_URL}/adminUpdateGeneralQuestions`,
              body
            )
            .subscribe(
              (res) => {
                if (res.Status == 200) {
                  this.isLoading = false;
                  this.appService.showToastMessage(
                    AppEnums.ToastTypeSuccess,
                    'Update successful',
                    'Success'
                  );
                  this.statusUpdate.emit(false);
                } else {
                  this.isLoading = false;
                  this.appService.showToastMessage(
                    AppEnums.ToastTypeWarning,
                    '',
                    res.Payload
                  );
                }
              },
              (error) => {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  this.appService.processHttpErrors(error)
                );
              }
            );
          break;
          case 'gadget':
            this.appService
              .makePostRequest(
                `${environment.DOMESTIC_API_URL}/adminUpdateGadgetQuestionsGeneral`,
                body
              )
              .subscribe(
                (res) => {
                  if (res.Status == 200) {
                    this.isLoading = false;
                    this.appService.showToastMessage(
                      AppEnums.ToastTypeSuccess,
                      'Update successful',
                      'Success'
                    );
                    this.statusUpdate.emit(false);
                  } else {
                    this.isLoading = false;
                    this.appService.showToastMessage(
                      AppEnums.ToastTypeWarning,
                      '',
                      res.Payload
                    );
                  }
                },
                (error) => {
                  this.isLoading = false;
                  this.appService.showToastMessage(
                    AppEnums.ToastTypeError,
                    '',
                    this.appService.processHttpErrors(error)
                  );
                }
              );
            break;
            default:
              break;
      }
    }
  }
  isFormValid(): boolean {
    if (this.startDateForm.invalid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Invalid Form Date'
      );
      return false;
    }
    return true;
  }
}
