<div class="card card-stats">
  <!-- Card body -->
  <div class="card-body">
    <div class="d-flex justify-content-between align-items-center">
      <h4 class="card-title text-uppercase mb-2 text-mtek d-inline">
        <i class="fa fa-pencil-square-o text-mtek h5"></i>
        Audit Log Information
      </h4>
      <button class="btn btn-outline-primary mb-2" *ngIf="showAuditLog == false; else hide" (click)="onShowAuditLog()">
        Show Audit Log
      </button>
      <ng-template #hide>
        <button class="btn btn-outline-primary mb-2" (click)="hideAuditLog()">
          Hide Audit Log
        </button>
      </ng-template>
    </div>
    <app-loader *ngIf="isLoading == true"></app-loader>
    <hr />
    <div class="container" *ngIf="showAuditLog">
      <div class="row">
        <!-- Vehicle Details -->
        <div class="col-md">
          <p>
            <small class="d-block text-dark text-uppercase bold-text">Date</small>
          </p>
        </div>

        <div class="col-md">
          <p>
            <small class="d-block text-dark text-uppercase bold-text">Name</small>
          </p>
        </div>

        <div class="col-md">
          <p>
            <small class="d-block text-dark text-uppercase bold-text">Operation</small>
          </p>
        </div>
        <div class="col-md-5">
          <p>
            <small class="d-block text-dark text-uppercase bold-text">Message</small>
          </p>
        </div>
      </div>
      <div *ngIf="auditLogs?.length > 0; else noLogs">
        <div class="row" *ngFor="let audit of auditLogs">
          <div class="col-md">
            <p>
              {{ audit?._timestamp | date }}
            </p>
          </div>

          <div class="col-md">
            <p>
              {{ audit?.name }}
            </p>
          </div>

          <div class="col-md">
            <p>
              {{ audit?.operation | titlecase }}
            </p>
          </div>
          <div class="col-md-5">
            <pre>{{ audit?.message | json }}</pre>
          </div>
        </div>
      </div>
      <ng-template #noLogs>
        <div class="alert alert-warning">
          There are no audit logs for this policy.
        </div>
      </ng-template>
    </div>
  </div>
</div>