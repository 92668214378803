export const environment = {
  appVersion: require('../../package.json').version + '-dev',
  test: true,
  production: false,
  title: 'mTek Administrator Portal',
  firebaseConfig: {
    apiKey: 'AIzaSyDYZcTmUhossqtk49Ok7IAVv70mbtRrb8g',
    authDomain: 'mtek-9fb22.firebaseapp.com',
    databaseURL: 'https://mtek-9fb22.firebaseio.com',
    projectId: 'mtek-9fb22',
    storageBucket: 'mtek-9fb22.appspot.com',
    messagingSenderId: '225875384266',
    appId: '1:225875384266:web:657f5e6e49b6cd16',
  },
  CORE_NTSA_API_URL: 'https://production.hillcroftinsurance.com/ntsa/graphql',
  CORE_API_URL_LIST: 'https://production.hillcroftinsurance.com/ntsa',
  CORE_API_URL: 'https://production.hillcroftinsurance.com/core',
  REPORTS_API_URL: 'https://reportsdev.hillcroftinsurance.com/api',
  PREMIUM_API_URL: 'https://production.hillcroftinsurance.com/ip',
  MPESA_API_URL: 'https://production.hillcroftinsurance.com/mpesa',
  SELL_API_URL: 'https://production.hillcroftinsurance.com/upxsell',
  CLAIMS_API_URL: 'https://production.hillcroftinsurance.com/claims',
  VALUATION_API_URL: 'https://production.hillcroftinsurance.com/valuation',
  MEDICAL_API_URL: 'https://production.hillcroftinsurance.com/medical',
  IPF_API_URL: 'https://production.hillcroftinsurance.com/ipf',
  MOTOR_API_URL: 'https://production.hillcroftinsurance.com/motor',
  TRAVEL_API_URL: 'https://production.hillcroftinsurance.com/travel',
  DOMESTIC_API_URL: 'https://production.hillcroftinsurance.com/domestic',
  PA_API_URL: 'https://production.hillcroftinsurance.com/pa',
  GOLF_API_URL: 'https://production.hillcroftinsurance.com/golf',
  EVACUATION_API_URL: 'https://production.hillcroftinsurance.com/evacuation',
  DATA_API_URL: 'https://production.hillcroftinsurance.com/data',
  PAYMENTS_API_URL: 'https://production.hillcroftinsurance.com/mpesa/api',
  KMS_API_URL: 'https://kms.hillcroftinsurance.com',
  AZURE_BLOB_MTEK_UPLOADS_URL:
    'https://hillcroftblobstoragev2.blob.core.windows.net/mtek-uploads',
  ADMIN_MODULE_ID: 'kcb-6584-499f-b2a7-419c72398543',
  ADMIN_API_KEY: "5be7011a2bd9beceb62d4469",
  CDN_BASE_URL: 'https://mtekcdnstoragev2.blob.core.windows.net/admin',
  MIXPANEL_PROJECT_TOKEN: 'e8deeb2495fec1e6cb8b4560f8a030e1',
  LEADS_GENERATED_URL:
    'https://production.hillcroftinsurance.com/data/leads_generated',
  DS_API_URL: 'https://production.hillcroftinsurance.com/ds',
  LIFE_API_URL: 'https://production.hillcroftinsurance.com/life',
  CDN_BASE_SAS_TOKEN:
    '?sp=acw&st=2022-07-12T15:19:11Z&se=2030-07-12T23:19:11Z&spr=https&sv=2021-06-08&sr=c&sig=djm29QhoQWiK%2B6WnAuyNQNyZqFZxzBF%2BqCP77aha6Oo%3D',
  THIRD_PARTIES_CDN_URL:
    'https://mtekcdnstoragev2.blob.core.windows.net/admin/thirdparties_kcb.json',
  UNDERWRITERS_CDN_URL:
    'https://mtekcdnstoragev2.blob.core.windows.net/admin/underwriters_kcb.json',
  DATA_ANALYTICS_API_URL: 'https://production.hillcroftinsurance.com/analytics',
  KYOSK_WEB_URL: 'https://kyoskdev.hillcroftinsurance.com',
  KCB_PARTNER_ID: 'kcb-6584-499f-b2a7-419c72398543',
};
