<div *ngIf="members.length > 0" class="">

  <div class="d-flex justify-content-between mb-10 ben-card ben-container"
    *ngFor="let member of members; let i = index">
    <div>
      <small class="d-block text-dark text-uppercase bold-text">
        Name
      </small>
      <span>
        {{ member.name }}
      </span>
    </div>
    <div>
      <small class="d-block text-dark text-uppercase bold-text">
        Email
      </small>
      <span>
        {{ member.email }}
      </span>
    </div>
    <div>
      <small class="d-block text-dark text-uppercase bold-text">
        Gender
      </small>
      <span>
        {{ member.gender }}
      </span>
    </div>
    <div>
      <small class="d-block text-dark text-uppercase bold-text">
        DOB
      </small>
      <span>
        {{ member.dateOfBirth | date }}
      </span>
    </div>
    <div>
      <small class="d-block text-dark text-uppercase bold-text">
        ID Number
      </small>
      <span>
        {{ member.idNo }}
      </span>
    </div>
    <div>
      <small class="d-block text-dark text-uppercase bold-text">
        Phone Number
      </small>
      <span>
        {{ member.phone }}
      </span>
    </div>
    <div>
      <small class="d-block text-dark text-uppercase bold-text">
        Policy Number
      </small>
      <span>
        {{ member.policyNumber }}
      </span>
    </div>
    <div>
      <small class="d-block text-dark text-uppercase bold-text">
        Membership Number
      </small>
      <span>
        {{ member.membershipNo }}
      </span>
    </div>

    <span class="remove">
      <button mat-mini-fab color="accent" aria-label="Example icon button with a delete icon"
        (click)='removeMembers(i)'>
        <mat-icon>delete</mat-icon>
      </button>
    </span>
  </div>

</div>
<br />
<app-csv-to-json [fields]="fields" (itemsEvent)="updateMembers($event)"></app-csv-to-json>
<h1>OR</h1>
<br />
<form [formGroup]="membersFormGroup" class="mt-2">
  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
        <label for="name">Name</label>
        <input class="form-control" id="name" type="text" formControlName="name" placeholder="e.g John Doe" />
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="share">Email</label>
        <input class="form-control" id="share" type="email" formControlName="email"
          placeholder="e.g johndoe@gmail.com" />
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="gender">Gender</label>
        <select class="custom-select" formControlName="gender" id="gender">
          <option>Male</option>
          <option>Female</option>
        </select>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="dateOfBirth">Date of birth</label>
        <div class="input-group">
          <input matInput class="form-control" formControlName="dateOfBirth" [matDatepicker]="picker2" />
          <div class="input-group-append">
            <button (click)="picker2.open()" class="btn btn-outline-secondary" type="button">
              <span class="fa fa-calendar"></span>
            </button>
          </div>
        </div>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="id">ID Number or Birth certificate number(for U-18)</label>
        <input class="form-control" id="id" type="text" formControlName="idNo" placeholder="e.g 266412554" />
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="phoneNumber">Phone Number</label>
        <input class="form-control" id="phoneNumber" type="text" formControlName="phone" placeholder="e.g 0712364125" />
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="policyNumber">Policy Number</label>
        <input class="form-control" id="policyNumber" type="text" formControlName="policyNumber" />
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="membershipNo">Membership Number</label>
        <input class="form-control" id="membershipNo" type="text" formControlName="membershipNo" />
      </div>
    </div>
  </div>
</form>
<div class="d-flex justify-content-between">
  <div></div>
  <button mat-raised-button color="primary" (click)="addMembers()" class="m-3">
    Add member
  </button>
</div>
<br />
<button mat-raised-button color="primary" (click)="emitGroupInfo()" class="m-3">
  Submit members
</button>