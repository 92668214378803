import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppEnums } from 'src/app/enums/app.enum';
import {
  ICountyRepsDirectSale,
  ICountyRepsDirectSaleAggregate,
  IPataPesaSales,
} from 'src/app/models/county-reps-direct-sales.interface';
import { IUser } from 'src/app/models/user.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import { CountyRepsReportComponent } from '../county-reps-report/county-reps-report.component';

interface Irange {
  start_date: string;
  end_date: string;
}

interface IDirectSaleBody {
  refCode: string;
  startDate: string;
  endDate: string;
}

@Component({
  selector: 'app-sales-statistics-partial',
  templateUrl: './sales-statistics-partial.component.html',
  styleUrls: ['./sales-statistics-partial.component.scss'],
})
export class SalesStatisticsPartialComponent implements OnInit {
  refCode: string;
  @Input() feduid: string;
  @Input() userHeader: string;

  userDetails: IUser;
  isLoading: boolean = false;
  directSalePayload: ICountyRepsDirectSale[] = [];
  directSales: ICountyRepsDirectSaleAggregate[] = [];
  countyRepsPataPesaSales: any[] = [];
  policyKeys: IPataPesaSales[] = [];
  selectedDateRange: Irange;
  downloadCount: number = 0;
  signUpCount: number = 0;
  reportIsLoading = false;
  constructor(
    private appService: AppService,
    private router: Router,
    private dialog: MatDialog
  ) {
    let today = new Date();
    let priorDate = new Date(new Date().setDate(today.getDate() - 30));
    this.selectedDateRange = {
      start_date: `${priorDate.getFullYear()}-${
        priorDate.getMonth() + 1
      }-${priorDate.getDate()}`,
      end_date: `${today.getFullYear()}-${
        today.getMonth() + 1
      }-${today.getDate()}`,
    };
  }

  ngOnInit(): void {
    this.getUser({
      feduid: this.feduid,
      startDate: this.selectedDateRange.start_date,
      endDate: this.selectedDateRange.end_date,
    });
  }

  getUser({ feduid, startDate, endDate }: any) {
    this.isLoading = true;
    this.appService.getUserDetailsByFeduid(feduid, []).subscribe((res) => {
      this.userDetails = res;
      this.refCode = this.userDetails.referralCode;
      if (this.userHeader == 'Prime Partner') {
        this.refCode = this.userDetails?.promo;
      }
      this.getDirectSales({
        refCode: this.refCode,
        startDate: startDate,
        endDate: endDate,
      });
    });
  }

  updateRange(range) {
    this.downloadCount = 0;
    this.signUpCount = 0;
    this.policyKeys = [];
    this.selectedDateRange = range;
    if (this.userHeader == 'Prime Partner') {
      this.refCode = this.userDetails?.promo;
    }
    this.getDirectSales({
      refCode: this.refCode,
      startDate: this.selectedDateRange.start_date,
      endDate: this.selectedDateRange.end_date,
    });
  }

  getDirectSales(body: IDirectSaleBody) {
    this.isLoading = true;
    this.directSalePayload = null;
    this.directSales = [];
    this.appService
      .makePostRequest(
        `${environment.DATA_ANALYTICS_API_URL}/county-reps-report/`,
        body
      )
      .subscribe((response) => {
        this.isLoading = false;
        if (response.Status == 200) {
          this.directSalePayload = response.Payload;
          this.directSales = this.directSalePayload[0]?.directSales;
          this.downloadCount = this.directSalePayload[0]?.downloads;
          this.signUpCount = this.directSalePayload[0]?.patapesaSignups;
          this.policyKeys = this.directSalePayload[0]?.patapesaSales;
        } else {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            'Failed',
            response.Payload
          );
        }
        this.isLoading = false;
      });
    (err) => {
      this.isLoading = false;
      this.appService.showToastMessage(
        AppEnums.ToastTypeError,
        '',
        this.appService.processHttpErrors(err)
      );
      this.isLoading = false;
    };
  }

  goToDownloads() {
    this.router.navigate(['/county-reps/downloads', this.refCode]);
  }

  goToAgents() {
    this.router.navigate(['/county-reps/agents', this.refCode]);
  }

  goToDirectSales(cover: string) {
    this.router.navigate(['/county-reps/directSales', cover, this.refCode]);
  }
  addInvoice() {
    this.router.navigate(['/invoice-payments/list', this.userDetails?.feduid]);
  }

  downloadReport() {
    this.reportIsLoading = true;
    let data = {
      promoCode: this.userDetails.referralCode,
      sendTo: this.appService.getSessionUser()?.email,
    };
    this.appService
      .makePostRequest(
        `${environment.DATA_ANALYTICS_API_URL}/sales-agents/county-reps-list`,
        data
      )
      .subscribe(
        (res) => {
          if (res.Status == 200) {
            this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              res.Message,
              res.Payload
            );
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeError,
              '',
              res.Payload
            );
          }
          this.reportIsLoading = false;
        },
        (error) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(error)
          );
          this.reportIsLoading = false;
        }
      );
  }

  downloadDetailReport() {
    const dialogRef = this.dialog.open(CountyRepsReportComponent, {
      width: '50%',
      data: { referralCode: this.userDetails.referralCode },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
}
