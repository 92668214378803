import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { IGolfAggregate } from 'src/app/models/golf-aggregate.interface';

@Component({
  selector: 'app-golf-list-table',
  templateUrl: './golf-list-table.component.html',
  styleUrls: ['./golf-list-table.component.scss'],
})
export class GolfListTableComponent implements OnInit {
  @Input() policies: IGolfAggregate[];
  filteredRows: IGolfAggregate[];
  selectedRecord: IGolfAggregate;
  tableFiltersFormGroup: FormGroup;
  @Input() dataLoaded: boolean;

  constructor(private formBuilder: FormBuilder, private router: Router) {
    this.tableFiltersFormGroup = this.formBuilder.group({
      searchFilter: '',
    });
    this.filterSearchTable();
  }

  ngOnInit(): void {}

  filterSearchTable() {
    this.tableFiltersFormGroup
      .get('searchFilter')
      .valueChanges.subscribe((val) => {
        this.filteredRows = this.policies.filter((record) => {
          return Object.keys(record).reduce((acc, curr) => {
            return (
              acc ||
              JSON.stringify(record[curr])
                .toLowerCase()
                .includes(val.toLowerCase())
            );
          }, false);
        });
      });
  }

  goToPolicyDetails(_id: string) {
    this.router.navigate(['/golf/list/golf-details', _id]);
  }

  highlightCancelledPolicies(policy: any) {
    if (policy.hasOwnProperty('cancellation')) {
      return {
        'background-color': '#DB3A34',
        color: '#fff',
      };
    }
  }
}
