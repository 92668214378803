<table class="table table-striped table-responsive-sm">
  <tr>
    <th scope="col">Repayment Date</th>
    <th scope="col">Installment Amount</th>
    <th scope="col">Paid</th>
  </tr>

  <tbody>
    <tr *ngFor="let plan of sheduledPaymentPlans" class="section-step">
      <td class="p-3">{{ plan?.date | date: 'medium' }}</td>
      <td class="p-3">{{ plan?.amount }}</td>
      <td class="p-3">
        {{ plan?.paid }}
      </td>
    </tr>
  </tbody>
</table>
