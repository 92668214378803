import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-display-travel-group-info',
  templateUrl: './display-travel-group-info.component.html',
  styleUrls: ['./display-travel-group-info.component.scss'],
})
export class DisplayTravelGroupInfoComponent implements OnInit {
  @Input() members: any[] = [];

  constructor() {
    this.members.forEach((member) => {
      if (isNaN(member.dateOfBirth)) {
        member.dateOfBirth = Date.parse(member.dateOfBirth);
      }
    });
  }
  ngOnInit(): void {}
}
