import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexGrid,
  ApexLegend,
  ApexPlotOptions,
  ApexTitleSubtitle,
  ApexYAxis,
  ChartComponent,
} from 'ng-apexcharts';
import { AppEnums } from 'src/app/enums/app.enum';
import { Iseries } from 'src/app/models/datahub.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
type ApexXAxis = {
  type?: 'category' | 'datetime' | 'numeric';
  categories?: any;
  labels?: {
    style?: {
      colors?: string | string[];
      fontSize?: string;
    };
  };
};
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  colors: string[];
  legend: ApexLegend;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-motor-renewal-users-leaderboard',
  templateUrl: './motor-renewal-users-leaderboard.component.html',
  styleUrls: ['./motor-renewal-users-leaderboard.component.scss'],
})
export class MotorRenewalUsersLeaderboardComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  isLoading: boolean = false;
  chartSeries: Iseries[] = [];

  constructor(public appService: AppService) {}

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers() {
    this.appService
      .makePostRequest(`${environment.DATA_API_URL}/Renewals_leaderboard`, {})
      .subscribe(
        (res) => {
          let data: any[] = res.data;
          data.splice(0, 10).forEach((item) => {
            this.chartSeries.push({ x: item['client.name'], y: item.count });
          });

          this.chartOptions = {
            series: [
              {
                name: 'Renewals',
                data: this.chartSeries,
              },
            ],
            chart: {
              height: 480,
              type: 'bar',
            },
            colors: [
              '#008FFB',
              '#00E396',
              '#FEB019',
              '#FF4560',
              '#775DD0',
              '#546E7A',
              '#26a69a',
              '#D10CE8',
            ],
            plotOptions: {
              bar: {
                columnWidth: '66%',
                //endingShape: 'rounded',
                distributed: true,
              },
            },
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: false,
            },
            xaxis: {
              type: 'category',
              labels: {
                style: {
                  colors: [
                    '#008FFB',
                    '#00E396',
                    '#FEB019',
                    '#FF4560',
                    '#775DD0',
                    '#546E7A',
                    '#26a69a',
                    '#D10CE8',
                  ],
                },
              },
            },
            title: {
              text: 'Motor Renewal Users Leaderboard',
            },
          };
          this.isLoading = true;
        },
        (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            'Something went wrong',
            'Failed to load motor renewal users leaderboard'
          );
        }
      );
  }
}
