import { Component, OnInit, Input } from '@angular/core';
import { IDomestic } from 'src/app/models/domestic.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-domestic-documents',
  templateUrl: './domestic-documents.component.html',
  styleUrls: ['./domestic-documents.component.scss'],
})
export class DomesticDocumentsComponent implements OnInit {
  @Input() selectedRecord: IDomestic;

  allDataLoaded: boolean = true;
  allowedFileExtentions = ['jpg', 'jpeg', 'png', 'pdf', 'docx'];
  azureFileLocation: string = '';
  azureFileName: string = '';
  uploadEndpoint: string = '';
  questionsEndpoint: string = '';

  constructor(public appService: AppService) {}

  ngOnInit(): void {
    let feduid = this.selectedRecord?.feduid;
    let selectedRecordId = this.selectedRecord?._id;
    this.azureFileLocation = `${feduid}/policies/domestic`;
    this.azureFileName = `${selectedRecordId}`;
    this.uploadEndpoint = `${environment.DOMESTIC_API_URL}/adminUpdateQuestions`;
    this.questionsEndpoint = `${environment.DOMESTIC_API_URL}/getdomesticquotequestions`;
  }
}
