<h1 mat-dialog-title>Cancel Policy</h1>
<div mat-dialog-content>
    <app-loader *ngIf="isLoading"></app-loader>
    <form [formGroup]="cancelPolicyFormGroup" (submit)="submitCancellation()">
        <div class="form-group">
            <label for="reason">Reason for Cancelling</label>
            <textarea class="form-control" id="reason" rows="3" formControlName="reason"></textarea>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
    <button mat-button color="primary" (click)="submitCancellation()">Submit</button>
</div>