import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AppEnums } from 'src/app/enums/app.enum';
import { IRemittancePayment } from 'src/app/models/remittance-payment.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-policy-remittance',
  templateUrl: './policy-remittance.component.html',
  styleUrls: ['./policy-remittance.component.scss'],
})
export class PolicyRemittanceComponent implements OnInit {
  @Input() product: string;
  @Input() selectedRecord: any;
  @Output() emitSaveRemittance = new EventEmitter<string>();
  showAddRemittance: boolean = false;
  isLoading: boolean = false;
  totalRemittance: number = 0;
  remittancePayments: IRemittancePayment[] = [];
  remittanceFormGroup: FormGroup;

  constructor(
    public appService: AppService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe
  ) {
    this.remittanceFormGroup = this.formBuilder.group({
      amount: new FormControl('', Validators.compose([Validators.required])),
      reference: new FormControl('', Validators.compose([Validators.required])),
      dateRemitted: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      paymentMode: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
    });
  }

  toggleAddPayment() {
    this.showAddRemittance = !this.showAddRemittance;
  }
  ngOnInit(): void {
    if (this.selectedRecord.hasOwnProperty('remittance')) {
      let remittance = this.selectedRecord.remittance;
      if (remittance.hasOwnProperty('payments')) {
        this.remittancePayments = remittance?.payments;
        this.remittancePayments.forEach((aPayment) => {
          this.totalRemittance += aPayment.paid;
        });
      }
    }
  }

  formatRemittanceDate(dateRemitted: any) {
    if (typeof dateRemitted === 'string') {
      return dateRemitted;
    }
    if (typeof dateRemitted === 'number') {
      return this.datePipe.transform(dateRemitted, 'mediumDate');
    }
    return dateRemitted;
  }

  deleteRemittance() {}

  submitRemittance() {
    if (!this.remittanceFormGroup.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Fill all fields'
      );
      return;
    }
    let url = `${environment.PAYMENTS_API_URL}/adminUpdateRemittance`;
    this.isLoading = true;
    let data = this.remittanceFormGroup.value;
    data['_id'] = this.selectedRecord._id;
    data['product'] = this.product;
    let start: NgbDateStruct =
      this.remittanceFormGroup.get('dateRemitted').value;
    let m = `${start.month}`.padStart(2, '0');
    let d = `${start.day}`.padStart(2, '0');
    data['dateRemitted'] = `${d}-${m}-${start.year}`;
    data['remittedTimestamp'] = this.appService.getTimestampFromDate(
      start.year,
      Number(m),
      Number(d)
    );

    this.appService.makePostRequest(url, data).subscribe(
      (res) => {
        this.isLoading = false;
        if (res.Status == 200) {
          this.remittanceFormGroup.reset();
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            '',
            'Remittance saved Successfully'
          );
          this.emitSaveRemittance.emit(this.selectedRecord?._id);
        } else {
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            res.Payload
          );
        }
      },
      (err) => {
        this.isLoading = false;
        this.appService.showToastMessage(
          AppEnums.ToastTypeWarning,
          '',
          this.appService.processHttpErrors(err)
        );
      }
    );
  }
}
