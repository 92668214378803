import { Component, OnInit, Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { AppService } from '../../services/app.service';
import { AppEnums } from 'src/app/enums/app.enum';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @Input() searchParams;
  @Output() searchResults = new EventEmitter<string>();
  @Output() InputEmpty = new EventEmitter<boolean>();
  searchForm: FormGroup;
  isSearching: boolean = false;
  searchQuery: {} = {};

  name = new FormControl('');

  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService
  ) {
    this.searchForm = this.formBuilder.group({
      inputItem: ['', [Validators.required]],
    });
  }

  ngOnInit() { }

  search(searchParams: any) {
    this.isSearching = true;
    const { inputItem } = this.searchForm.value;

    let cleanInputItem: any = inputItem;

    if (inputItem == 'true') {
      cleanInputItem = true;
    }

    if (inputItem == 'false') {
      cleanInputItem = false;
    }

    if (!inputItem) {
      this.isSearching = false;
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        'Missing Input',
        'Please enter your search item!'
      );
      return;
    }

    const fields = searchParams.searchFields.map((item) => {
      return { [item]: { $regex: cleanInputItem, $options: 'i' } };
    });

    if (searchParams.customQuery.$or) {
      let andQuery = {
        $and: [{ $or: fields }, { $or: searchParams.customQuery.$or }],
      };

      delete searchParams.customQuery.$or;
      this.searchQuery = {
        ...andQuery,
        ...searchParams.customQuery,
      };
    } else {
      this.searchQuery = {
        $or: fields,
        ...searchParams.customQuery,
      };
    }

    try {
      this.appService
        .makeSearchRequest(searchParams.url, {
          ...this.searchQuery,
        })
        .subscribe(
          (apiResponse) => {
            this.isSearching = false;
            apiResponse.Status === 200
              ? apiResponse.Payload.length === 0
                ? (this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'No results!',
                  'No results found. Try searching other options'
                ),
                  this.searchResults.emit(apiResponse.Payload))
                : this.searchResults.emit(apiResponse.Payload)
              : this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                'Something went wrong',
                'Failed to get search results. Please try again'
              );
          },
          (err) => {
            this.isSearching = false;
            this.appService.showToastMessage(
              AppEnums.ToastTypeError,
              'Something went wrong',
              'Please try again'
            );
          }
        );
    } catch (ex) {
      this.isSearching = false;
      this.appService.showToastMessage(
        AppEnums.ToastTypeError,
        'Something went wrong',
        'Please try again'
      );
      throw new Error(ex.toString);
    }
  }

  onInputChange() {
    const { inputItem } = this.searchForm.value;
    if (!inputItem) {
      this.InputEmpty.emit();
    }
  }
}
