import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { BaseLayoutComponent } from '../components/base-layout/base-layout.component';
import { HeaderComponent } from '../components/header/header.component';
import { FooterComponent } from '../components/footer/footer.component';
import { PageTitleComponent } from '../components/page-title/page-title.component';
import { OptionsDrawerComponent } from '../components/options-drawer/options-drawer.component';
import { DrawerComponent } from '../components/drawer/drawer.component';
import { SearchBoxComponent } from '../components/header/search-box/search-box.component';
import { MegaMenuComponent } from '../components/header/mega-menu/mega-menu.component';
import { DotsComponent } from '../components/header/dots/dots.component';
import { UserBoxComponent } from '../components/header/user-box/user-box.component';
import { FooterDotsComponent } from '../components/footer/footer-dots/footer-dots.component';
import { FooterMenuComponent } from '../components/footer/footer-menu/footer-menu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TrendModule } from 'ngx-trend';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ChartsModule } from 'ng2-charts';
import { BarChartComponent } from '../components/bar-chart/bar-chart.component';
import { DoughnutChartComponent } from '../components/doughnut-chart/doughnut-chart.component';
import { SearchComponent } from '../components/search/search.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from '../components/loader/loader.component';
import { ClientPartialComponent } from '../partials/client-partial/client-partial.component';
import { PolicyClientPartialComponent } from '../partials/policy-client-partial/policy-client-partial.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PhotoGalleryPartialComponent } from '../partials/photo-gallery-partial/photo-gallery-partial.component';
import { PaymentPlanPartialComponent } from '../partials/payment-plan-partial/payment-plan-partial.component';
import { PaymentPlanPipe } from '../partials/payment-plan-partial/payment-plan.pipe';
import { PaymentPlanNewPipe } from '../partials/payment-plan-partial/payment-plan-new.pipe';
import { MotorListTableComponent } from 'src/app/motor-policy/motor-list-table/motor-list-table.component';
import { TravelListTableComponent } from 'src/app/travel-policy/travel-list-table/travel-list-table.component';
import { DomesticListTableComponent } from 'src/app/domestic-policy/domestic-list-table/domestic-list-table.component';
import { MedicalListTableComponent } from 'src/app/medical-policy/medical-list-table/medical-list-table.component';
import { PaListTableComponent } from 'src/app/personal-accident-policy/pa-list-table/pa-list-table.component';
import { EvacuationListTableComponent } from 'src/app/evacuation-policy/evacuation-list-table/evacuation-list-table.component';
import { GolfListTableComponent } from 'src/app/golf-policy/golf-list-table/golf-list-table.component';
import { ListMpesaPaymentsTableComponent } from 'src/app/payment/mpesa-payments/list-mpesa-payments-table/list-mpesa-payments-table.component';
import { ListCardPaymentsTableComponent } from 'src/app/payment/card-payments/list-card-payments-table/list-card-payments-table.component';
import { UnderwriterPipe } from 'src/app/pipes/underwriter.pipe';
import { ThirdPartyPipe } from 'src/app/pipes/third-party.pipe';
import { CountyNamePipe } from 'src/app/pipes/county-name.pipe';
import { CustomDatePipe } from 'src/app/pipes/date.pipe';
import { FileUploadComponent } from 'src/app/partials/file-upload/file-upload.component';
import { PartnerPaymentsDashboardComponent } from '../dashboard/partner-dashboard/partner-payments-dashboard/partner-payments-dashboard.component';
import { PartnerPoliciesDashboardComponent } from '../dashboard/partner-dashboard/partner-policies-dashboard/partner-policies-dashboard.component';
import { PartnerClaimsDashboardComponent } from '../dashboard/partner-dashboard/partner-claims-dashboard/partner-claims-dashboard.component';
import { PaginationComponent } from '../partials/pagination/pagination.component';
import { BreadcrumbComponent } from '../partials/breadcrumb/breadcrumb.component';
import { BreadcrumbModule } from 'angular-crumbs';
import { PaymentSummaryComponent } from '../payment/payment-summary/payment-summary.component';
import { UserStatsComponent } from '../dashboard/user-stats/user-stats.component';
import { PolicyStatsComponent } from '../dashboard/policy-stats/policy-stats.component';
import { UserActionsComponent } from '../partials/user-actions/user-actions.component';
import { MotorClaimListTableComponent } from '../motor-claim/motor-claim-list-table/motor-claim-list-table.component';
import { DocumentUploadComponent } from 'src/app/partials/document-upload/document-upload.component';
import { ListDocumentsComponent } from '../partials/list-documents/list-documents.component';
import { DateRangeComponent } from '../partials/date-range/date-range.component';
import { AggregateSearchComponent } from '../partials/aggregate-search/aggregate-search.component';
import { ValuationSummaryComponent } from '../valuation/valuation-summary/valuation-summary.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatStepperModule } from '@angular/material/stepper';
import { ReferralsListComponent } from '../partials/referrals-list/referrals-list.component';
import { CancelPolicyComponent } from '../partials/cancel-policy/cancel-policy.component';
import { PolicyOptionsComponent } from '../partials/policy-options/policy-options.component';
import { PolicyCancellationDetailsComponent } from '../partials/policy-cancellation-details/policy-cancellation-details.component';
import { ChangePolicyPeriodComponent } from '../partials/change-policy-period/change-policy-period.component';
import { PaymentLogEntryComponent } from '../partials/payment-log-entry/payment-log-entry.component';
import { FileUploaderComponent } from '../partials/file-uploader/file-uploader.component';
import { RegisterExternalPaymentPartialComponent } from '../partials/register-external-payment-partial/register-external-payment-partial.component';
import { CancelFinancePlanComponent } from '../partials/cancel-finance-plan/cancel-finance-plan.component';
import { UpdateFinancePlanComponent } from '../partials/update-finance-plan/update-finance-plan.component';
import { MotorRenewalUsersLeaderboardComponent } from '../data-hub/data-hub-dashboard/motor-renewal-users-leaderboard/motor-renewal-users-leaderboard.component';
import { DependentPhotoGalleryPartialComponent } from '../partials/dependent-photo-gallery-partial/dependent-photo-gallery-partial.component';
import { PaymentScheduleTableComponent } from '../partials/payment-plan-partial/payment-schedule-table/payment-schedule-table.component';
import { CreditRatingComponent } from '../partials/credit-rating/credit-rating.component';
import { SaveCredingRatingComponent } from '../partials/save-creding-rating/save-creding-rating.component';
import { DeleteCreditRatingComponent } from '../partials/delete-credit-rating/delete-credit-rating.component';
import { ClientInfoPartialComponent } from '../partials/client-info-partial/client-info-partial.component';
import { CollectionSearchComponent } from '../partials/collection-search/collection-search.component';
import { PolicyRemittanceComponent } from '../partials/policy-remittance/policy-remittance.component';
import { UserPipe } from '../pipes/user.pipe';
import { CompanyInfoPartialComponent } from '../partials/company-info-partial/company-info-partial.component';
import { GroupInfoPartialComponent } from '../partials/group-info-partial/group-info-partial.component';
import { BulkPayCommissionDialogComponent } from '../partials/bulk-pay-commission-dialog/bulk-pay-commission-dialog.component';
import { SendDeclarationReportDialogComponent } from '../partials/send-declaration-report-dialog/send-declaration-report-dialog.component';
import { DownloadCommissionStatementDialogComponent } from '../partials/download-commission-statement-dialog/download-commission-statement-dialog.component';
import { RepaymentStatusComponent } from '../partials/repayment-status/repayment-status.component';
import { CsvToJsonComponent } from '../partials/csv-to-json/csv-to-json.component';
import { DisplayGroupInfoComponent } from '../partials/display-group-info/display-group-info.component';
import { PushFileUploadComponent } from '../partials/push-file-upload/push-file-upload.component';
import { AggregateFinanceSearchComponent } from '../partials/aggregate-finance-search/aggregate-finance-search.component';
import { ClientInfoUpdatePartialComponent } from '../partials/client-info-update-partial/client-info-update-partial.component';
import { CompanyInfoUpdatePartialComponent } from '../partials/company-info-update-partial/company-info-update-partial.component';
import { WalletTransactionsComponent } from '../partials/wallet-transactions/wallet-transactions.component';
import { UploadPolicyGroupMembersComponent } from '../partials/upload-policy-group-members/upload-policy-group-members.component';
import { DeclareIceaRiskNoteComponent } from '../partials/declare-icea-risk-note/declare-icea-risk-note.component';
import { DisplayClientDetailsComponent } from '../partials/display-client-details/display-client-details.component';
import { UpdateClientDetailsComponent } from '../partials/update-client-details/update-client-details.component';
import { UpdateCompanyDetailsComponent } from '../partials/update-company-details/update-company-details.component';
import { UpdateNokDetailsComponent } from '../partials/update-nok-details/update-nok-details.component';
import { DisplayNokDetailsComponent } from '../partials/display-nok-details/display-nok-details.component';
import { GenericSearchComponent } from '../partials/generic-search/generic-search.component';
import { AuditLogComponent } from '../partials/audit-log/audit-log.component';
import { DisplayTravelGroupInfoComponent } from '../partials/display-travel-group-info/display-travel-group-info.component';
import { SidenavComponent } from '../components/sidenav/sidenav.component';
import { SidebarSublevelMenuComponent } from '../components/sidenav/sidebar-sublevel-menu.component';
import { StatutoryPremiumsComponent } from '../partials/statutory-premiums/statutory-premiums.component';
import { AddCardMembershipInforPartialComponent } from '../partials/add-card-membership-infor-partial/add-card-membership-infor-partial.component';
import { UpdatePolicyStartDateComponent } from '../partials/update-policy-start-date/update-policy-start-date.component';
import { WalletTransactionsSearchPartialComponent } from '../partials/wallet-transactions-search-partial/wallet-transactions-search-partial.component';
import { SalesStatisticsPartialComponent } from '../partials/sales-statistics-partial/sales-statistics-partial.component';
import { ClientWalletComponent } from '../partials/client-wallet/client-wallet.component';
import { AssessorPartialComponent } from '../partials/assessor-partial/assessor-partial.component';
import { ValuerPartialComponent } from '../partials/valuer-partial/valuer-partial.component';
import { CommissionsDivisionComponent } from '../partials/commissions-division/commissions-division.component';
import { PolicyCommissionsListComponent } from '../partials/policy-commissions-list/policy-commissions-list.component';
import { SmePolicyNamePipe } from '../pipes/sme-policy-name.pipe';
import { CountyRepsReportComponent } from '../partials/county-reps-report/county-reps-report.component';
import { MatMenuModule } from '@angular/material/menu';
import { JsonfileToJsonComponent } from '../partials/jsonfile-to-json/jsonfile-to-json.component';
import { ReferralLinkSelectorComponent } from '../partials/referral-link-selector/referral-link-selector.component';
import { PolicyPostCommissionComponent } from '../partials/policy-post-commission/policy-post-commission.component';
import { DateRangePickerDashboardComponent } from '../partials/date-range-picker-dashboard/date-range-picker-dashboard.component';
import { CollectionPolicyNamePipe } from '../pipes/collection-policy-name.pipe';

@NgModule({
  declarations: [
    BaseLayoutComponent,
    HeaderComponent,
    FooterComponent,
    PageTitleComponent,
    OptionsDrawerComponent,
    DrawerComponent,
    SearchBoxComponent,
    MegaMenuComponent,
    DotsComponent,
    UserBoxComponent,
    FooterDotsComponent,
    FooterMenuComponent,
    BarChartComponent,
    DoughnutChartComponent,
    SearchComponent,
    LoaderComponent,
    ClientPartialComponent,
    PaymentPlanPartialComponent,
    PhotoGalleryPartialComponent,
    PaymentPlanPipe,
    PaymentPlanNewPipe,
    MotorListTableComponent,
    MotorClaimListTableComponent,
    PaymentPlanPipe,
    TravelListTableComponent,
    DomesticListTableComponent,
    MedicalListTableComponent,
    PaListTableComponent,
    EvacuationListTableComponent,
    GolfListTableComponent,
    ListMpesaPaymentsTableComponent,
    ListCardPaymentsTableComponent,
    UnderwriterPipe,
    ThirdPartyPipe,
    CustomDatePipe,
    FileUploadComponent,
    PartnerPaymentsDashboardComponent,
    PartnerPoliciesDashboardComponent,
    PartnerClaimsDashboardComponent,
    PaginationComponent,
    BreadcrumbComponent,
    PaymentSummaryComponent,
    UserStatsComponent,
    PolicyStatsComponent,
    UserActionsComponent,
    DocumentUploadComponent,
    ListDocumentsComponent,
    DateRangeComponent,
    AggregateSearchComponent,
    ValuationSummaryComponent,
    ReferralsListComponent,
    CancelPolicyComponent,
    PolicyOptionsComponent,
    PolicyCancellationDetailsComponent,
    ChangePolicyPeriodComponent,
    PaymentLogEntryComponent,
    FileUploaderComponent,
    RegisterExternalPaymentPartialComponent,
    CancelFinancePlanComponent,
    UpdateFinancePlanComponent,
    MotorRenewalUsersLeaderboardComponent,
    DependentPhotoGalleryPartialComponent,
    PaymentScheduleTableComponent,
    CreditRatingComponent,
    SaveCredingRatingComponent,
    DeleteCreditRatingComponent,
    ClientInfoPartialComponent,
    CollectionSearchComponent,
    PolicyRemittanceComponent,
    UserPipe,
    CompanyInfoPartialComponent,
    BulkPayCommissionDialogComponent,
    SendDeclarationReportDialogComponent,
    DownloadCommissionStatementDialogComponent,
    RepaymentStatusComponent,
    GroupInfoPartialComponent,
    CsvToJsonComponent,
    DisplayGroupInfoComponent,
    PushFileUploadComponent,
    AggregateFinanceSearchComponent,
    PolicyClientPartialComponent,
    ClientInfoUpdatePartialComponent,
    CompanyInfoUpdatePartialComponent,
    WalletTransactionsComponent,
    CountyNamePipe,
    UploadPolicyGroupMembersComponent,
    DeclareIceaRiskNoteComponent,
    DisplayClientDetailsComponent,
    UpdateClientDetailsComponent,
    UpdateCompanyDetailsComponent,
    UpdateNokDetailsComponent,
    DisplayNokDetailsComponent,
    GenericSearchComponent,
    AuditLogComponent,
    DisplayTravelGroupInfoComponent,
    SidenavComponent,
    SidebarSublevelMenuComponent,
    StatutoryPremiumsComponent,
    AddCardMembershipInforPartialComponent,
    UpdatePolicyStartDateComponent,
    WalletTransactionsSearchPartialComponent,
    SalesStatisticsPartialComponent,
    ClientWalletComponent,
    AssessorPartialComponent,
    ValuerPartialComponent,
    CommissionsDivisionComponent,
    PolicyCommissionsListComponent,
    SmePolicyNamePipe,
    CountyRepsReportComponent,
    JsonfileToJsonComponent,
    ReferralLinkSelectorComponent,
    PolicyPostCommissionComponent,
    DateRangePickerDashboardComponent,
    CollectionPolicyNamePipe,
  ],
  imports: [
    CommonModule,
    LoadingBarRouterModule,
    PerfectScrollbarModule,
    NgbModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    SlickCarouselModule,
    LeafletModule,
    //Angular Material
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSortModule,
    MatInputModule,
    TrendModule,
    RoundProgressModule,
    MatTabsModule,
    MatIconModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatButtonModule,
    MatTooltipModule,
    MatExpansionModule,
    MatDialogModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BreadcrumbModule,
    PdfViewerModule,
    MatStepperModule,
    ClipboardModule,
    MatMenuModule,
  ],
  exports: [
    BaseLayoutComponent,
    HeaderComponent,
    FooterComponent,
    PageTitleComponent,
    OptionsDrawerComponent,
    DrawerComponent,
    SearchBoxComponent,
    MegaMenuComponent,
    DotsComponent,
    UserBoxComponent,
    FooterDotsComponent,
    FooterMenuComponent,
    DoughnutChartComponent,
    BarChartComponent,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSortModule,
    MatInputModule,
    MatAutocompleteModule,
    MatButtonModule,
    SearchComponent,
    LoaderComponent,
    MatTabsModule,
    MatIconModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDatepickerModule,
    SearchComponent,
    ClientPartialComponent,
    PaymentPlanPartialComponent,
    MatTooltip,
    MatExpansionModule,
    SearchComponent,
    MatDialogModule,
    MatRadioModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    SlickCarouselModule,
    PhotoGalleryPartialComponent,
    PaymentPlanPipe,
    PaymentPlanNewPipe,
    MotorListTableComponent,
    MotorClaimListTableComponent,
    TravelListTableComponent,
    DomesticListTableComponent,
    MedicalListTableComponent,
    PaListTableComponent,
    EvacuationListTableComponent,
    GolfListTableComponent,
    ListMpesaPaymentsTableComponent,
    ListCardPaymentsTableComponent,
    UnderwriterPipe,
    ThirdPartyPipe,
    FileUploadComponent,
    NgbModule,
    PartnerPaymentsDashboardComponent,
    PartnerPoliciesDashboardComponent,
    PartnerClaimsDashboardComponent,
    PaginationComponent,
    BreadcrumbComponent,
    BreadcrumbModule,
    PaymentSummaryComponent,
    UserStatsComponent,
    PolicyStatsComponent,
    UserActionsComponent,
    DocumentUploadComponent,
    ListDocumentsComponent,
    DateRangeComponent,
    AggregateSearchComponent,
    ValuationSummaryComponent,
    LeafletModule,
    MatStepperModule,
    PolicyOptionsComponent,
    PolicyCancellationDetailsComponent,
    PaymentLogEntryComponent,
    ClipboardModule,
    FileUploaderComponent,
    RegisterExternalPaymentPartialComponent,
    CancelFinancePlanComponent,
    UpdateFinancePlanComponent,
    MotorRenewalUsersLeaderboardComponent,
    DependentPhotoGalleryPartialComponent,
    CreditRatingComponent,
    SaveCredingRatingComponent,
    DeleteCreditRatingComponent,
    ClientInfoPartialComponent,
    CollectionSearchComponent,
    PolicyRemittanceComponent,
    UserPipe,
    CompanyInfoPartialComponent,
    BulkPayCommissionDialogComponent,
    SendDeclarationReportDialogComponent,
    DownloadCommissionStatementDialogComponent,
    RepaymentStatusComponent,
    GroupInfoPartialComponent,
    CsvToJsonComponent,
    DisplayGroupInfoComponent,
    PushFileUploadComponent,
    AggregateFinanceSearchComponent,
    PolicyClientPartialComponent,
    ClientInfoUpdatePartialComponent,
    CompanyInfoUpdatePartialComponent,
    WalletTransactionsComponent,
    CountyNamePipe,
    UploadPolicyGroupMembersComponent,
    DeclareIceaRiskNoteComponent,
    DisplayClientDetailsComponent,
    UpdateClientDetailsComponent,
    UpdateCompanyDetailsComponent,
    UpdateNokDetailsComponent,
    DisplayNokDetailsComponent,
    GenericSearchComponent,
    AuditLogComponent,
    DisplayTravelGroupInfoComponent,
    SidenavComponent,
    SidebarSublevelMenuComponent,
    StatutoryPremiumsComponent,
    AddCardMembershipInforPartialComponent,
    UpdatePolicyStartDateComponent,
    WalletTransactionsSearchPartialComponent,
    SalesStatisticsPartialComponent,
    ClientWalletComponent,
    AssessorPartialComponent,
    ValuerPartialComponent,
    CommissionsDivisionComponent,
    PolicyCommissionsListComponent,
    SmePolicyNamePipe,
    CountyRepsReportComponent,
    MatMenuModule,
    JsonfileToJsonComponent,
    ReferralLinkSelectorComponent,
    PolicyPostCommissionComponent,
    DateRangePickerDashboardComponent,
    CollectionPolicyNamePipe,
  ],
  //entryComponents: [NotificationDetailsComponent],
  providers: [
    DatePipe,
    UnderwriterPipe,
    CountyNamePipe,
    SmePolicyNamePipe,
    CollectionPolicyNamePipe,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ],
})
export class SharedModule {}
