<div class="card form-container">
  <form
  [formGroup]="searchForm"
  (ngSubmit)="search(searchParams)"
  class="search-form"
>
  <div class="col-lg-12 search-item-box">
      <div class="col-lg-12 d-flex form-group">
        <input
        placeholder="Search..."
        class="form-control"
        formControlName="inputItem"
        (input)="onInputChange()"
      />
      <div (click)="search(searchParams)" class="icon-container">
        <i class="fa fa-search"></i>
      </div>
      </div>
      
  </div>
  <!-- <div class="col-lg-2 button-container">
    <button mat-raised-button class="search-btn btn-info" type="submit">
      Search
    </button>
  </div> -->
</form>
<app-loader class="search-loader" *ngIf="isSearching"></app-loader>
</div>