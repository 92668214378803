import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EditUserRoleComponent } from 'src/app/client/edit-user-role/edit-user-role.component';
import { PasswordResetComponent } from 'src/app/client/password-reset/password-reset.component';
import { UserSendNotificationComponent } from 'src/app/client/user-send-notification/user-send-notification.component';
import { AppEnums } from 'src/app/enums/app.enum';
import { IUser } from 'src/app/models/user.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-actions',
  templateUrl: './user-actions.component.html',
  styleUrls: ['./user-actions.component.scss'],
})
export class UserActionsComponent implements OnInit {
  @Input() userDetails: IUser;
  @Output() emitReloadUserDetails = new EventEmitter();
  searchForm: FormGroup;
  userRoles: string[] = [];

  constructor(
    private dialog: MatDialog,
    public appService: AppService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userRoles = this.appService.getRoles(this.userDetails?.roles);
  }
  goToEditUserDetails() {
    this.router.navigate(['/users/edit-user', this.userDetails.feduid]);
  }
  showUpdateRoleDialog() {
    let dialogRef = this.dialog.open(EditUserRoleComponent, {
      data: this.userDetails,
      width: '50%',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.emitReloadUserDetails.emit();
    });
  }

  showChangePasswordDialog() {
    let dialogRef = this.dialog.open(PasswordResetComponent, {
      data: this.userDetails,
      width: '60%',
      maxHeight: '90vh',
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.emitReloadUserDetails.emit();
    });
  }

  openAddNotification() {
    const dialogRef = this.dialog.open(UserSendNotificationComponent, {
      width: '50%',
      data: {
        feduid: this.userDetails?.feduid,
      },
    });
  }

  sendClientCertificate() {
    if (this.userDetails.email) {
      this.appService
        .makePostRequest(`${environment.KMS_API_URL}/getCerts`, {
          email: [this.userDetails.email],
        })
        .subscribe((data) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            '',
            'Certificate sent successfully.'
          );
        });
      (err) => {
        this.appService.showToastMessage(
          AppEnums.ToastTypeError,
          '',
          this.appService.processHttpErrors(err)
        );
      };
    }
  }

  enableDisableAccount() {
    this.appService
      .makePostRequest(`${environment.CORE_API_URL}/disableuser`, {
        feduid: this.userDetails?.feduid,
        disabled: !this.userDetails?.disabled,
      })
      .subscribe((data) => {
        if (data.Status == 200) {
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            '',
            data.Message
          );
        } else {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            'Failed',
            data.Payload
          );
        }
        this.emitReloadUserDetails.emit();
      });
    (err) => {
      this.appService.showToastMessage(
        AppEnums.ToastTypeError,
        '',
        this.appService.processHttpErrors(err)
      );
    };
  }
}
