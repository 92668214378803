import { Component, Input, OnInit } from '@angular/core';
import { AppEnums } from 'src/app/enums/app.enum';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
interface ICommissionPayment {
  feduid: string;
  userid: string;
  paid: number;
  name: string;
  paymentMode: string;
  reference: string;
  datePaid: number;
  _date: any;
  _timestamp: number;
}
@Component({
  selector: 'app-commissions-division',
  templateUrl: './commissions-division.component.html',
  styleUrls: ['./commissions-division.component.scss'],
})
export class CommissionsDivisionComponent implements OnInit {
  @Input() receivedId: string;
  isLoading: boolean = false;
  commissionShare: any = {};
  totalPaidCommission: number = 0;
  commissionPayments: ICommissionPayment[] = [];
  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.getCommissionDivisions();
  }

  getCommissionDivisions() {
    this.isLoading = true;
    const pipeline = {
      pipeline: [
        {
          $match: {
            quoteID: this.receivedId,
          },
        },
        {
          $project: {
            _id: 1,
            expectedCommission: 1, // Gross (Before WHT)
            totalCommission: 1, // Net (After WHT)
            whtTax: 1, //WHT
            mtekCommission: 1,
            patapesaCommission: 1,
            countyRepCommission: 1,
            embeddedCommission: 1,
            consultantCommission: 1,
            primePartnerCommission: 1,
            _timestamp: 1,
            payments: 1,
          },
        },
      ],
    };
    this.appService
      .makePostRequest(`${environment.MPESA_API_URL}/getComissions`, pipeline)
      .subscribe(
        (res) => {
          this.isLoading = false;
          if (res.Status == 200) {
            if (res.Payload[0]) {
              this.commissionShare = res.Payload[0];
              if (this.commissionShare.hasOwnProperty('payments')) {
                this.commissionPayments = this.commissionShare.payments;
                this.totalPaidCommission = this.commissionPayments
                  .map((a) => a.paid)
                  .reduce(function (a, b) {
                    return a + b;
                  });
              }
            }
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              res.message,
              'Warning'
            );
          }
        },
        (error) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            this.appService.processHttpErrors(error),
            'Error!'
          );
        }
      );
  }
}
