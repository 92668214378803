<div class="card mt-3">
  <div class="card-body">
    <h4
      class="card-title text-uppercase mb-0 text-mtek d-inline"
      *ngIf="appService.hasPermission('admin-policy-upload-documents')"
    >
      <i class="fa fa-check-square-o fa-2x text-mtek h5"></i>
      Uploaded documents
    </h4>
    <hr />
    <div *ngIf="documents.length > 0; else noDocument">
      <div *ngFor="let document of documents; index as i" class="card mb-3">
        <div class="card-body">
          <div class="row">
            <div class="col-md-3">
              <p>
                <small class="text-muted d-block">Document Name</small>
                {{ document.documentName }}
              </p>
            </div>
            <div class="col-md-3">
              <p>
                <small class="text-muted d-block">Description</small>
                {{ document.description }}
              </p>
            </div>
            <div class="col-md-2">
              <p>
                <small class="text-muted d-block">Uploaded by</small>
                {{ document.uploadedBy }}
              </p>
            </div>
            <div class="col-md-2">
              <p>
                <small class="text-muted d-block">Uploaded on</small>
                {{ document._timestamp | date: 'medium' }}
              </p>
            </div>

            <div class="col-md-2">
              <button
                class="btn btn-info"
                (click)="previewDocument(document.docUrl, i)"
              >
                Show Document
              </button>
            </div>
          </div>
          <app-loader *ngIf="isLoading"></app-loader>

          <div
            class="row"
            *ngIf="fullUrl && showDocument && selectedIndex === i"
          >
            <div class="col-md-12">
              <div
                class="pdf-viewer-container"
                *ngIf="fileType === 'pdf'; else itsImage"
              >
                <pdf-viewer
                  [src]="fullUrl"
                  [render-text]="true"
                  style="display: block; height: 100vh; position: relative"
                ></pdf-viewer>
              </div>
              <ng-template #itsImage>
                <img
                  [src]="fullUrl"
                  class="img-fluid"
                  alt="{{ document.documentName }}"
                />
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #noDocument>
      <div class="alert alert-warning" role="alert">
        No Document has been uploaded for this user!
      </div>
    </ng-template>
  </div>
</div>
<!-- <pdf-viewer 
                        [src]="fullUrl"
                        [render-text]="true"
                        style="display: block;"
                      ></pdf-viewer> -->
