<div class="d-flex">
  <div class="header-btn-lg pr-0">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left">
          <div class="btn-group" ngbDropdown placement="bottom-right">
            <button type="button" class="btn btn-link p-0 mr-2" ngbDropdownToggle>
              <span>
                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                  <img width="42" src="./assets/images/mtek-icon.png" alt="" class="rounded-circle" />
                </div>
              </span>
            </button>
            <div class="dropdown-menu-lg" ngbDropdownMenu>
              <div class="dropdown-menu-header">
                <div class="dropdown-menu-header-inner bg-info">
                  <div class="menu-header-image opacity-2 dd-header-bg-6"></div>
                  <div class="menu-header-content text-left">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left mr-3">
                          <img width="42" src="./assets/images/mtek-icon.png" alt="" class="rounded-circle" />
                        </div>
                        <div class="widget-content-left">
                          <div class="widget-heading">
                            {{ appService.getSessionUser()?.userName }}
                          </div>
                          <div class="widget-subheading opacity-8">
                            {{ appService.getSessionUser()?.email }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="scroll-area-xs" *ngIf="appService.hasPermission('admin-menu-system-settings')">
                <perfect-scrollbar [autoPropagation]="true">
                  <ul class="nav flex-column">
                    <li class="nav-item-header nav-item">
                      System Settings</li>
                    <li class="nav-item" *ngIf="appService.hasPermission('admin-menu-view-motorPolicy-numbers')">
                      <a routerLink="/motor-policy-numbers/list" class="nav-link">
                        <i class="vsm-icon pe-7s-lock mr-2"></i><span class="vsm-title">Motor Policy Numbers</span></a>
                    </li>
                    <li class="nav-item" *ngIf="appService.hasPermission('admin-menu-view-dmvic-inventory')">
                      <a routerLink="/dmvic/list" class="nav-link">
                        <i class="vsm-icon pe-7s-lock mr-2"></i><span class="vsm-title">DMVIC Inventory</span></a>
                    </li>
                    <li class="nav-item" *ngIf="appService.hasPermission('admin-menu-view-roles-permissions')">
                      <a routerLink="/rbacs/list" class="nav-link">
                        <i class="vsm-icon pe-7s-lock mr-2"></i>
                        <span class="vsm-title">Roles & Permissions</span>
                      </a>
                    </li>
                    <!-- <li class="nav-item"
                      *ngIf="appService.hasPermission('admin-menu-view-referralcode-thirdparty-mapping')">
                      <a routerLink="/referral-thirdparty" class="nav-link">
                        <i class="vsm-icon pe-7s-link mr-2"></i>
                        <span class="vsm-title">Referral code & Third-party Mapping</span>
                      </a>
                    </li> -->
                    <!--  <li class="nav-item" *ngIf="appService.hasPermission('admin-menu-view-ntsa-payments')">
                      <a routerLink="/payments/ntsa" class="nav-link">
                        <i class="vsm-icon pe-7s-credit mr-2"></i>
                        <span class="vsm-title">NTSA Payments</span>
                      </a>
                    </li> -->
                    <!-- <li class="nav-item" *ngIf="appService.hasPermission('admin-menu-view-notifications')">
                      <a routerLink="/notifications/list" class="nav-link">
                        <i class="vsm-icon pe-7s-mail-open-file mr-2"></i>
                        <span class="vsm-title">Notifications</span>
                      </a>
                    </li>
                    <li class="nav-item" *ngIf="appService.hasPermission('admin-menu-view-promo-codes')">
                      <a routerLink="/promocodes/list" class="nav-link">
                        <i class="vsm-icon pe-7s-medal mr-2"></i>
                        <span class="vsm-title">Promo Codes</span>
                      </a>
                    </li> -->
                    <!--  <li class="nav-item" *ngIf="appService.hasPermission('admin-menu-view-asana-tasks')">
                      <a routerLink="/asanas/list" class="nav-link">
                        <i class="vsm-icon pe-7s-menu mr-2"></i>
                        <span class="vsm-title">Asana Tasks</span>
                      </a>
                    </li>
                    <li class="nav-item" *ngIf="appService.hasPermission('admin-menu-view-heatmap')">
                      <a routerLink="/heatmaps/display" class="nav-link">
                        <i class="vsm-icon pe-7s-map-2 mr-2"></i>
                        <span class="vsm-title">Heatmap</span>
                      </a>
                    </li>
                    <li class="nav-item" *ngIf="appService.hasPermission('admin-menu-view-commissions-life')">
                      <a routerLink="/commissions-life" class="nav-link">
                        <i class="fa fa-handshake-o fa-2x mr-2"></i>
                        <span class="vsm-title">Commissions Life</span>
                      </a>
                    </li> -->
                  </ul>
                </perfect-scrollbar>
              </div>
              <ul class="nav flex-column">
                <li class="nav-item-divider nav-item"></li>
                <li class="nav-item-btn text-center nav-item">
                  <button class="btn-wide btn btn-primary btn-sm" (click)="logout()">
                    Log Out
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="widget-content-left ml-3 header-user-info">
          <div class="widget-heading">
            {{ appService.getSessionUser()?.userName }}
          </div>
          <div class="widget-subheading">
            {{ appService.getSessionUser()?.email }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>