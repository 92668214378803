import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppEnums } from 'src/app/enums/app.enum';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-statutory-premiums',
  templateUrl: './statutory-premiums.component.html',
  styleUrls: ['./statutory-premiums.component.scss'],
})
export class StatutoryPremiumsComponent implements OnInit {
  @Input() selectedRecord: any;
  @Input() cover: string;
  @Output() reloadPolicyEvent = new EventEmitter<string>();
  @Output() statutoryTaxes = new EventEmitter<any>();
  @Output() statusUpdate = new EventEmitter<boolean>();
  check: boolean = false;
  statutoryPremiumFormGroup: FormGroup;
  statutoryPremiums = ['training levy', 'stamp duty', 'phpf'];
  isLoading: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService
  ) {
    this.statutoryPremiumFormGroup = this.formBuilder.group({
      taxes: new FormArray([]),
      totalStatutoryPremium: [
        this.selectedRecord?.premium?.totalStatutoryPremium,
      ],
    });
  }

  ngOnInit(): void {
    this.populateFormFields();
    this.statutoryPremiumFormGroup.patchValue({
      totalStatutoryPremium: [
        this.selectedRecord?.premium?.totalStatutoryPremium,
      ],
    });
  }
  createParticulars(): FormGroup {
    if (
      this.cover == 'motor' ||
      this.cover == 'travel' ||
      this.cover == 'evacuation' ||
      this.cover == 'other'
    ) {
      return this.formBuilder.group({
        name: ['', Validators.required],
        isAmount: [this.check],
        amount: [, Validators.required],
      });
    }
    return this.formBuilder.group({
      name: ['', Validators.required],
      isAmount: [this.check],
      percentage: [, Validators.required],
    });
  }
  get taxes(): FormArray {
    return this.statutoryPremiumFormGroup.get('taxes') as FormArray;
  }
  populateFormFields() {
    if (
      this.cover == 'motor' ||
      this.cover == 'travel' ||
      this.cover == 'evacuation' ||
      this.cover == 'other'
    ) {
      this.selectedRecord?.premium?.statutoryPremium?.forEach((taxes) => {
        let name = taxes?.name.toLowerCase();
        this.taxes.push(
          this.formBuilder.group({
            name: [name, Validators.required],
            isAmount: [taxes?.isAmount],
            amount: [taxes?.amount, Validators.required],
          })
        );
      });
    } else if (this.cover == 'medical') {
      this.selectedRecord?.inpatient?.statutoryPremiums?.forEach((taxes) => {
        let name = taxes?.name.toLowerCase();
        this.taxes.push(
          this.formBuilder.group({
            name: [name, Validators.required],
            isAmount: [taxes?.isAmount],
            percentage: [taxes?.percentage, Validators.required],
          })
        );
      });
    } else {
      this.selectedRecord?.premium?.statutoryPremium?.forEach((taxes) => {
        let name = taxes?.name.toLowerCase();
        this.taxes.push(
          this.formBuilder.group({
            name: [name, Validators.required],
            isAmount: [taxes?.isAmount],
            percentage: [taxes?.percentage, Validators.required],
          })
        );
      });
    }
  }
  addFormFields() {
    this.taxes.push(this.createParticulars());
  }
  removeForm(i: number) {
    this.taxes.removeAt(i);
  }
  onSubmit() {
    if (this.isFormValid() == true) {
      this.isLoading = true;
      switch (this.cover) {
        case 'motor':
          let statutoryPremium = this.statutoryPremiumFormGroup.value.taxes;
          let totalStatutoryPremium =
            this.statutoryPremiumFormGroup.value.totalStatutoryPremium;
          let motorData = {
            _id: this.selectedRecord?._id,
            update: {
              premium: {
                ...this.selectedRecord?.premium,
                statutoryPremium: statutoryPremium,
                totalStatutoryPremium: totalStatutoryPremium,
              },
            },
          };
          this.appService
            .makePostRequest(
              `${environment.MOTOR_API_URL}/adminupdatequote`,
              motorData
            )
            .subscribe(
              (res) => {
                if (res.Status == 200) {
                  this.appService.showToastMessage(
                    AppEnums.ToastTypeSuccess,
                    '',
                    res.Message
                  );
                  this.statusUpdate.emit(false);
                } else {
                  this.appService.showToastMessage(
                    AppEnums.ToastTypeError,
                    'Update unsuccessful',
                    res.Payload
                  );
                }
                this.isLoading = false;
              },
              (err) => {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  this.appService.processHttpErrors(err),
                  'Error'
                );
                this.isLoading = false;
              }
            );
          break;
        case 'medical':
          let medicalStatutoryPremium =
            this.statutoryPremiumFormGroup.value.taxes;
          let medicalData = {
            _id: this.selectedRecord?._id,
            update: {
              inpatient: {
                ...this.selectedRecord?.inpatient,
                statutoryPremiums: medicalStatutoryPremium,
              },
            },
          };
          this.appService
            .makePostRequest(
              `${environment.MEDICAL_API_URL}/adminupdatequotes`,
              medicalData
            )
            .subscribe(
              (res) => {
                if (res.Status == 200) {
                  this.appService.showToastMessage(
                    AppEnums.ToastTypeSuccess,
                    '',
                    res.Message
                  );
                  this.statusUpdate.emit(false);
                } else {
                  this.appService.showToastMessage(
                    AppEnums.ToastTypeError,
                    'Update unsuccessful',
                    res.Payload
                  );
                }
                this.isLoading = false;
              },
              (err) => {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  this.appService.processHttpErrors(err),
                  'Error'
                );
                this.isLoading = false;
              }
            );
          break;
        case 'domestic':
          let domesticStatutoryPremium =
            this.statutoryPremiumFormGroup.value.taxes;
          let domesticData = {
            _id: this.selectedRecord?._id,
            update: {
              premium: {
                ...this.selectedRecord?.premium,
                statutoryPremium: domesticStatutoryPremium,
              },
            },
          };
          this.appService
            .makePostRequest(
              `${environment.DOMESTIC_API_URL}/adminupdatequote`,
              domesticData
            )
            .subscribe(
              (res) => {
                if (res.Status == 200) {
                  this.appService.showToastMessage(
                    AppEnums.ToastTypeSuccess,
                    '',
                    res.Message
                  );
                  this.statusUpdate.emit(false);
                } else {
                  this.appService.showToastMessage(
                    AppEnums.ToastTypeError,
                    'Update unsuccessful',
                    res.Payload
                  );
                }
                this.isLoading = false;
              },
              (err) => {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  this.appService.processHttpErrors(err),
                  'Error'
                );
                this.isLoading = false;
              }
            );
          break;
        case 'evacuation':
          let evacuationStatutoryPremium =
            this.statutoryPremiumFormGroup.value.taxes;
          let evacuationData = {
            _id: this.selectedRecord?._id,
            update: {
              premium: {
                ...this.selectedRecord?.premium,
                statutoryPremium: evacuationStatutoryPremium,
              },
            },
          };
          this.appService
            .makePostRequest(
              `${environment.EVACUATION_API_URL}/adminupdatequote`,
              evacuationData
            )
            .subscribe(
              (res) => {
                if (res.Status == 200) {
                  this.appService.showToastMessage(
                    AppEnums.ToastTypeSuccess,
                    '',
                    res.Message
                  );
                  this.statusUpdate.emit(false);
                } else {
                  this.appService.showToastMessage(
                    AppEnums.ToastTypeError,
                    'Update unsuccessful',
                    res.Payload
                  );
                }
                this.isLoading = false;
              },
              (err) => {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  this.appService.processHttpErrors(err),
                  'Error'
                );
                this.isLoading = false;
              }
            );
          break;
        case 'golf':
          let golfStatutoryPremium = this.statutoryPremiumFormGroup.value.taxes;
          let golfData = {
            _id: this.selectedRecord?._id,
            update: {
              premium: {
                ...this.selectedRecord?.premium,
                statutoryPremium: golfStatutoryPremium,
              },
            },
          };
          this.appService
            .makePostRequest(
              `${environment.GOLF_API_URL}/adminupdatequote`,
              golfData
            )
            .subscribe(
              (res) => {
                if (res.Status == 200) {
                  this.appService.showToastMessage(
                    AppEnums.ToastTypeSuccess,
                    '',
                    res.Message
                  );
                  this.statusUpdate.emit(false);
                } else {
                  this.appService.showToastMessage(
                    AppEnums.ToastTypeError,
                    'Update unsuccessful',
                    res.Payload
                  );
                }
                this.isLoading = false;
              },
              (err) => {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  this.appService.processHttpErrors(err),
                  'Error'
                );
                this.isLoading = false;
              }
            );
          break;
        case 'personalaccident':
          let paStatutoryPremium = this.statutoryPremiumFormGroup.value.taxes;
          let paData = {
            _id: this.selectedRecord?._id,
            update: {
              premium: {
                ...this.selectedRecord?.premium,
                statutoryPremium: paStatutoryPremium,
              },
            },
          };
          this.appService
            .makePostRequest(
              `${environment.PA_API_URL}/adminupdatequote`,
              paData
            )
            .subscribe(
              (res) => {
                if (res.Status == 200) {
                  this.appService.showToastMessage(
                    AppEnums.ToastTypeSuccess,
                    '',
                    res.Message
                  );
                  this.statusUpdate.emit(false);
                } else {
                  this.appService.showToastMessage(
                    AppEnums.ToastTypeError,
                    'Update unsuccessful',
                    res.Payload
                  );
                }
                this.isLoading = false;
              },
              (err) => {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  this.appService.processHttpErrors(err),
                  'Error'
                );
                this.isLoading = false;
              }
            );
          break;
        case 'travel':
          let travelStatutoryPremium =
            this.statutoryPremiumFormGroup.value.taxes;
          let travelData = {
            _id: this.selectedRecord?._id,
            update: {
              premium: {
                ...this.selectedRecord?.premium,
                statutoryPremium: travelStatutoryPremium,
              },
            },
          };
          this.appService
            .makePostRequest(
              `${environment.TRAVEL_API_URL}/adminupdatequote`,
              travelData
            )
            .subscribe(
              (res) => {
                if (res.Status == 200) {
                  this.appService.showToastMessage(
                    AppEnums.ToastTypeSuccess,
                    '',
                    res.Message
                  );
                  this.statusUpdate.emit(false);
                } else {
                  this.appService.showToastMessage(
                    AppEnums.ToastTypeError,
                    'Update unsuccessful',
                    res.Payload
                  );
                }
                this.isLoading = false;
              },
              (err) => {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  this.appService.processHttpErrors(err),
                  'Error'
                );
                this.isLoading = false;
              }
            );
          break;
        case 'other':
          let otherStatutoryPremium =
            this.statutoryPremiumFormGroup.value.taxes;
          let otherData = {
            _id: this.selectedRecord?._id,
            update: {
              premium: {
                ...this.selectedRecord?.premium,
                statutoryPremium: otherStatutoryPremium,
              },
            },
          };
          this.appService
            .makePostRequest(
              `${environment.PA_API_URL}/adminotherupdatequote`,
              otherData
            )
            .subscribe(
              (res) => {
                if (res.Status == 200) {
                  this.appService.showToastMessage(
                    AppEnums.ToastTypeSuccess,
                    '',
                    res.Message
                  );
                  this.statusUpdate.emit(false);
                } else {
                  this.appService.showToastMessage(
                    AppEnums.ToastTypeError,
                    'Update unsuccessful',
                    res.Payload
                  );
                }
                this.isLoading = false;
              },
              (err) => {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  this.appService.processHttpErrors(err),
                  'Error'
                );
                this.isLoading = false;
              }
            );
          break;
        case 'gadget':
          let gadgetStatutoryPremium =
            this.statutoryPremiumFormGroup.value.taxes;
          let gadgetData = {
            _id: this.selectedRecord?._id,
            update: {
              premium: {
                ...this.selectedRecord?.premium,
                statutoryPremium: gadgetStatutoryPremium,
              },
            },
          };
          this.appService
            .makePostRequest(
              `${environment.DOMESTIC_API_URL}/adminupdategadgetquote`,
              gadgetData
            )
            .subscribe(
              (res) => {
                if (res.Status == 200) {
                  this.appService.showToastMessage(
                    AppEnums.ToastTypeSuccess,
                    '',
                    res.Message
                  );
                  this.statusUpdate.emit(false);
                } else {
                  this.appService.showToastMessage(
                    AppEnums.ToastTypeError,
                    'Update unsuccessful',
                    res.Payload
                  );
                }
                this.isLoading = false;
              },
              (err) => {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  this.appService.processHttpErrors(err),
                  'Error'
                );
                this.isLoading = false;
              }
            );
          break;
        default:
          break;
      }
    }
  }
  isFormValid(): boolean {
    if (this.statutoryPremiumFormGroup.invalid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeInfo,
        '',
        'Invalid Taxes Form'
      );
      return false;
    }
    return true;
  }
}
