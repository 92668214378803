<div class="card card-stats section1">
  <div class="card-body">
    <h4 class="card-title text-uppercase mb-0 text-mtek">
      <strong><i class="fa fa-settings-o fa-2x text-mtek h5"></i></strong>
      Policy Options
    </h4>
    <hr />

    <div class="row">
      <div class="col-sm-12">
        <button (click)="fileClaim()" mat-button *ngIf="
            appService.isAdminOrThirdParty() ||
            appService.isAdminOrPartner()
          ">
          <i class="fa fa-clipboard"></i> File claim
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <button (click)="popSendDeclarationReportDialog()" mat-button *ngIf="
            appService.isAdminOrPartner() || appService.isAdminOrThirdParty()
          ">
          <i class="fa fa-download"></i> Get Report
        </button>
      </div>
    </div>
    <div class="row" *ngIf="appService.isAdmin() && isIceaMotor">
      <div class="col-sm-12">
        <button mat-button (click)="popDeclereIceaRiskNoteDialog()">
          <i class="fa fa-folder"></i> ICEA Declaration
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <button (click)="updatePolicyComponent()" mat-button *ngIf="appService.isAdmin()">
          <i class="fa fa-pencil-square-o"></i> Update Policy
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <button (click)="popUploadGroupMembers()" mat-button *ngIf="appService.isAdmin()">
          <i class="fa fa-users"></i> Upload Group Members
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <button (click)="popAddMembershipInfo()" mat-button *ngIf="appService.isAdmin() && cover == 'medical'">
          <i class="fa fa-users"></i> Upload Member(s) Card Info
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <button (click)="popCancelPolicyDialog()" mat-button *ngIf="appService.isAdmin()">
          <i class="fa fa-ban"></i> Cancel Policy
        </button>
      </div>
    </div>
  </div>
</div>