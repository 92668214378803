import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AppEnums } from "src/app/enums/app.enum";
import { AppService } from "src/app/services/app.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-delete-credit-rating",
  templateUrl: "./delete-credit-rating.component.html",
  styleUrls: ["./delete-credit-rating.component.scss"],
})
export class DeleteCreditRatingComponent implements OnInit {
  deleteCreditForm: FormGroup;
  isLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeleteCreditRatingComponent>
  ) {}

  delete() {
    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.CORE_API_URL}/removecreditratingreview`, {
        _id: this.data._id,
      })
      .subscribe(
        (res) => {
          this.isLoading = false;
          if (res.Status === 200) {
            this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              "",
              "Success"
            );
            this.dialogRef.close();
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              "Something went wrong",
              "Unable to get the remove review list"
            );
          }
        },
        (error) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            "",
            this.appService.processHttpErrors(error)
          );
        }
      );
  }

  ngOnInit(): void {}
}
