<form [formGroup]="clientFormGroup" class="mt-2">
  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
        <label for="userName">Name</label>
        <input class="form-control" type="text" formControlName="userName" placeholder="Client Name" />
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="email">Email Address</label>
        <input class="form-control" type="email" formControlName="email" placeholder="Email Address" />
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="phoneNumber">Phone</label>
        <input class="form-control" type="text" formControlName="phoneNumber" placeholder="Phone Number" />
      </div>
    </div>
    <div class="col-md-4">
      <label for="gender">Gender</label>
      <select class="custom-select" formControlName="gender" id="gender" required>
        <option *ngFor="let gender of genders" [value]="gender.value">
          {{ gender.name }}
        </option>
      </select>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="kraPin">KRA PIN</label>
        <input class="form-control" type="text" formControlName="kraPin" placeholder="KRA PIN" />
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="idNumber">ID Number</label>
        <input class="form-control" type="text" formControlName="idNumber" placeholder="ID Number" />
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label for="passportNumber">Passport Number</label>
        <input class="form-control" type="text" formControlName="passportNumber" placeholder="Passport Number" />
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label for="physicaladdress">Physical Address</label>
        <input class="form-control" type="text" formControlName="physicaladdress" placeholder="Physical Address" />
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="postaladdress">Postal Address</label>
        <input class="form-control" type="text" formControlName="postaladdress" placeholder="Postal Address" />
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="postalcode">Postal Code</label>
        <input class="form-control" type="text" formControlName="postalcode" placeholder="Postal Code" />
      </div>
    </div>
    <div class="col-md-8">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="multiUploadFormGroup" class="mt-2">
            <div class="form-group">
              <label for="fileObj">Select File to Upload</label>
              <select class="custom-select" formControlName="fileObj" (change)="onFileObjChange($event.target.value)"
                id="fileObj">
                <option *ngFor="let afileObj of clientFiles; let i = index" [value]="i">
                  {{ afileObj.name }}
                </option>
              </select>
            </div>
            <app-file-upload [allowedFileExtentions]="allowedFileExtentions" [azureFileLocation]="azureFileLocation"
              [azureFileName]="activeFileName" (emitFileUploadedEvent)="uploadEvent($event)">
            </app-file-upload>
          </form>
        </div>
      </div>
    </div>
  </div>
</form>
<button mat-raised-button color="primary" (click)="validateClient()" class="m-3" matStepperNext>
  Next
</button>
<button mat-raised-button color="primary" (click)="updateClient()" class="m-3" matStepperNext
  *ngIf="currentRecordQuestionsId">
  Update
</button>
<app-loader *ngIf="isLoading"></app-loader>