import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppEnums } from 'src/app/enums/app.enum';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
export interface Idata {
  _id: string;
  product: string;
}

@Component({
  selector: 'app-cancel-finance-plan',
  templateUrl: './cancel-finance-plan.component.html',
  styleUrls: ['./cancel-finance-plan.component.scss'],
})
export class CancelFinancePlanComponent implements OnInit {
  cancelPlanFormGroup: FormGroup;
  isLoading: boolean = false;

  constructor(
    private appService: AppService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Idata,
    public dialogRef: MatDialogRef<CancelFinancePlanComponent>
  ) {
    this.cancelPlanFormGroup = formBuilder.group({
      reason: new FormControl('', Validators.compose([Validators.required])),
    });
  }

  ngOnInit(): void {}

  submitCancellation() {
    if (!this.cancelPlanFormGroup.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Cancellation Reason is Required'
      );
      return;
    }
    let headers = {
      received_underscore_id: this.data._id,
      product: this.data.product,
      isCancellation: true,
      amount: 0,
      cancellation: {
        reason: this.cancelPlanFormGroup.get('reason').value,
        feduid: this.appService.getSessionUser()?.feduid,
        _timestamp: new Date().getTime().toString(),
      },
    };

    this.appService
      .makePostRequest(`${environment.IPF_API_URL}/adjustFinancePlan`, headers)
      .subscribe((res) => {
        this.isLoading = true;
        if (res.Status === 200) {
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            '',
            'Success'
          );
          this.isLoading = false;
          this.dialogRef.close();
          window.location.reload();
        } else {
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            'Ooops!',
            'Something went wrong'
          );
        }
      });
  }

  closeDialog() {
    this.dialogRef.close('Closed');
    window.location.reload();
  }
}
