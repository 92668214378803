import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';
import { UpdateNokDetailsComponent } from '../update-nok-details/update-nok-details.component';

@Component({
  selector: 'app-display-nok-details',
  templateUrl: './display-nok-details.component.html',
  styleUrls: ['./display-nok-details.component.scss'],
})
export class DisplayNokDetailsComponent implements OnInit {
  @Input() client: any;
  @Input() selectedRecordQuestionsId: string;
  @Input() adminReports: any;
  @Input() cover: string;
  @Output() reloadPolicyEvent = new EventEmitter();
  constructor(public dialog: MatDialog, public appService: AppService) {}

  ngOnInit(): void {
    this.prepareClient();
  }

  prepareClient() {
    if (this.adminReports) {
      if (this.adminReports.hasOwnProperty('update')) {
        if (this.adminReports?.update?.moreQuestions?.client) {
          this.client = this.adminReports?.update?.moreQuestions?.client;
        }
      }
    }
  }

  popUpdateNokInformation() {
    let dialogRef = this.dialog.open(UpdateNokDetailsComponent, {
      width: '50%',
      data: {
        selectedRecordQuestionsId: this.selectedRecordQuestionsId,
        client: this.client,
        cover: this.cover,
      },
    });
    dialogRef.afterClosed().subscribe((selectedRecordQuestionsId) => {
      this.reloadPolicyEvent.emit(selectedRecordQuestionsId);
    });
  }
}
