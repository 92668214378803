import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppEnums } from 'src/app/enums/app.enum';
import { IContent, IDomestic } from 'src/app/models/domestic.interface';
import { IGalleryImage } from 'src/app/models/gallery-image.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import { IHouse } from 'src/app/models/house.interface';

@Component({
  selector: 'app-domestic-list-details',
  templateUrl: './domestic-list-details.component.html',
  styleUrls: ['./domestic-list-details.component.scss'],
})
export class DomesticListDetailsComponent implements OnInit {
  selectedRecord: IDomestic;
  feduid: string;
  selectedRecordId: string;
  contents: IContent[];
  allRisk: IContent[];
  pets: any[];
  domesticQuestions: any[] = [];
  moreQuestions: any;
  client: any;
  company: any;
  questions: any;
  startDate: any;
  allDataLoaded: boolean = false;
  sasToken: string;
  product: string = 'domestic';
  sheduledPaymentPlans: any;

  imagesArray: IGalleryImage[] = [
    { imageName: 'Front View', fieldName: 'frontImage', url: '' },
    { imageName: 'Left View', fieldName: 'leftSideImage', url: '' },
    { imageName: 'Back View', fieldName: 'backImage', url: '' },
    { imageName: 'Right View', fieldName: 'rightSideImage', url: '' },
  ];
  house: any;

  constructor(
    public appService: AppService,
    private activeRoute: ActivatedRoute
  ) {
    this.activeRoute.params?.subscribe((p) => {
      this.selectedRecordId = p['_id'];
      this.getDomesticQuoteOrPolicy(this.selectedRecordId);
    });
  }

  ngOnInit(): void {
    this.appService.getSasToken()?.subscribe((sasToken) => {
      this.sasToken = sasToken;
    });
  }

  getDomesticQuoteOrPolicy(selectedPolicyId: string) {
    this.selectedRecord = null;
    this.allDataLoaded = false;
    let data = {
      pipeline: [
        {
          $match: {
            // This is the query to the quotes you want to display
            _id: selectedPolicyId,
          },
        },
        {
          $lookup: {
            from: 'domestic_questions',
            localField: 'questions_id',
            foreignField: '_id',
            as: 'questions',
          },
        },
        {
          $addFields: {
            questions: {
              $first: '$questions',
            },
          },
        },
        {
          $project: {
            // Limits the fields you want. To get everything remove the section.
            _id: 1,
            feduid: 1,
            stage: 1,
            client: 1,
            amount: 1,
            insurer: 1,
            policy: 1,
            quotePackage: 1,
            'questions.moreQuestions.client': 1,
            'questions.moreQuestions.company': 1,
            'questions.moreQuestions.startDate': 1,
            'questions.moreQuestions.endDate': 1,
            'questions.questions': 1,
            'questions.historyQuestions': 1,
            'questions.moreQuestions.domesticImages': 1,
            'questions.moreQuestions.domesticHouseContentModelList': 1,
            'questions.feduid': 1,
            'questions.startDate': 1,
            'questions.endDate': 1,
            sme: 1,
            _timestamp: 1,
            _utimestamp: 1,
            paymentdetails: 1,
            pdfs: 1,
            coverLimit: 1,
            cancellation: 1,
            commission: 1,
            salesDateTimestamp: 1,
            salesDate: 1,
            quotation: 1,
            _udate: 1,
            questions_id: 1,
            paidamount: 1,
            operation: 1,
            insurerName: 1,
            remittance: 1,
            quoteId: 1,
            quote: 1,
            benefits: 1,
            addons: 1,
            policyDocUrl: 1,
            aggregatedPremiums: 1,
            clientid: 1,
            agentfeduid: 1,
            agentname: 1,
            agentemail: 1,
            agentphone: 1,
            agencyid: 1,
            _date: 1,
            starttimestamp: 1,
            endtimestamp: 1,
            lastPaymentTimestamp: 1,
            policyNumber: 1,
            group: 1,
            uwname: 1,
            pdfLinks: 1,
            errors: 1,
            premium: 1,
            policySchedule: 1,
            ModuleID: 1,
          },
        },
      ],
      'client.email': { $exists: true },
    };
    this.appService
      .makePostRequest(`${environment.DOMESTIC_API_URL}/adminaggregate`, data)
      ?.subscribe((domestic) => {
        let p: IDomestic[] = [];

        if (domestic.Payload.constructor.name == 'Array') {
          p = domestic.Payload;
        } else {
          p.push(domestic.Payload);
        }

        this.selectedRecord = p[0];
        this.feduid = this.selectedRecord?.feduid;
        this.contents = this.selectedRecord?.policy?.contents;
        this.pets = this.selectedRecord?.policy?.pets;
        this.allRisk = this.selectedRecord.policy.allRiskItems;

        if (this.selectedRecord.hasOwnProperty('paymentdetails')) {
          if (
            this.selectedRecord.paymentdetails.hasOwnProperty('moreDetails')
          ) {
            this.sheduledPaymentPlans =
              this.selectedRecord?.paymentdetails.moreDetails.schedule.sort(
                (a, b) => {
                  return b.date - a.date;
                }
              );
          }
        }

        // Questions
        this.questions = this.selectedRecord?.questions;
        this.startDate = this.selectedRecord?.questions?.startDate;
        if (this.questions?.hasOwnProperty('questions')) {
          let q =
            this.questions?.questions?.questions || this.questions?.questions;
          this.domesticQuestions = [...q];
          this.domesticQuestions = q;
        }

        if (this.questions?.hasOwnProperty('moreQuestions')) {
          let moreQuestions = this.questions['moreQuestions'];

          if (moreQuestions.hasOwnProperty('domesticImages')) {
            let domesticImages = moreQuestions['domesticImages'];
            this.prepareHouseImages(domesticImages);
          }

          if (moreQuestions.hasOwnProperty('client')) {
            this.client = moreQuestions?.client;
          }
          if (moreQuestions.hasOwnProperty('company')) {
            this.company = moreQuestions?.company;
          }
          if (
            this.selectedRecord.questions.moreQuestions.hasOwnProperty(
              'startDate'
            )
          ) {
            this.startDate =
              this.selectedRecord.questions.moreQuestions.startDate;
          }
        }
        this.allDataLoaded = true;
      });
    (err) => {
      this.allDataLoaded = true;
      this.appService.showToastMessage(
        AppEnums.ToastTypeError,
        '',
        this.appService.processHttpErrors(err)
      );
    };
  }

  prepareHouseImages(house: IHouse) {
    for (let index = 0; index < this.imagesArray.length; index++) {
      const anImage = this.imagesArray[index];
      if (house.hasOwnProperty(anImage?.fieldName)) {
        anImage;
        this.imagesArray[index]['url'] =
          house[anImage?.fieldName] + this.sasToken;
      }
    }
  }

  reloadImageUrls($event) {
    this.prepareHouseImages(this.house);
  }
}
