import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AppEnums } from 'src/app/enums/app.enum';
import { ApiResponse } from 'src/app/models/api-response.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import { PolicyOptionsComponent } from '../policy-options/policy-options.component';

@Component({
  selector: 'app-upload-policy-group-members',
  templateUrl: './upload-policy-group-members.component.html',
  styleUrls: ['./upload-policy-group-members.component.scss'],
})
export class UploadPolicyGroupMembersComponent implements OnInit {
  @Input() receivedId: string;
  groupMembers: any;
  isLoading: boolean = false;

  constructor(
    private appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PolicyOptionsComponent>
  ) {}

  ngOnInit(): void {}

  getGroupInfo($event) {
    this.groupMembers = {
      group: $event,
    };
    this.addGroupMembers().subscribe(
      (res) => {
        this.isLoading = true;
        if (res.Status == 200) {
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            'Group members added',
            'Success'
          );
          this.dialogRef.close();
        } else {
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            res.Message,
            'Warning'
          );
        }
      },
      (error) => {
        this.appService.showToastMessage(
          AppEnums.ToastTypeError,
          this.appService.processHttpErrors(error),
          'Error!'
        );
      }
    );
  }
  addGroupMembers(): Observable<ApiResponse> {
    let cover: string = this.data?.cover;
    let policyDetails = this.data?.policy;
    let policyId: string = policyDetails?._id;
    let data: any;

    if (policyDetails.hasOwnProperty('group')) {
      if (cover == 'travel') {
        data = {
          _id: policyId,
          update: this.groupMembers,
        };
      } else {
        delete policyDetails.group;
        data = {
          _id: policyId,
          update: this.groupMembers,
        };
      }
    } else {
      data = {
        _id: policyId,
        update: this.groupMembers,
      };
    }
    let env: string = '';
    let endpoint: string = '';
    switch (cover) {
      case 'domestic':
        env = environment.DOMESTIC_API_URL;
        endpoint = 'adminupdatequote';
        break;
      case 'evacuation':
        env = environment.EVACUATION_API_URL;
        endpoint = 'adminupdatequote';
        break;
      case 'golf':
        env = environment.GOLF_API_URL;
        endpoint = 'adminupdatequote';
        break;
      case 'life':
        env = environment.LIFE_API_URL;
        endpoint = 'adminupdatequote';
        break;
      case 'medical':
        env = environment.MEDICAL_API_URL;
        endpoint = 'adminupdatequotes';
        break;
      case 'motor':
        env = environment.MOTOR_API_URL;
        endpoint = 'adminupdatequote';
        break;
      case 'pa':
        env = environment.PA_API_URL;
        endpoint = 'adminupdatequote';
        break;
      case 'travel':
        env = environment.TRAVEL_API_URL;
        endpoint = 'adminupdatequote';
        break;
      case 'unisure':
        env = environment.MEDICAL_API_URL;
        endpoint = 'unisureadminupdatequotes';
        break;
      case 'other':
        env = environment.PA_API_URL;
        endpoint = 'adminotherupdatequote';
        break;
      default:
        return;
    }
    return this.appService.makePostRequest(`${env}/${endpoint}`, data);
  }
}
