<div>
  <app-loader *ngIf="isLoading"></app-loader>
  <form [formGroup]="statutoryPremiumFormGroup" (submit)="onSubmit()">
    <ng-container formArrayName="taxes">
      <div
        class="row"
        *ngFor="let tax of taxes.controls; let i = index"
        [formGroupName]="i"
      >
        <div class="col-md-3">
          <div class="form-group">
            <label for="chassisNumber"
              >Name <span class="text-danger">*</span></label
            >
            <select class="form-control" formControlName="name" id="name">
              <option *ngFor="let type of statutoryPremiums" [value]="type">
                {{ type | titlecase }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <label for="chassisNumber"
            >IsAmount <span class="text-danger">*</span></label
          >
          <div class="form-check">
            <input
              class="form-check-input"
              [checked]="checked"
              type="checkbox"
              formControlName="isAmount"
              id="defaultCheck1"
            />
            <label class="form-check-label" for="defaultCheck1">
              Is Amount
            </label>
          </div>
        </div>
        <div class="col-md-3">
          <div
            class="form-group"
            *ngIf="
              cover == 'motor' ||
                cover == 'travel' ||
                cover == 'evacuation' ||
                cover == 'other';
              else noMotor
            "
          >
            <label for="chassisNumber"
              >Amount <span class="text-danger">*</span></label
            >
            <input
              type="number"
              name="amount"
              id="amount"
              class="form-control"
              formControlName="amount"
            />
          </div>
          <ng-template #noMotor>
            <div class="form-group">
              <label for="chassisNumber"
                >Percentage <span class="text-danger">*</span></label
              >
              <input
                type="number"
                name="percentage"
                id="percentage"
                min="0"
                max="100"
                class="form-control"
                formControlName="percentage"
              />
            </div>
          </ng-template>
        </div>
        <div class="col-md-3 delete">
          <i
            type="button"
            class="fa fa-trash"
            aria-hidden="true"
            (click)="removeForm(i)"
          ></i>
        </div>
      </div>
    </ng-container>
    <div class="row" *ngIf="cover == 'motor'">
      <div class="col-md-4">
        <label for="totalStatutoryPremium"
          >Total Statutory Premium <span class="text-danger">*</span></label
        >
        <input
          type="number"
          name="totalStatutoryPremium"
          id="totalStatutoryPremium"
          class="form-control"
          formControlName="totalStatutoryPremium"
        />
      </div>
    </div>
  </form>
  <p class="buttons d-flex justify-content-between align-items-center">
    <button class="btn btn-outline-primary mr-3" (click)="addFormFields()">
      Add Form
    </button>
    <button
      class="btn btn-outline-success"
      *ngIf="isLoading == false"
      (click)="onSubmit()"
    >
      Submit
    </button>
  </p>
</div>
