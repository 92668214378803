import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-date-range-picker-dashboard',
  templateUrl: './date-range-picker-dashboard.component.html',
  styleUrls: ['./date-range-picker-dashboard.component.scss'],
})
export class DateRangePickerDashboardComponent implements OnInit {
  startDate = new Date();
  endDate = new Date();
  formGroupDatePicker: FormGroup;
  @Output() dateRange = new EventEmitter<{}>();

  constructor(private formBuilder: FormBuilder) {
    this.startDate.setDate(new Date().getDate() - 30);
    this.formGroupDatePicker = this.formBuilder.group({
      startDate: new FormControl(
        this.startDate,
        Validators.compose([Validators.required])
      ),
      endDate: new FormControl(
        this.endDate,
        Validators.compose([Validators.required])
      ),
    });
  }

  ngOnInit(): void {}

  updateRate() {
    if (this.formGroupDatePicker.value.startDate)
      this.startDate = this.formGroupDatePicker.value.startDate;
    if (this.formGroupDatePicker.value.endDate)
      this.endDate = this.formGroupDatePicker.value.endDate;
    if (this.endDate.getTime() < this.startDate.getTime()) {
      this.formGroupDatePicker.patchValue({
        startDate: this.endDate,
        endDate: this.startDate,
      });
    }

    this.dateRange.emit({
      end_date: this.endDate,
      start_date: this.startDate,
    });
  }
}
