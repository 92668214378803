import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SearchComponent } from 'src/app/components/search/search.component';
import { AppEnums } from 'src/app/enums/app.enum';
import {
  IPagination,
  IPatapesaPagination,
} from 'src/app/models/pagination.interface';
import { IUser } from 'src/app/models/user.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-client-partial',
  templateUrl: './client-partial.component.html',
  styleUrls: ['./client-partial.component.scss'],
})
export class ClientPartialComponent implements OnInit {
  @ViewChild(SearchComponent) search: SearchComponent;
  @Input() feduid: string;
  userDetails: IUser;
  valuerDetails: IUser;
  assessorDetails: IUser;
  referralDetails: IUser[] = [];
  isLoading: boolean = false;
  sasToken: string;
  userRoles: string[] = [];
  showReferralList: boolean = false;
  referralCode: any;
  referrerCode: any;

  dataLoading: boolean = false;
  dataLength: number = 0;
  isSearchData: boolean = false;
  allDataLoaded: boolean = false;

  pagination: IPatapesaPagination = {
    limit: 10,
    skip: 0,
    sort: { 'bimaProfile.balance': -1 },
  };
  bPpagination: IPatapesaPagination = {
    limit: 10,
    skip: 0,
    sort: { 'bimaProfile.balance': -1 },
  };

  searchParams = {
    url: `${environment.CORE_API_URL}/listUsers`,
    searchFields: ['userName', 'email', 'phoneNumber', 'idNumber', 'kraPin'],
    customQuery: {
      roles: { $elemMatch: { client: true } },
      fields: [
        '_id',
        'feduid',
        'insurer',
        'userName',
        'email',
        'phoneNumber',
        'idNumber',
        'kraPin',
        '_timestamp',
        'bimaProfile',
      ],
      pagination: this.pagination,
    },
  };

  userWalletBalance: number = 0;
  userWalletTransactions: any[] = [];
  walletPagination: IPagination = {
    limit: 10,
    skip: 0,
    sort: {
      _timestamp: -1,
    },
  };
  walletDataLength: number = 0;
  walletSearchData: boolean = false;
  searchItem: string = '';
  transactionsSearchParams = {};

  constructor(
    public appService: AppService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.appService.getSasToken().subscribe((sasToken) => {
      this.sasToken = sasToken;
    });
    this.prepareTransactionSearchParams();
    this.getUserDetailsByFeduid();
  }

  viewAttachment(attachmentUrl: string) {
    if (attachmentUrl) {
      let url = `${attachmentUrl}${this.sasToken}`;
      window.open(url, '_blank');
    }
  }

  get getKyoskReferralLink() {
    let promo = 'KSK-001';
    if (this.userDetails.promo) {
      promo = this.userDetails.promo;
    }
    return `${environment.KYOSK_WEB_URL}?referral=${promo}`;
  }

  prepareTransactionSearchParams() {
    this.transactionsSearchParams = {
      url: `${environment.MPESA_API_URL}/adminGetWalletTransactions`,
      searchFields: [
        'amount',
        'status',
        'transactionType',
        'transactionDirection',
        'MpesaReceiptNumber',
      ],
      customQuery: {
        feduid: this.feduid,
        fields: [
          '_id',
          'MerchantRequestID',
          'CheckoutRequestID',
          'amount',
          'status',
          'feduid',
          'transactionType',
          'transactionDirection',
          'clientid',
          'MpesaReceiptNumber',
          '_date',
          '_timestamp',
        ],
        pagination: this.walletPagination,
      },
    };
  }

  getUserDetailsByFeduid() {
    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.CORE_API_URL}/getUserDetailsByFeduid`, {
        feduid: this.feduid,
        fields: [],
      })
      .subscribe(
        (apiResponse) => {
          if (apiResponse.Status == 200) {
            this.userDetails = apiResponse.Payload;
            this.userRoles = this.appService.getRoles(this.userDetails?.roles);
            let principleId = this.userDetails?._id;
            //this.getValuerDetails(principleId);

            let principleFeduid = this.userDetails?.principleFeduid;
            // this.getAssessorDetails(principleFeduid);

            this.referralCode = this.userDetails?.bimaProfile?.referralCode;
            this.referrerCode = this.userDetails?.bimaProfile?.referrerCode;
            this.fetchUsers();
          } else {
            this.isLoading = false;
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              apiResponse.Status + '',
              apiResponse.Payload
            );
          }
        },
        (err) => {
          this.isLoading = true;
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
        }
      );
  }

  getAssessorDetails(principleFeduid: string) {
    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.CLAIMS_API_URL}/listassessor`, {
        principleFeduid: principleFeduid,
      })
      .subscribe(
        (apiResponse) => {
          if (apiResponse.Status == 200) {
            this.assessorDetails = apiResponse.Payload[0];
          } else {
            this.isLoading = false;
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              apiResponse.Status + '',
              apiResponse.Payload
            );
          }
        },
        (err) => {
          this.isLoading = true;
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
        }
      );
  }

  getValuerDetails(principleId: string) {
    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.VALUATION_API_URL}/listvaluer`, {
        principle_id: principleId,
      })
      .subscribe(
        (apiResponse) => {
          if (apiResponse.Status == 200) {
            this.valuerDetails = apiResponse.Payload[0];
          } else {
            this.isLoading = false;
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              apiResponse.Status + '',
              apiResponse.Payload
            );
          }
        },
        (err) => {
          this.isLoading = true;
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
        }
      );
  }

  toggleReferralList() {
    this.showReferralList = !this.showReferralList;
  }

  fetchUsers() {
    this.showReferralList = !this.showReferralList;
    this.dataLoading = true;
    let body = {
      roles: { $elemMatch: { client: true } },
      fields: [
        '_id',
        'feduid',
        'insurer',
        'userName',
        'email',
        'phoneNumber',
        'idNumber',
        'kraPin',
        '_timestamp',
        'bimaProfile',
      ],
      pagination: this.pagination,
      'bimaProfile.referrerCode': this.referralCode,
    };
    this.appService
      .makePostRequest(`${environment.CORE_API_URL}/listUsers`, body)
      .subscribe(
        (res) => {
          this.referralDetails = res.Payload.sort((a, b) => {
            return b.bimaProfile?.balance - a.bimaProfile?.balance;
          });
          this.allDataLoaded = true;
          this.dataLoading = false;
          this.dataLength = this.referralDetails.length;
        },
        (err) => {
          this.allDataLoaded = true;
          this.dataLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
        }
      );
  }

  goToProfile(feduid: string) {
    this.router.navigate(['/clients/list-client-details', feduid]);
  }

  showSearchResults(results) {
    this.isSearchData = true;
    this.dataLength = results.length;
    this.referralDetails = results;
  }

  updateSkip(newSkip) {
    if (newSkip) {
      this.bPpagination.skip = newSkip;
      this.fetchUsers();
    }
  }

  updateSearchSkip(newSkip) {
    if (newSkip) {
      this.pagination.skip = newSkip;
      this.search.search(this.searchParams);
    }
  }

  resetData() {
    this.isSearchData = false;
    this.pagination.skip = 0;
    this.fetchUsers();
  }
}
