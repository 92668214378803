import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IContent, IDomestic } from 'src/app/models/domestic.interface';
import { StatutoryPremiumsComponent } from 'src/app/partials/statutory-premiums/statutory-premiums.component';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-domestic-quote-details',
  templateUrl: './domestic-quote-details.component.html',
  styleUrls: ['./domestic-quote-details.component.scss'],
})
export class DomesticQuoteDetailsComponent implements OnInit {
  @Input() selectedRecord: IDomestic;
  @Input() imagesArray: any;
  @Input() contents: IContent;
  @Input() allRisk: IContent;
  @Input() pets: any;
  @Input() moreQuestions: any;
  @Input() startDate: any;
  sasToken: string;
  cover: string = 'domestic';
  client: any;
  editCoverDetails: boolean = false;
  company: any;
  @Output() emitReFetchPolicyEvent = new EventEmitter<string>();
  groupMembers: any[] = [];

  constructor(public appService: AppService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.appService.getSasToken().subscribe((sasToken) => {
      this.sasToken = sasToken;
    });
    this.prepareGroupMembersInfo();
  }

  reloadPolicy() {
    this.emitReFetchPolicyEvent.emit(this.selectedRecord?._id);
  }
  formatStartDate(dateString: string) {
    if (dateString) {
      let dateArray = dateString.split('/');
      return `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`;
    }
    return '1990-01-01';
  }

  viewAttachment(attachmentUrl: string) {
    if (attachmentUrl) {
      let url = `${attachmentUrl}${this.sasToken}`;
      window.open(url, '_blank');
    }
  }
  prepareGroupMembersInfo() {
    if (this.selectedRecord?.group) {
      this.groupMembers = this.selectedRecord?.group.members;
    }
  }
  openEditCoverDetailsForm() {
    this.editCoverDetails = true;
  }
  openCoverDetails($event: boolean) {
    this.editCoverDetails = $event;
  }
  sanitaizePackageName(packageName: string): string {
    let name = packageName.replace('_', ' ');
    return name;
  }
}
