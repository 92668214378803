import {
  trigger,
  transition,
  style,
  animate,
  keyframes,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppEnums } from 'src/app/enums/app.enum';
import { IpfProvider } from 'src/app/enums/ipf.provider';
import { MatatuSaccoEnum } from 'src/app/enums/matatu-sacco.enum.enum';
import { AppService } from 'src/app/services/app.service';
import { ThemeOptions } from '../base-layout/theme-options';

export interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}
export interface IPolicyMenuItem {
  link: string;
  policyName: string;
}
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('350ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('350ms', style({ opacity: 0 })),
      ]),
    ]),
    trigger('rotate', [
      transition(':enter', [
        animate(
          '1000ms',
          keyframes([
            style({ transform: 'rotate(0deg)', offset: '0' }),
            style({ transform: 'rotate(2turn)', offset: '1' }),
          ])
        ),
      ]),
    ]),
  ],
})
export class SidenavComponent implements OnInit {
  @Output() onToggleSideNav: EventEmitter<SideNavToggle> = new EventEmitter();
  public extraParameter: any;
  policyMenuItems: IPolicyMenuItem[] = [];
  claimMenuItems: IPolicyMenuItem[] = [];
  thirdPartyMenuItems: IPolicyMenuItem[] = [];
  multiple: boolean = false;
  public isIpfProvider: boolean = false;
  public isMatatuSaccoMember: boolean = false;

  collapsed = true;
  screenWidth = 0;
  navData: any = [];

  constructor(
    public appService: AppService,
    private router: Router,
    public globals: ThemeOptions,
    private store: Store<any>
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 768) {
      this.collapsed = false;
      this.onToggleSideNav.emit({
        collapsed: this.collapsed,
        screenWidth: this.screenWidth,
      });
    }
  }

  ngOnInit(): void {
    this.setisIpfProvider();
    this.checkMatatuSaccoMembers();
    this.setPolicyProducts();
    this.setClaimsProducts();
    this.screenWidth = window.innerWidth;
    this.navData = [
      {
        link: '',
        icon: 'vsm-icon pe-7s-graph2',
        policyName: 'Dashboard',
        roles: this.appService.hasPermission('admin-dashboard'),
      },
      {
        link: '/users',
        icon: 'vsm-icon pe-7s-user',
        policyName: 'Users',
        roles: this.appService.hasPermission('admin-menu-users'),
      },
      {
        icon: 'vsm-icon pe-7s-umbrella',
        policyName: 'Policies',
        roles: this.appService.hasPermission('admin-select-policies'),
        items: this.policyMenuItems,
      },
      {
        icon: 'vsm-icon pe-7s-target',
        policyName: 'Leads',
        link: '/leads',
        roles: this.appService.hasPermission('admin-menu-view-leads'),
      },
      {
        icon: 'vsm-icon pe-7s-user',
        policyName: 'Underwriter Contacts',
        link: '/underwriter-contacts',
        roles: this.appService.hasPermission('admin-view-underwriter-contacts'),
      },
      /* {
        link: '/patapesa/list-patapesa-agents',
        icon: 'vsm-icon pe-7s-user',
        policyName: 'Pata Pesa Agents',
        roles: this.appService.hasPermission('admin-menu-patapesa-agents'),
      }, */
      /* {
        link: '/referrals',
        icon: 'vsm-icon pe-7s-user',
        policyName: 'Referrals',
        roles: this.appService.hasPermission('admin-menu-referrals'),
      }, */

      {
        icon: 'vsm-icon pe-7s-note2',
        policyName: 'Claims',
        roles: this.appService.hasPermission('admin-menu-claims'),
        items: this.claimMenuItems,
      },
      /* {
        icon: 'vsm-icon pe-7s-note2',
        policyName: 'Valuation',
        roles: this.appService.hasPermission('admin-menu-valuations'),
        items: [
          {
            link: '/valuations/summary',
            policyName: 'Valuation Summary',
          },
          {
            link: '/motor-valuation/list-valuations',
            policyName: 'Motor Valuation',
          },
        ],
      }, */
      /* {
        icon: 'vsm-icon pe-7s-calculator',
        policyName: 'Bima Points',
        roles: this.appService.hasPermission('admin-menu-bimapoints'),
        items: [
          {
            link: '/bima-points/bima-points-stats',
            policyName: 'Bima Points Summary',
          },
          {
            link: '/bima-points/list',
            policyName: 'Bima Points List',
          },
        ],
      }, */
      /* {
        icon: 'vsm-icon pe-7s-calculator',
        policyName: 'Payment Plan',
        roles: this.appService.hasPermission('admin-menu-payment-plan'),
        items: [
          {
            link: '/payment-plans/summary',
            policyName: 'Payment Plan Summary',
          },
          {
            link: '/payment-plans/list',
            policyName: 'All Plans',
          },
          {
            link: '/payment-plans/credit-rating',
            policyName: 'Credit Rating',
          },
          {
            link: '/payment-plan-calculator',
            policyName: 'Payment Plan Calculator',
          },
        ],
      }, */
      /* {
        icon: 'vsm-icon pe-7s-calculator',
        policyName: 'Payment Plan',
        roles: this.isIpfProvider,
        items: [
          {
            link: '/payment-plans/summary',
            policyName: 'Payment Plan Summary',
          },
          {
            link: '/payment-plans/list',
            policyName: 'All Plans',
          },
        ],
      }, */
     {
        icon: 'vsm-icon pe-7s-wallet',
        policyName: 'Payments',
        roles: this.appService.hasPermission('admin-menu-payments'),
        items: [
          {
            link: '/payments/summary',
            policyName: 'Payment Summary',
          },
          {
            link: '/mpesa/mpesa-list',
            policyName: 'Mpesa Payments',
          },
          {
            link: '/card/card-list',
            policyName: 'Card Payments',
          },
          
        ],
      }, 

      {
        link: '/financials',
        icon: 'vsm-icon pe-7s-graph2',
        policyName: 'Financials',
        roles: this.appService.hasPermission('admin-menu-financials'),
      },
      /*  {
        link: '/datahub/dashboard',
        icon: 'vsm-icon pe-7s-graph2',
        policyName: 'Data Hub',
        roles: this.appService.hasPermission('admin-menu-datahub'),
      }, */
      // {
      //   link: '/currencies/list',
      //   icon: 'vsm-icon pe-7s-credit',
      //   policyName: 'Currencies',
      //   roles: this.appService.hasPermission('admin-menu-currencies'),
      // },
      // {
      //   link: '/api-credentials',
      //   icon: 'vsm-icon pe-7s-key',
      //   policyName: 'API Credentials',
      //   roles: this.appService.hasPermission(
      //     'admin-menu-view-apikey-credentials'
      //   ),
      // },
      // {
      //   link: '/wallet-transactions',
      //   icon: 'vsm-icon fa fa-money',
      //   policyName: 'Wallet Transactions',
      //   roles:
      //     this.isMatatuSaccoMember &&
      //     this.appService.hasPermission('admin-menu-wallet-transactions'),
      // },
      /*  {
        link: `/invoice-payments/list`,
        icon: 'vsm-icon pe-7s-credit',
        policyName: 'Invoice Payments',
        roles: this.appService.isAdmin(),
      }, */
      /* {
        link: `/invoice-payments/list/${
          this.appService.getSessionUser()?.feduid
        }`,
        icon: 'vsm-icon pe-7s-credit',
        policyName: 'Invoice Payments',
        roles: this.appService.isPrimePartner(),
      }, */
    ];
  }

  toggleCollapse(): void {
    this.collapsed = !this.collapsed;
    this.onToggleSideNav.emit({
      collapsed: this.collapsed,
      screenWidth: this.screenWidth,
    });
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }

  closeSidenav(): void {
    this.collapsed = false;
    this.onToggleSideNav.emit({
      collapsed: this.collapsed,
      screenWidth: this.screenWidth,
    });
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }

  handleClick(item): void {
    if (!this.multiple) {
      for (let modelItem of this.navData) {
        if (item !== modelItem && modelItem.expanded) {
          modelItem.expanded = false;
        }
      }
    }
    item.expanded = !item.expanded;
  }

  getActiveClass(data): string {
    return this.router.url.includes(data.link) ? 'active' : '';
  }

  private setisIpfProvider() {
    let _tpId = this.appService.getSessionUser()?.thirdPartyId;

    this.isIpfProvider = _tpId == IpfProvider.TRUSTGRO ? true : false;
  }

  private checkMatatuSaccoMembers() {
    let thirdPartyID = this.appService.getSessionUser()?.thirdPartyId;
    if (
      thirdPartyID === MatatuSaccoEnum.astrabel_matatu_sacco ||
      MatatuSaccoEnum.embassava_matatu_sacco ||
      MatatuSaccoEnum.luminous_matatu_sacco
    ) {
      this.isMatatuSaccoMember = true;
    }
  }

  setThirdPartyProducts() {
    this.appService.getAuthedThirdPartyFromCDN.subscribe((res) => {
      if (res) {
        if (res.hasOwnProperty('products')) {
          res.products
            .sort((a, b) => a.policyName.localeCompare(b.policyName))
            .forEach((aProduct) => {
              switch (aProduct.id) {
                case 'motor':
                  this.policyMenuItems.push({
                    link: '/motor/list',
                    policyName: aProduct.policyName,
                  });
                  break;
                case 'medical':
                  this.policyMenuItems.push({
                    link: '/medical/list',
                    policyName: aProduct.policyName,
                  });
                  break;
                case 'pa':
                  this.policyMenuItems.push({
                    link: '/personal-accident/list',
                    policyName: aProduct.policyName,
                  });
                  break;

                case 'travel':
                  this.policyMenuItems.push({
                    link: '/travel/list',
                    policyName: aProduct.policyName,
                  });
                  break;

                case 'domestic':
                  this.policyMenuItems.push({
                    link: '/domestic/list',
                    policyName: aProduct.policyName,
                  });
                  break;

                case 'evacuation':
                  this.policyMenuItems.push({
                    link: '/evacuation/list',
                    policyName: aProduct.policyName,
                  });
                  break;
                case 'life':
                  this.policyMenuItems.push({
                    link: '/life/list',
                    policyName: 'Life Insurance',
                  });
                  break;
                case 'lastexpense':
                  this.policyMenuItems.push({
                    link: '/lastexpense/list',
                    policyName: aProduct.policyName,
                  });
                  break;
                case 'unisure':
                  this.policyMenuItems.push({
                    link: '/unisure/list',
                    policyName: aProduct.policyName,
                  });
                  break;
                case 'golf':
                  this.policyMenuItems.push({
                    link: '/golf/list',
                    policyName: aProduct.policyName,
                  });
                  break;
                case 'gadget':
                  this.policyMenuItems.push({
                    link: '/gadget/list',
                    policyName: aProduct.policyName,
                  });
                  break;
                case 'telemedicine':
                  this.policyMenuItems.push({
                    link: '/telemedicine/list',
                    policyName: aProduct.policyName,
                  });
                case 'credit_life':
                  this.policyMenuItems.push({
                    link: '/credit-life',
                    policyName: aProduct.policyName,
                  });
                  break;

                default:
                  break;
              }
            });
        }
      }
    });
  }
  setClaimsProducts() {
    this.appService.getAuthedMenuFromCDN.subscribe(
      (res) => {
        if (res) {
          if (res.hasOwnProperty('products')) {
            res.products
              .sort((a, b) => a.policyName.localeCompare(b.policyName))
              .forEach((aProduct) => {
                //Change from .id to .policyName because id is not unique
                switch (aProduct.policyName) {
                  // case 'golf':
                  //   this.claimMenuItems.push({
                  //     link: '/golf-claims/list',
                  //     policyName: aProduct.policyName,
                  //   });
                  //   break;
                  case 'Motor Insurance':
                    this.claimMenuItems.push({
                      link: '/motor-claims/list',
                      policyName: aProduct.policyName,
                    });
                    break;
                  // case 'medical':
                  //   this.claimMenuItems.push({
                  //     link: '/medical-claims/list',
                  //     policyName: aProduct.policyName,
                  //   });
                  //   break;
                  // case 'travel':
                  //   this.claimMenuItems.push({
                  //     link: '/travel-claims/list',
                  //     policyName: aProduct.policyName,
                  //   });
                  //   break;

                  /*  case 'Domestic Insurance':
                    this.claimMenuItems.push({
                      link: '/domestic-claims/list',
                      policyName: aProduct.policyName,
                    });
                    break; */

                  // case 'pa':
                  //   this.claimMenuItems.push({
                  //     link: '/personal-accident-claims/list',
                  //     policyName: aProduct.policyName,
                  //   });
                  //   break;
                  // case 'evacuation':
                  //   this.claimMenuItems.push({
                  //     link: '/evacuation-claims/list',
                  //     policyName: aProduct.policyName,
                  //   });
                  //   break;
                  // case 'lastexpense':
                  //   this.claimMenuItems.push({
                  //     link: '/lastexpense/list',
                  //     policyName: aProduct.policyName,
                  //   });
                  //   break;
                  // case 'telemedicine':
                  //   this.claimMenuItems.push({
                  //     link: '/telemedicine/list',
                  //     policyName: aProduct.policyName,
                  //   });
                  //   break;
                  /*  case 'Gadget Insurance':
                    this.claimMenuItems.push({
                      link: '/gadget-claims/list',
                      policyName: aProduct.policyName,
                    });
                    break; */
                  default:
                    break;
                }
              });
          }
        }
      },
      (err) => {
        this.appService.showToastMessage(
          AppEnums.ToastTypeError,
          '',
          this.appService.processHttpErrors(err)
        );
      }
    );
  }
  setPolicyProducts() {
    if (this.appService.isAdminOrPartner()) {
      this.appService.getAuthedMenuFromCDN.subscribe(
        (res) => {
          if (res) {
            if (res.hasOwnProperty('products')) {
              res.products
                .sort((a, b) => a.policyName.localeCompare(b.policyName))
                .forEach((aProduct) => {
                  switch (aProduct.id) {
                    case 'motor':
                      this.policyMenuItems.push({
                        link: '/motor/list',
                        policyName: aProduct.policyName,
                      });
                      break;
                    case 'medical':
                      this.policyMenuItems.push({
                        link: '/medical/list',
                        policyName: aProduct.policyName,
                      });
                      break;

                    /* case 'golf':
                      this.policyMenuItems.push({
                        link: '/golf/list',
                        policyName: aProduct.policyName,
                      });
                      break;
                    case 'motor':
                      this.policyMenuItems.push({
                        link: '/motor/list',
                        policyName: aProduct.policyName,
                      });
                      break;
                    case 'medical':
                      this.policyMenuItems.push({
                        link: '/medical/list',
                        policyName: aProduct.policyName,
                      });
                      break;
                    case 'travel':
                      this.policyMenuItems.push({
                        link: '/travel/list',
                        policyName: aProduct.policyName,
                      });
                      break;

                    case 'domestic':
                      this.policyMenuItems.push({
                        link: '/domestic/list',
                        policyName: aProduct.policyName,
                      });
                      break;

                    case 'pa':
                      this.policyMenuItems.push({
                        link: '/personal-accident/list',
                        policyName: aProduct.policyName,
                      });
                      break;
                    case 'evacuation':
                      this.policyMenuItems.push({
                        link: '/evacuation/list',
                        policyName: aProduct.policyName,
                      });
                      break;
                    case 'life':
                      this.policyMenuItems.push({
                        link: '/life/list',
                        policyName: 'Life Insurance',
                      });
                      break;
                    case 'lastexpense':
                      this.policyMenuItems.push({
                        link: '/lastexpense/list',
                        policyName: aProduct.policyName,
                      });
                      break;
                    case 'unisure':
                      this.policyMenuItems.push({
                        link: '/unisure/list',
                        policyName: aProduct.policyName,
                      });
                      break;
                    case 'telemedicine':
                      this.policyMenuItems.push({
                        link: '/telemedicine/list',
                        policyName: aProduct.policyName,
                      });
                      break;
                    case 'gadget':
                      this.policyMenuItems.push({
                        link: '/gadget/list',
                        policyName: aProduct.policyName,
                      });
                      break; */
                    default:
                      break;
                  }
                });
            }
          }
        },
        (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
        }
      );
      /* this.policyMenuItems.push({
        link: '/other-manual-sales',
        policyName: 'Other manual sales',
      }); */
      return this.policyMenuItems.sort((a, b) => {
        return a.policyName.localeCompare(b.policyName);
      });
    } else {
      this.setThirdPartyProducts();
    }
  }
}
