import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AppEnums } from 'src/app/enums/app.enum';
import { ApiResponse } from 'src/app/models/api-response.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import { DisplayClientDetailsComponent } from '../display-client-details/display-client-details.component';
import { UpdateCompanyDetailsComponent } from '../update-company-details/update-company-details.component';

@Component({
  selector: 'app-update-client-details',
  templateUrl: './update-client-details.component.html',
  styleUrls: ['./update-client-details.component.scss'],
})
export class UpdateClientDetailsComponent implements OnInit {
  clientFormGroup: FormGroup;
  multiUploadFormGroup: FormGroup;
  allowedFileExtentions = ['jpg', 'jpeg', 'png', 'pdf'];
  azureFileLocation: string = 'manual-sales';
  activeFileName: string = '';
  activeFile: any;
  clientFiles: any[] = [
    { name: 'ID Card (Front)', value: 'idPhotoFront', suffix: '_ID_FRONT' },
    { name: 'ID Card (Back)', value: 'idPhotoBack', suffix: '_ID_BACK' },
    { name: 'KRA PIN Document', value: 'kraPhoto', suffix: '_KRA_PIN' },
    { name: 'Passport Document', value: 'passportPhoto', suffix: '_PASSPORT' },
  ];
  genders: any[] = [
    { name: 'Female', value: 'female' },
    { name: 'Male', value: 'male' },
  ];
  _tstamp: number = 0;
  currentRecord: any;
  questionsId: string;
  capturedGender: string;
  isLoading: boolean = false;
  moreQuestions: any;
  isMoreQuestionsObjectAvailable: boolean = false;
  constructor(
    private _formBuiler: FormBuilder,
    private appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DisplayClientDetailsComponent>,
    private dialog: MatDialog
  ) {
    this._tstamp = Date.now();
    this.clientFormGroup = _formBuiler.group({
      email: ['', Validators.email],
      userName: [''],
      phoneNumber: [''],
      kraPin: [''],
      kraPhoto: [''],
      gender: [''],
      idNumber: [''],
      passportNumber: [''],
      passportPhoto: [''],
      idPhotoBack: [''],
      idPhotoFront: [''],
      dlPhoto: [''],
      physicaladdress: [''],
      postaladdress: [''],
      postalcode: [''],
    });
    this.multiUploadFormGroup = this._formBuiler.group({
      fileObj: [''],
    });
  }

  onFileObjChange(idx: number) {
    this.activeFile = this.clientFiles[idx];
    this.activeFileName = this._tstamp + this.activeFile.suffix;
  }

  uploadEvent($event) {
    switch (this.activeFile.value) {
      case 'idPhotoFront':
        this.clientFormGroup.patchValue({
          idPhotoFront: $event,
        });
        break;
      case 'idPhotoBack':
        this.clientFormGroup.patchValue({
          idPhotoBack: $event,
        });
        break;
      case 'kraPhoto':
        this.clientFormGroup.patchValue({
          kraPhoto: $event,
        });
        break;
      case 'passportPhoto':
        this.clientFormGroup.patchValue({
          passportPhoto: $event,
        });
        break;
      case 'dlphoto':
        this.clientFormGroup.patchValue({
          dlphoto: $event,
        });
        break;
      default:
        break;
    }
  }

  setClientInformation() {
    this.convertGenderValue();
    if (this.data?.client) {
      this.clientFormGroup = this._formBuiler.group({
        email: [
          this.data.client?.email || this.currentRecord?.email,
          Validators.email,
        ],
        userName: [
          this.data.client?.userName ||
            this.currentRecord?.name ||
            this.data.client?.name,
        ],
        phoneNumber: [
          this.data.client?.phoneNumber || this.currentRecord?.phoneNumber,
        ],
        kraPin: [this.data.client?.kraPin],
        kraPhoto: [this.data.client?.kraPhoto],
        gender: [this.capturedGender || this.data.client?.gender],
        idNumber: [this.data.client?.idNumber],
        passportNumber: [this.data.client?.passportNumber],
        passportPhoto: [this.data.client?.passportPhoto],
        idPhotoBack: [this.data.client?.idPhotoBack],
        idPhotoFront: [this.data.client?.idPhotoFront],
        dlphoto: [this.data.client?.dlphoto],
        physicaladdress: [this.data.client?.physicaladdress],
        postaladdress: [this.data.client?.postaladdress],
        postalcode: [this.data.client?.postalcode],
      });
    }
  }

  convertGenderValue() {
    let gender = this.data.client?.gender;
    if (gender == 'Male') {
      this.capturedGender = 'male';
    } else if (gender == 'Female') {
      this.capturedGender = 'female';
    }
  }

  getQuoteQuestions(
    res: ApiResponse,
    microService: string
  ): Observable<ApiResponse> {
    let q = res.Payload[0].questions_id;

    return this.appService.makePostRequest(
      `${microService}/admingetquestions`,
      {
        _id: q,
      }
    );
  }

  getMoreQuestions() {
    this.isMoreQuestionsObjectAvailable = true;
    switch (this.data?.cover) {
      case 'personalaccident':
        this.appService
          .makePostRequest(`${environment.PA_API_URL}/getquestions`, {
            _id: this.data?.selectedRecordQuestionsId,
          })
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'motor':
        this.appService
          .makePostRequest(`${environment.MOTOR_API_URL}/getquestions`, {
            _id: this.data?.selectedRecordQuestionsId,
          })
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.clientFiles = [
                  {
                    name: 'ID Card (Front)',
                    value: 'idPhotoFront',
                    suffix: '_ID_FRONT',
                  },
                  {
                    name: 'ID Card (Back)',
                    value: 'idPhotoBack',
                    suffix: '_ID_BACK',
                  },
                  {
                    name: 'KRA PIN Document',
                    value: 'kraPhoto',
                    suffix: '_KRA_PIN',
                  },
                  {
                    name: 'Passport Document',
                    value: 'passportPhoto',
                    suffix: '_PASSPORT',
                  },
                  {
                    name: 'Driving License',
                    value: 'dlphoto',
                    suffix: '_LICENSE',
                  },
                ];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'medical':
        this.appService
          .makePostRequest(`${environment.MEDICAL_API_URL}/admingetquestions`, {
            _id: this.data?.selectedRecordQuestionsId,
          })
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'travel':
        this.appService
          .makePostRequest(`${environment.TRAVEL_API_URL}/admingetquestions`, {
            _id: this.data?.selectedRecordQuestionsId,
          })
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'domestic':
        this.appService
          .makePostRequest(
            `${environment.DOMESTIC_API_URL}/getdomesticquotequestions`,
            {
              _id: this.data?.selectedRecordQuestionsId,
            }
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'evacuation':
        this.appService
          .makePostRequest(`${environment.EVACUATION_API_URL}/getQuestions`, {
            _id: this.data?.selectedRecordQuestionsId,
          })
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'golf':
        this.appService
          .makePostRequest(`${environment.GOLF_API_URL}/getQuestions`, {
            _id: this.data?.selectedRecordQuestionsId,
          })
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'life':
        this.appService
          .makePostRequest(`${environment.LIFE_API_URL}/getQuestions`, {
            _id: this.data?.selectedRecordQuestionsId,
          })
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'other':
        this.appService
          .makePostRequest(`${environment.PA_API_URL}/getotherquestions`, {
            _id: this.data?.selectedRecordQuestionsId,
          })
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload;
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
      case 'unisure':
        this.appService
          .makePostRequest(
            `${environment.MEDICAL_API_URL}/unisureadmingetquestions`,
            {
              _id: this.data?.selectedRecordQuestionsId,
            }
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.moreQuestions = res.Payload[0];
                this.isMoreQuestionsObjectAvailable = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  res.Payload
                );
              }
            },
            (err) => {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                '',
                this.appService.processHttpErrors(err)
              );
              this.dialogRef.close();
            }
          );
        break;
        case 'gadget':
          this.appService
            .makePostRequest(
              `${environment.DOMESTIC_API_URL}/getgadgetquotequestions`,
              {
                _id: this.data?.selectedRecordQuestionsId,
              }
            )
            .subscribe(
              (res) => {
                if (res.Status == 200) {
                  this.moreQuestions = res.Payload[0];
                  this.isMoreQuestionsObjectAvailable = false;
                } else {
                  this.appService.showToastMessage(
                    AppEnums.ToastTypeError,
                    '',
                    res.Payload
                  );
                }
              },
              (err) => {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  '',
                  this.appService.processHttpErrors(err)
                );
                this.dialogRef.close();
              }
            );
          break;
      default:
        break;
    }
  }

  ngOnInit(): void {
    this.setClientInformation();
    this.getMoreQuestions();
  }

  updateClientInfo() {
    this.isLoading = true;
    let client = this.clientFormGroup.value;
    let body = {
      _id: this.data.selectedRecordQuestionsId,
      update: {
        moreQuestions: {
          client: {
            ...this.moreQuestions?.moreQuestions?.client,
            ...client,
          },
        },
      },
    };
    switch (this.data.cover) {
      case 'personalaccident':
        this.appService
          .makePostRequest(
            `${environment.PA_API_URL}/adminUpdateGeneralQuestions`,
            body
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeSuccess,
                  'Update successful',
                  'Success'
                );
                this.dialogRef.close();
              } else {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'Update unsuccessful',
                  'Something went wrong'
                );
              }
            },
            (error) => {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                this.appService.processHttpErrors(error),
                'Error'
              );
            }
          );
        break;
      case 'medical':
        this.appService
          .makePostRequest(
            `${environment.MEDICAL_API_URL}/adminUpdateGeneralQuestions`,
            body
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeSuccess,
                  'Update successful',
                  'Success'
                );
                this.dialogRef.close();
              } else {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'Update unsuccessful',
                  'Something went wrong'
                );
              }
            },
            (error) => {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                this.appService.processHttpErrors(error),
                'Error'
              );
            }
          );
        break;
      case 'motor':
        this.appService
          .makePostRequest(
            `${environment.MOTOR_API_URL}/adminUpdateGeneralQuestions`,
            body
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeSuccess,
                  'Update successful',
                  'Success'
                );
                this.dialogRef.close();
              } else {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'Update unsuccessful',
                  'Something went wrong'
                );
              }
            },
            (error) => {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                this.appService.processHttpErrors(error),
                'Error'
              );
            }
          );
        break;
      case 'travel':
        this.appService
          .makePostRequest(
            `${environment.TRAVEL_API_URL}/adminUpdateGeneralQuestions`,
            body
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeSuccess,
                  'Update successful',
                  'Success'
                );
                this.dialogRef.close();
              } else {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'Update unsuccessful',
                  'Something went wrong'
                );
              }
            },
            (error) => {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                this.appService.processHttpErrors(error),
                'Error'
              );
            }
          );
        break;
      case 'golf':
        this.appService
          .makePostRequest(
            `${environment.GOLF_API_URL}/adminUpdateGeneralQuestions`,
            body
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeSuccess,
                  'Update successful',
                  'Success'
                );
                this.dialogRef.close();
              } else {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'Update unsuccessful',
                  'Something went wrong'
                );
              }
            },
            (error) => {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                this.appService.processHttpErrors(error),
                'Error'
              );
            }
          );
        break;
      case 'domestic':
        this.appService
          .makePostRequest(
            `${environment.DOMESTIC_API_URL}/adminUpdateGeneralQuestions`,
            body
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeSuccess,
                  'Update successful',
                  'Success'
                );
                this.dialogRef.close();
              } else {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'Update unsuccessful',
                  'Something went wrong'
                );
              }
            },
            (error) => {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                this.appService.processHttpErrors(error),
                'Error'
              );
            }
          );
        break;
      case 'evacuation':
        this.appService
          .makePostRequest(
            `${environment.EVACUATION_API_URL}/adminUpdateGeneralQuestions`,
            body
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeSuccess,
                  'Update successful',
                  'Success'
                );
                this.dialogRef.close();
              } else {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'Update unsuccessful',
                  'Something went wrong'
                );
              }
            },
            (error) => {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                this.appService.processHttpErrors(error),
                'Error'
              );
            }
          );
        break;
      case 'life':
        this.appService
          .makePostRequest(
            `${environment.LIFE_API_URL}/adminUpdateLifeGeneralQuestions`,
            body
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeSuccess,
                  'Update successful',
                  'Success'
                );
                this.dialogRef.close();
              } else {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'Update unsuccessful',
                  'Something went wrong'
                );
              }
            },
            (error) => {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                this.appService.processHttpErrors(error),
                'Error'
              );
            }
          );
        break;
      case 'other':
        this.appService
          .makePostRequest(
            `${environment.PA_API_URL}/adminUpdateOtherGeneralQuestions`,
            body
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeSuccess,
                  'Update successful',
                  'Success'
                );
                this.dialogRef.close();
              } else {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'Update unsuccessful',
                  'Something went wrong'
                );
              }
            },
            (error) => {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                this.appService.processHttpErrors(error),
                'Error'
              );
            }
          );
        break;
      case 'unisure':
        this.appService
          .makePostRequest(
            `${environment.MEDICAL_API_URL}/adminUpdateUnisureGeneralQuestions`,
            body
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeSuccess,
                  'Update successful',
                  'Success'
                );
                this.dialogRef.close();
              } else {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'Update unsuccessful',
                  'Something went wrong'
                );
              }
            },
            (error) => {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                this.appService.processHttpErrors(error),
                'Error'
              );
            }
          );
        break;
        case 'gadget':
        this.appService
          .makePostRequest(
            `${environment.DOMESTIC_API_URL}/adminUpdateGadgetQuestionsGeneral`,
            body
          )
          .subscribe(
            (res) => {
              if (res.Status == 200) {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeSuccess,
                  'Update successful',
                  'Success'
                );
                this.dialogRef.close();
              } else {
                this.isLoading = false;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'Update unsuccessful',
                  'Something went wrong'
                );
              }
            },
            (error) => {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                this.appService.processHttpErrors(error),
                'Error'
              );
            }
          );
        break;
        default:
          break;
    }
  }
  popCompany() {
    this.dialogRef.close();
    this.dialog.open(UpdateCompanyDetailsComponent, {
      width: '50%',
      data: {
        selectedRecordQuestionsId: this.data?.selectedRecordQuestionsId,
        cover: this.data?.cover,
      },
    });
  }
}
