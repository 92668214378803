<h1 mat-dialog-title>
    Download Commission Statement
</h1>
<app-loader *ngIf="isLoading"></app-loader>
<mat-tab-group>
    <mat-tab label="New Report">
        <div mat-dialog-content>
            <form [formGroup]="formGroupCommStmt">
                <mat-form-field>
                    <mat-label>Underwriter</mat-label>
                    <mat-select formControlName="underwriter">
                        <mat-option *ngFor="let underwriter of appService.getUnderwritersFromCdn | async"
                            [value]="underwriter?.insurer">
                            {{ underwriter?.underwriter }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <input matInput [matDatepicker]="startDatePicker" placeholder="Start Date"
                        formControlName="startDate" />
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field>
                    <input matInput [matDatepicker]="endDatePicker" placeholder="End Date" formControlName="endDate" />
                    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker></mat-datepicker>
                </mat-form-field>

            </form>
            <div class="alert alert-success mt-2" role="alert" *ngIf="reportLink">
                Your report is ready <a [href]="reportLink" target="_blank"
                    class="btn btn-sm btn-success float-right">Download</a>
            </div>
        </div>

        <div mat-dialog-actions class="mb-2">
            <button mat-button mat-dialog-close>Close</button>
            <button mat-button color="primary" (click)="downloadStatement()">
                Download
            </button>
        </div>
    </mat-tab>
    <mat-tab label="Previous Reports">
        <div class="list-group listv">
            <div *ngIf="reportReferenceNumbers.length > 0;else noReportReferenceNumbers">
                <li class="list-group-item flex-column align-items-start"
                    *ngFor="let reportReferenceNumber of reportReferenceNumbers">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="mb-1">
                            <p class="mb-1">Reference Number: <strong>{{reportReferenceNumber?.refNo}}</strong>.</p>
                            <small class="text-muted">Generated on {{reportReferenceNumber?._utimestamp |
                                date:"medium"}} by
                                {{reportReferenceNumber?.name}}</small>
                        </div>
                        <button class="btn btn-primary"
                            (click)="downloadOldStatement(reportReferenceNumber.refNo)">Download</button>
                    </div>

                </li>
            </div>
            <ng-template #noReportReferenceNumbers>
                <div class="alert alert-warning" role="alert">
                    No reports exist.
                </div>
            </ng-template>
        </div>
    </mat-tab>
</mat-tab-group>