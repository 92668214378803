<breadcrumb #parent>
  <ol class="breadcrumb">
    <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
      <li *ngIf="route.terminal" class="breadcrumb-item active">
        <a [routerLink]="[route.url]">{{ route.displayName }}</a>
      </li>
      <li *ngIf="!route.terminal" class="breadcrumb-item" aria-current="page">
        {{ route.displayName }}
      </li>
    </ng-template>
  </ol>
</breadcrumb>
<app-policy-cancellation-details *ngIf="allDataLoaded" [policy]="selectedRecord"></app-policy-cancellation-details>
<mat-tab-group>
  <!-- Customer Details -->
  <mat-tab label="Client Details" *ngIf="appService.hasPermission('admin-policy-client-details')">
    <ng-template matTabContent>
      <!-- Cutomer Information and the Actions -->

      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <app-client-partial *ngIf="feduid" [feduid]="feduid"></app-client-partial>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </mat-tab>

  <!-- Cover Details -->
  <mat-tab label="Cover Details">
    <ng-template matTabContent>
      <app-domestic-quote-details *ngIf="allDataLoaded == true" [selectedRecord]="selectedRecord" [client]="client"
        [company]="company" [moreQuestions]="questions?.moreQuestions" [contents]="contents" [allRisk]="allRisk"
        [pets]="pets" [startDate]="startDate" [imagesArray]="imagesArray"
        (emitReFetchPolicyEvent)="getDomesticQuoteOrPolicy($event)">
      </app-domestic-quote-details>
      <div class="col-md-12 text-center card">
        <app-loader *ngIf="allDataLoaded == false"></app-loader>
      </div>
    </ng-template>
  </mat-tab>

  <!-- Questions -->
  <mat-tab label="Questions">
    <ng-template matTabContent>
      <app-domestic-questions *ngIf="domesticQuestions.length > 0; else noMoreQuestions"
        [selectedRecord]="selectedRecord" [moreQuestions]="domesticQuestions"></app-domestic-questions>
    </ng-template>
    <ng-template #noMoreQuestions>
      <div class="card">
        <div class="card-body">
          <div class="alert alert-warning" role="alert">
            There are no questions for this cover.
          </div>
        </div>
      </div>
    </ng-template>
  </mat-tab>

  <!-- Payment Details -->
  <mat-tab label="Payment Details">
    <div *ngIf="selectedRecordId && product && selectedRecord">
      <app-payment-plan-partial [selectedRecordId]="selectedRecordId" [paymentdetails]="selectedRecord?.paymentdetails"
        [selectedRecord]="selectedRecord" [product]="product" [sheduledPaymentPlans]="sheduledPaymentPlans">
      </app-payment-plan-partial>
    </div>
  </mat-tab>
  <!-- Remittance & Commissions -->
  <mat-tab label="Remittance & Commissions" *ngIf="appService.hasPermission('admin-policy-remittance-commissions')">
    <div *ngIf="selectedRecordId" class="mb-3">
      <app-policy-post-commission (emitSaveCommission)="getDomesticQuoteOrPolicy($event)"
        [selectedRecordId]="selectedRecordId"></app-policy-post-commission>
    </div>
    <div class="row" *ngIf="selectedRecordId">
      <div class="col-md-12">
        <app-commissions-division [receivedId]="selectedRecordId"></app-commissions-division>
      </div>
    </div>
    <div class="row mt-3" *ngIf="selectedRecord">
      <div class="col-md-12">
        <app-policy-remittance [product]="product" [selectedRecord]="selectedRecord"
          (emitSaveRemittance)="getDomesticQuoteOrPolicy($event)">
        </app-policy-remittance>
      </div>
    </div>

  </mat-tab>
  <mat-tab label="Documents" *ngIf="selectedRecord">
    <app-domestic-documents [selectedRecord]="selectedRecord"></app-domestic-documents>
  </mat-tab>
  <mat-tab label="Credit Rating" *ngIf="
      selectedRecordId && appService.hasPermission('admin-policy-credit-rating')
    ">
    <app-credit-rating [client]="client" [qid]="selectedRecordId"></app-credit-rating>
  </mat-tab>
</mat-tab-group>