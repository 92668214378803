<div class="d-flex justify-content-between align-items-center">
  <h5 class="card-title text-uppercase mb-0 text-info">Quote Owner</h5>
  <button
    class="btn btn-outline-primary ml-2"
    (click)="popUpdateClientInformation()"
    *ngIf="appService.hasPermission('admin-update-client-details')"
  >
    Edit
  </button>
</div>

<hr />
<div [ngSwitch]="entity">
  <div *ngSwitchCase="policy_holder_client">
    <!-- Name -->
    <p>
      <small class="d-block text-dark text-uppercase bold-text"
        >Client Name</small
      >
      <span *ngIf="client?.userName || client?.name; else noClientName">
        {{ client?.userName || client?.name }}
      </span>
      <ng-template #noClientName>
        {{ selectedRecord?.client?.userName || selectedRecord?.client?.name }}
      </ng-template>
    </p>

    <!-- Email -->
    <p>
      <small class="d-block text-dark text-uppercase bold-text"
        >Client Email</small
      >
      <span *ngIf="client?.email; else noClientEmail">
        {{ client?.email }}
      </span>
      <ng-template #noClientEmail>
        {{ selectedRecord?.client?.email }}
      </ng-template>
    </p>

    <!-- Phone Number -->
    <p>
      <small class="d-block text-dark text-uppercase bold-text"
        >Client Phone</small
      >
      <span *ngIf="client?.phoneNumber; else noClientPhone">
        {{ client?.phoneNumber }}
      </span>
      <ng-template #noClientPhone>
        {{
          selectedRecord?.client?.phoneNumber || selectedRecord?.client?.phone
        }}
      </ng-template>
    </p>

    <!-- ID Number -->
    <p>
      <small class="d-block text-dark text-uppercase bold-text"
        >Client ID</small
      >
      <span *ngIf="client?.idNumber; else noClientId">
        {{ client?.idNumber }}
      </span>
      <ng-template #noClientId>
        {{
          selectedRecord?.client?.idNumber || selectedRecord?.client?.id_number
        }}
      </ng-template>
    </p>

    <!-- KRA Pin -->
    <p>
      <small class="d-block text-dark text-uppercase bold-text"
        >Client KRA</small
      >
      <span *ngIf="client?.kraPin; else noClientKra">
        {{ client?.kraPin }}
      </span>
      <ng-template #noClientKra>
        {{ selectedRecord?.client?.kraPin || selectedRecord?.client?.kra }}
      </ng-template>
    </p>

    <!-- Postal Address -->
    <p>
      <small class="d-block text-dark text-uppercase bold-text"
        >Client Postal Address</small
      >
      <span *ngIf="client?.postaladdress; else noClientAddress">
        {{ client?.postaladdress }}
      </span>
      <ng-template #noClientAddress>
        {{ selectedRecord?.client?.postaladdress }}</ng-template
      >
    </p>

    <!-- Postal Code -->
    <p>
      <small class="d-block text-dark text-uppercase bold-text"
        >Client Postal Code</small
      >
      <span *ngIf="client?.postalcode; else noClientCode">
        {{ client?.postalcode }}
      </span>
      <ng-template #noClientCode>
        {{ selectedRecord?.client?.postalcode }}
      </ng-template>
    </p>

    <!-- Location -->
    <p>
      <small class="d-block text-dark text-uppercase bold-text"
        >Client Location</small
      >
      <span *ngIf="client?.physicaladdress; else noClientLocation">
        {{ client?.physicaladdress }}
      </span>
      <ng-template #noClientLocation>
        {{ selectedRecord?.client?.physicaladdress }}
      </ng-template>
    </p>

    <!-- Gender -->
    <p>
      <small class="d-block text-dark text-uppercase bold-text"
        >Client Gender</small
      >
      <span *ngIf="client?.gender; else noClientGender">
        {{ client?.gender }}
      </span>
      <ng-template #noClientGender>
        {{ selectedRecord?.client?.gender }}
      </ng-template>
    </p>
  </div>
  <div *ngSwitchCase="company_client">
    <p>
      <small class="d-block text-dark text-uppercase bold-text"
        >Company Name</small
      >
      <span *ngIf="company?.userName || company?.name; else noCompanyName">
        {{ company?.userName || company?.name }}
      </span>
      <ng-template #noCompanyName> NA </ng-template>
    </p>

    <p>
      <small class="d-block text-dark text-uppercase bold-text"
        >Company Email</small
      >
      <span *ngIf="company?.emailAddress; else noCompanyEmail">
        {{ company?.emailAddress }}
      </span>
      <ng-template #noCompanyEmail> NA </ng-template>
    </p>

    <p>
      <small class="d-block text-dark text-uppercase bold-text"
        >Company Phone</small
      >
      <span *ngIf="company?.phoneNumber; else noCompanyPhone">
        {{ company?.phoneNumber }}
      </span>
      <ng-template #noCompanyPhone> NA </ng-template>
    </p>

    <p>
      <small class="d-block text-dark text-uppercase bold-text"
        >Company KRA</small
      >
      <span *ngIf="company?.kraPin; else noCompanyKra">
        {{ company?.kraPin }}
      </span>
      <ng-template #noCompanyKra> NA </ng-template>
    </p>

    <p>
      <small class="d-block text-dark text-uppercase bold-text"
        >Company Postal Address</small
      >
      <span *ngIf="company?.postalAddress; else noCompanyAddress">
        {{ company?.postalAddress }}
      </span>
      <ng-template #noCompanyAddress> NA </ng-template>
    </p>

    <p>
      <small class="d-block text-dark text-uppercase bold-text"
        >Company Postal Code</small
      >
      <span *ngIf="company?.postalCode; else noCompanyCode">
        {{ company?.postalCode }}
      </span>
      <ng-template #noCompanyCode> NA </ng-template>
    </p>

    <p>
      <small class="d-block text-dark text-uppercase bold-text"
        >Company Location</small
      >
      <span *ngIf="company?.physicaladdress; else noCompanyLocation">
        {{ company?.physicaladdress }}
      </span>
      <ng-template #noCompanyLocation> NA </ng-template>
    </p>
  </div>
  <div *ngSwitchCase="app_user_client">
    <!-- Name -->
    <p>
      <small class="d-block text-dark text-uppercase bold-text"
        >Client Name</small
      >
      <span *ngIf="selectedRecord?.client?.userName; else isName">
        {{ selectedRecord?.client?.userName }}
      </span>
      <ng-template #isName>
        {{ selectedRecord?.client?.name }}
      </ng-template>
    </p>

    <!-- Email -->
    <p>
      <small class="d-block text-dark text-uppercase bold-text"
        >Client Email</small
      >
      {{ selectedRecord?.client?.email }}
    </p>

    <!-- Phone Number -->
    <p>
      <small class="d-block text-dark text-uppercase bold-text"
        >Phone Number</small
      >
      <span *ngIf="selectedRecord?.client?.phoneNumber; else isPhone">
        {{ selectedRecord?.client?.phoneNumber }}
      </span>
      <ng-template #isPhone>
        {{ selectedRecord?.client?.phone }}
      </ng-template>
    </p>

    <!-- ID Number -->
    <p>
      <small class="d-block text-dark text-uppercase bold-text"
        >Client ID Number</small
      >
      <span *ngIf="selectedRecord?.client?.idNumber; else isIdNumber">
        {{ selectedRecord?.client?.idNumber }}
      </span>
      <ng-template #isIdNumber>
        {{ selectedRecord?.client?.id_number }}
      </ng-template>
    </p>
    <!-- Duka Code for Kyosk -->
    <p *ngIf="client?.dukaCode">
      <small class="d-block text-dark text-uppercase bold-text"
        >Duka Code</small
      >
      {{ client?.dukaCode }}
    </p>
    <!-- KRA Pin -->
    <p>
      <small class="d-block text-dark text-uppercase bold-text"
        >Client KRA PIN</small
      >
      <span *ngIf="client?.kraPin; else isKraPin">
        {{ selectedRecord?.client?.kraPin }}
      </span>
      <ng-template #isKraPin>
        {{ selectedRecord?.client?.kra }}
      </ng-template>
    </p>

    <!-- Postal Address and Code -->
    <p>
      <small class="d-block text-dark text-uppercase bold-text"
        >Client Postal Address
      </small>
      {{ selectedRecord?.client?.postaladdress }}
    </p>
    <p>
      <small class="d-block text-dark text-uppercase bold-text"
        >Client Postal Code</small
      >
      {{ selectedRecord?.client?.postalcode }}
    </p>

    <!-- Location -->
    <p>
      <small class="d-block text-dark text-uppercase bold-text"
        >Client Physical Location</small
      >
      {{ selectedRecord?.client?.physicaladdress }}
    </p>

    <!-- Gender -->
    <p *ngIf="selectedRecord?.client?.gender">
      <small class="d-block text-dark text-uppercase bold-text"
        >Client Gender</small
      >
      {{ selectedRecord?.client?.gender }}
    </p>
  </div>
</div>
