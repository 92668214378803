<div class="card">
  <div class="card-body">
    <div class="table-responsive-sm">
      <table class="table table-striped table-responsive-sm">
        <tr>
          <th scope="col">Date</th>
          <th scope="col">Client</th>
          <th scope="col">Status</th>
          <th scope="col">underwriter</th>
          <th scope="col">Details</th>
        </tr>

        <tbody>
          <tr *ngFor="let aClaim of allClaims" class="section-step" (click)="goToClaimDetail(aClaim)">
            <td class="p-3">{{ aClaim?._utimestamp | date: 'medium' }}</td>
            <td class="p-3">{{ aClaim?.client?.name }}</td>
            <td class="p-3" *ngIf="aClaim?.claimProgress?.progress.length > 0; else useStatus">
              {{ aClaim?.claimProgress?.progress.pop()?.label }}
            </td>
            <ng-template #useStatus>
              <td class="p-3">{{ aClaim?.status }}</td>
            </ng-template>
            <td class="p-3">
              {{ aClaim?.policy?.insurer | underwriter | async }}
            </td>
            <td class="p-3">
              <i class="vsm-icon pe-7s-angle-right"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>