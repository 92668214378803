<div class="d-flex justify-content-between align-items-center">
  <h5 class="card-title text-uppercase mb-0 text-info">Next of Kin Details</h5>
  <button
    class="btn btn-outline-primary ml-2"
    (click)="popUpdateNokInformation()"
    *ngIf="appService.hasPermission('admin-update-next-of-kin-details')"
  >
    Edit
  </button>
</div>

<hr />

<p>
  <small class="d-block text-dark text-uppercase bold-text">Name</small>
  {{ client?.nokName }}
</p>

<p>
  <small class="d-block text-dark text-uppercase bold-text">Phone Number</small>
  {{ client?.nokPhoneNo }}
</p>
<p>
  <small class="d-block text-dark text-uppercase bold-text">Relationship</small>
  {{ client?.nokRelationship }}
</p>
<p>
  <small class="d-block text-dark text-uppercase bold-text">Address</small>
  {{ client?.nokPostalAddress }} -
  {{ client?.nokPostalCode }}
</p>
<p>
  <small class="d-block text-dark text-uppercase bold-text">Location</small>
  {{ client?.nokLocation }}
</p>

<ng-template #noNOK>
  <div class="alert alert-warning" role="alert">
    No Next of Kin Details Added.
  </div>
</ng-template>
