<form [formGroup]="formGroupDatePicker" (submit)="updateRate()">
    <div class="row">

        <div class="col-md-4">
            <mat-form-field>
                <mat-label>Start Date</mat-label>
                <input matInput [matDatepicker]="periodStartDatePicker" placeholder="Start Date"
                    formControlName="startDate" />
                <mat-datepicker-toggle matSuffix [for]="periodStartDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #periodStartDatePicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field>
                <mat-label>End Date</mat-label>
                <input matInput [matDatepicker]="periodEndDatePicker" placeholder="End Date"
                    formControlName="endDate" />
                <mat-datepicker-toggle matSuffix [for]="periodEndDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #periodEndDatePicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <button type="submit" class="btn btn-primary btn-sm mt-2">
                Update Period</button>
        </div>
    </div>
</form>