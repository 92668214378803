<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<h1 mat-dialog-title>Edit Role for {{ data.userName }}</h1>
<div mat-dialog-content>
  <form [formGroup]="editUserRoleFormGroup" (ngSubmit)="submitRole()">
    <mat-form-field>
      <mat-label>Select Role</mat-label>
      <mat-select [formControl]="selectedRoles" multiple>
        <mat-option *ngFor="let role of allRoles" [value]="role">
          {{ role.roleName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="requireunderwriter">
      <mat-label>Select underwriter</mat-label>
      <mat-select formControlName="insurer">
        <mat-option *ngFor="let uw of underwritersCache$ | async" value="{{uw.insurer}}">{{uw.underwriter}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="requireThirdParty">
      <mat-label>Select Third Party</mat-label>
      <mat-select formControlName="thirdPartyId">
        <mat-option *ngFor="let tp of thirdPartiesCache$ | async" value="{{tp.thirdPartyId}}">{{tp.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </form>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button color="primary" (click)="submitRole()">Submit</button>
</div>