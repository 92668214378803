<h1 mat-dialog-title>Card Membership Information</h1>
<div mat-dialog-content>
  <app-loader *ngIf="isLoading"></app-loader>
  <form [formGroup]="cardFormGroup" (submit)="(validateMember)">
    <div class="row">
      <div class="col-md-4">
        <label for="cancer">Select Member</label>
        <select
          class="custom-select"
          formControlName="member"
          (change)="memberChange($event.target.value)"
          id="member"
        >
          <option
            *ngFor="let member of cardMembersDetails"
            [value]="member.name"
          >
            {{ member.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-4">
        <div class="form-group">
          <label for="name">Member's Name</label>
          <input
            class="form-control"
            type="text"
            formControlName="name"
            placeholder="Member's Name"
            required
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="name">Member's Number</label>
          <input
            class="form-control"
            type="text"
            formControlName="memberNo"
            placeholder="Member's No."
            required
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="name">Scheme Name</label>
          <input
            class="form-control"
            type="text"
            formControlName="schemeName"
            placeholder="Scheme Name"
            required
          />
        </div>
      </div>
      <div class="col-lg-4">
        <mat-form-field appearance="fill">
          <mat-label>Date Issued</mat-label>
          <input
            matInput
            [matDatepicker]="picker10"
            formControlName="dateIssued"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker10"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker10></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <label for="new Child">Add Member</label>
        <br />
        <i
          class="fa fa-plus-square text-mtek"
          aria-hidden="true"
          (click)="addMember()"
          style="font-size: 42px; margin-left: 3px; cursor: pointer"
        ></i>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-md-4" *ngFor="let member of allCardMembers; let i = index">
      <div class="card mt-3">
        <div class="card-body">
          <p>Name: {{ member.name }}</p>
          <p>Scheme Name: {{ member.schemeName }}</p>
          <p>Member No: {{ member.memberNo }}</p>
          <p>Date Issued: {{ member.dateIssued }}</p>
          <a
            href="javascript:void(0)"
            class="text-danger"
            (click)="removeMember(i)"
            >Remove</a
          ><br />
        </div>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
  <button mat-button color="primary" (click)="validateMember()">Submit</button>
</div>
