import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IPayment } from 'src/app/models/payment.interface';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-list-card-payments-table',
  templateUrl: './list-card-payments-table.component.html',
  styleUrls: ['./list-card-payments-table.component.scss'],
})
export class ListCardPaymentsTableComponent implements OnInit {
  @Input() payments: IPayment[] = [];
  @Input() feduid: string;

  constructor(public appService: AppService, private router: Router) {}

  ngOnInit(): void {}

  goToPaymentDetails(_id: string) {
    this.router.navigate(['/card/payment-details', _id]);
  }
}
