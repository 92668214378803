import { Component, OnInit, ViewChild } from '@angular/core';
import {
  IPolicySummaries,
  IPolicySummary,
} from 'src/app/models/policy-summary.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import {
  ApexChart,
  ApexAxisChartSeries,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexGrid,
  ApexTitleSubtitle,
} from 'ng-apexcharts';
import { ECovers } from 'src/app/enums/covers.enum';

type ApexXAxis = {
  type?: 'category' | 'datetime' | 'numeric';
  categories?: any;
  labels?: {
    style?: {
      colors?: string | string[];
      fontSize?: string;
    };
  };
};

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  colors: string[];
  legend: ApexLegend;
  title: ApexTitleSubtitle;
};

interface Irange {
  end_date: number;
  start_date: number;
}

export interface Iproducts {
  id: string;
  policyName: string;
  policyNickName: string;
}

export interface ITheme {
  bg: string;
  textColor: string;
}

@Component({
  selector: 'app-partner-claims-dashboard',
  templateUrl: './partner-claims-dashboard.component.html',
  styleUrls: ['./partner-claims-dashboard.component.scss'],
})
export class PartnerClaimsDashboardComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  policySummary: IPolicySummary[];
  policySummeries: IPolicySummaries[];

  ECovers = ECovers;

  chartDataLoaded: boolean = false;

  themes: ITheme[] = [
    { bg: 'bg-gradient-info', textColor: 'text-info' },
    { bg: 'bg-gradient-success', textColor: 'text-success' },
    { bg: 'bg-gradient-danger', textColor: 'text-danger' },
    { bg: 'bg-gradient-warning', textColor: 'text-warning' },
    { bg: 'bg-gradient-blue', textColor: 'text-violet' },
    { bg: 'bg-gradient-dark', textColor: 'text-dark' },
    { bg: 'bg-gradient-indigo', textColor: 'text-indigo' },
    { bg: 'bg-gradient-info', textColor: 'text-info' },
    { bg: 'bg-gradient-info', textColor: 'text-info' },
    { bg: 'bg-gradient-info', textColor: 'text-info' },
    { bg: 'bg-gradient-info', textColor: 'text-info' },
    { bg: 'bg-gradient-info', textColor: 'text-info' },
  ];

  totalClaims: number = 0;
  coverNamesArray: string[] = [];
  totalClaimsArray: number[] = [];
  underwriterProducts: Iproducts[];

  partnerId: string;

  selectedDateRange: Irange;

  constructor(private appService: AppService) {
    let todayStamp = Date.now();
    let today = new Date(todayStamp);
    let last30Days = new Date(Date.now() - 2592000000);

    let startDate =
      last30Days.getFullYear() +
      '-' +
      (last30Days.getMonth() + 1) +
      '-' +
      last30Days.getDate();

    let endDate =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate();

    this.selectedDateRange = {
      end_date: Date.parse(endDate),
      start_date: Date.parse(startDate),
    };
  }

  ngOnInit(): void {
    this?.getPolicySummary();
    this?.getProducts();
    if (this.appService?.getSessionUser()?.partner != undefined) {
      this.partnerId = this.appService.getSessionUser()?.partner;
    }
  }

  updateRange(range) {
    this.selectedDateRange.start_date = Date.parse(range.start_date);
    this.selectedDateRange.end_date = Date.parse(range.end_date);
    this.totalClaims = 0;

    this.coverNamesArray = [];
    this.totalClaimsArray = [];
    this.getPolicySummary();
  }

  getProducts() {
    this.appService
      .makeAnyDataGetRequest(`${environment.UNDERWRITERS_CDN_URL}`)
      ?.subscribe((response) => {
        response.forEach((item) => {
          if (item.insurer === this.partnerId) {
            this.underwriterProducts = item.products;
          }
        });
      });
  }

  getPolicySummary() {
    this.appService
      .makePostRequest(`${environment.PREMIUM_API_URL}/adminpolicysummary`, {
        $and: [
          { _timestamp: { $gte: this.selectedDateRange.start_date } },
          { _timestamp: { $lte: this.selectedDateRange.end_date } },
        ],
      })
      ?.subscribe((apiResponse) => {
        this.policySummary = apiResponse.Payload;

        this.policySummeries = this.policySummary.map((item) => {
          let claims = 0;

          claims = item.values
            .map((element) => {
              return element.claims;
            })
            .reduce((total, number) => {
              return total + number;
            }, 0);

          this.totalClaims += claims;

          this.totalClaimsArray.push(claims);

          this.coverNamesArray.push(ECovers[item.name]);

          return {
            name: item.name,
            displayName: ECovers[item.name],
            quotes: 0,
            policies: 0,
            claims,
          };
        });
        this.chartOptions = {
          series: [
            {
              name: 'Claims',
              data: this.totalClaimsArray,
            },
          ],
          chart: {
            height: 400,
            type: 'bar',
          },
          colors: [
            '#008ffb',
            '#00e396',
            '#f65740',
            '#fa8440',
            '#7367e5',
            '#212429',
            '#7703ac',
          ],
          plotOptions: {
            bar: {
              horizontal: true,
              columnWidth: '66%',
              //endingShape: 'rounded',
              distributed: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: true,
          },
          grid: {
            show: false,
          },
          xaxis: {
            categories: this.coverNamesArray,
          },
          title: {
            text: 'Claims per cover comparison',
          },
        };
        this.chartDataLoaded = true;
      });
  }
}
