import { Component, OnInit } from '@angular/core';
import { ChartType } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
})
export class DoughnutChartComponent implements OnInit {
  // Doughnut
  public doughnutChartLabels: Label[] = [
    'Motor',
    'Golf',
    'Domestic',
    'Cancer',
    'School',
    'Health',
    'Evacuation',
    'Travel',
  ];
  public doughnutChartData: MultiDataSet = [
    [350, 450, 100, 350, 450, 100, 20, 50, 80],
    // [50, 150, 120],
    // [250, 130, 70],
  ];
  public doughnutChartType: ChartType = 'doughnut';

  public options: any = {
    legend: { position: 'right' },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  constructor() {}

  ngOnInit() {}

  // events
  public chartClicked({
    event,
    active,
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {}

  public chartHovered({
    event,
    active,
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {}
}
