import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-display-group-info',
  templateUrl: './display-group-info.component.html',
  styleUrls: ['./display-group-info.component.scss'],
})
export class DisplayGroupInfoComponent implements OnInit {
  @Input() members: any[] = [];

  constructor() {
    this.members.forEach(member => {
      if (isNaN(member.dateOfBirth)) {
        member.dateOfBirth = Date.parse(member.dateOfBirth);
      }
    }
    );
  }

  ngOnInit(): void { }
}
