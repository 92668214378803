import { Component, Input, OnInit } from '@angular/core';
import { AppEnums } from 'src/app/enums/app.enum';
import { IPaymentLog } from 'src/app/models/payment-log.interface';

@Component({
  selector: 'app-payment-log-entry',
  templateUrl: './payment-log-entry.component.html',
  styleUrls: ['./payment-log-entry.component.scss'],
})
export class PaymentLogEntryComponent implements OnInit {
  @Input() paymentLogs: IPaymentLog[];
  @Input() paymentdetails;
  @Input() isLife;
  @Input() selectedRecord;
  hillcroft: string = AppEnums.MTEK_GUUID;

  months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  constructor() { }

  ngOnInit(): void {

  }
}
