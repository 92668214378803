import { ITemplateConfig } from '../../models/template-config.interface';
import {
  GetConfigAction,
  UpdateConfigAction,
  GetAuthedUserAction,
  UpdateAuthedUserAction,
} from '../actions/index';
import { createReducer, on } from '@ngrx/store';
import { IUser } from '../../models/user.interface';

export interface AppState {
  templateConfig: ITemplateConfig;
  authenticatedUser: IUser;
}

const initialState: AppState = {
  templateConfig: {
    headerTheme: '',
    sidebarTheme: '',
  },
  authenticatedUser: {},
};

export const templateConfigReducer = createReducer(
  initialState,
  on(GetConfigAction, (state) => {
    return state;
  }),
  on(UpdateConfigAction, (state, templateConfig) => {
    return {
      ...state,
      templateConfig: { ...templateConfig },
    };
  })
);

export const authenticatedUserReducer = createReducer(
  initialState,

  on(GetAuthedUserAction, (state) => {
    return state;
  }),
  on(UpdateAuthedUserAction, (state, authenticatedUser) => {
    return {
      ...state,
      authenticatedUser: { ...authenticatedUser },
    };
  })
);
