<div class="d-flex justify-content-between align-items-center">
  <h1 mat-dialog-title>Update Client Information</h1>
  <button
    mat-button
    color="primary"
    (click)="popCompany()"
    *ngIf="
      !data?.company &&
      !data?.client &&
      data.cover != 'golf' &&
      data.cover != 'domestic' &&
      data.cover != 'gadget'
    "
  >
    Update Company Infomation
  </button>
</div>

<div mat-dialog-content>
  <app-loader *ngIf="isLoading"></app-loader>
  <form [formGroup]="clientFormGroup" (submit)="updateClientInfo()">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label for="userName">Name</label>
          <input
            class="form-control"
            type="text"
            formControlName="userName"
            placeholder="Client Name"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="email">Email Address</label>
          <input
            class="form-control"
            type="email"
            formControlName="email"
            placeholder="Email Address"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="phoneNumber">Phone</label>
          <input
            class="form-control"
            type="text"
            formControlName="phoneNumber"
            placeholder="Phone Number"
          />
        </div>
      </div>
      <div class="col-md-4">
        <label for="gender">Gender</label>
        <select class="custom-select" formControlName="gender" id="gender">
          <option *ngFor="let gender of genders" [value]="gender.value">
            {{ gender.name }}
          </option>
        </select>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="kraPin">KRA PIN</label>
          <input
            class="form-control"
            type="text"
            formControlName="kraPin"
            placeholder="KRA PIN"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="idNumber">ID Number</label>
          <input
            class="form-control"
            type="text"
            formControlName="idNumber"
            placeholder="ID Number"
          />
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label for="passportNumber">Passport Number</label>
          <input
            class="form-control"
            type="text"
            formControlName="passportNumber"
            placeholder="Passport Number"
          />
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label for="physicaladdress">Physical Address</label>
          <input
            class="form-control"
            type="text"
            formControlName="physicaladdress"
            placeholder="Physical Address"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="postaladdress">Postal Address</label>
          <input
            class="form-control"
            type="text"
            formControlName="postaladdress"
            placeholder="Postal Address"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="postalcode">Postal Code</label>
          <input
            class="form-control"
            type="text"
            formControlName="postalcode"
            placeholder="Postal Code"
          />
        </div>
      </div>
      <div class="col-md-8">
        <div class="card">
          <div class="card-body">
            <form [formGroup]="multiUploadFormGroup" class="mt-2">
              <div class="form-group">
                <label for="fileObj">Select File to Upload</label>
                <select
                  class="custom-select"
                  formControlName="fileObj"
                  (change)="onFileObjChange($event.target.value)"
                  id="fileObj"
                >
                  <option selected></option>
                  <option
                    *ngFor="let afileObj of clientFiles; let i = index"
                    [value]="i"
                  >
                    {{ afileObj.name }}
                  </option>
                </select>
              </div>
              <app-file-upload
                [allowedFileExtentions]="allowedFileExtentions"
                [azureFileLocation]="azureFileLocation"
                [azureFileName]="activeFileName"
                (emitFileUploadedEvent)="uploadEvent($event)"
              >
              </app-file-upload>
            </form>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
  <app-loader *ngIf="isMoreQuestionsObjectAvailable"></app-loader>
  <button
    mat-button
    color="primary"
    *ngIf="isMoreQuestionsObjectAvailable == false"
    (click)="updateClientInfo()"
  >
    Submit
  </button>
</div>
