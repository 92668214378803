import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppEnums } from 'src/app/enums/app.enum';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-group-info-partial',
  templateUrl: './group-info-partial.component.html',
  styleUrls: ['./group-info-partial.component.scss']
})
export class GroupInfoPartialComponent implements OnInit {
  @Input() receivedId: string;
  @Output() groupInfoEvent: EventEmitter<any> = new EventEmitter<any>()
  fields: string[] = ["name", "email", "phone", "dateOfBirth", "gender", "idNo", "policyNumber", "membershipNo"]
  membersFormGroup: FormGroup;

  public members: any[] = [];

  constructor(
    private _formBuilder: FormBuilder,
    public appService: AppService,
  ) {

    this.membersFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      dateOfBirth: ['', Validators.required],
      gender: ['', Validators.required],
      idNo: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      policyNumber: [''],
      membershipNo: ['']
    })

  }

  ngOnInit(): void {
  }

  addMembers() {
    if (!this.membersFormGroup.valid) {
      const controls = this.membersFormGroup.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            "Field Required",
            name
          );
        }
      }
      return;
    }

    this.members.push({
      name: this.membersFormGroup.get('name').value,
      dateOfBirth: this.membersFormGroup.get('dateOfBirth').value,
      gender: this.membersFormGroup.get('gender').value,
      idNo: this.membersFormGroup.get('idNo').value,
      email: this.membersFormGroup.get('email').value,
      phone: this.membersFormGroup.get('phone').value,
      policyNumber: this.membersFormGroup.get('policyNumber').value,
      membershipNo: this.membersFormGroup.get('membershipNo').value,
    });

    this.membersFormGroup.reset()

  }

  removeMembers(index: number) {
    if (index > -1) {
      this.members.splice(index, 1);
    }

  }

  updateMembers(members) {
    this.members = members;
  }

  emitGroupInfo() {
    this.groupInfoEvent.emit({
      members: this.members,
      count: this.members.length
    });

    this.appService.showToastMessage(
      AppEnums.ToastTypeSuccess,
      "Members successfully submitted",
      "Success"
    );
  }

}
