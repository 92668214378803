import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICompany } from 'src/app/models/company.interface';
import { IUser } from 'src/app/models/user.interface';
import { AppService } from 'src/app/services/app.service';
import { UpdateClientDetailsComponent } from '../update-client-details/update-client-details.component';
import { UpdateCompanyDetailsComponent } from '../update-company-details/update-company-details.component';

@Component({
  selector: 'app-display-client-details',
  templateUrl: './display-client-details.component.html',
  styleUrls: ['./display-client-details.component.scss'],
})
export class DisplayClientDetailsComponent implements OnInit {
  @Input() client: IUser;
  @Input() company: ICompany;
  @Input() selectedRecordQuestionsId: string;
  @Input() selectedRecord: any;
  @Output() reloadPolicyEvent = new EventEmitter();
  @Input() cover: string;
  entity: string = '';
  company_client: string = 'company';
  policy_holder_client: string = 'policy_holder';
  app_user_client: string = 'app_user';
  constructor(public dialog: MatDialog, public appService: AppService) {}

  ngOnInit(): void {
    if (this.company && this.company.hasOwnProperty('kraPin')) {
      this.entity = 'company';
    } else if (this.client && this.client.hasOwnProperty('kraPin')) {
      this.entity = 'policy_holder';
    } else {
      this.entity = 'app_user';
    }
  }

  popUpdateClientInformation() {
    let company = [];
    let client = [];
    if (this.company) {
      company = Object.keys(this.company);
    }
    if (this.client) {
      client = Object.keys(this.client);
    }
    if (company.length > 0) {
      let dialogRef = this.dialog.open(UpdateCompanyDetailsComponent, {
        width: '50%',
        data: {
          selectedRecordQuestionsId: this.selectedRecordQuestionsId,
          company: this.company,
          cover: this.cover,
        },
      });
      dialogRef.afterClosed().subscribe((selectedRecordQuestionsId) => {
        this.reloadPolicyEvent.emit(selectedRecordQuestionsId);
      });
    } else if (client.length > 0) {
      let dialogRef = this.dialog.open(UpdateClientDetailsComponent, {
        width: '50%',
        data: {
          selectedRecordQuestionsId: this.selectedRecordQuestionsId,
          client: this.client,
          cover: this.cover,
        },
      });
      dialogRef.afterClosed().subscribe((selectedRecordQuestionsId) => {
        this.reloadPolicyEvent.emit(selectedRecordQuestionsId);
      });
    } else {
      let dialogRef = this.dialog.open(UpdateClientDetailsComponent, {
        width: '50%',
        data: {
          selectedRecordQuestionsId: this.selectedRecordQuestionsId,
          cover: this.cover,
        },
      });
      dialogRef.afterClosed().subscribe((selectedRecordQuestionsId) => {
        this.reloadPolicyEvent.emit(selectedRecordQuestionsId);
      });
    }
  }
}
