<h1 mat-dialog-title class="text-mtek">Save Credit Rating Review</h1>
<div mat-dialog-content>
  <app-loader *ngIf="isLoading"></app-loader>
  <form [formGroup]="saveReviewFormGroup" (submit)="saveCreditReviewRating">
    <div class="d-flex fields-container">

      <div class="col-md-4">
        <mat-form-field appearance="fill">
          <mat-label>ID Number</mat-label>
          <input type="text" matInput formControlName="id_number" placeholder="ID Number" />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="fill">
          <mat-label>First Name</mat-label>
          <input type="text" matInput formControlName="first_name" placeholder="First name" />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="fill">
          <mat-label>Email</mat-label>
          <input type="email" matInput formControlName="email" placeholder="Email" />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="fill">
          <mat-label>Affodability</mat-label>
          <input type="number" matInput placeholder="Affodability e.g 2000" formControlName="affordability" />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="fill">
          <mat-label>Description</mat-label>
          <input type="text" matInput placeholder="Description" formControlName="description" />
        </mat-form-field>
      </div>

    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true">Close</button>
  <button mat-button color="primary" (click)="saveCreditReviewRating()">
    Submit
  </button>
</div>