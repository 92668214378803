import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppEnums } from 'src/app/enums/app.enum';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import { ICreditRatingResult } from '../credit-rating/credit-rating.component';

@Component({
  selector: 'app-save-creding-rating',
  templateUrl: './save-creding-rating.component.html',
  styleUrls: ['./save-creding-rating.component.scss'],
})
export class SaveCredingRatingComponent implements OnInit {
  isLoading: boolean = false;
  saveReviewFormGroup: FormGroup;

  constructor(
    private appService: AppService,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: ICreditRatingResult,
    public dialogRef: MatDialogRef<SaveCredingRatingComponent>
  ) {
    this.saveReviewFormGroup = this._formBuilder.group({
      id_number: [data?.id_number, Validators.required],
      affordability: [
        data?.trustgro_response?.affordability,
        Validators.required,
      ],
      description: ['', Validators.required],
      first_name: [data?.first_name, Validators.required],
      email: [data?.client?.email, Validators.required],
      score: [true, Validators.required],
    });
  }

  saveCreditReviewRating() {
    if (!this.saveReviewFormGroup.valid) {
      const controls = this.saveReviewFormGroup.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            'Field Required',
            name
          );
        }
      }
    }
    this.isLoading = true;
    this.appService
      .makePostRequest(
        `${environment.CORE_API_URL}/savecreditratingreview`,
        this.saveReviewFormGroup.value
      )
      .subscribe(
        (res) => {
          this.isLoading = false;
          if (res.Status === 200) {
            this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              '',
              'Success'
            );
            this.dialogRef.close();
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              'Something went wrong',
              'Unable to get the creding review list'
            );
          }
        },
        (error) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(error)
          );
        }
      );
  }

  ngOnInit(): void {}
}
