<mat-tab-group mat-align-tabs="start" *ngIf="appService.hasPermission('admin-menu-view-assessor')">
    <mat-tab label="Assessor Details">
        <div class="row">
            <div class="col-md-12">
                <div class="card section1">
                    <div class="card-body">
                        <h4 class="card-title text-uppercase mb-0 text-mtek">
                            <i class="fa fa-address-card-o text-mtek icon-big h5"></i>
                            Assessor Details
                        </h4>
                        <hr />
                        <div class="row">
                            <div class="col-md-4">
                                <h5 class="card-title text-uppercase mb-0 text-info">
                                    Principle Assessor Details
                                </h5>
                                <p>
                                    <small class="d-block text-dark text-uppercase bold-text">Name</small>
                                    <span>{{ user?.userName }}</span>
                                </p>

                                <p>
                                    <small class="d-block text-dark text-uppercase bold-text">Phone</small>
                                    <span>{{ user?.phone }}</span>
                                </p>

                                <p>
                                    <small class="d-block text-dark text-uppercase bold-text">Email</small>
                                    <span>{{ user?.email }}</span>
                                </p>

                                <p *ngIf="appService.isAdmin()">
                                    <small class="d-block text-dark text-uppercase bold-text">Profile</small>
                                    <button class="btn btn-sm btn-light" (click)="goToUserProfile()">
                                        Go to Profile
                                    </button>
                                </p>
                            </div>
                            <div class="col-md-4">
                                <h5 class="card-title text-uppercase mb-0 text-info">
                                    Assessor Documents
                                </h5>

                                <p *ngIf="assessor?.businessCertNo">
                                    <small class="d-block text-dark text-uppercase bold-text">Certificate of
                                        Incorporation</small>
                                    <a href="javascript:void(0)" (click)="viewAttachment(assessor?.businessCertImage)"
                                        class="font-weight-bold">{{ assessor?.businessCertNo }}</a>
                                </p>
                                <p *ngIf="assessor?.copNo">
                                    <small class="d-block text-dark text-uppercase bold-text">Certificate of
                                        Proficiency</small>
                                    <a href="javascript:void(0)" (click)="viewAttachment(assessor?.copImage)"
                                        class="font-weight-bold">{{ assessor?.copNo }}</a>
                                </p>
                                <p *ngIf="assessor?.kraPin">
                                    <small class="d-block text-dark text-uppercase bold-text">KRA PIN</small>
                                    <a href="javascript:void(0)" (click)="viewAttachment(assessor?.kraPinImage)"
                                        class="font-weight-bold">{{ assessor?.kraPin }}</a>
                                </p>

                                <p *ngIf="assessor?.nationalIdNo">
                                    <small class="d-block text-dark text-uppercase bold-text">KRA PIN</small>
                                    <span class="d-block">{{ assessor?.nationalIdNo }}</span>
                                    <a href="javascript:void(0)"
                                        (click)="viewAttachment(assessor?.nationalIdImageFront)">Front</a>
                                    <a href="javascript:void(0)" class="d-inline ml-2"
                                        (click)="viewAttachment(assessor?.nationalIdImageBack)">Back</a>
                                </p>
                            </div>
                            <div class="col-md-4">
                                <h5 class="card-title text-uppercase mb-0 text-info">
                                    Status & Approvals
                                </h5>

                                <p>
                                    <small class="d-block text-dark text-uppercase bold-text">Signup Status</small>
                                    <span *ngIf="
                          assessor?.hasOwnProperty('completed');
                          else signupPending
                        ">
                                        <small *ngIf="assessor?.completed; else signupPending"
                                            class="badge badge-success">Completed</small>
                                    </span>
                                    <ng-template #signupPending>
                                        <small class="badge badge-warning">Pending</small>
                                    </ng-template>
                                </p>

                                <p>
                                    <small class="d-block text-dark text-uppercase bold-text">mTek Approval</small>

                                    <span class="badge badge-success"
                                        *ngIf="isMtekApproved; else notMtekApproved">Approved</span>
                                    <ng-template #notMtekApproved>
                                        <span class="badge badge-danger">Approval Pending</span>
                                    </ng-template>
                                    <span class="ml-2" *ngIf="appService.isAdmin()">
                                        <mat-slide-toggle [checked]="isMtekApproved" (click)="mtekApproveAssessor()">
                                        </mat-slide-toggle>
                                    </span>
                                </p>

                                <p>
                                    <small class="d-block text-dark text-uppercase bold-text">Underwriter
                                        Approval</small>
                                    <span class="badge badge-info">{{ approvedUnderwriterCount }} out of
                                        {{ assessor?.underwriters.length }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </mat-tab>

    <mat-tab label="Underwriters">
        <div class="row">
            <div class="col-md-12">
                <div class="card section1">
                    <div class="card-body">
                        <h4 class="card-title text-uppercase mb-0 text-mtek">
                            <i class="fa fa-address-card-o text-mtek icon-big h5"></i>
                            Underwriters
                        </h4>
                        <hr />
                        <div class="card mb-2" *ngFor="let underwriter of underwriterList">
                            <div class="card-header">{{ underwriter.name }}</div>
                            <div class="card-body">
                                <div *ngIf="underwriter.approved; else notApproved">
                                    <div class="alert alert-success">
                                        This Assessor has been approved by
                                        {{ underwriter.name }}
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <p>
                                                <small
                                                    class="d-block text-uppercase bold-text text-info">Approver</small>
                                                {{ underwriter.name }}
                                            </p>
                                        </div>
                                        <div class="col-md-3">
                                            <p>
                                                <small
                                                    class="d-block text-uppercase bold-text text-info">Commission</small>
                                                {{ underwriter.commission }}%
                                            </p>
                                        </div>
                                        <div class="col-md-3">
                                            <p>
                                                <small class="d-block text-uppercase bold-text text-info">Approved
                                                    on</small>
                                                {{ underwriter._timestamp | date : 'medium' }}
                                            </p>
                                        </div>
                                        <div class="col-md-3">
                                            <button *ngIf="appService.isPartner()" class="btn btn-sm btn-danger"
                                                (click)="underwriterCancelAssessorApproval(underwriter)">
                                                Cancel Approval
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <ng-template #notApproved>
                                    <div *ngIf="appService.isAdmin()" class="alert alert-warning">
                                        This Assessor is yet to be approved by
                                        {{ underwriter.name }}
                                        <button class="btn btn-primary btn-sm"
                                            (click)="requestUnderwriterApproval(underwriter.id)">
                                            Request Underwrirter Approval
                                        </button>
                                    </div>
                                    <div *ngIf="appService.isPartner()">
                                        <div class="alert alert-warning">
                                            This assessor is yet to be approved by
                                            {{ underwriter.name }}.<br />
                                            To approve them, set their commission below.
                                        </div>

                                        <div class="row">
                                            <div class="col-md-3">
                                                <form [formGroup]="approveAssessorForm"
                                                    (ngSubmit)="underwriterApproveAssessor(underwriter)">
                                                    <mat-form-field>
                                                        <mat-label>Enter Percentage Commission</mat-label>
                                                        <input matInput type="number" formControlName="commission"
                                                            placeholder="e.g 10" />
                                                    </mat-form-field>
                                                    <button mat-button color="primary"
                                                        [disabled]="!approveAssessorForm.valid">
                                                        Submit
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>
            </div>

        </div>
    </mat-tab>

</mat-tab-group>