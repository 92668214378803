import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppEnums } from 'src/app/enums/app.enum';
import { AppService } from 'src/app/services/app.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-csv-to-json',
  templateUrl: './csv-to-json.component.html',
  styleUrls: ['./csv-to-json.component.scss'],
})
export class CsvToJsonComponent implements OnInit {
  isLoading: boolean = false;
  csvContent: string;
  items: Array<any> = [];
  properties: any = '';
  flag: boolean = false;
  @Output() itemsEvent: EventEmitter<any> = new EventEmitter<any>();
  optionsFormGroup: FormGroup;
  @Input() fields: string[] = [];

  constructor(
    public appService: AppService,
    private _formBuilder: FormBuilder
  ) {
    this.optionsFormGroup = this._formBuilder.group({
      startRow: [''],
      endRow: [''],
      startCol: [''],
      endCol: [''],
    });
  }

  ngOnInit(): void {}

  get fieldStrings() {
    return this.fields.toString();
  }

  onFileLoad = (fileLoadedEvent) => {
    const textFromFileLoaded = fileLoadedEvent.target.result;
    this.csvContent = textFromFileLoaded;

    //Flag is for extracting first line
    let flag = false;
    // Main Data
    let objarray: Array<any> = [];
    //Properties
    let prop: Array<any> = [];
    //Total Length
    let size: any = 0;
    this.isLoading = true;
    for (const line of this.csvContent.split(/[\r\n]+/)) {
      if (flag) {
        let obj = {};
        for (let k = 0; k < size; k++) {
          //Dynamic Object Properties
          let v = line.split(',')[k];
          if (v) {
            v = v.replace(/"|'/g, '').trim();
          }
          obj[prop[k]] = v;
        }
        objarray.push(obj);
      } else {
        //First Line of CSV will be having Properties
        for (let k = 0; k < line.split(',').length; k++) {
          size = line.split(',').length;
          //Removing all the spaces to make them usefull
          let x = line.split(',')[k].replace(/ /g, '').replace(/"|'/g, '');
          prop.push(x);
        }
        flag = true;
      }
    }
    this.items = objarray;
    this.properties = [];

    this.properties = prop;
    this.items.pop();
    this.flag = true;

    let filteredList: any = [];
    this.items.forEach((item) => {
      let myObj = item;
      for (var k in myObj) {
        if (!this.fields.includes(k)) {
          delete myObj[k];
        }
      }
      filteredList.push(myObj);
    });

    this.itemsEvent.emit(filteredList);
    this.isLoading = false;
  };

  onFileSelect(input: HTMLInputElement) {
    const files = input.files;
    var fileTypes = ['csv']; //acceptable file types

    if (files && files.length) {
      var extension = input.files[0].name.split('.').pop().toLowerCase(), //file extension from input file
        isSuccess = fileTypes.indexOf(extension) > -1; //is extension in acceptable types
      if (isSuccess) {
        const fileToRead = files[0];

        const fileReader = new FileReader();
        fileReader.onload = this.onFileLoad;

        fileReader.readAsText(fileToRead, 'UTF-8');
      } else {
        this.appService.showToastMessage(
          AppEnums.ToastTypeSuccess,
          'Invalid file type!',
          'Failed!'
        );
      }
    }
  }
}
