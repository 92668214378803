import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { IMotor } from 'src/app/models/motor.interface';

@Component({
  selector: 'app-motor-claim-list-table',
  templateUrl: './motor-claim-list-table.component.html',
  styleUrls: ['./motor-claim-list-table.component.scss'],
})
export class MotorClaimListTableComponent implements OnInit {
  @Input() allClaims: IMotor[] = [];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  goToClaimDetail(aClaim: IMotor) {
    this.router.navigate(['/motor-claims/details', aClaim._id]);
  }
}
