<h1 mat-dialog-title>Cancel Finance Plan</h1>
<div mat-dialog-content>
  <app-loader *ngIf="isLoading"></app-loader>
  <form [formGroup]="cancelPlanFormGroup" (submit)="submitCancellation">
    <div class="form-group">
      <label for="reason">Reason For Cancelling</label>
      <textarea
        id="reason"
        rows="3"
        class="form-control"
        formControlName="reason"
      ></textarea>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="closeDialog()">Close</button>
  <button mat-button color="primary" (click)="submitCancellation()">
    Submit
  </button>
</div>
