import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppEnums } from 'src/app/enums/app.enum';
import { AppService } from 'src/app/services/app.service';
import { CancelPolicyComponent } from '../cancel-policy/cancel-policy.component';
import { ChangePolicyPeriodComponent } from '../change-policy-period/change-policy-period.component';
import { SendDeclarationReportDialogComponent } from '../send-declaration-report-dialog/send-declaration-report-dialog.component';
import { Router } from '@angular/router';
import { UploadPolicyGroupMembersComponent } from '../upload-policy-group-members/upload-policy-group-members.component';
import { DeclareIceaRiskNoteComponent } from '../declare-icea-risk-note/declare-icea-risk-note.component';
import { AddCardMembershipInforPartialComponent } from '../add-card-membership-infor-partial/add-card-membership-infor-partial.component';

@Component({
  selector: 'app-policy-options',
  templateUrl: './policy-options.component.html',
  styleUrls: ['./policy-options.component.scss'],
})
export class PolicyOptionsComponent implements OnInit {
  @Input() policy: any;
  @Input() cover: string;
  @Output() reloadPolicyEvent = new EventEmitter();
  constructor(
    public appService: AppService,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {}
  popSendDeclarationReportDialog() {
    this.dialog.open(SendDeclarationReportDialogComponent, {
      width: '50%',
      data: {
        product: this.cover,
        _id: this.policy?._id,
        insurer: this.policy?.insurer,
      },
    });
  }
  popChangePolicyPeriodDialog() {
    let dialogRef = this.dialog.open(ChangePolicyPeriodComponent, {
      data: { cover: this.cover, policy: this.policy },
      width: '50%',
    });
    dialogRef.afterClosed().subscribe((policyId) => {
      this.reloadPolicyEvent.emit(policyId);
    });
  }
  popCancelPolicyDialog() {
    let dialogRef = this.dialog.open(CancelPolicyComponent, {
      data: { cover: this.cover, policy: this.policy },
      width: '50%',
    });
    dialogRef.afterClosed().subscribe((policyId) => {
      this.reloadPolicyEvent.emit(policyId);
    });
  }
  updatePolicyComponent() {
    if (this.cover === 'motor') {
      this.router.navigate(['manual-sale-motor/update'], {
        queryParams: { _id: this.policy?._id },
      });
    } else if (this.cover === 'medical') {
      this.router.navigate(['medical/list/medical-update'], {
        queryParams: { _id: this.policy?._id },
      });
    } else if (this.cover == 'travel') {
      this.router.navigate(['travel/manual-sales-travel/create'], {
        queryParams: { _id: this.policy?._id },
      });
    }
  }
  get isIceaMotor() {
    return (
      this.cover == 'motor' &&
      this.policy?.certificate?.complete &&
      this.policy.insurer === AppEnums.PartnerIdICEA
    );
  }

  popDeclereIceaRiskNoteDialog() {
    this.dialog.open(DeclareIceaRiskNoteComponent, {
      width: '50%',
      data: {
        product: this.cover,
        _id: this.policy?._id,
        insurer: this.policy?.insurer,
      },
    });
  }

  fileClaim() {
    switch (this.cover) {
      case 'motor':
        if (
          this.policy.stage === 'PAY' 
          && this.policy.hasOwnProperty('certificate')
        ) {
          this.router.navigate(['motor/claims/create'], {
            queryParams: { _id: this.policy?._id },
          });
        } else {
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            'Motor certificate and payment is required',
            'Cannot file claim'
          );
        }
        break;
      case 'gadget':
        if (this.policy.stage === 'PAY') {
          this.router.navigate(['gadget-claims/create'], {
            queryParams: { _id: this.policy?._id },
          });
        } else {
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            'Payment is required',
            'Cannot file claim'
          );
        }
        break;
      default:
        this.appService.showToastMessage(
          AppEnums.ToastTypeWarning,
          `Claims for ${this.cover} coming soon`,
          ''
        );
        break;
    }
  }

  popUploadGroupMembers() {
    let dialogRef = this.dialog.open(UploadPolicyGroupMembersComponent, {
      data: { cover: this.cover, policy: this.policy },
      width: '90%',
    });
    dialogRef.afterClosed().subscribe((policyId) => {
      this.reloadPolicyEvent.emit(policyId);
    });
  }

  popAddMembershipInfo() {
    let dialogRef = this.dialog.open(AddCardMembershipInforPartialComponent, {
      data: { cover: this.cover, policy: this.policy },
      width: '50%',
    });
    dialogRef.afterClosed().subscribe((policyId) => {
      this.reloadPolicyEvent.emit(policyId);
    });
  }
}
