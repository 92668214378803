import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppEnums } from 'src/app/enums/app.enum';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

export interface IFormQuery {
  startDate: number;
  endDate: number;
  refCode: string;
}

@Component({
  selector: 'app-county-reps-report',
  templateUrl: './county-reps-report.component.html',
  styleUrls: ['./county-reps-report.component.scss'],
})
export class CountyRepsReportComponent implements OnInit {
  isLoading: boolean = false;
  formGroupDownloadReport: FormGroup;
  queryData: IFormQuery;
  constructor(
    private appService: AppService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.formGroupDownloadReport = this.formBuilder.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
  }

  ngOnInit(): void {}

  submitData() {
    if (this.isFormValid(this.formGroupDownloadReport)) {
      this.isLoading = true;
      this.generateDeclarationReport({
        startDate: this.getQueryData()?.startDate,
        endDate: this.getQueryData()?.endDate,
      }).subscribe(
        (res) => {
          if (res.Status == 200) {
            this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              res.Message,
              res.Payload
            );
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeError,
              res.Message,
              res.Payload
            );
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          if (err.status == 422) {
            if (err.error?.Payload?.data[0] === 'The data field is required.') {
              this.appService.showToastMessage(
                AppEnums.ToastTypeWarning,
                '',
                'Data not found. Report is empty'
              );
            }
          } else if (err.status == 500) {
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              '',
              'Data not found. Report is empty'
            );
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeError,
              '',
              this.appService.processHttpErrors(err)
            );
          }
        }
      );
    }
  }

  getQueryData(): IFormQuery {
    let startDate = this.formGroupDownloadReport.value.startDate;
    let endDate = this.formGroupDownloadReport.value.endDate;
    this.queryData = {
      startDate: Number(Date.parse(startDate)),
      endDate: Number(Date.parse(endDate)),
      refCode: this.data.referralCode ? this.data.referralCode : 'all',
    };
    return this.queryData;
  }

  generateDeclarationReport({ startDate, endDate }: any) {
    let responseData = {
      startDate: startDate,
      endDate: endDate,
      refCode: this.data.referralCode ? this.data.referralCode : 'all',
      sendTo: this.appService.getSessionUser().email,
    };

    return this.appService.makePostRequest(
      `${environment.DATA_ANALYTICS_API_URL}/sales-agents//county-reps-sales_report`,
      responseData
    );
  }

  isFormValid(form: FormGroup): boolean {
    let startDate = form.value.startDate;
    let endDate = form.value.endDate;
    if (form.invalid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Invalid Form'
      );
      return false;
    }
    if (endDate.getTime() < startDate.getTime()) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Invalid Start/End Date Selection'
      );
      return false;
    }
    return true;
  }
}
