import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppEnums } from 'src/app/enums/app.enum';
import { IPatapesaPagination } from 'src/app/models/pagination.interface';
import { IUser } from 'src/app/models/user.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-policy-client-partial',
  templateUrl: './policy-client-partial.component.html',
  styleUrls: ['./policy-client-partial.component.scss']
})
export class PolicyClientPartialComponent implements OnInit {

  @Input() feduid: string;
  @Input() clientData: any;
  userDetails: IUser;
  valuerDetails: IUser;
  assessorDetails: IUser;
  referralDetails: IUser[] = [];
  isLoading: boolean = false;
  sasToken: string;
  userRoles: string[] = [];
  referralCode: any;
  referrerCode: any;

  dataLoading: boolean = false;
  dataLength: number = 0;
  isSearchData: boolean = false;
  allDataLoaded: boolean = false;

  pagination: IPatapesaPagination = {
    limit: 10,
    skip: 0,
    sort: { 'bimaProfile.balance': -1 },
  };
  bPpagination: IPatapesaPagination = {
    limit: 10,
    skip: 0,
    sort: { 'bimaProfile.balance': -1 },
  };

  searchParams = {
    url: `${environment.CORE_API_URL}/listUsers`,
    searchFields: ['userName', 'email', 'phoneNumber', 'idNumber', 'kraPin'],
    customQuery: {
      roles: { $elemMatch: { client: true } },
      fields: [
        '_id',
        'feduid',
        'insurer',
        'userName',
        'email',
        'phoneNumber',
        'idNumber',
        'kraPin',
        '_timestamp',
        'bimaProfile',
      ],
      pagination: this.pagination,
    },
  };

  constructor(public appService: AppService, private router: Router) { }

  ngOnInit(): void {
    this.appService.getSasToken().subscribe((sasToken) => {
      this.sasToken = sasToken;
    });

    if (this.feduid) {
      this.getUserDetailsByFeduid();
    } else {
      this.userDetails = this.clientData
    }

  }

  getUserDetailsByFeduid() {
    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.CORE_API_URL}/getUserDetailsByFeduid`, {
        feduid: this.feduid,
        fields: [],
      })
      .subscribe(
        (apiResponse) => {
          if (apiResponse.Status == 200) {
            this.userDetails = apiResponse.Payload;
            this.userRoles = this.appService.getRoles(this.userDetails?.roles);
            let principleId = this.userDetails?._id;

            let principleFeduid = this.userDetails?.principleFeduid;

            this.referralCode = this.userDetails?.bimaProfile?.referralCode;
            this.referrerCode = this.userDetails?.bimaProfile?.referrerCode;
          } else {
            this.isLoading = false;
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              apiResponse.Status + '',
              apiResponse.Payload
            );
          }
        },
        (err) => {
          this.isLoading = true;
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
        }
      );
  }


  goToProfile() {
    this.router.navigate(['/users/list-client-details', this.feduid]);
  }

}
