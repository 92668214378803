import { Component, Inject, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppEnums } from 'src/app/enums/app.enum';
import { SendNotificationComponent } from 'src/app/notification/send-notification/send-notification.component';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import { IIntent } from 'src/app/models/intent.interface';

@Component({
  selector: 'app-user-send-notification',
  templateUrl: './user-send-notification.component.html',
  styleUrls: ['./user-send-notification.component.scss'],
})
export class UserSendNotificationComponent implements OnInit {
  sendNotifications: FormGroup;
  isLoading: boolean = false;

  templates = [
    { id: '1st update reminder', value: 'client-update-is-coming' },
    { id: '2nd update reminder', value: 'update-app-not-backwards-compatible' },
    { id: 'Last update reminder ', value: 'client-update-reminder' },
    { id: 'Update App', value: 'update-app' },
    { id: 'New features', value: 'updated-features' },
    { id: 'Holiday', value: 'Holiday' },
    { id: 'Others', value: 'Others' },
  ];

  intents: IIntent[] = [
    { value: 'update', viewValue: 'Update' },
    { value: 'holiday', viewValue: 'Holiday' },
    { value: 'others', viewValue: 'Others' },
    { value: 'earned_bima_points', viewValue: 'Bima Points' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SendNotificationComponent>
  ) {
    this.sendNotifications = this.formBuilder.group({
      title: new FormControl('', Validators.required),
      message: new FormControl('', Validators.required),
      intent: new FormControl('', Validators.required),
      template: new FormControl('', Validators.required),
      analyticsLabel: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {}

  submit() {
    if (!this.sendNotifications.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeError,
        'Fail',
        'Fill in the required input fields'
      );
      return;
    } else {
      var query = this.data;
      var title = this.sendNotifications.get('title').value;
      var template = this.sendNotifications.get('template').value;
      var message = this.sendNotifications.get('message').value;
      var intent = this.sendNotifications.get('intent').value;
      var analyticsLabel = this.sendNotifications.get('analyticsLabel').value;
      this.appService
        .makePostRequest(`${environment.CORE_API_URL}/pushnotification`, {
          query,
          title,
          template,
          message,
          intent,
          analyticsLabel,
        })
        .subscribe((apiResponse) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            '',
            apiResponse.Message
          );
        });
      this.closeDialog();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
