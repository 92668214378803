<h1 mat-dialog-title>Change Policy Period</h1>
<div mat-dialog-content>

    <p class="h4" *ngIf="
    data.policy.hasOwnProperty('starttimestamp') &&
    data.policy.hasOwnProperty('endtimestamp');
  ">
        <small class="text-muted d-block">Current Policy Period</small>
        {{ data.policy?.starttimestamp | date: 'mediumDate' }} -
        {{ data.policy?.endtimestamp | date: 'mediumDate' }}
    </p>
    <app-loader *ngIf="isLoading"></app-loader>
    <form [formGroup]="formGroup" (submit)="submit()">
        <div class="form-group">
            <label for="startDate">Start Date</label>
            <div class="input-group">
                <input class="form-control" placeholder="yyyy-mm-dd" name="startDate" formControlName="startDate"
                    ngbDatepicker #dStartDate="ngbDatepicker" />
                <div class="input-group-append">
                    <button type="button" class="btn btn-primary" (click)="dStartDate.toggle()" type="button">
                        <i class="fa fa-calendar"></i>
                    </button>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label for="endDate">End Date</label>
            <div class="input-group">
                <input class="form-control" placeholder="yyyy-mm-dd" name="endDate" formControlName="endDate"
                    ngbDatepicker #dendDate="ngbDatepicker" />
                <div class="input-group-append">
                    <button type="button" class="btn btn-primary" (click)="dendDate.toggle()" type="button">
                        <i class="fa fa-calendar"></i>
                    </button>
                </div>
            </div>
        </div>

    </form>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
    <button mat-button color="primary" [disabled]="!formGroup.valid" (click)="submit()">Submit</button>
</div>