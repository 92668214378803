<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <div class="d-flex justify-content-between">
          <h2 class="text-mtek">
            <i class="fa fa-handshake-o text-mtek fa-2x"></i>
            <span class="text-capitalize" *ngIf="productName; else isProduct">
              {{ productName }}</span
            >
            Commissions
          </h2>
          <!-- <div class="col-md-4 d-flex justify-content-end">
            <a
              href="javascript:void(0)"
              (click)="popCommissionReportDialog()"
              class="text-mtek bold-text mr-3"
            >
              <i class="vsm-icon pe-7s-cloud-download h6"></i>
              <span class="aligned-with-icon h6"> Download Statement</span>
            </a>
            <a
              href="javascript:void(0)"
              (click)="popCommissionBulkPaymentDialog()"
              class="text-mtek bold-text"
            >
              <i class="vsm-icon pe-7s-cloud-upload h6"></i>
              <span class="aligned-with-icon h6"> Record Payment</span>
            </a>
          </div> -->
        </div>
        <app-loader *ngIf="commissionDataLoading"></app-loader>
        <div class="row mt-2">
          <div class="col-md-12 mb-3">
            <app-aggregate-finance-search
              [searchParams]="searchParamsCommissions"
              (searchResults)="showCommissionSearchResults($event)"
              (InputEmpty)="resetCommissionData()"
              (searchItem)="updateSearchInput($event)"
            >
            </app-aggregate-finance-search>
          </div>
        </div>
        <div
          *ngIf="
            allCommissionDataLoaded && allCommissions.length > 0;
            else noCommissionBalances
          "
        >
          <div class="table-responsive-sm mt-3">
            <table class="table table-striped table-responsive-sm">
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Client</th>
                <th scope="col">Total</th>
                <th scope="col">mTek</th>
                <th scope="col">Patapesa</th>
                <th scope="col">County Reps</th>
                <th scope="col">Embedded</th>
                <th scope="col">Consultant</th>
                <th scope="col">Prime Partner</th>
              </tr>

              <tbody>
                <tr
                  *ngFor="let policy of allCommissions"
                  (click)="goToPolicyDetails(policy.quoteID)"
                  class="section-step"
                >
                  <td class="p-3">
                    <small>
                      {{ policy?._timestamp | date : 'medium' }}
                    </small>
                  </td>
                  <td class="p-3">
                    <small>
                      <span *ngIf="policy?.xusr?.userName; else isNa">
                        {{ policy?.xusr?.userName }}
                      </span>
                      <ng-template #isNa>
                        <span>NA</span>
                      </ng-template>
                      <br />
                      <span *ngIf="policy?.xusr?.userNamepolicy?.xusr?.email">
                        {{ policy?.xusr?.userNamepolicy?.xusr?.email }}
                      </span>
                    </small>
                  </td>

                  <td class="p-3 text-capitalize">
                    <small>
                      {{ policy?.totalCommission | currency : 'Ksh. ' }}
                    </small>
                  </td>
                  <td class="p-3 text-capitalize">
                    <small>
                      {{ policy?.mtekCommission | currency : 'Ksh. ' }}
                    </small>
                  </td>
                  <td class="p-3 text-capitalize">
                    <small>
                      {{ policy?.patapesaCommission | currency : 'Ksh. ' }}
                    </small>
                  </td>
                  <td class="p-3 text-capitalize">
                    <small>
                      {{ policy?.countyRepCommission | currency : 'Ksh. ' }}
                    </small>
                  </td>
                  <td class="p-3 text-capitalize">
                    <small>
                      {{ policy?.embeddedCommission | currency : 'Ksh. ' }}
                    </small>
                  </td>
                  <td class="p-3 text-capitalize">
                    <small>
                      {{ policy?.consultantCommission | currency : 'Ksh. ' }}
                    </small>
                  </td>
                  <td class="p-3 text-capitalize">
                    <small>
                      {{ policy?.primePartnerCommission | currency : 'Ksh. ' }}
                    </small>
                  </td>
                  <td class="p-3">
                    <i class="vsm-icon pe-7s-angle-right"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <app-pagination
            class="section2"
            *ngIf="!isCommissionSearchData"
            [skip]="CommissionPagination.skip"
            [limit]="CommissionPagination.limit"
            [dataLength]="dataPolicyLength"
            [dataLoading]="commissionDataLoading"
            (newSkip)="updateCommissionSkip($event)"
          ></app-pagination>

          <app-pagination
            class="section2"
            *ngIf="isCommissionSearchData"
            [skip]="CommissionPagination.skip"
            [limit]="CommissionPagination.limit"
            [dataLength]="dataPolicyLength"
            [dataLoading]="commissionDataLoading"
            (newSkip)="updateCommissionSearchSkip($event)"
          ></app-pagination>
        </div>
        <ng-template #noCommissionBalances>
          <div class="alert alert-success" role="alert">
            All commissions have been received
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <ng-template #isProduct>
    <span class="text-capitalize"> {{ product }}</span></ng-template
  >
</div>
