import { Component, OnInit, HostBinding } from '@angular/core';
import { ThemeOptions } from '../base-layout/theme-options';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @HostBinding('class.isActive')
  get isActiveAsGetter() {
    return this.isActive;
  }

  isActive: boolean;
  public config$: Observable<any>;
  public authConfig$: Observable<any>;

  constructor(
    public globals: ThemeOptions,
    private store: Store<any>,
    public appService: AppService
  ) {}

  ngOnInit(): void {
    this.config$ = this.store.select('templateConfig');
  }

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

  toggleHeaderMobile() {
    this.globals.toggleHeaderMobile = !this.globals.toggleHeaderMobile;
  }
}
