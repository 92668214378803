import { Component, OnInit } from '@angular/core';
import { ThemeOptions } from '../base-layout/theme-options';
import { ITemplateConfig } from 'src/app/models/template-config.interface';
import { Store } from '@ngrx/store';
import { UpdateConfigAction } from '../../store/actions/index';

@Component({
  selector: 'app-options-drawer',
  templateUrl: './options-drawer.component.html',
  styleUrls: ['./options-drawer.component.scss'],
})
export class OptionsDrawerComponent implements OnInit {
  toggleOptionsDrawer() {
    this.globals.toggleThemeOptions = !this.globals.toggleThemeOptions;
  }

  toggleFixedFooter() {
    this.globals.toggleFixedFooter = !this.globals.toggleFixedFooter;
  }

  constructor(public globals: ThemeOptions, private store: Store<any>) {}
  ngOnInit(): void {}

  updateConfig(templateConfig: ITemplateConfig) {
    this.store.dispatch(UpdateConfigAction(templateConfig));
  }
}
