<div class="card card-stats">
  <div class="card-body">
    <div class="d-flex justify-content-between align-items-center">
      <h4 class="card-title text-uppercase mb-2 text-mtek d-inline">
        <i class="fa fa-car text-mtek h5"></i>
        Edit Cover Information
      </h4>
      <button
        class="btn btn-outline-danger mb-2"
        *ngIf="appService.isAdmin()"
        (click)="cancelEditMode()"
      >
        Cancel
      </button>
    </div>

    <hr />
    <app-loader *ngIf="isLoading"></app-loader>
    <div>
      <form [formGroup]="coverDetailsFormGroup">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="bodyType"
                >Basic Premium <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                id="basicPremium"
                formControlName="basicPremium"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="amount"
                >Amount / Premium Amount
                <span class="text-danger">*</span></label
              >
              <input
                type="number"
                class="form-control"
                min="0"
                id="amount"
                formControlName="amount"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="paidamount"
                >Paid Amount <span class="text-danger">*</span></label
              >
              <input
                min="0"
                type="number"
                class="form-control"
                id="paidamount"
                formControlName="paidamount"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="assured"
                >Sum Assured <span class="text-danger">*</span></label
              >
              <input
                min="0"
                type="number"
                class="form-control"
                id="assured"
                formControlName="assured"
              />
            </div>
          </div>
          <div class="col-md-3" *ngIf="policy?.paymentdetails?.financed">
            <div class="form-check mt-4">
              <input
                class="form-check-input"
                [checked]="checked"
                formControlName="check"
                type="checkbox"
                id="defaultCheck1"
              />
              <label class="form-check-label" for="defaultCheck1">
                Update finance plan
              </label>
            </div>
          </div>
        </div>
      </form>
      <p class="buttons d-flex justify-content-end align-items-center">
        <app-loader *ngIf="isLoading"></app-loader>
        <button
          (click)="submitCoverDetails()"
          type="submit"
          class="btn btn-outline-primary"
        >
          Update
        </button>
      </p>
    </div>

    <div class="d-flex justify-content-start align-items-center">
      <h4 class="text-mtek mt-2 mb-b mr-3">Do you want to Edit Taxes:</h4>
      <ng-template #showYes>
        <button
          class="btn btn-outline-primary mr-3"
          (click)="onShowTaxesForm()"
        >
          Yes
        </button>
      </ng-template>
      <button
        class="btn btn-outline-danger"
        (click)="onHideTaxesForm()"
        *ngIf="showTaxesForm; else showYes"
      >
        No
      </button>
    </div>
    <hr />
    <app-statutory-premiums
      [selectedRecord]="policy"
      [cover]="cover"
      (statusUpdate)="onReceiveTaxes($event)"
      *ngIf="showTaxesForm"
    ></app-statutory-premiums>
    <br />
    <br *ngIf="showTaxesForm" />
    <hr *ngIf="showTaxesForm" />

    <div class="d-flex justify-content-start align-items-center">
      <h4 class="text-mtek mt-2 mb-b mr-3">
        Do you want to Edit Policy Start Date:
      </h4>
      <ng-template #hidePolicyStartDateForm>
        <button
          class="btn btn-outline-primary mr-3"
          (click)="onShowPolicyStartDateForm()"
        >
          Yes
        </button>
      </ng-template>
      <button
        class="btn btn-outline-danger"
        (click)="onHidePolicyStartDateForm()"
        *ngIf="showPolicyStartDateForm; else hidePolicyStartDateForm"
      >
        No
      </button>
    </div>
    <hr />
    <app-update-policy-start-date
      [cover]="cover"
      [questions_id]="policy?.questions_id"
      (statusUpdate)="onReceivePolicyUpdateStatus($event)"
      *ngIf="showPolicyStartDateForm"
    ></app-update-policy-start-date>
    <br *ngIf="showPolicyStartDateForm" />
    <hr *ngIf="showPolicyStartDateForm" />
  </div>
</div>
