<h2>Valuation Summary</h2>

<div class="row">
  <!-- Total Valuations -->
  <div class="col-lg-4 card-cont">
    <div class="card tot-card-wrapper com-card">
      <div class="text-center texts-container">
        <h5 class="card-title text-uppercase text-mtek mb-0">
          Total Valuations
        </h5>
        <hr />
        <span *ngIf="dataLoaded">
          <span *ngIf="totalValuations !== 0; else isZero" class="h2 mb-0"
            >{{ totalValuations }}
          </span>
          <ng-template #isZero>
            <div class="alert alert-info" role="alert">No Valuations Yet</div>
          </ng-template>
        </span>
        <app-loader *ngIf="!dataLoaded"></app-loader>
      </div>
    </div>
  </div>

  <!-- Total Pending Valuations -->
  <div class="col-lg-4 card-cont">
    <div class="card tot-card-wrapper com-card">
      <div class="text-center texts-container">
        <h5 class="card-title text-uppercase text-mtek mb-0">
          Total Pending Valuations
        </h5>
        <hr />
        <span *ngIf="dataLoaded">
          <span
            *ngIf="totalPendingValuations !== 0; else isZero"
            class="h2 mb-0"
            >{{ totalPendingValuations }}
          </span>
          <ng-template #isZero>
            <div class="alert alert-info" role="alert">
              No Pending Valuations Yet
            </div>
          </ng-template>
        </span>
        <app-loader *ngIf="!dataLoaded"></app-loader>
      </div>
    </div>
  </div>

  <!-- Total Submitted Valuations -->
  <div class="col-lg-4 card-cont">
    <div class="card tot-card-wrapper">
      <div class="text-center texts-container">
        <h5 class="card-title text-uppercase text-mtek mb-0">
          Total Submitted Valuations
        </h5>
        <hr />
        <span *ngIf="dataLoaded">
          <span
            *ngIf="totalSubmittedValuations !== 0; else isZero"
            class="h2 mb-0"
            >{{ totalSubmittedValuations }}
          </span>
          <ng-template #isZero>
            <div class="alert alert-info" role="alert">
              No Submitted Valuations Yet
            </div>
          </ng-template>
        </span>
        <app-loader *ngIf="!dataLoaded"></app-loader>
      </div>
    </div>
  </div>
</div>

<hr />
