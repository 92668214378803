<h1 mat-dialog-title>
  Record Bulk Commission Payments for {{ data?.product | uppercase }}
</h1>
<div mat-dialog-content>
  <app-loader *ngIf="isLoading"></app-loader>
  <form [formGroup]="formGroupAddBulkCommission">
    <mat-form-field>
      <mat-label>Underwriter</mat-label>
      <mat-select formControlName="underwriter">
        <mat-option
          *ngFor="let underwriter of appService.getMotorUnderwriters | async"
          [value]="underwriter?.insurer"
        >
          {{ underwriter?.underwriter }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Commission Amount Received</mat-label>
      <input type="number" matInput formControlName="received" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Reference</mat-label>
      <input type="text" matInput formControlName="reference" />
    </mat-form-field>
  </form>

  <div
    class="alert alert-info"
    *ngIf="bulkCommissionPaymentResponse.quotes.length > 0"
  >
    <p class="h5">
      Received Commission:
      {{ bulkCommissionPaymentResponse.received | currency : ' ' }}
    </p>
    <p class="h5">
      Disbursed Amount:
      {{ bulkCommissionPaymentResponse.disbursed | currency : ' ' }}
    </p>
    <p class="h5">
      Balance:
      {{
        bulkCommissionPaymentResponse.received -
          bulkCommissionPaymentResponse.disbursed | currency : ' '
      }}
    </p>
    <hr />
    <p>
      <small
        class="d-block"
        *ngFor="let item of bulkCommissionPaymentResponse.quotes"
      >
        {{ item }}
      </small>
    </p>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
  <button
    *ngIf="!isLoading && bulkCommissionPaymentResponse.quotes.length == 0"
    mat-button
    color="primary"
  >
    Submit Commission
  </button>
</div>
