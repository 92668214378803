<div class="row">
    <div class="col-md-12">
        <div class="card section1">
            <div class="card-body">
                <h4 class="card-title text-uppercase mb-0 text-mtek">
                    <i class="fa fa-address-card-o text-mtek icon-big h5"></i>
                    User Details
                </h4>
                <hr />

                <div class="row">
                    <div class="col-md-3">
                        <h5 class="card-title text-uppercase mb-0 text-info">
                            Personal Details
                        </h5>
                        <hr />
                        <p>
                            <strong>
                                <small class="d-block text-dark text-uppercase bold-text">Name</small>
                            </strong>
                            {{ userDetails?.userName || userDetails?.name}}
                        </p>
                        <p>
                            <small class="d-block text-dark text-uppercase bold-text">Email</small>
                            {{ userDetails?.email }}
                        </p>
                        <p>
                            <small class="d-block text-dark text-uppercase bold-text">Phone Number</small>
                            {{ userDetails?.phoneNumber || userDetails?.phone}}
                        </p>
                        <p>
                            <small class="d-block text-dark text-uppercase bold-text">Birthday</small>
                            {{ userDetails?.dob }}
                        </p>
                        <p>
                            <small class="d-block text-dark text-uppercase bold-text">Occupation</small>
                            <span *ngIf="userDetails?.occupation; else principleProfile">
                                {{ userDetails?.occupation }}
                            </span>
                            <ng-template #principleProfile>
                                <span>{{
                                    assessorDetails?.principleProfile?.occupation
                                    }}</span>
                            </ng-template>
                        </p>
                        <p>
                            <small class="d-block text-dark text-uppercase bold-text">Employment Status</small>
                            {{ userDetails?.employmentStatus }}
                        </p>

                        <p>
                            <small class="d-block text-dark text-uppercase bold-text">Spouse or Kids</small>
                            <span *ngIf="userDetails?.spouseOrKids; else single">
                                {{ userDetails?.spouseOrKids }}
                            </span>

                            <ng-template #single>
                                {{ assessorDetails?.principleProfile?.maritalStatus }}
                            </ng-template>
                        </p>

                        <p>
                            <small class="d-block text-dark text-uppercase bold-text">Monthly Income</small>
                            {{ userDetails?.monthlyIncome }}
                        </p>

                        <p>
                            <small class="d-block text-dark text-uppercase bold-text">Own a Car</small>
                            {{ userDetails?.ownACar }}
                        </p>
                        <br />
                        <br />
                        <br />
                    </div>
                    <div class="col-md-3">
                        <h5 class="card-title text-uppercase mb-0 text-info">
                            Identification Details
                        </h5>
                        <hr />
                        <p>
                            <small class="d-block text-dark text-uppercase bold-text">ID Number</small>
                            <span *ngIf="userDetails?.idNumber; else nationalIdNo">
                                {{ userDetails?.idNumber }}
                            </span>

                            <ng-template #nationalIdNo>
                                {{ valuerDetails?.nationalIdNo }}
                            </ng-template>
                        </p>
                        <p>
                            <small class="d-block text-dark text-uppercase bold-text">Passport Number</small>
                            {{ userDetails?.passportNumber }}
                        </p>
                        <p>
                            <small class="d-block text-dark text-uppercase bold-text">KRA PIN</small>
                            <span *ngIf="userDetails?.kraPin; else valuerKra">
                                {{ userDetails?.kraPin }}
                            </span>
                            <ng-template #valuerKra>
                                {{ valuerDetails?.kraPin }}
                            </ng-template>
                        </p>
                        <p>
                            <small class="d-block text-dark text-uppercase bold-text">Nationality</small>
                            {{ userDetails?.nationality }}
                        </p>
                        <p>
                            <small class="d-block text-dark text-uppercase bold-text">Driving License</small>
                            {{ userDetails?.driverLicense }}
                        </p>

                        <p *ngIf="valuerDetails?.copNo">
                            <small class="d-block text-dark text-uppercase bold-text">COP Number</small>
                            {{ valuerDetails?.copNo }}
                        </p>

                        <p *ngIf="valuerDetails?.businessCertNo">
                            <small class="d-block text-dark text-uppercase bold-text">Business Certificate
                                Number</small>
                            {{ valuerDetails?.businessCertNo }}
                        </p>
                    </div>
                    <div class="col-md-3">
                        <h5 class="card-title text-uppercase mb-0 text-info">
                            Address Details
                        </h5>
                        <hr />
                        <p>
                            <small class="d-block text-dark text-uppercase bold-text">Physical Address</small>
                            {{ userDetails?.physicalAddress }}
                        </p>

                        <p>
                            <small class="d-block text-dark text-uppercase bold-text">Postal Address</small>
                            {{ userDetails?.postalAddress }}
                        </p>
                        <p>
                            <small class="d-block text-dark text-uppercase bold-text">Postal Code</small>
                            {{ userDetails?.postalCode }}
                        </p>

                        <h5 class="card-title text-uppercase mb-0 text-info">
                            User Roles
                        </h5>
                        <hr />
                        <p>
                            <span *ngFor="let arole of userRoles" class="badge text-muted mr-5">{{ arole
                                }}</span>
                        </p>
                    </div>

                    <div class="col-md-3">
                        <h5 class="card-title text-uppercase mb-0 text-info">
                            Bima Profile
                        </h5>
                        <hr />
                        <div *ngIf="userDetails?.bimaProfile; else noBimaProfile">
                            <p>
                                <small class="d-block text-dark text-uppercase bold-text">Referal Code</small>
                                {{ userDetails?.bimaProfile?.referralCode }}
                            </p>

                            <p>
                                <small class="d-block text-dark text-uppercase bold-text">Total Points</small>
                                {{ userDetails?.bimaProfile?.totalPoints }}
                            </p>

                            <p>
                                <small class="d-block text-dark text-uppercase bold-text">Balance Points</small>
                                {{ userDetails?.bimaProfile?.balance }}
                            </p>

                            <p>
                                <small class="d-block text-dark text-uppercase bold-text">Redeemed
                                    Points</small>
                                {{ userDetails?.bimaProfile?.redeemed }}
                            </p>

                            <p>
                                <small class="d-block text-dark text-uppercase bold-text">Referals</small>
                                {{ userDetails?.bimaProfile?.referrals }}
                            </p>

                            <p>
                                <small class="d-block text-dark text-uppercase bold-text">Referrer Code</small>
                                <span *ngIf="
                          userDetails?.bimaProfile?.referrerCode;
                          else noReferrerCode
                        ">
                                    {{ userDetails?.bimaProfile?.referrerCode }}
                                </span>

                                <ng-template #noReferrerCode>
                                    <div class="alert alert-info">No referrerCode</div>
                                </ng-template>
                            </p>
                        </div>
                    </div>
                    <ng-template #noBimaProfile>
                        <div class="alert alert-info" role="alert">
                            No Bima Profile Found.
                        </div>
                    </ng-template>
                </div>
                <button *ngIf="feduid" class="btn btn-sm btn-outline-info mb-3" (click)="goToProfile()">
                    Go to Policy
                </button>
                <p class="mb-0">
                    <span class="text-info"> Created on : </span>
                    {{ userDetails?._utimestamp | date }}
                </p>
                <p class="mb-0">
                    <span class="text-info"> Last updated on : </span>
                    {{ userDetails?._utimestamp | date }}
                </p>
                <hr />
            </div>
        </div>
    </div>
</div>