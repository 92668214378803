<div class="sidenav" [ngClass]="collapsed ? 'sidenav-collapsed' : ''">
  <div class="logo-container">
    <button class="logo" (click)="toggleCollapse()">
      <i class="fa fa-bars" aria-hidden="true"></i>
    </button>
    <div class="logo-text" @fadeInOut *ngIf="collapsed">
      <img src="assets/images/auth/logo.png" width="160px" />
    </div>
    <button class="btn-close" @rotate *ngIf="collapsed" (click)="closeSidenav()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
  </div>

  <div class="scrollwrap scrollwrap_delayed">
    <ul class="sidenav-nav">
      <ng-container *ngFor="let data of navData">
        <li class="sidenav-nav-item" *ngIf="
            (data?.roles && !data.items) ||
            (data?.roles && data.items && data.items.length === 0)
          ">
          <a class="sidenav-nav-link" [routerLink]="[data.link]" routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }">
            <i class="sidenav-link-icon" [class]="data.icon"></i>
            <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">
              {{ data.policyName }}
            </span>
          </a>
        </li>
      </ng-container>

      <ng-container *ngFor="let data of navData">
        <li class="sidenav-nav-item" *ngIf="data?.roles && data.items && data.items.length > 0">
          <a class="sidenav-nav-link" (click)="handleClick(data)" [ngClass]="getActiveClass(data)">
            <i class="sidenav-link-icon" [class]="data.icon"></i>
            <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">
              {{ data.policyName }}
            </span>
            <i *ngIf="data.items && collapsed" class="menu-collapsed-icon" aria-hidden="true" [ngClass]="
                !data.expanded ? 'fa fa-angle-right' : 'fa fa-angle-down'
              "></i>
          </a>
          <div *ngIf="
              collapsed && data.items && data.items.length > 0 && data?.roles
            ">
            <app-sidebar-sublevel-menu [data]="data" [collapsed]="collapsed" [multiple]="multiple"
              [expanded]="data.expanded"></app-sidebar-sublevel-menu>
          </div>
        </li>
      </ng-container>

      <div class="sidenav-nav-item">
        <a class="sidenav-nav-link" target="_blank"
          href="https://mtekcdnstoragev2.blob.core.windows.net/admin/mTek_Web_Administration_Portal_User_Manual.pdf">
          <i class="sidenav-link-icon vsm-icon pe-7s-notebook"></i>
          <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">
            User Manual
          </span>
        </a>
      </div>
    </ul>
  </div>
</div>