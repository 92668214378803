<mat-accordion *ngIf="appService.hasPermission('admin-policy-record-commissions')">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title class="card-title text-uppercase mb-0 text-mtek"> Record commissions from
                underwriter</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
            <div class="col-md-12">
                <app-loader *ngIf="isLoading"></app-loader>
                <form [formGroup]="formGroupPostCommission" (submit)="submitCommission()">
                    <div class="row mt-3">
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-label>Amount Received</mat-label>
                                <input type="number" matInput placeholder="Amount Received" formControlName="amount" />
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-label>Payment Reference</mat-label>
                                <input matInput placeholder="Payment Reference" formControlName="reference" />
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-label>Payment Channel</mat-label>
                                <mat-select formControlName="paymentMode">
                                    <mat-option *ngFor="let p of appService.getPaymentChannels" [value]="p">
                                        {{p }}
                                    </mat-option>

                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-label>Payment Date</mat-label>
                                <input matInput [matDatepicker]="paymentDatePicker" placeholder="Payment Date"
                                    formControlName="paymentDate" />
                                <mat-datepicker-toggle matSuffix [for]="paymentDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #paymentDatePicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3"> <button type="submit" class="btn btn-primary btn-sm">
                                Submit Commission
                            </button></div>
                    </div>
                </form>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>