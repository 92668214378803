import { Component, Inject, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppEnums } from 'src/app/enums/app.enum';
import { IUser } from 'src/app/models/user.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
  userDetails: IUser;
  isLoading = false;
  changPasswordForm: FormGroup;
  visibility: string = 'password';

  constructor(
    private appService: AppService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: IUser,
    public dialogRef: MatDialogRef<PasswordResetComponent>
  ) {
    this.userDetails = this.data;
    this.changPasswordForm = this.formBuilder.group({
      password: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {}

  togglePasswordVisibility() {
    if (this.visibility == 'password') {
      this.visibility = 'text';
    } else {
      this.visibility = 'password';
    }
  }

  submit() {
    this.isLoading = true;
    if (
      this.changPasswordForm.get('password').value ==
      this.changPasswordForm.get('confirmPassword').value
    ) {
      this.appService
        .makePostRequest(`${environment.CORE_API_URL}/resetpassword`, {
          feduid: this.userDetails.feduid,
          pass: this.changPasswordForm.get('password').value,
        })
        .subscribe((response) => {
          if (response.Status == 200) {
            this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              response.Status + '',
              response.Message
            );
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeError,
              'Failed',
              response.Payload
            );
          }
          this.dialogRef.close();
        });
    } else {
      this.appService.showToastMessage(
        AppEnums.ToastTypeError,
        'Failed',
        'Passwords Must Match'
      );
      this.isLoading = false;
    }
  }
}
