<app-date-range (dateRange)="updateRange($event)"></app-date-range>
<h4>
  {{ selectedDateRange.start_date | date: 'fullDate' }} to
  {{ selectedDateRange.end_date | date: 'fullDate' }}
</h4>
<div
  *ngIf="polTotal === 0 && policyLoaded"
  class="alert alert-warning"
  role="alert"
>
  No policies for the selected period. Please try another date range!
</div>
<div class="row totals-container" *ngIf="polTotal > 0">
  <div class="col-lg-4">
    <div class="card tot-card-wrapper">
      <div class="icon-big icon-warning">
        <i class="fa fa-users text-info"></i>
      </div>
      <div class="text-center texts-container">
        <h5 class="card-title text-uppercase text-muted mb-0">
          Total Policies
        </h5>
        <span *ngIf="policyLoaded" class="h2 mb-0">{{ polTotal || 0 }}</span>
        <app-loader *ngIf="!policyLoaded"></app-loader>
      </div>
    </div>
  </div>
</div>

<div *ngIf="policyLoaded && polTotal > 0" class="row">
  <div
    *ngFor="let cover of polArr; index as indexOfelement"
    class="cover-card-container col-lg-2"
  >
    <div class="card cover-card-wrapper bg-white">
      <h3 class="text-center {{ themes[indexOfelement].textColor }}">
        {{ cover?.cover | uppercase }}
      </h3>
      <div class="row row-wrapper">
        <div>
          <div class="icon-container {{ themes[indexOfelement].bg }} col-lg-5">
            <i class="fa fa-users text-white"></i>
          </div>
        </div>
        <span class="col-lg-4 val text-center">{{ cover?.count || 0 }}</span>
      </div>
    </div>
  </div>
</div>

<hr />
<br />
<div
  *ngIf="quoteTotal === 0 && quotesLoaded"
  class="alert alert-warning"
  role="alert"
>
  No Quotes for the selected period. Please try another date range!
</div>
<div class="row totals-container" *ngIf="quoteTotal > 0">
  <div class="col-lg-4">
    <div class="card tot-card-wrapper">
      <div class="icon-big icon-warning">
        <i class="fa fa-users text-info"></i>
      </div>
      <div class="text-center texts-container">
        <h5 class="card-title text-uppercase text-muted mb-0">Total Quotes</h5>
        <span *ngIf="quotesLoaded" class="h2 mb-0">{{ quoteTotal || 0 }}</span>
        <app-loader *ngIf="!quotesLoaded"></app-loader>
      </div>
    </div>
  </div>
</div>

<div *ngIf="quotesLoaded && quoteTotal > 0" class="row">
  <div
    *ngFor="let cover of quotesArr; index as indexOfelement"
    class="cover-card-container col-lg-2"
  >
    <div class="card cover-card-wrapper bg-white">
      <h3 class="text-center {{ themes[indexOfelement].textColor }}">
        {{ cover?.cover | uppercase }}
      </h3>
      <div class="row row-wrapper">
        <div>
          <div class="icon-container {{ themes[indexOfelement].bg }} col-lg-5">
            <i class="fa fa-users text-white"></i>
          </div>
        </div>
        <span class="col-lg-4 val text-center">{{ cover?.count || 0 }}</span>
      </div>
    </div>
  </div>
</div>
<br />
<div class="row">
  <div class="col-lg-10 pa-10">
    <div class="card chart-container">
      <apx-chart
        *ngIf="chartsLoaded && (polTotal > 0 || quoteTotal > 0)"
        [colors]="chartOptions.colors"
        [title]="chartOptions.title"
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [dataLabels]="chartOptions.dataLabels"
        [plotOptions]="chartOptions.plotOptions"
        [yaxis]="chartOptions.yaxis"
        [xaxis]="chartOptions.xaxis"
        [fill]="chartOptions.fill"
        [tooltip]="chartOptions.tooltip"
        [stroke]="chartOptions.stroke"
        [legend]="chartOptions.legend"
        [labels]="chartOptions.labels"
      ></apx-chart>
      <app-loader *ngIf="!chartsLoaded"></app-loader>
    </div>
  </div>
</div>
<br />
<br />
