import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IGalleryImage } from 'src/app/models/gallery-image.interface';

@Component({
  selector: 'app-photo-gallery-partial',
  templateUrl: './photo-gallery-partial.component.html',
  styleUrls: ['./photo-gallery-partial.component.scss'],
})
export class PhotoGalleryPartialComponent implements OnInit {
  @Input() imagesArray: IGalleryImage[] = [];
  allImages: IGalleryImage[] = [];
  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
    this.allImages = this.imagesArray;
  }

  popSlideShow(content) {
    this.modalService.open(content, {
      size: 'lg',
    });
  }
}
