<div class="card">
  <div class="card-body">
    <h4 class="card-title text-uppercase mb-0 text-mtek">Remittance</h4>
    <div *ngIf="selectedRecord.hasOwnProperty('remittance'); else noRemittance">
      <div class="row">
        <div class="col-md-4">
          <p class="h4 text-center">
            {{ selectedRecord?.remittance?.amount | currency: ' ' }}
            <small class="h6 d-block text-muted">Expected Remittance</small>
          </p>
        </div>
        <div class="col-md-4">
          <p class="h4 text-center">
            {{ totalRemittance | currency: ' ' }}
            <small class="h6 d-block text-muted">Amount Remitted</small>
          </p>
          <div class="text-center">
            <button *ngIf="
                !showAddRemittance &&
                appService.hasPermission('admin-policy-record-remittance')
              " class="btn btn-outline-primary" (click)="toggleAddPayment()">
              Record Remittance
            </button>
          </div>
        </div>
        <div class="col-md-4">
          <p class="h4 text-center">
            {{
            selectedRecord?.remittance?.amount - totalRemittance
            | currency: ' '
            }}
            <small class="h6 d-block text-muted">Remittance Balance</small>
          </p>
        </div>
      </div>
      <app-loader *ngIf="isLoading"></app-loader>
      <div class="table-responsive-sm" *ngIf="remittancePayments.length > 0">
        <table class="table table-striped table-responsive-sm">
          <thead>
            <tr>
              <th scope="col">Posted</th>
              <th scope="col">Amount</th>
              <th scope="col">Reference</th>
              <th scope="col">Remitted Date</th>
              <th scope="col">Mode</th>
              <th scope="col">Posted By</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let remittancePayment of remittancePayments">
              <td scope="col">
                {{ remittancePayment?._timestamp | date: 'mediumDate' }}
              </td>
              <td scope="col">{{ remittancePayment?.paid | currency: ' ' }}</td>
              <td scope="col">{{ remittancePayment?.reference }}</td>
              <td scope="col">{{ formatRemittanceDate(remittancePayment?.dateRemitted) }}</td>
              <td scope="col">{{ remittancePayment?.paymentMode }}</td>
              <td scope="col">{{ remittancePayment?.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <ng-template #noRemittance>
      <div class="alert alert-warning" role="alert">
        No remittances recorded.
      </div>
      <button *ngIf="
          !showAddRemittance &&
          appService.hasPermission('admin-policy-record-remittance')
        " class="btn btn-outline-primary" (click)="toggleAddPayment()">
        Record Remittance
      </button>
    </ng-template>

    <div *ngIf="
        showAddRemittance &&
        appService.hasPermission('admin-policy-record-remittance')
      ">
      <form [formGroup]="remittanceFormGroup" (submit)="submitRemittance()">
        <div class="form-group row">
          <label for="amount" class="col-sm-2 col-form-label">Amount Remitted</label>
          <div class="col-sm-10">
            <input type="number" class="form-control" id="amount" formControlName="amount" />
          </div>
        </div>
        <div class="form-group row">
          <label for="reference" class="col-sm-2 col-form-label">Reference</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" id="reference" formControlName="reference" />
          </div>
        </div>
        <div class="form-group row">
          <label for="dateRemitted" class="col-sm-2 col-form-label">Date Remitted</label>
          <div class="col-sm-10">
            <div class="input-group">
              <input class="form-control" placeholder="Date Remitted" name="dateRemitted" formControlName="dateRemitted"
                ngbDatepicker #dRemittanceDate="ngbDatepicker" />
              <div class="input-group-append">
                <button type="button" class="btn btn-primary" (click)="dRemittanceDate.toggle()" type="button">
                  <i class="fa fa-calendar"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="paymentMode" class="col-sm-2 col-form-label">Payment Channel</label>
          <div class="col-sm-10">
            <select class="custom-select" formControlName="paymentMode" id="paymentMode">
              <option></option>
              <option *ngFor="let c of appService.getPaymentChannels">
                {{ c }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-2 col-form-label"></label>
          <div class="col-sm-10">
            <button type="button" (click)="toggleAddPayment()" class="btn btn-default btn-sm">
              Cancel
            </button>
            <button type="submit" class="btn btn-primary btn-sm">
              Record Remittance
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>