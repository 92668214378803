import { createAction, props } from '@ngrx/store';
import { ITemplateConfig } from '../../models/template-config.interface';
import { IUser } from '../../models/user.interface';

export enum AppActionTypes {
  //Template Actions
  ACTION_GET_CONFIG = '[Template Action] Get Config',
  ACTION_UPDATE_CONFIG = '[Template Action] Update Config',

  //Authenticated User Actions
  ACTION_GET_AUTHED_USER = '[Authed User Action] Get Authenticated User',
  ACTION_UPDATE_AUTHED_USER = '[Authed User Action] Update Authenticated User',
}

export const GetConfigAction = createAction(AppActionTypes.ACTION_GET_CONFIG);

export const UpdateConfigAction = createAction(
  AppActionTypes.ACTION_UPDATE_CONFIG,
  props<ITemplateConfig>()
);

export const GetAuthedUserAction = createAction(
  AppActionTypes.ACTION_GET_AUTHED_USER
);
export const UpdateAuthedUserAction = createAction(
  AppActionTypes.ACTION_UPDATE_AUTHED_USER,
  props<IUser>()
);
