import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IPayment } from 'src/app/models/payment.interface';
import { AppService } from 'src/app/services/app.service';
import { ReversedPaymentsComponent } from 'src/app/payment/mpesa-payments/reversed-payments/reversed-payments.component';

@Component({
  selector: 'app-list-mpesa-payments-table',
  templateUrl: './list-mpesa-payments-table.component.html',
  styleUrls: ['./list-mpesa-payments-table.component.scss'],
})
export class ListMpesaPaymentsTableComponent implements OnInit {
  @Input() payments: IPayment[] = [];
  @Input() _id: string;
  allMpesa: IPayment[];

  allDataLoaded: Boolean = false;
  mpesaTransactions: IPayment[];

  constructor(
    public appService: AppService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {}

  getPaymentDetails(payment: IPayment) {
    let dialogRef = this.dialog.open(ReversedPaymentsComponent, {
      data: payment,
      width: '60%',
      maxHeight: '90vh',
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  goToPaymentDetails(_id: string) {
    this.router.navigate(['/mpesa/payment-details', _id]);
  }
}
