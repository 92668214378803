<div class="row">
  <div class="col-md-9">
    <!-- Quote Details -->
    <div class="row section2">
      <div class="col-md-12 col-1-wrapper">
        <div class="card card-stats">
          <app-update-cover-details *ngIf="editCoverDetails" (setEditVehicleEvent)="openCoverDetails($event)"
            (reloadPolicyEvent)="reloadPolicy()" [policy]="selectedRecord" [cover]="cover"></app-update-cover-details>
          <!-- Card body -->
          <div class="card-body" *ngIf="!editCoverDetails">
            <h4 class="card-title text-uppercase mb-0 text-mtek">
              <i class="fa fa-book text-mtek icon-big h5"></i>
              Cover Information
            </h4>
            <hr />
            <div class="row">
              <!-- Quote Owner -->
              <div class="col-md-3">
                <app-display-client-details [client]="moreQuestions?.client"
                  [selectedRecordQuestionsId]="selectedRecord?.questions_id" [selectedRecord]="selectedRecord"
                  [cover]="cover" (reloadPolicyEvent)="reloadPolicy()"></app-display-client-details>
              </div>

              <!-- Quote Details-->
              <div class="col-md-3">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="card-title text-uppercase mb-0 text-info">
                    Quote Details
                  </h5>
                  <button class="btn btn-outline-primary ml-2" (click)="openEditCoverDetailsForm()" *ngIf="
                      appService.hasPermission('admin-update-cover-details')
                    ">
                    Edit
                  </button>
                </div>
                <hr />
                <p>
                  <small class="d-block text-dark text-uppercase bold-text">Policy Name</small>
                  {{ selectedRecord?.sme | smePolicyName: "Domestic"}}
                </p>

                <p>
                  <small class="d-block text-dark text-uppercase bold-text">Lead Generator</small>
                  {{ selectedRecord?.ModuleID | thirdParty | async }}
                </p>
                <p>
                  <small class="d-block text-dark text-uppercase bold-text">Generated</small>
                  {{ selectedRecord?._timestamp | date : 'medium' }}
                </p>
                <p>
                  <small class="d-block text-dark text-uppercase bold-text">Sales Date</small>
                  {{ selectedRecord?.salesDateTimestamp | date : 'medium' }}
                </p>
                <p>
                  <small class="d-block text-dark text-uppercase bold-text">Policy Start Date</small>
                  {{ startDate }}
                </p>
                <p>
                  <small class="d-block text-dark text-uppercase bold-text">Underwriter</small>
                  {{ selectedRecord?.insurer | underwriter | async }}
                </p>
                <p *ngIf="
                    selectedRecord.hasOwnProperty('starttimestamp') &&
                    selectedRecord.hasOwnProperty('endtimestamp')
                  ">
                  <small class="d-block text-dark text-uppercase bold-text">Policy Period</small>
                  {{ selectedRecord?.starttimestamp | date : 'mediumDate' }} -
                  {{ selectedRecord?.endtimestamp | date : 'mediumDate' }}
                </p>
                <p>
                  <small class="d-block text-dark text-uppercase bold-text">Assured</small>
                  {{ selectedRecord?.premium?.assured | currency : 'KES. ' }}
                </p>

                <p>
                  <small class="d-block text-dark text-uppercase bold-text">Basic Premium</small>
                  {{
                  selectedRecord?.premium?.totalPremiumAmount
                  | currency : 'KES. '
                  }}
                </p>
                <p>
                  <small class="d-block text-dark text-uppercase bold-text">Premium Payable</small>
                  {{ selectedRecord?.amount | currency : 'KES. ' }}
                </p>

                <br />

                <h5 class="card-title text-uppercase mb-0 text-info">
                  Total paid amount
                </h5>
                <hr />
                <p class="d-block text-dark text-uppercase bold-text">
                  {{ selectedRecord?.paidamount | currency : 'KES. ' }}
                </p>
              </div>

              <!--NOK Details & Taxes -->
              <div class="col-md-3">
                <!-- Taxes -->
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="card-title text-uppercase mb-0 text-info">
                    Taxes Applied
                  </h5>
                </div>
                <hr />

                <div *ngIf="
                    selectedRecord?.premium?.statutoryPremium.length > 0;
                    else noTaxes
                  ">
                  <div *ngIf="
                      selectedRecord?.premium?.hasOwnProperty(
                        'statutoryPremium'
                      )
                    ">
                    <div *ngIf="
                        selectedRecord?.premium?.statutoryPremium.length > 0
                      ">
                      <div *ngFor="
                          let statutoryPremium of selectedRecord?.premium
                            ?.statutoryPremium
                        ">
                        <small class="d-block text-dark text-uppercase bold-text">{{ statutoryPremium?.name }}</small>

                        <!-- isAmount Tax Details -->
                        <span *ngIf="statutoryPremium?.isAmount === false">{{ statutoryPremium?.percentage }} %</span>
                        <span *ngIf="
                            statutoryPremium?.isAmount === true;
                            else noFlag
                          ">{{
                          statutoryPremium?.percentage | currency : 'KES. '
                          }}</span>

                        <!-- isPercentage Tax Details -->
                        <span *ngIf="statutoryPremium?.isPercentage === true">{{ statutoryPremium?.percentage }}
                          %</span>
                        <span *ngIf="
                            statutoryPremium?.isPercentage === false;
                            else noFlag
                          ">{{
                          statutoryPremium?.percentage | currency : 'KES. '
                          }}</span>

                        <!-- No isAmount or isPercentage Tax Details -->
                        <ng-template #noFlag>
                          <span *ngIf="statutoryPremium?.isAmount === 0">{{
                            statutoryPremium?.percentage | currency : 'KES. '
                            }}</span>

                          <span *ngIf="statutoryPremium?.isPercentage === 0">{{
                            statutoryPremium?.percentage | currency : 'KES. '
                            }}</span>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>

                <ng-template #noTaxes>
                  <div class="alert alert-warning" role="alert">No Taxes.</div>
                </ng-template>
                <app-display-nok-details [client]="moreQuestions?.client" [cover]="cover"
                  [selectedRecordQuestionsId]="selectedRecord?.questions_id" (reloadPolicyEvent)="reloadPolicy()">
                </app-display-nok-details>
              </div>
              <div class="col-md-3">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="card-title text-uppercase mb-0 text-info">
                    More Cover Details
                  </h5>
                </div>
                <hr />
                <p *ngIf="selectedRecord?.policy?.hasOwnProperty('term')">
                  <small class="d-block text-dark text-uppercase bold-text">Term</small>
                  {{ selectedRecord?.policy?.term }}
                </p>
                <p *ngIf="selectedRecord?.hasOwnProperty('quotePackage')">
                  <small class="d-block text-dark text-uppercase bold-text">Option</small>
                  {{ sanitaizePackageName(selectedRecord?.quotePackage) }}
                </p>
                <p *ngIf="moreQuestions?.hasOwnProperty('endDate')">
                  <small class="d-block text-dark text-uppercase bold-text">End Date</small>
                  {{ moreQuestions?.endDate }}
                </p>
                <div *ngIf="
                    selectedRecord?.policy.hasOwnProperty('houseValue') ||
                    selectedRecord?.policy.hasOwnProperty(
                      'houseContentsValue'
                    ) ||
                    selectedRecord?.policy.hasOwnProperty(
                      'businessInteruptionByFire'
                    )
                  ">
                  <div class="d-flex justify-content-between align-items-center">
                    <h5 class="card-title text-uppercase mb-0 text-info">
                      Cover Benefits
                    </h5>
                  </div>
                  <hr />
                  <p *ngIf="selectedRecord?.policy?.houseValue">
                    <small class="d-block text-dark text-uppercase bold-text">Fire</small>
                    {{
                    selectedRecord?.policy?.houseValue | currency : 'KES. '
                    }}
                  </p>
                  <p *ngIf="selectedRecord?.policy?.houseContentsValue">
                    <small class="d-block text-dark text-uppercase bold-text">Burglary (Theft through forced
                      entry)</small>
                    {{
                    selectedRecord?.policy?.houseContentsValue
                    | currency : 'KES. '
                    }}
                  </p>
                  <p *ngIf="selectedRecord?.policy?.businessInteruptionByFire">
                    <small class="d-block text-dark text-uppercase bold-text">Business Interuption By Fire</small>
                    {{
                    selectedRecord?.policy?.businessInteruptionByFire
                    | currency : 'KES. '
                    }}
                  </p>
                </div>
                <div class="d-flex justify-content-between align-items-center" *ngIf="
                    selectedRecord?.policy?.personalAccidentAmount ||
                    selectedRecord?.policy?.hospitalizationAmount
                  ">
                  <h5 class="card-title text-uppercase mb-0 text-info">
                    Additional cover Benefits
                  </h5>
                </div>
                <hr />
                <p *ngIf="selectedRecord?.policy?.personalAccidentAmount">
                  <small class="d-block text-dark text-uppercase bold-text">Personal Accident</small>
                  {{
                  selectedRecord?.policy?.personalAccidentAmount
                  | currency : 'KES. '
                  }}
                </p>
                <p *ngIf="selectedRecord?.policy?.hospitalizationAmount">
                  <small class="d-block text-dark text-uppercase bold-text">BI due to Hospitalization</small>
                  {{
                  selectedRecord?.policy?.hospitalizationAmount
                  | currency : 'KES. '
                  }}
                </p>
              </div>
              <!-- House Details-->
              <div class="col-md-3">
                <div *ngIf="selectedRecord?.policy?.house">
                  <h5 class="card-title text-uppercase mb-0 text-info">
                    House/ Building Details
                  </h5>
                  <hr />

                  <div *ngIf="selectedRecord?.policy?.house; else noHouseDetails">
                    <!-- <div> -->
                    <p>
                      <small class="d-block text-dark text-uppercase bold-text">Value of House</small>
                      {{ selectedRecord?.policy?.house?.value }}
                    </p>
                    <p>
                      <small class="d-block text-dark text-uppercase bold-text">Location</small>
                      {{ selectedRecord?.policy?.house?.location }}
                    </p>
                    <p>
                      <small class="d-block text-dark text-uppercase bold-text">Owned</small>
                      {{ selectedRecord?.policy?.house?.owned }}
                    </p>
                    <p>
                      <small class="d-block text-dark text-uppercase bold-text">Rental</small>
                      {{ selectedRecord?.policy?.house?.rental }}
                    </p>
                  </div>

                  <ng-template #noHouseDetails>
                    <div class="alert alert-warning" role="alert">
                      No House/Building Selected.
                    </div>
                  </ng-template>
                </div>
                <br />

                <!-- Add Ons -->

                <div *ngIf="selectedRecord?.policy?.addons">
                  <h5 class="card-title text-uppercase mb-0 text-info">
                    Add Ons Details
                  </h5>
                  <hr />

                  <div *ngIf="selectedRecord?.policy?.addons; else noAddons">
                    <div>
                      <p *ngFor="let addon of selectedRecord?.policy?.addons">
                        <small class="text-muted d-block">{{
                          addon?.name
                          }}</small>
                        {{ addon?.premium | currency : 'KES. ' }}
                      </p>
                    </div>
                  </div>

                  <ng-template #noAddons>
                    <div class="alert alert-warning" role="alert">
                      No Add-ons Selected.
                    </div>
                  </ng-template>
                </div>

                <!-- Pets Section -->
                <br />

                <div *ngIf="selectedRecord?.policy?.hasOwnProperty('pets')">
                  <h5 class="card-title text-uppercase mb-0 text-info">
                    Pet Details
                  </h5>
                  <hr />

                  <div *ngIf="
                      selectedRecord?.policy?.pets?.length > 0;
                      else noPets
                    ">
                    <p>
                      <small class="d-block text-dark text-uppercase bold-text">Pet Name</small>
                      {{ selectedRecord?.policy?.pets[0]?.name }}
                    </p>

                    <p>
                      <small class="d-block text-dark text-uppercase bold-text">Pet Type</small>
                      {{ selectedRecord?.policy?.pets[0]?.specie }}
                    </p>

                    <p>
                      <small class="d-block text-dark text-uppercase bold-text">Pet Value</small>
                      {{ selectedRecord?.policy?.pets[0]?.value }}
                    </p>
                    <p>
                      <small class="d-block text-dark text-uppercase bold-text">Pet Medical Expense</small>
                      {{ selectedRecord?.policy?.pets[0]?.medicalExpense }}
                    </p>
                  </div>

                  <ng-template #noPets>
                    <div class="alert alert-warning" role="alert">
                      No Pets Added.
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Categories -->
    <div class="row section2">
      <div class="col-md-12 col-1-wrapper">
        <div class="card card-stats">
          <!-- Card body -->
          <div class="card-body">
            <h3 class="card-title text-uppercase text-muted h2 mb-0">
              Categories
            </h3>
            <div class="row" *ngIf="
                selectedRecord?.policy?.fire ||
                  selectedRecord?.policy?.allrisk ||
                  selectedRecord?.policy?.publicLiability;
                else noCategory
              ">
              <table class="table table-striped table-responsive-sm">
                <tr>
                  <th scope="col">Fire</th>
                  <th scope="col">Theft</th>
                  <th scope="col">All Risk</th>
                  <th scope="col">Public Liability</th>
                  <th scope="col">WIBA</th>
                </tr>

                <tbody>
                  <tr>
                    <!-- *ngFor="
                      let selectedRecord of selectedRecord?.policy?.contents
                    "
                    class="section-step" -->

                    <td class="p-3">
                      {{ selectedRecord?.policy?.fire | currency : 'KES. ' }}
                    </td>
                    <td class="p-3">
                      {{ selectedRecord?.policy?.theft | currency : 'KES. ' }}
                    </td>
                    <td class="p-3">
                      {{ selectedRecord?.policy?.allrisk | currency : 'KES. ' }}
                    </td>
                    <td class="p-3">
                      {{ selectedRecord?.policy?.publicLiability }}
                    </td>
                    <td class="p-3">
                      {{ selectedRecord?.policy?.wiba | currency : 'KES. ' }}
                    </td>
                    <!-- <td class="p-3">
                      {{ selectedRecord.value | currency: 'KES. ' }}
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
            <ng-template #noCategory>
              <div class="alert alert-warning" role="alert">
                No Categories added yet.
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <!-- Contents Details -->
    <div class="row section2">
      <div class="col-md-12 col-1-wrapper">
        <div class="card card-stats">
          <!-- Card body -->
          <div class="card-body">
            <h3 class="card-title text-uppercase text-muted h2 mb-0">
              Contents Details
            </h3>
            <div class="row" *ngIf="
                moreQuestions?.domesticHouseContentModelList?.length > 0;
                else noContents
              ">
              <table class="table table-striped table-responsive-sm">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">SerialNumber</th>
                  <!-- <th scope="col">Type Name</th> -->
                  <th scope="col">Value</th>
                </tr>

                <tbody>
                  <tr *ngFor="
                      let content of moreQuestions?.domesticHouseContentModelList
                    " class="section-step">
                    <td class="p-3">{{ content.itemName }}</td>
                    <td class="p-3">{{ content.itemSerialNumber }}</td>
                    <!-- <td class="p-3">{{ content.itemValue }}</td> -->
                    <td class="p-3">
                      {{ content.itemValue | currency : 'KES. ' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ng-template #noContents>
              <div class="alert alert-warning" role="alert">
                No Contents added yet.
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <!-- All Risk Details -->
    <div class="row section2">
      <div class="col-md-12 col-1-wrapper">
        <div class="card card-stats">
          <!-- Card body -->
          <div class="card-body">
            <h3 class="card-title text-uppercase text-muted h2 mb-0">
              All Risk Items Details
            </h3>
            <div class="row" *ngIf="
                selectedRecord?.policy?.allRiskItems?.length > 0;
                else noAllRisk
              ">
              <table class="table table-striped table-responsive-sm">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Content Type</th>
                  <th scope="col">Type Name</th>
                  <th scope="col">Value</th>
                </tr>

                <tbody>
                  <tr *ngFor="
                      let selectedRecord of selectedRecord?.policy?.allRiskItems
                    " class="section-step">
                    <td class="p-3">{{ selectedRecord.name }}</td>
                    <td class="p-3">{{ selectedRecord.type }}</td>
                    <td class="p-3">{{ selectedRecord.typeName }}</td>
                    <td class="p-3">
                      {{ selectedRecord.value | currency : 'KES. ' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ng-template #noAllRisk>
              <div class="alert alert-warning" role="alert">
                No All Risk items added yet.
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div class="row group" *ngIf="groupMembers?.length > 0">
      <div class="col-md-12">
        <app-display-group-info [members]="groupMembers" class="card-stats section2"></app-display-group-info>
      </div>
    </div>
    <div class="row section2 mb-3" *ngIf="appService.hasPermission('admin-policy-audit-log')">
      <div class="col-md-12 col-1-wrapper">
        <app-audit-log [policyID]="selectedRecord._id" [policyName]="cover"></app-audit-log>
      </div>
    </div>
  </div>

  <!-- Downloads & House Images -->
  <div class="col-md-3">
    <app-policy-options [cover]="cover" [policy]="selectedRecord" (reloadPolicyEvent)="reloadPolicy()"
      *ngIf="appService.hasPermission('admin-view-policy-options')"></app-policy-options>
    <!-- Policy Docs Downloads Section-->
    <div class="card card-stats section1">
      <!-- Card body -->
      <div class="card-body">
        <h4 class="card-title text-uppercase mb-0 text-mtek">
          <strong><i class="fa fa-download text-mtek icon-big h5"></i></strong>
          Downloads
        </h4>
        <hr />
        <!-- <div class="row section2"> -->
        <div class="col-md-12">
          <div class="row" *ngIf="moreQuestions?.client?.signature">
            <p>
              <a href="javascript:void(0)" (click)="viewAttachment(moreQuestions?.client?.signature)"
                class="text-success bold-text">
                <i class="vsm-icon pe-7s-cloud-download h5"></i>
                <span class="aligned-with-icon h6"> Signature </span>
              </a>
            </p>
          </div>
          <div class="row" *ngIf="moreQuestions?.client?.hasOwnProperty('kraPhoto')">
            <p>
              <a href="javascript:void(0)" (click)="viewAttachment(moreQuestions?.client?.kraPhoto)"
                class="text-success bold-text">
                <i class="vsm-icon pe-7s-cloud-download h5"></i>
                <span class="aligned-with-icon h6"> KRA Document </span>
              </a>
            </p>
          </div>
          <div class="row" *ngIf="moreQuestions?.client?.passportPhoto">
            <p>
              <a href="javascript:void(0)" (click)="viewAttachment(moreQuestions?.client?.passportPhoto)"
                class="text-success bold-text">
                <i class="vsm-icon pe-7s-cloud-download h5"></i>
                <span class="aligned-with-icon h6"> Passport Document </span>
              </a>
            </p>
          </div>
          <div class="row" *ngIf="moreQuestions?.client?.idPhotoFront">
            <p>
              <a href="javascript:void(0)" (click)="viewAttachment(moreQuestions?.client?.idPhotoFront)"
                class="text-success bold-text">
                <i class="vsm-icon pe-7s-cloud-download h5"></i>
                <span class="aligned-with-icon h6"> ID - Front </span>
              </a>
            </p>
          </div>
          <div class="row" *ngIf="moreQuestions?.client?.idPhotoBack">
            <p>
              <a href="javascript:void(0)" (click)="viewAttachment(moreQuestions?.client?.idPhotoBack)"
                class="text-success bold-text">
                <i class="vsm-icon pe-7s-cloud-download h6"></i>
                <span class="aligned-with-icon h6"> ID - Back </span>
              </a>
            </p>
          </div>
          <div class="row" *ngIf="moreQuestions?.company?.KraDoc">
            <p>
              <a href="javascript:void(0)" (click)="viewAttachment(moreQuestions?.company?.KraDoc)"
                class="text-success bold-text">
                <i class="vsm-icon pe-7s-cloud-download h5"></i>
                <span class="aligned-with-icon h6"> Company KRA Doc </span>
              </a>
            </p>
          </div>
          <div class="row" *ngIf="moreQuestions?.company?.incorporationCert">
            <p>
              <a href="javascript:void(0)" (click)="
                  viewAttachment(moreQuestions?.company?.incorporationCert)
                " class="text-success bold-text">
                <i class="vsm-icon pe-7s-cloud-download h5"></i>
                <span class="aligned-with-icon h6">
                  Company Incorporation Certificate
                </span>
              </a>
            </p>
          </div>
          <div class="row" *ngIf="moreQuestions?.domesticImages?.officeVideo">
            <p>
              <a href="javascript:void(0)" (click)="
                  viewAttachment(moreQuestions?.domesticImages?.officeVideo)
                " class="text-success bold-text">
                <i class="vsm-icon pe-7s-cloud-download h5"></i>
                <span class="aligned-with-icon h6"> Office Video </span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- House/Building Images -->
    <div class="card section1" *ngIf="moreQuestions?.domesticImages">
      <!-- Card body -->
      <div class="card-body">
        <h4 class="card-title text-uppercase mb-0 text-mtek">
          <strong><i class="fa fa-picture-o text-mtek icon-big h5"></i></strong>
          House/Building Images
        </h4>
        <hr />
        <app-photo-gallery-partial [imagesArray]="imagesArray"></app-photo-gallery-partial>
      </div>
    </div>
  </div>
</div>