<div class="card chart-wrapper">
  <app-loader *ngIf="!isLoading"></app-loader>
  <apx-chart
    *ngIf="isLoading"
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [colors]="chartOptions.colors"
    [plotOptions]="chartOptions.plotOptions"
    [dataLabels]="chartOptions.dataLabels"
    [legend]="chartOptions.legend"
    [grid]="chartOptions.grid"
    [xaxis]="chartOptions.xaxis"
    [title]="chartOptions.title"
  ></apx-chart>
</div>
