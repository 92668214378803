import { Input, Output, EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IPayment } from 'src/app/models/payment.interface';
import { AppService } from 'src/app/services/app.service';
import { CancelFinancePlanComponent } from '../cancel-finance-plan/cancel-finance-plan.component';
import { UpdateFinancePlanComponent } from '../update-finance-plan/update-finance-plan.component';

export interface IBimaRedmption {
  pointValue: number;
  redeemedPoints: number;
  redeemedValue: number;
}
export interface IPaymentdetailSchedule {
  id: string;
  amount: number;
  order: number;
  downpayment: boolean;
  _date: any;
  paid: true;
  datePaid: string;
}
export interface IPaymentdetail {
  schedule: IPaymentdetailSchedule[];
  bank: string;
  plan: number;
  payable: number;
  interest: number;
  totalPayable: number;
  downpayment: number;
  financed: boolean;
  principle: number;
  perUnitRepaymentAmount: number;
  nextPaymentDate: string;
  _nextPaymentDate: any;
  totalPaidAmount: number;
  transactionRef: string;
  isSettledToUnderwriter: boolean;
  bimaRedemption: IBimaRedmption;
}

@Component({
  selector: 'app-payment-plan-partial',
  templateUrl: './payment-plan-partial.component.html',
  styleUrls: ['./payment-plan-partial.component.scss'],
})
export class PaymentPlanPartialComponent implements OnInit {
  @Input() paymentdetails: IPaymentdetail;
  @Input() selectedRecordId: string;
  @Input() promocode;
  @Input() product: string;
  @Input() sheduledPaymentPlans;
  @Input() isLife;
  totalBalance: number;
  showAddPayment: boolean = false;
  showSchedulePayment: boolean = false;

  @Input() selectedRecord: any;
  @Output() emitReloadQuote = new EventEmitter<string>();

  adjustPlanFormGroup: FormGroup;

  check: boolean = false;
  isLoading: boolean = false;
  paymentLogs: IPayment[] = [];

  constructor(
    public appService: AppService,
    public dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.paymentdetails) {
      if (this.paymentdetails.hasOwnProperty('payable')) {
        this.totalBalance =
          this.paymentdetails?.payable - this.paymentdetails?.totalPaidAmount;
      } else {
        this.totalBalance =
          this.paymentdetails?.totalPayable -
          this.paymentdetails?.totalPaidAmount;
      }
    }

    this.loadPaymentLogs();
  }

  loadPaymentLogs() {
    if (this.selectedRecordId) {
      this.appService
        .getPaymentsLogByQuoteId(this.selectedRecordId)
        .subscribe((res) => {
          this.paymentLogs = res;
        });
    }
    this.getCurrentQuote();
  }

  popCancelPolicyDialog() {
    let dialogRef = this.dialog.open(CancelFinancePlanComponent, {
      width: '50%',
      data: { _id: this.selectedRecordId, product: this.product },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.emitReloadQuote.emit(this.selectedRecordId);
    });
  }

  popUpdatePolicyDialog() {
    let dialogRef = this.dialog.open(UpdateFinancePlanComponent, {
      width: '50%',
      data: {
        _id: this.selectedRecordId,
        product: this.product,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.emitReloadQuote.emit(this.selectedRecordId);
    });
  }

  popAction($event) {
    this.getCurrentQuote();
    if ($event.checked === true) {
      this.popUpdatePolicyDialog();
    } else {
      this.popCancelPolicyDialog();
    }
  }
  onUpdateFinancePlan() {
    this.router.navigate(['/payment-plan-calculator'], {
      queryParams: {
        quote: this.selectedRecordId,
        product: this.product,
      },
    });
  }
  getCurrentQuote() {
    if (
      this.selectedRecord?.cancellation != null ||
      this.selectedRecord?.cancellation != undefined ||
      this.totalBalance <= 0
    ) {
      this.check = false;
    } else {
      this.check = true;
    }
  }

  toggleAddPayment() {
    this.showAddPayment = !this.showAddPayment;
  }

  toggleSchedulePayment() {
    this.showSchedulePayment = !this.showSchedulePayment;
  }

  completePayment($event) {
    this.toggleAddPayment();
    this.loadPaymentLogs();
  }
}
