<div class="p-3">
  <h1 mat-dialog-title>Add Wallet Payment</h1>
  <hr />
  <div mat-dialog-content>
    <form [formGroup]="addPaymentFormGroup" (ngSubmit)="addPayment()">
      <mat-form-field>
        <mat-label>Amount</mat-label>
        <input matInput formControlName="amount" type="number" />
      </mat-form-field>
      <br />
      <mat-form-field>
        <mat-label>Payment Reference</mat-label>
        <input matInput formControlName="referenceNo" type="text" />
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button color="primary" (click)="addPayment()">
      Submit Payment
    </button>
    <app-loader *ngIf="isLoading"></app-loader>
  </div>
</div>
