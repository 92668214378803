import { Component, Input, OnInit } from '@angular/core';
import { AppEnums } from 'src/app/enums/app.enum';
import { IUser } from 'src/app/models/user.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-wallet-transactions',
  templateUrl: './wallet-transactions.component.html',
  styleUrls: ['./wallet-transactions.component.scss'],
})
export class WalletTransactionsComponent implements OnInit {
  @Input() userDetails: IUser;
  isLoading: boolean = false;
  userWalletBalance: number = 0;
  userWalletTransactions: any[] = [];

  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.getUserWallet();
    this.getUserWalletTransactions();
  }

  getUserWallet() {
    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.MPESA_API_URL}/admitGetWallets`, {
        feduid: 'OUPw4HkOgdZ9ut2goyv496sVLdm2',
      })
      .subscribe(
        (res) => {
          this.isLoading = false;
          if (res.Status === 200) {
            if (res.Payload.amount) {
              this.userWalletBalance = res.Payload.amount;
            }
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              'Something went wrong',
              'Warning'
            );
          }
        },
        (error) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            this.appService.processHttpErrors(error),
            'Error'
          );
        }
      );
  }

  getUserWalletTransactions() {
    this.isLoading = true;
    this.appService
      .makePostRequest(
        `${environment.MPESA_API_URL}/admitGetWalletTransactions`,
        {
          feduid: 'OUPw4HkOgdZ9ut2goyv496sVLdm2',
        }
      )
      .subscribe(
        (res) => {
          this.isLoading = false;
          if (res.Status === 200) {
            this.userWalletTransactions = res.Payload;
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              'Something went wrong',
              'Warning'
            );
          }
        },
        (error) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            this.appService.processHttpErrors(error),
            'Error'
          );
        }
      );
  }
}
