import { Component, Input, OnInit } from '@angular/core';
import { IDomestic } from 'src/app/models/domestic.interface';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-domestic-questions',
  templateUrl: './domestic-questions.component.html',
  styleUrls: ['./domestic-questions.component.scss'],
})
export class DomesticQuestionsComponent implements OnInit {
  @Input() selectedRecord: IDomestic;
  @Input() moreQuestions: any;
  sasToken: string;

  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.appService.getSasToken().subscribe((sasToken) => {
      this.sasToken = sasToken;
    });
  }
}
