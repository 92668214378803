import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ITravel } from 'src/app/models/travel.interface';

@Component({
  selector: 'app-travel-list-table',
  templateUrl: './travel-list-table.component.html',
  styleUrls: ['./travel-list-table.component.scss'],
})
export class TravelListTableComponent implements OnInit {
  @Input() policies: ITravel[];
  filteredRows: ITravel[];
  selectedRecord: ITravel;
  tableFiltersFormGroup: FormGroup;
  @Input() dataLoaded: boolean;

  constructor(private formBuilder: FormBuilder, private router: Router) {
    this.tableFiltersFormGroup = this.formBuilder.group({
      searchFilter: '',
    });
    this.filterSearchTable();
  }

  ngOnInit(): void {
    this.filteredRows = this.policies;
  }

  filterSearchTable() {
    this.tableFiltersFormGroup
      .get('searchFilter')
      .valueChanges.subscribe((val) => {
        this.filteredRows = this.policies.filter((record) => {
          return Object.keys(record).reduce((acc, curr) => {
            return (
              acc ||
              JSON.stringify(record[curr])
                .toLowerCase()
                .includes(val.toLowerCase())
            );
          }, false);
        });
      });
  }

  goToPolicyDetails(_id: string) {
    this.router.navigate(['/travel/list/travel-details', _id]);
  }

  highlightCancelledPolicies(policy: any) {
    if (policy.hasOwnProperty('cancellation')) {
      return {
        'background-color': '#DB3A34',
        color: '#fff',
      };
    }
  }
}
