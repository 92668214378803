import { Component, OnInit, ViewChild } from '@angular/core';
import { AggregateSearchComponent } from 'src/app/partials/aggregate-search/aggregate-search.component';
import { AppEnums } from 'src/app/enums/app.enum';
import { IDomestic } from 'src/app/models/domestic.interface';
import { IPagination } from 'src/app/models/pagination.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DomesticDeclarationReportComponent } from '../domestic-declaration-report/domestic-declaration-report.component';

@Component({
  selector: 'app-domestic-list',
  templateUrl: './domestic-list.component.html',
  styleUrls: ['./domestic-list.component.scss'],
})
export class DomesticListComponent implements OnInit {
  @ViewChild(AggregateSearchComponent) search: AggregateSearchComponent;

  allQuoteDataLoaded: boolean = false;
  allPolicyDataLoaded: boolean = false;
  allCommissionDataLoaded: boolean = false;

  allQuotes: IDomestic[];
  allPolicies: IDomestic[];
  allCommissions: IDomestic[] = [];

  quoteRows: IDomestic[];
  policyRows: IDomestic[];
  commissionRows: IDomestic[] = [];

  QuotesPagination: IPagination = {
    limit: 10,
    skip: 0,
    sort: {
      _timestamp: -1,
    },
  };

  PoliciesPagination: IPagination = {
    limit: 10,
    skip: 0,
    sort: {
      _utimestamp: -1,
    },
  };

  CommissionPagination: IPagination = {
    limit: 10,
    skip: 0,
    sort: {
      _utimestamp: -1,
    },
  };

  QuoteDataLoading: boolean = false;
  PolicyDataLoading: boolean = false;
  CommissionDataLoading: boolean = false;

  dataQuoteLength: number = 0;
  dataPolicyLength: number = 0;
  dataCommissionLength: number = 0;

  isQuoteSearchData: boolean = false;
  isPolicySearchData: boolean = false;
  isCommissionSearchData: boolean = false;
  showQuote: boolean;

  commissionIds: any[] = [];
  commissionPaid: string = '';
  commissionsData: IDomestic[] = [];

  quotesLabel: string = 'QUOTES';
  policiesLabel: string = 'POLICIES';

  searchParamsQuotes = {
    url: `${environment.DOMESTIC_API_URL}/adminaggregate`,
    searchFields: [
      'client.name',
      'client.email',
      'client.phone',
      'client.id_number',
      'policy.policyTypeName',
      'policy.useName',
      'client.kra',
      'insurerName',
      'questions.moreQuestions.client.userName',
      'questions.moreQuestions.client.email',
      'questions.moreQuestions.client.phoneNumber',
      'questions.moreQuestions.client.idNumber',
      'questions.moreQuestions.client.passportNumber',
      '_id',
      'questions.moreQuestions.company.name',
      'questions.moreQuestions.company.email',
      'questions.moreQuestions.company.phoneNumber',
    ],
    lookup: {
      from: 'domestic_questions',
      localField: 'questions_id',
      foreignField: '_id',
      as: 'questions',
    },
    fields: {
      feduid: 1,
      stage: 1,
      client: 1,
      amount: 1,
      insurer: 1,
      sme: 1,
      'policy.policyTypeName': 1,
      'policy.period': 1,
      'questions.moreQuestions.client.userName': 1,
      'questions.moreQuestions.client.phoneNumber': 1,
      _timestamp: 1,
      _utimestamp: 1,
      'questions.moreQuestions.company.name': 1,
      'questions.moreQuestions.company.phoneNumber': 1,
    },
    pagination: [
      { $sort: this.QuotesPagination.sort },
      { $skip: this.QuotesPagination.skip },
      { $limit: this.QuotesPagination.limit },
    ],
    customQuery:
      this.appService.isThirdParty() || this.appService.isPrimePartner()
        ? [
            { $nor: [{ stage: 'PAY' }] },
            {
              ModuleID: this.appService.getSessionUser()?.ModuleID,
            },
          ]
        : [{ $nor: [{ stage: 'PAY' }] }],
  };

  searchParamsPolicies = {
    url: `${environment.DOMESTIC_API_URL}/adminaggregate`,
    searchFields: [
      'client.name',
      'client.email',
      'client.phone',
      'client.id_number',
      'policy.policyTypeName',
      'policy.useName',
      'client.kra',
      'insurerName',
      'questions.moreQuestions.client.userName',
      'questions.moreQuestions.client.email',
      'questions.moreQuestions.client.phoneNumber',
      'questions.moreQuestions.client.idNumber',
      'questions.moreQuestions.client.passportNumber',
      '_id',
      'questions.moreQuestions.company.name',
      'questions.moreQuestions.company.email',
      'questions.moreQuestions.company.phoneNumber',
    ],
    lookup: {
      from: 'domestic_questions',
      localField: 'questions_id',
      foreignField: '_id',
      as: 'questions',
    },
    fields: {
      feduid: 1,
      stage: 1,
      client: 1,
      amount: 1,
      insurer: 1,
      sme: 1,
      'policy.policyTypeName': 1,
      'policy.period': 1,
      'questions.moreQuestions.client.userName': 1,
      'questions.moreQuestions.client.phoneNumber': 1,
      _timestamp: 1,
      _utimestamp: 1,
      'questions.moreQuestions.company.name': 1,
      'questions.moreQuestions.company.phoneNumber': 1,
    },
    pagination: [
      { $sort: this.PoliciesPagination.sort },
      { $skip: this.PoliciesPagination.skip },
      { $limit: this.PoliciesPagination.limit },
    ],
    customQuery:
      this.appService.isThirdParty() || this.appService.isPrimePartner()
        ? [
            { stage: 'PAY' },
            {
              paidamount: {
                $gt: 0,
              },
            },
            {
              ModuleID: this.appService.getSessionUser()?.ModuleID,
            },
          ]
        : [
            { stage: 'PAY' },
            {
              paidamount: {
                $gt: 0,
              },
            },
          ],
  };

  searchParamsCommissions = {
    url: `${environment.DOMESTIC_API_URL}/adminaggregate`,
    searchFields: [
      'client.name',
      'client.email',
      'client.phone',
      'client.id_number',
      'policy.policyTypeName',
      'policy.useName',
      'client.kra',
      'insurerName',
      'questions.moreQuestions.client.userName',
      'questions.moreQuestions.company.name',
      'questions.moreQuestions.company.email',
      'questions.moreQuestions.company.phoneNumber',
    ],
    lookup: {
      from: 'domestic_questions',
      localField: 'questions_id',
      foreignField: '_id',
      as: 'questions',
    },
    fields: {
      feduid: 1,
      stage: 1,
      client: 1,
      amount: 1,
      insurer: 1,
      sme: 1,
      'policy.policyTypeName': 1,
      'policy.period': 1,
      'questions.moreQuestions.client.userName': 1,
      'questions.moreQuestions.client.phoneNumber': 1,
      'questions.moreQuestions.company.name': 1,
      'questions.moreQuestions.company.phoneNumber': 1,
      _timestamp: 1,
      _utimestamp: 1,
    },
    pagination: [
      { $sort: this.QuotesPagination.sort },
      { $skip: this.QuotesPagination.skip },
      { $limit: this.QuotesPagination.limit },
    ],
    customQuery: [{ stage: 'QUOTE' }],
  };

  searchItem: string = '';
  commissionToggle: boolean = false;
  product = 'domestic';

  constructor(
    public appService: AppService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.appService.isAdminOrThirdPartyOrPrimePartner()) {
      this.fetchAllQuotes();
    }
    this.fetchAllPolicies();
    this.onChangeLabelsOnThirdPartyOrPrimePartner();
  }

  goToPolicyDetails(_id: string) {
    this.router.navigate(['/domestic/list/domestic-details', _id]);
  }

  fetchAllQuotes() {
    let data = {
      pipeline: [
        {
          $match: {
            // This is the query to the quotes you want to display
            stage: 'QUOTE',
          },
        },
        { $sort: { _timestamp: this.QuotesPagination.sort._timestamp } },
        { $skip: this.QuotesPagination.skip },
        { $limit: this.QuotesPagination.limit },
        {
          $lookup: {
            from: 'domestic_questions',
            localField: 'questions_id',
            foreignField: '_id',
            as: 'questions',
          },
        },
        {
          $addFields: {
            questions: {
              $first: '$questions',
            },
          },
        },
        {
          $project: {
            // Limits the fields you want. To get everything remove the section.
            feduid: 1,
            stage: 1,
            client: 1,
            amount: 1,
            insurer: 1,
            sme: 1,
            'policy.policyTypeName': 1,
            'policy.period': 1,
            'questions.moreQuestions.client.userName': 1,
            'questions.moreQuestions.client.phoneNumber': 1,
            _timestamp: 1,
            _utimestamp: 1,
            'questions.moreQuestions.company.name': 1,
            'questions.moreQuestions.company.phoneNumber': 1,
          },
        },
      ],
      'client.email': { $exists: true },
    };

    if (this.appService.isThirdParty() || this.appService.isPrimePartner()) {
      data.pipeline[0].$match['ModuleID'] =
        this.appService.getSessionUser()?.ModuleID;
    }

    this.appService.getDomesticAggregateQuotesAndPolicies(data)?.subscribe(
      (data) => {
        if (data.Status == 200) {
          this.allQuotes = data.Payload;
          this.quoteRows = this.allQuotes.sort((a, b) => {
            return b._timestamp - a._timestamp;
          });
          this.dataQuoteLength = this.allQuotes.length;
          this.QuoteDataLoading = false;
          this.allQuoteDataLoaded = true;
        } else {
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            `${data.Status}`,
            data.Message
          );
        }
      },
      (err) => {
        this.QuoteDataLoading = true;
        this.allQuoteDataLoaded = false;
        this.appService.showToastMessage(
          AppEnums.ToastTypeError,
          '',
          this.appService.processHttpErrors(err)
        );
      }
    );
  }

  fetchAllPolicies() {
    let data = {
      pipeline: [
        {
          $match: {
            // This is the query to the quotes you want to display
            stage: 'PAY',
            paidamount: {
              $gt: 0,
            },
          },
        },
        { $sort: { _utimestamp: this.PoliciesPagination.sort._utimestamp } },
        { $skip: this.PoliciesPagination.skip },
        { $limit: this.PoliciesPagination.limit },
        {
          $lookup: {
            from: 'domestic_questions',
            localField: 'questions_id',
            foreignField: '_id',
            as: 'questions',
          },
        },
        {
          $addFields: {
            questions: {
              $first: '$questions',
            },
          },
        },
        {
          $project: {
            // Limits the fields you want. To get everything remove the section.
            feduid: 1,
            stage: 1,
            client: 1,
            amount: 1,
            insurer: 1,
            sme: 1,
            'policy.policyTypeName': 1,
            'policy.period': 1,
            'questions.moreQuestions.client.userName': 1,
            'questions.moreQuestions.client.phoneNumber': 1,
            _timestamp: 1,
            _utimestamp: 1,
            cancellation: 1,
            commission: 1,
            salesDateTimestamp: 1,
            salesDate: 1,
            'questions.moreQuestions.company.name': 1,
            'questions.moreQuestions.company.phoneNumber': 1,
          },
        },
      ],
      'client.email': { $exists: true },
    };

    if (this.appService.isThirdParty() || this.appService.isPrimePartner()) {
      data.pipeline[0].$match['ModuleID'] =
        this.appService.getSessionUser()?.ModuleID;
    }

    this.appService.getDomesticAggregateQuotesAndPolicies(data)?.subscribe(
      (data) => {
        if (data.Status == 200) {
          this.allPolicies = data.Payload;
          this.allPolicies.forEach((policy) => {
            if (policy.hasOwnProperty('commission')) {
              this.commissionsData.push(policy);
              this.checkToggle(false);
            }
          });
          this.dataCommissionLength = this.allCommissions.length;
          this.policyRows = this.allPolicies.sort((a, b) => {
            return b._utimestamp - a._utimestamp;
          });
          this.dataPolicyLength = this.allPolicies.length;
          this.PolicyDataLoading = false;
          this.allPolicyDataLoaded = true;
        } else {
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            `${data.Status}`,
            data.Message
          );
        }
      },
      (err) => {
        this.PolicyDataLoading = false;
        this.allPolicyDataLoaded = true;
        this.appService.showToastMessage(
          AppEnums.ToastTypeError,
          '',
          this.appService.processHttpErrors(err)
        );
      }
    );
  }

  checkToggle($event: boolean) {
    this.commissionToggle = $event;
    this.CommissionDataLoading = true;
    if (this.commissionToggle === false) {
      this.allCommissions = [];
      this.commissionPaid = 'Partially/Unpaid Commissions';
      this.commissionsData.forEach((com) => {
        if (com.commission.amount > com.commission.received) {
          this.allCommissions.push(com);
        }
      });
    } else if (this.commissionToggle === true) {
      this.allCommissions = [];
      this.commissionPaid = 'Fully-Paid Commissions';
      this.commissionsData.forEach((com) => {
        if (com.commission.amount == com.commission.received) {
          this.allCommissions.push(com);
        }
      });
    }
    this.CommissionDataLoading = false;
  }

  showQuotesSearchResults(results) {
    this.isQuoteSearchData = true;
    this.dataQuoteLength = results.length;
    this.allQuotes = results;
    this.quoteRows = this.allQuotes.sort((a, b) => {
      return b._timestamp - a._timestamp;
    });
  }

  showPolicySearchResults(results) {
    this.isPolicySearchData = true;
    this.dataPolicyLength = results.length;
    this.allPolicies = results;
    this.policyRows = this.allPolicies.sort((a, b) => {
      return b._utimestamp - a._utimestamp;
    });
  }

  updateQuoteSkip(newSkip) {
    if (newSkip) {
      if (newSkip === -10) {
        this.QuotesPagination.skip = 0;
      } else {
        this.QuotesPagination.skip = newSkip;
      }
      this.fetchAllQuotes();
    }
  }

  updatePolicySkip(newSkip) {
    if (newSkip) {
      if (newSkip === -10) {
        this.PoliciesPagination.skip = 0;
      } else {
        this.PoliciesPagination.skip = newSkip;
      }
      this.fetchAllPolicies();
    }
  }

  updateQuotesSearchSkip(newSkip) {
    if (newSkip) {
      if (newSkip === -10) {
        this.QuotesPagination.skip = 0;
        this.searchParamsQuotes.pagination[1].$skip = 0;
      } else {
        this.QuotesPagination.skip = newSkip;
        this.searchParamsQuotes.pagination[1].$skip = newSkip;
      }
      this.pagSkipSearch(this.searchParamsQuotes, this.searchItem, 'quote');
    }
  }

  updatePolicySearchSkip(newSkip) {
    if (newSkip) {
      if (newSkip === -10) {
        this.PoliciesPagination.skip = 0;
        this.searchParamsPolicies.pagination[1].$skip = 0;
      } else {
        this.PoliciesPagination.skip = newSkip;
        this.searchParamsPolicies.pagination[1].$skip = newSkip;
      }
      this.pagSkipSearch(this.searchParamsPolicies, this.searchItem, 'policy');
    }
  }

  updateCommissionSkip(newSkip) {
    if (newSkip) {
      if (newSkip === -10) {
        this.CommissionPagination.skip = 0;
      } else {
        this.CommissionPagination.skip = newSkip;
      }
      this.fetchAllPolicies();
    }
  }

  updateCommissionSearchSkip(newSkip) {
    if (newSkip) {
      if (newSkip === -10) {
        this.CommissionPagination.skip = 0;
        this.searchParamsCommissions.pagination[1].$skip = 0;
      } else {
        this.CommissionPagination.skip = newSkip;
        this.searchParamsCommissions.pagination[1].$skip = newSkip;
      }
      this.pagSkipSearch(
        this.searchParamsPolicies,
        this.searchItem,
        'commission'
      );
    }
  }

  showCommissionSearchResults(results) {
    this.isCommissionSearchData = true;
    this.dataCommissionLength = results.length;
    this.allCommissions = results;
    this.commissionRows = this.allCommissions.sort((a, b) => {
      return b._timestamp - a._timestamp;
    });
  }

  updateSearchInput(item) {
    this.searchItem = item;
  }

  pagSkipSearch(searchParams, searchItem, type) {
    if (type === 'policy') {
      this.PolicyDataLoading = true;
    } else {
      this.PolicyDataLoading = false;
    }
    if (type === 'quote') {
      this.QuoteDataLoading = true;
    } else {
      this.QuoteDataLoading = false;
    }
    if (type === 'commission') {
      this.CommissionDataLoading = true;
    } else {
      this.CommissionDataLoading = false;
    }

    if (!searchItem) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        'Missing Input',
        'Please enter your search item!'
      );
      return;
    }

    let cleanInputItem: any = searchItem;

    const fields = searchParams.searchFields.map((item) => {
      return { [item]: { $regex: cleanInputItem, $options: 'i' } };
    });

    const searchQuery = {
      pipeline: [
        { $match: {} },
        {
          $lookup: {
            ...searchParams.lookup,
          },
        },
        { $unwind: '$' + searchParams.lookup.as },
        {
          $match: {
            $and: [
              ...searchParams.customQuery,
              {
                $or: fields,
              },
            ],
          },
        },

        ...searchParams.pagination,

        {
          $project: {
            // Limits the fields you want. To get everything remove the section.
            ...searchParams.fields,
          },
        },
      ],
    };

    try {
      this.appService
        .makeSearchRequest(searchParams.url, {
          ...searchQuery,
        })
        ?.subscribe(
          (apiResponse) => {
            if (apiResponse.Status === 200) {
              if (apiResponse.Payload.length === 0) {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  'No results!',
                  'No results found. Try searching other options'
                );
              } else {
                if (type === 'policy') {
                  this.showPolicySearchResults(apiResponse.Payload);
                } else {
                  this.PolicyDataLoading = false;
                }
                if (type === 'quote') {
                  this.showQuotesSearchResults(apiResponse.Payload);
                } else {
                  this.QuoteDataLoading = false;
                }
                if (type === 'commission') {
                  this.showCommissionSearchResults(apiResponse.Payload);
                } else {
                  this.CommissionDataLoading = false;
                }
              }
            } else {
              this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                'Something went wrong',
                'Failed to get search results. Please try again'
              );
            }
          },
          (err) => {
            this.QuoteDataLoading = false;
            this.PolicyDataLoading = false;
            this.appService.showToastMessage(
              AppEnums.ToastTypeError,
              'Something went wrong',
              'Please try again'
            );
          }
        );
    } catch (ex) {
      this.QuoteDataLoading = false;
      this.PolicyDataLoading = false;
      this.appService.showToastMessage(
        AppEnums.ToastTypeError,
        'Something went wrong',
        'Please try again'
      );
      throw new Error(ex.toString);
    }
  }
  popDialogReport() {
    const dialogRef = this.dialog.open(DomesticDeclarationReportComponent, {
      width: '50%',
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  resetQuoteData() {
    this.fetchAllQuotes();
  }

  resetPolicyData() {
    this.fetchAllPolicies();
  }

  resetCommissionData() {
    this.fetchAllPolicies();
  }

  onChangeLabelsOnThirdPartyOrPrimePartner() {
    if (this.appService.isThirdParty() || this.appService.isPrimePartner()) {
      this.quotesLabel = 'GENERATED LEADS';
      this.policiesLabel = 'CONVERTED LEADS';
    }
  }
}
