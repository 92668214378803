<ul class="header-megamenu nav">
  <li class="nav-item">
    <button
      [ngbPopover]="headerMegaPopover"
      [autoClose]="'outside'"
      placement="bottom-left"
      popoverClass="rm-max-width"
      type="button"
      class="btn btn-link dropdown-toggle"
    >
      <span>
        <i class="nav-link-icon pe-7s-gift"></i>
        Mega Menu
      </span>
    </button>
  </li>
  <li class="nav-item">
    <div class="btn-group" ngbDropdown>
      <button class="btn btn-link" ngbDropdownToggle>
        <span>
          <div class="badge badge-pill badge-danger ml-0 mr-2">4</div>
          Settings
        </span>
      </button>
      <div ngbDropdownMenu>
        <div class="dropdown-menu-header">
          <div class="dropdown-menu-header-inner bg-focus">
            <div class="menu-header-image opacity-2 dd-header-bg-5"></div>
            <div class="menu-header-content">
              <h5 class="menu-header-title">Overview</h5>
              <h6 class="menu-header-subtitle">Dropdown menus for everyone</h6>
            </div>
          </div>
        </div>
        <div class="scroll-area-xs">
          <perfect-scrollbar [autoPropagation]="true">
            <h6 class="dropdown-header">Key Figures</h6>
            <button type="button" class="dropdown-item">
              Service Calendar
            </button>
            <button type="button" class="dropdown-item">Knowledge Base</button>
            <button type="button" class="dropdown-item">Accounts</button>
            <div class="dropdown-divider"></div>
            <button type="button" class="dropdown-item">Products</button>
            <button type="button" class="dropdown-item">Rollup Queries</button>
          </perfect-scrollbar>
        </div>
        <ul class="nav flex-column">
          <li class="nav-item-divider nav-item"></li>
          <li class="nav-item-btn nav-item">
            <button class="btn-wide btn-shadow btn btn-danger btn-sm">
              Cancel
            </button>
          </li>
        </ul>
      </div>
    </div>
  </li>
  <li class="nav-item">
    <div class="dropdown-menu-rounded btn-group" ngbDropdown>
      <button type="button" class="btn btn-link" ngbDropdownToggle>
        <span>
          <i class="nav-link-icon pe-7s-settings"></i>
          Projects
        </span>
      </button>
      <div class="dropdown-menu-lg" ngbDropdownMenu>
        <div class="dropdown-menu-header">
          <div class="dropdown-menu-header-inner bg-success">
            <div class="menu-header-image opacity-1 dd-header-bg-3"></div>
            <div class="menu-header-content text-left">
              <h5 class="menu-header-title">Overview</h5>
              <h6 class="menu-header-subtitle">Unlimited options</h6>
              <div class="menu-header-btn-pane">
                <button class="mr-2 btn btn-dark btn-sm">Settings</button>
                <button class="btn-icon btn-icon-only btn btn-warning btn-sm">
                  <i class="pe-7s-config btn-icon-wrapper"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <button type="button" class="dropdown-item">
          <i class="dropdown-icon lnr-file-empty"></i>Graphic Design
        </button>
        <button type="button" class="dropdown-item">
          <i class="dropdown-icon lnr-file-empty"></i>App Development
        </button>
        <button type="button" class="dropdown-item">
          <i class="dropdown-icon lnr-file-empty"></i>Icon Design
        </button>
        <div class="dropdown-divider"></div>
        <button type="button" class="dropdown-item">
          <i class="dropdown-icon lnr-file-empty"></i>Miscellaneous
        </button>
        <button type="button" class="dropdown-item">
          <i class="dropdown-icon lnr-file-empty"></i>Frontend Dev
        </button>
      </div>
    </div>
  </li>
</ul>

<ng-template #headerMegaPopover>
  <div class="dropdown-mega-menu">
    <div class="grid-menu grid-menu-3col">
      <div class="no-gutters row">
        <div class="col-sm-6 col-xl-4">
          <ul class="nav flex-column">
            <li class="nav-item-header nav-item">Overview</li>
            <li class="nav-item">
              <a [routerLink]="" class="nav-link"
                ><i class="nav-link-icon lnr-inbox"></i><span>Contacts</span></a
              >
            </li>
            <li class="nav-item">
              <a [routerLink]="" class="nav-link"
                ><i class="nav-link-icon lnr-book"></i><span>Incidents</span>
                <div class="ml-auto badge badge-pill badge-danger">5</div>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="" class="nav-link"
                ><i class="nav-link-icon lnr-picture"></i
                ><span>Companies</span></a
              >
            </li>
            <li class="nav-item">
              <a disabled="disabled" [routerLink]="" class="nav-link disabled"
                ><i class="nav-link-icon lnr-file-empty"></i
                ><span>Dashboards</span></a
              >
            </li>
          </ul>
        </div>
        <div class="col-sm-6 col-xl-4">
          <ul class="nav flex-column">
            <li class="nav-item-header nav-item">Favourites</li>
            <li class="nav-item">
              <a [routerLink]="" class="nav-link">Reports Conversions</a>
            </li>
            <li class="nav-item">
              <a [routerLink]="" class="nav-link"
                >Quick Start
                <div class="ml-auto badge badge-success">New</div>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="" class="nav-link">Users &amp; Groups</a>
            </li>
            <li class="nav-item">
              <a [routerLink]="" class="nav-link">Proprieties</a>
            </li>
          </ul>
        </div>
        <div class="col-sm-6 col-xl-4">
          <ul class="nav flex-column">
            <li class="nav-item-header nav-item">Sales &amp; Marketing</li>
            <li class="nav-item">
              <a [routerLink]="" class="nav-link">Queues</a>
            </li>
            <li class="nav-item">
              <a [routerLink]="" class="nav-link">Resource Groups</a>
            </li>
            <li class="nav-item">
              <a [routerLink]="" class="nav-link"
                >Goal Metrics
                <div class="ml-auto badge badge-warning">3</div>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="" class="nav-link">Campaigns</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-template>
