<div class="card" *ngIf="showUploadForm">
  <div class="card-body">
    <h4 class="card-title text-uppercase mb-0 text-mtek d-inline">
      <i class="fa fa-file fa-2x text-mtek h5"></i>
      Upload Documents
    </h4>
    <button class="btn btn-outline-danger d-inline float-right" (click)="toggleUploadForm()">
      Cancel
    </button>
    <hr />

    <div class="row" *ngIf="!allDataLoaded">
      <div class="col-md-12 text-center">
        <app-loader></app-loader>
      </div>
    </div>
    <div>
      <app-file-upload [allowedFileExtentions]="allowedFileExtentions" [azureFileLocation]="azureFileLocation"
        [azureFileName]="azureFileName" (emitFileUploadedEvent)="documentUploadedEvent($event)">
      </app-file-upload>
    </div>

    <form [formGroup]="uploadForm" (ngSubmit)="submitDocument()">
      <div class="form-group">
        <label for="name">Document URL</label>
        <input class="form-control" type="text" readonly formControlName="docUrl" placeholder="Document URL" />
      </div>
      <div class="row align-items-center justify-content-between">
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Document</mat-label>
            <mat-select formControlName="docName">
              <mat-option *ngFor="let docName of (appService.getPolicyDocNamesFromCdn|async)" [value]="docName">
                {{ docName.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-7">
          <mat-form-field>
            <textarea matInput formControlName="description" placeholder="Description">
                </textarea>
          </mat-form-field>
        </div>
      </div>
      <br />
      <button type="submit" class="btn btn-info d-inline float-right" [disabled]="!uploadForm.valid">
        Submit Details
      </button>
      <button class="btn btn-outline-danger d-inline float-right mr-2" (click)="toggleUploadForm()">
        Cancel
      </button>
    </form>

  </div>
</div>

<div class="card mt-3">
  <div class="card-body">
    <h4 class="card-title text-uppercase mb-0 text-mtek d-inline">
      Documents
      <button class="btn btn-info d-inline float-right" (click)="toggleUploadForm()">
        Upload Document
      </button>
    </h4>
  </div>
</div>