<div class="card mb-3">
  <div class="card-body">
    <div class="d-flex justify-content-between">
      <h2 class="text-mtek">
        <i class="fa fa-users text-mtek"></i> {{ userDetails?.userName }}
        <small>{{ userHeader }} Details</small>
      </h2>
      <div
        class="rep-details d-flex justify-content-between align-items-center"
      >
        <app-loader *ngIf="reportIsLoading" class="mr-5"></app-loader>
        <a
          href="javascript:void(0)"
          (click)="downloadDetailReport()"
          class="text-mtek bold-text mr-3"
          *ngIf="userHeader == 'County Rep' && reportIsLoading == false"
        >
          <i class="vsm-icon pe-7s-cloud-download h6"></i>
          <span class="aligned-with-icon h6"> Download Detailed Report</span>
        </a>
        <a
          href="javascript:void(0)"
          (click)="downloadReport()"
          class="text-mtek bold-text"
          *ngIf="userHeader == 'County Rep' && reportIsLoading == false"
        >
          <i class="vsm-icon pe-7s-cloud-download h6"></i>
          <span class="aligned-with-icon h6"> Download Agents list Report</span>
        </a>
        <button
          *ngIf="userHeader == 'Prime Partner'"
          class="btn btn-info p-3"
          (click)="addInvoice()"
        >
          INVOICE PAYMENTS
        </button>
      </div>
    </div>

    <app-loader *ngIf="isLoading"></app-loader>
    <hr />
    <h3>
      <strong>Direct Sales Statistics</strong> - From
      {{ selectedDateRange?.start_date }} To
      {{ selectedDateRange?.end_date }}
    </h3>
    <app-date-range (dateRange)="updateRange($event)"> </app-date-range>
    <div *ngIf="directSales.length > 0; else noDirectSales">
      <div class="row">
        <div
          class="col-2"
          *ngFor="let aPolicy of directSales"
          (click)="goToDirectSales(aPolicy.policy)"
        >
          <div class="card m-1">
            <div class="card-body">
              <p class="text-center">
                {{ aPolicy.policy | uppercase }}
                <strong class="d-block">{{ aPolicy.Count | number }}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #noDirectSales>
      <div class="alert alert-warning">
        <app-loader *ngIf="isLoading"></app-loader>
        <p class="d-block">
          {{ userDetails?.userName }} has no Direct Sales for the selected
          period
        </p>
      </div>
    </ng-template>

    <hr />
    <h3>
      <strong>Download Statistics</strong> - From
      {{ selectedDateRange.start_date }} To
      {{ selectedDateRange.end_date }}
    </h3>
    <div *ngIf="downloadCount > 0; else noDownloads" (click)="goToDownloads()">
      <div class="row">
        <div class="col-12">
          <div class="card m-1">
            <div class="card-body">
              <p class="text-center">
                Downloads
                <strong class="d-block">{{ downloadCount | number }}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #noDownloads>
      <div class="alert alert-warning">
        <app-loader *ngIf="isLoading"></app-loader>
        <p class="d-block">
          {{ userDetails?.userName }} has no downloads for the selected period
        </p>
      </div>
    </ng-template>

    <hr />
    <h3>
      <strong>PataPesa Agents Sign-Up Statistics</strong> - From
      {{ selectedDateRange.start_date }} To
      {{ selectedDateRange.end_date }}
    </h3>
    <div *ngIf="signUpCount > 0; else noSignups" (click)="goToAgents()">
      <div class="row">
        <div class="col-12">
          <div class="card m-1">
            <div class="card-body">
              <p class="text-center">
                PataPesa Agents Sign-Up
                <strong class="d-block">{{ signUpCount | number }}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #noSignups>
      <div class="alert alert-warning">
        <app-loader *ngIf="isLoading"></app-loader>
        <p class="d-block">
          {{ userDetails?.userName }} has no PataPesa Agents Sign-Up for the
          selected period
        </p>
      </div>
    </ng-template>

    <hr />
    <h3>
      <strong>Patapesa Performance Statistics</strong> - From
      {{ selectedDateRange.start_date }} To
      {{ selectedDateRange.end_date }}
    </h3>
    <div *ngIf="policyKeys.length > 0; else noPatapesaSales">
      <div class="row">
        <div class="col-2" *ngFor="let aSaleKey of policyKeys">
          <div class="card m-1">
            <div class="card-body">
              <p class="text-center">
                {{ aSaleKey?.cover | uppercase }}
                <strong class="d-block">{{ aSaleKey?.count | number }}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #noPatapesaSales>
      <div class="alert alert-warning">
        <app-loader *ngIf="isLoading"></app-loader>
        <p class="d-block">
          {{ userDetails?.userName }} has no PataPesa Sales for the selected
          period
        </p>
      </div>
    </ng-template>
  </div>
</div>
