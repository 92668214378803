<div class="row">
    <div class="col-md-6">
        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <label class="input-group-text" for="referralLinks">Referral Link</label>
            </div>
            <select class="custom-select" id="referralLinks" name="referralLink" (change)="setReferralLink($event)">
                <option selected></option>
                <option *ngFor="let aLink of referralLinks" [value]="aLink">{{aLink}}</option>
            </select>
        </div>
    </div>
</div>