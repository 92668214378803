import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDependants } from 'src/app/models/dependants.interface';
import { IGalleryImage } from 'src/app/models/gallery-image.interface';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-dependent-photo-gallery-partial',
  templateUrl: './dependent-photo-gallery-partial.component.html',
  styleUrls: ['./dependent-photo-gallery-partial.component.scss'],
})
export class DependentPhotoGalleryPartialComponent implements OnInit {
  @Input() membership: any[];

  specificDependantFormGroup: FormGroup;

  sasToken: string;
  imagesArray: IGalleryImage[] = [
    { imageName: 'Headshot Photo', fieldName: 'headshotPhoto', url: '' },
    { imageName: 'Id Back View', fieldName: 'idPhotoBack', url: '' },
    { imageName: 'Id Front View', fieldName: 'idPhotoFront', url: '' },
    { imageName: 'Kra Photo', fieldName: 'kraPhoto', url: '' },
    { imageName: 'Passport Photo', fieldName: 'passportPhoto', url: '' },
  ];
  allImages: any[] = [];

  constructor(
    private modalService: NgbModal,
    private appService: AppService,
    private _formBuilder: FormBuilder
  ) {
    this.specificDependantFormGroup = this._formBuilder.group({
      dependant: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.appService.getSasToken().subscribe((sasToken) => {
      this.sasToken = sasToken;
    });
  }

  popSlideShow(content) {
    this.modalService.open(content, {
      size: 'lg',
    });
  }

  onFileObjChange($event) {
    let dependent: any = {};
    dependent = this.membership[$event];

    for (let index = 0; index < this.imagesArray.length; index++) {
      const anImage = this.imagesArray[index];

      if (dependent.hasOwnProperty(anImage?.fieldName)) {
        this.imagesArray[index]['url'] =
          dependent[anImage?.fieldName] + this.sasToken;
        this.allImages.push(this.imagesArray[index]['url']);
      }
    }
  }
}
