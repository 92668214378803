<h1 mat-dialog-title>Update Next of Kin Information</h1>
<div mat-dialog-content>
  <app-loader *ngIf="isLoading"></app-loader>
  <form
    [formGroup]="evacuationNextOfKinFormGroup"
    *ngIf="data.cover == 'evacuation'; else nexofKinFormgroup"
  >
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label for="name">Kin's Name</label>
          <input
            class="form-control"
            type="text"
            formControlName="nokName"
            placeholder="Kin's Name"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="height">Kin's Phone</label>
          <input
            class="form-control"
            type="text"
            formControlName="nokPhoneNo"
            placeholder="e.g 07*********"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="height">Kin's ID</label>
          <input
            class="form-control"
            type="text"
            formControlName="nokId_Passport"
            placeholder="ID Number"
          />
        </div>
      </div>
      <div class="col-md-4">
        <label for="cancer">Relationship to Client</label>
        <select
          class="custom-select"
          formControlName="nokRelationship"
          id="nokRelationship"
          required
        >
          <option
            *ngFor="let relationship of relationships"
            [value]="relationship.value"
          >
            {{ relationship.name }}
          </option>
        </select>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="height">Kin's Location</label>
          <input
            class="form-control"
            type="text"
            formControlName="nokLocation"
            placeholder="Leave Blank If Not Sure"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="height">Kin's Postal Code</label>
          <input
            class="form-control"
            type="text"
            formControlName="nokPostalCode"
            placeholder="Leave Blank If Not Sure"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="height">Emergency Kin Location</label>
          <input
            class="form-control"
            type="text"
            formControlName="emergencyContactLocation"
            placeholder="Leave Blank If Not Sure"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="height">Emergency Contact Phone Number</label>
          <input
            class="form-control"
            type="text"
            formControlName="emergencyContactPhoneNo"
            placeholder="e.g 07*********"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="height">Emergency Contact Name</label>
          <input
            class="form-control"
            type="text"
            formControlName="emergencyContactName"
            placeholder="Leave Blank If Not Sure"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="height">Emergency Kin Relationship</label>
          <input
            class="form-control"
            type="text"
            formControlName="emergencyContactRelationship"
            placeholder="Leave Blank If Not Sure"
          />
        </div>
      </div>
    </div>
  </form>
  <ng-template #nexofKinFormgroup>
    <form [formGroup]="nextOfKinFormGroup">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="name">Kin's Name</label>
            <input
              class="form-control"
              type="text"
              formControlName="nokName"
              placeholder="Kin's Name"
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="height">Kin's Phone</label>
            <input
              class="form-control"
              type="text"
              formControlName="nokPhoneNo"
              placeholder="e.g 07*********"
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="height">Kin's ID</label>
            <input
              class="form-control"
              type="text"
              formControlName="nokId_Passport"
              placeholder="ID Number"
            />
          </div>
        </div>
        <div class="col-md-4">
          <label for="cancer">Relationship to Client</label>
          <select
            class="custom-select"
            formControlName="nokRelationship"
            id="nokRelationship"
            required
          >
            <option
              *ngFor="let relationship of relationships"
              [value]="relationship.value"
            >
              {{ relationship.name }}
            </option>
          </select>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="height">Kin's Location</label>
            <input
              class="form-control"
              type="text"
              formControlName="nokLocation"
              placeholder="Leave Blank If Not Sure"
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="height">Kin's Postal Code</label>
            <input
              class="form-control"
              type="text"
              formControlName="nokPostalCode"
              placeholder="Leave Blank If Not Sure"
            />
          </div>
        </div>
      </div>
    </form>
  </ng-template>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
  <app-loader *ngIf="isMoreQuestionsObjectAvailable"></app-loader>
  <button
    mat-button
    color="primary"
    *ngIf="isMoreQuestionsObjectAvailable == false"
    (click)="updateNokInfo()"
  >
    Submit
  </button>
</div>
