import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppService } from '../services/app.service';

@Pipe({
  name: 'countyName',
})
export class CountyNamePipe implements PipeTransform {
  constructor(private appService: AppService) {}
  transform(countyCode: string): Observable<string> {
    return this.appService.getCountiesFromCdn?.pipe(
      map((tps) => {
        return tps.find((tp) => tp.code == countyCode)?.name;
      })
    );
  }
}
