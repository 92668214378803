import { Component, Inject, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AppEnums } from 'src/app/enums/app.enum';
import { IRole } from 'src/app/models/role.interface';
import { IUnderwriter } from 'src/app/models/underwriter.interface';
import { IThirdParty } from 'src/app/models/third-parties.interface';
import { IUser } from 'src/app/models/user.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-user-role',
  templateUrl: './edit-user-role.component.html',
  styleUrls: ['./edit-user-role.component.scss'],
})
export class EditUserRoleComponent implements OnInit {
  selectedRoles = new FormControl();
  underwritersCache$: Observable<IUnderwriter[]>;
  thirdPartiesCache$: Observable<IThirdParty[]>;
  editUserRoleFormGroup: FormGroup;
  updateableRoles: IRole[] = [];
  selectedUserRoles: string[];
  isLoading: boolean = false;
  requireunderwriter: boolean = false;
  requireThirdParty: boolean = false;
  allRoles: IRole[] = [];
  body: any;

  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: IUser,
    public dialogRef: MatDialogRef<EditUserRoleComponent>
  ) {
    this.allRoles = this.appService.allLocalRolesObject();
    this.editUserRoleFormGroup = this.formBuilder.group({
      insurer: new FormControl(''),
      _id: new FormControl(this.data._id, Validators.required),
      role: this.selectedRoles,
      thirdPartyId: new FormControl(''),
      ModuleID: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.highlightSelectedUserRoles();
    this.setRequirement();
  }

  highlightSelectedUserRoles() {
    this.selectedUserRoles = this.appService.getRoles(this.data.roles);
  }

  setRequirement() {
    this.selectedRoles.valueChanges.subscribe((r) => {
      if (r.some((sel) => sel.requireunderwriter)) {
        this.requireunderwriter = true;
        this.isLoading = false;
        this.underwritersCache$ = this.appService.getAuthedUnderwriterAccount;
      } else if (r.some((sel) => sel.roleName == 'Lead Generator')) {
        this.requireThirdParty = true;
        this.isLoading = false;
        this.thirdPartiesCache$ = this.appService.getThirdPartiesFromCdn;
      } else {
        this.requireunderwriter = false;
        this.requireThirdParty = false;
      }
    });
  }

  setInsurer(insurer: string) {
    return {
      insurer: insurer,
      partner: insurer,
      underwriter: insurer,
    }
  }

  setThirdParties(thirdParty: string) {
    return {
      thirdPartyId: thirdParty,
      ModuleID: thirdParty
    }
  }


  submitRole() {
    if (!this.editUserRoleFormGroup.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeError,
        '',
        'Fill all fields'
      );
      return;
    }
    if (
      (this.editUserRoleFormGroup.controls['insurer'].value === '' &&
        this.requireunderwriter) ||
      (this.editUserRoleFormGroup.controls['thirdPartyId'].value === '' &&
        this.requireThirdParty)
    ) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeError,
        '',
        'Select an underwriter/leadgeneraor'
      );
      return;
    }

    let data = this.editUserRoleFormGroup.value
    let insurer = this.editUserRoleFormGroup.get("insurer").value;
    let id = this.editUserRoleFormGroup.get("_id").value;
    let thirdParty = this.editUserRoleFormGroup.get("thirdPartyId").value;
    let rolePicked = this.editUserRoleFormGroup.get("role").value
    let prepared_roles = this.prepareRoles(rolePicked)


    let body;
    if (this.editUserRoleFormGroup.get("thirdPartyId").value !== '') {
      body = {
        _id: id,
        update: this.setThirdParties(thirdParty),
        remove: this.setInsurer(insurer),
      }
    } else if (this.editUserRoleFormGroup.controls['insurer'].value !== '') {
      body = {
        _id: id,
        update: this.setInsurer(insurer),
        remove: this.setThirdParties(thirdParty)
      }
    } else if (this.editUserRoleFormGroup.valid && data !== null) {
      body = {
        _id: id,
        update: {},
        remove: {
          ...this.setInsurer(insurer), ...this.setThirdParties(thirdParty)
        }
      }
    }
    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.CORE_API_URL}/adminupdateuser`, body)
      .subscribe(
        (apiResponse) => {
          this.updateRole(prepared_roles)
          this.isLoading = false;
          if (apiResponse.Status === 200) {
            this.dialogRef.close();
            this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              apiResponse.Status + '',
              apiResponse.Message
            );
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeError,
              apiResponse.Status + '',
              apiResponse.Payload
            );
          }
        },
        (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            err.Status,
            this.appService.processHttpErrors(err)
          );
        }
      );
  }

  updateRole(role: []) {
    this.appService
      .makeUpdateRequest(`${environment.CORE_API_URL}/updateRole`, {
        _id: this.editUserRoleFormGroup.get('_id').value,
        roles: role,
      })
      .subscribe(
        (apiResponse) => {
          this.isLoading = false;
          if (apiResponse.Status === 200) {
            this.dialogRef.close();
            this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              apiResponse.Status + '',
              apiResponse.Message
            );
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeError,
              apiResponse.Status + '',
              apiResponse.Payload
            );
          }
        },
        (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            err.Status,
            this.appService.processHttpErrors(err)
          );
        }
      );
  }

  prepareRoles(sroles: IRole[]): any {
    let roleObjArray = [
      {
        name: AppEnums.RoleClient,
        value: true,
      },
      {
        name: AppEnums.RoleAgent,
        value: false,
      },
      {
        name: AppEnums.RoleValuer,
        value: false,
      },
      {
        name: AppEnums.RoleAssessor,
        value: false,
      },
      {
        name: AppEnums.RolePartner,
        value: false,
      },
      {
        name: AppEnums.RoleAdmin,
        value: false,
      },
      {
        name: AppEnums.RoleSalesAgent,
        value: false,
      },
      {
        name: AppEnums.RoleLeadGenerator,
        value: false,
      },
      {
        name: AppEnums.RolePrimePartner,
        value: false,
      },
    ];
    for (let index = 0; index < roleObjArray.length; index++) {
      const role = roleObjArray[index];
      if (sroles.some((sel) => sel.name === role.name)) {
        roleObjArray[index].value = true;
      }
    }
    return roleObjArray;
  }
}
