<div class="alert alert-info d-block mb-1" role="alert">
  <span class="d-block">Use the following column names in the csv file:</span>
  <span class="d-block font-weight-bold">{{fieldStrings}}</span>
</div>
<div class="container-fluid">
  <div class="col-md-6">
    <app-loader *ngIf="isLoading"></app-loader>
    <img src="https://img.icons8.com/color/48/000000/csv.png" />
    <span class="text-muted" style="font-size: 16px;">Import CSV</span>
    <div class="form-group">
      <input class="form-control" accept=".csv" id="csv" type="file" (change)="onFileSelect($event.target)"
        name="myfile">
    </div>
  </div>
</div>