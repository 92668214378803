<div class="card-body">
  <div
    class="table-responsive-sm"
    *ngIf="referralDetails.length > 0; else noReferrals"
  >
    <table class="table table-striped table-responsive-sm">
      <tr>
        <th scope="col">Date</th>
        <th scope="col">Name</th>
        <th scope="col">Email</th>
        <th scope="col">Phone</th>
        <th scope="col">Total Points</th>
        <th scope="col">Balance</th>
        <th scope="col">Details</th>
      </tr>

      <tbody>
        <tr
          *ngFor="let user of referralDetails"
          class="section-step"
          (click)="goToProfile(user)"
        >
          <td class="p-3">
            {{ user?._timestamp | date: 'medium' }}
          </td>
          <td class="p-3">{{ user?.userName }}</td>
          <td class="p-3">{{ user?.email }}</td>
          <td class="p-3">
            <span *ngIf="user?.phone; else phoneNumber">
              {{ user?.phone }}
            </span>
            <ng-template #phoneNumber>
              <span>
                {{ user?.phoneNumber }}
              </span>
            </ng-template>
          </td>
          <td class="p-3">
            {{ user?.bimaProfile?.totalPoints }}
          </td>
          <td class="p-3">
            {{ user?.bimaProfile?.balance }}
          </td>
          <td class="p-3">
            <i class="vsm-icon pe-7s-angle-right"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <ng-template #noReferrals>
    <div class="alert-info">No Referrals Found Yet.</div>
  </ng-template>
</div>
