import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IMedicalInterface } from 'src/app/models/medical.inteface';
import { AppService } from 'src/app/services/app.service';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AppEnums } from 'src/app/enums/app.enum';
import { environment } from 'src/environments/environment';
export interface IChangePolicyObject {
  cover: string;
  policy: IMedicalInterface;
}

@Component({
  selector: 'app-change-policy-period',
  templateUrl: './change-policy-period.component.html',
  styleUrls: ['./change-policy-period.component.scss'],
})
export class ChangePolicyPeriodComponent implements OnInit {
  model: NgbDateStruct;

  formGroup: FormGroup;
  isLoading: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: IChangePolicyObject,
    public dialogRef: MatDialogRef<ChangePolicyPeriodComponent>
  ) {
    this.formGroup = this.formBuilder.group({
      startDate: new FormControl('', Validators.compose([Validators.required])),
      endDate: new FormControl('', Validators.compose([Validators.required])),
    });
  }

  ngOnInit(): void { }
  submit() {
    if (this.formGroup.invalid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Fill all fields'
      );
      return;
    }

    let start: NgbDateStruct = this.formGroup.get('startDate').value;
    let end: NgbDateStruct = this.formGroup.get('endDate').value;

    const from: NgbDate = new NgbDate(start.year, start.month, start.day);
    if (from.after(end) || from.equals(end)) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Invalid Effective/Expiry Date Selection'
      );
      return;
    }

    let data = {
      starttimestamp: new Date(
        start.year,
        start.month - 1,
        start.day
      ).getTime(),
      endtimestamp: new Date(end.year, end.month - 1, end.day).getTime(),
    };
    this.submitPolicyUpdate(data);
  }

  getCancellationUrl(cover: string) {
    let url = '';
    switch (cover) {
      case 'motor':
        url = `${environment.MOTOR_API_URL}/adminupdatequote`;
        break;
      case 'medical':
        url = `${environment.MEDICAL_API_URL}/adminupdatequotes`;
        break;
      case 'pa':
        url = `${environment.PA_API_URL}/adminupdatequote`;
        break;
      case 'travel':
        url = `${environment.TRAVEL_API_URL}/adminupdatequote`;
        break;
      case 'evacuation':
        url = `${environment.EVACUATION_API_URL}/adminupdatequote`;
        break;
      case 'domestic':
        url = `${environment.DOMESTIC_API_URL}/adminupdatequote`;
        break;
      case 'golf':
        url = `${environment.GOLF_API_URL}/adminupdatequote`;
        break;
      case 'life':
        url = `${environment.LIFE_API_URL}/adminupdatequote`;
        break;

      default:
        url = '';
        break;
    }

    return url;
  }

  submitPolicyUpdate(data) {
    this.isLoading = true;
    let url = this.getCancellationUrl(this.data.cover);
    this.appService
      .makePostRequest(url, {
        _id: this.data.policy._id,
        update: data,
      })
      ?.subscribe(
        (res) => {
          this.isLoading = false;
          this.isLoading = false;
          if (res.Status == 200) {
            this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              'Success',
              'Policy period updated successfully'
            );
            this.dialogRef.close(this.data.policy._id);
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              'Failed',
              'Failed to update policy period'
            );
          }
        },
        (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
        }
      );
  }
}
