<div class="container">
  <div class="row d-flex justify-content-between align-items-center">
    <div class="form">
      <form [formGroup]="startDateForm">
        <div class="form-group">
          <input type="date" class="form-control" formControlName="date" />
        </div>
      </form>
    </div>
    <div class="button">
      <app-loader *ngIf="isLoading"></app-loader>
      <button
        class="btn btn-outline-success"
        *ngIf="isLoading == false"
        (click)="updatePolicyStartDate()"
      >
        Submit
      </button>
    </div>
  </div>
</div>
