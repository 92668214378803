import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IUser } from 'src/app/models/user.interface';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-policy-cancellation-details',
  templateUrl: './policy-cancellation-details.component.html',
  styleUrls: ['./policy-cancellation-details.component.scss'],
})
export class PolicyCancellationDetailsComponent implements OnInit {
  @Input() policy;
  isCancelledPolicy: boolean = false;
  cancelledBy$: Observable<IUser> = new Observable<IUser>();
  constructor(public appService: AppService) {}

  ngOnInit(): void {
    if (this.policy?.hasOwnProperty('cancellation')) {
      this.isCancelledPolicy = true;
      this.cancelledBy$ = this.appService.getUserDetailsByFeduid(
        this.policy.cancellation.feduid,
        ['name', 'userName']
      );
    }
  }
}
