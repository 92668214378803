import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppEnums } from 'src/app/enums/app.enum';
import { IPayment } from 'src/app/models/payment.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reversed-payments',
  templateUrl: './reversed-payments.component.html',
  styleUrls: ['./reversed-payments.component.scss'],
})
export class ReversedPaymentsComponent implements OnInit {
  paymentDetails: IPayment;
  editPaymentForm: FormGroup;
  dataSent: boolean = false;
  paymentDetailsId: string;

  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: IPayment,
    public dialogRef: MatDialogRef<ReversedPaymentsComponent>
  ) {
    this.editPaymentForm = this.formBuilder.group({
      _id: new FormControl(this.data._id, Validators.required),
      reversalReference: new FormControl('', Validators.required),
      reversalReason: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {}

  updatePayment() {
    this.appService
      .makePostRequest(
        `${environment.MPESA_API_URL}/api/updateReversedPayment`,
        this.editPaymentForm.value
      )
      .subscribe((response) => {
        this.dataSent = true;
        this.dialogRef.close();
        if (response.Status == 200) {
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            response.Status + '',
            response.Message
          );
          this.dialogRef.close();
        } else {
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            'Failed',
            response.Payload
          );
        }
      });
    this.dialogRef.close();
    (err) => {
      this.appService.showToastMessage(
        AppEnums.ToastTypeError,
        '',
        this.appService.processHttpErrors(err)
      );
    };
  }
}
