import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppEnums } from 'src/app/enums/app.enum';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import { CancelFinancePlanComponent } from '../cancel-finance-plan/cancel-finance-plan.component';
export interface IData {
  _id: string;
  product: string;
}

@Component({
  selector: 'app-update-finance-plan',
  templateUrl: './update-finance-plan.component.html',
  styleUrls: ['./update-finance-plan.component.scss'],
})
export class UpdateFinancePlanComponent implements OnInit {
  @Input() receivedId: string;
  @Input() product: string;
  @Input() isCancelled: string;

  updatePlanFormGroup: FormGroup;
  isLoading: boolean = false;

  constructor(
    private appService: AppService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: IData,
    public dialogRef: MatDialogRef<CancelFinancePlanComponent>
  ) {
    this.updatePlanFormGroup = formBuilder.group({
      amount: ['', Validators.required],
      date: ['', Validators.required],
    });
  }

  ngOnInit(): void {}

  updatePlan() {
    if (!this.updatePlanFormGroup.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Amount and Next Payment Date is Required'
      );
      return;
    }
    this.isLoading = true;
    let headers: any = {
      received_underscore_id: this.data._id,
      product: this.data.product,
      isCancellation: false,
      amount: this.updatePlanFormGroup.get('amount').value,
      nextPaymentDate: this.updatePlanFormGroup.get('date').value,
    };

    this.appService
      .makePostRequest(`${environment.IPF_API_URL}/adjustFinancePlan`, headers)
      .subscribe(
        (res) => {
          this.isLoading = false;
          if (res.Status === 200) {
            this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              '',
              'Success'
            );
            this.dialogRef.close();
            window.location.reload();
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              'Failed',
              'Failed to update finance plan'
            );
          }
        },
        (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        }
      );
  }

  closeDialog() {
    this.dialogRef.close('Closed');
    window.location.reload();
  }
}
