import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoggedInGuard } from './guards/logged-in.guard';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Dashboard' },
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'auth',
    canActivate: [LoggedInGuard],
    data: { breadcrumb: 'Authentication' },
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'policies',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Policies' },
    loadChildren: () =>
      import('./policies/policies.module').then((m) => m.PoliciesModule),
  },
  {
    path: 'patapesa',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Pata PesaAgents List' },
    loadChildren: () =>
      import('./pata-pesa/pata-pesa.module').then((m) => m.PataPesaModule),
  },
  {
    path: 'medical',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Medical Policies' },
    loadChildren: () =>
      import('./medical-policy/medical-policy.module').then(
        (m) => m.MedicalPolicyModule
      ),
  },
  {
    path: 'domestic',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Domestic Policies' },
    loadChildren: () =>
      import('./domestic-policy/domestic-policy.module').then(
        (m) => m.DomesticPolicyModule
      ),
  },
  {
    path: 'golf',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Golf Policies' },
    loadChildren: () =>
      import('./golf-policy/golf-policy.module').then(
        (m) => m.GolfPolicyModule
      ),
  },
  {
    path: 'motor',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Motor Policies' },
    loadChildren: () =>
      import('./motor-policy/motor-policy.module').then(
        (m) => m.MotorPolicyModule
      ),
  },
  {
    path: 'personal-accident',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Personal Accident Policies' },
    loadChildren: () =>
      import('./personal-accident-policy/personal-accident-policy.module').then(
        (m) => m.PersonalAccidentPolicyModule
      ),
  },

  {
    path: 'payments',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Payments List' },
    loadChildren: () =>
      import('./payment/payment.module').then((m) => m.PaymentModule),
  },

  {
    path: 'mpesa',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Mpesa Payments List' },
    loadChildren: () =>
      import('./payment/mpesa-payments/mpesa.module').then(
        (m) => m.MpesaModule
      ),
  },

  {
    path: 'card',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Card Payments List' },
    loadChildren: () =>
      import('./payment/card-payments/card.module').then((m) => m.CardModule),
  },
  {
    path: 'heatmaps',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Heat Map' },
    loadChildren: () =>
      import('./heatmap/heatmap.module').then((m) => m.HeatmapModule),
  },

  {
    path: 'notifications',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Notifications List' },
    loadChildren: () =>
      import('./notification/notification.module').then(
        (m) => m.NotificationModule
      ),
  },

  {
    path: 'asanas',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Asana Tasks List' },
    loadChildren: () =>
      import('./asana/asana.module').then((m) => m.AsanaModule),
  },
  {
    path: 'evacuation',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Evacuation Policy Lists' },
    loadChildren: () =>
      import('./evacuation-policy/evacuation-policy.module').then(
        (m) => m.EvacuationPolicyModule
      ),
  },
  {
    path: 'travel',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Travel Policies' },
    loadChildren: () =>
      import('./travel-policy/travel-policy.module').then(
        (m) => m.TravelPolicyModule
      ),
  },
  {
    path: 'currencies',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Currencies List' },
    loadChildren: () =>
      import('./currency/currency.module').then((m) => m.CurrencyModule),
  },
  {
    path: 'promocodes',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Promo Codes List' },
    loadChildren: () =>
      import('./promocode/promocode.module').then((m) => m.PromocodeModule),
  },
  {
    path: 'datahub',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Data Hub' },
    loadChildren: () =>
      import('./data-hub/data-hub.module').then((m) => m.DataHubModule),
  },
  {
    path: 'claims',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Claims' },
    loadChildren: () =>
      import('./claims/claims.module').then((m) => m.ClaimsModule),
  },
  {
    path: 'motor-claims',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Motor Claims' },
    loadChildren: () =>
      import('./motor-claim/motor-claim.module').then(
        (m) => m.MotorClaimModule
      ),
  },
  {
    path: 'medical-claims',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Medical Claims' },
    loadChildren: () =>
      import('./medical-claim/medical-claim.module').then(
        (m) => m.MedicalClaimModule
      ),
  },
  {
    path: 'travel-claims',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Travel Claims' },
    loadChildren: () =>
      import('./travel-claim/travel-claim.module').then(
        (m) => m.TravelClaimModule
      ),
  },
  {
    path: 'domestic-claims',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Domestic Claims' },
    loadChildren: () =>
      import('./domestic-claim/domestic-claim.module').then(
        (m) => m.DomesticClaimModule
      ),
  },
  {
    path: 'personal-accident-claims',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Personal Accident Claims' },
    loadChildren: () =>
      import('./personal-accident-claim/personal-accident-claim.module').then(
        (m) => m.PersonalAccidentClaimModule
      ),
  },
  {
    path: 'evacuation-claims',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Evacuation Claims' },
    loadChildren: () =>
      import('./evacuation-claim/evacuation-claim.module').then(
        (m) => m.EvacuationClaimModule
      ),
  },
  {
    path: 'golf-claims',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Golf Claims' },
    loadChildren: () =>
      import('./golf-claim/golf-claim.module').then((m) => m.GolfClaimModule),
  },
  {
    path: 'payment-plans',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Payment Plan List' },
    loadChildren: () =>
      import('./payment-plan/payment-plan.module').then(
        (m) => m.PaymentPlanModule
      ),
  },
  {
    path: 'dmvic',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'DMVIC List' },
    loadChildren: () =>
      import('./dmvic/dmciv.module').then((m) => m.DmcivModule),
  },
  {
    path: 'rbacs',
    data: { breadcrumb: 'Roles and Permissions' },
    loadChildren: () => import('./rbac/rbac.module').then((m) => m.RbacModule),
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'All Users' },
    loadChildren: () =>
      import('./client/client.module').then((m) => m.ClientModule),
  },
  {
    path: 'underwriter-contacts',
    loadChildren: () =>
      import('./underwriter-contact/underwriter-contact.module').then(
        (m) => m.UnderwriterContactModule
      ),
  },

  {
    path: 'payments/ntsa',
    loadChildren: () =>
      import('./payments-ntsa/payments-ntsa.module').then(
        (m) => m.PaymentsNtsaModule
      ),
  },

  {
    path: 'valuations',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Valuations' },
    loadChildren: () =>
      import('./valuation/valuation.module').then((m) => m.ValuationModule),
  },

  {
    path: 'motor-valuation',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Motor Valuation' },
    loadChildren: () =>
      import('./motor-valuation/motor-valuation.module').then(
        (m) => m.MotorValuationModule
      ),
  },

  {
    path: 'bima-points',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Bima Points List' },
    loadChildren: () =>
      import('./bima-points/bima-points.module').then(
        (m) => m.BimaPointsModule
      ),
  },
  {
    path: 'motor-policy-numbers',
    loadChildren: () =>
      import('./motor-policy-number/motor-policy-number.module').then(
        (m) => m.MotorPolicyNumberModule
      ),
  },
  {
    path: 'manual-sale-motor',
    loadChildren: () =>
      import('./manual-sale-motor/manual-sale-motor.module').then(
        (m) => m.ManualSaleMotorModule
      ),
  },
  {
    path: 'other-manual-sales',
    loadChildren: () =>
      import('./other-manual-sales/other-manual-sales.module').then(
        (m) => m.OtherManualSalesModule
      ),
  },
  {
    path: 'financials',
    loadChildren: () =>
      import('./financial/financial.module').then((m) => m.FinancialModule),
  },
  {
    path: 'unisure',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Unisure' },
    loadChildren: () =>
      import('./unisure/unisure.module').then((m) => m.UnisureModule),
  },
  {
    path: 'life',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Life' },
    loadChildren: () =>
      import('./life-policy/life-policy.module').then(
        (m) => m.LifePolicyModule
      ),
  },
  {
    path: 'lastexpense-policy',
    loadChildren: () =>
      import('./lastexpense-policy/lastexpense-policy.module').then(
        (m) => m.LastexpensePolicyModule
      ),
  },
  {
    path: 'payment-plan-calculator',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./payment-plan-calculator/payment-plan-calculator.module').then(
        (m) => m.PaymentPlanCalculatorModule
      ),
  },
  {
    path: 'commissions-life',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./commissions-life/commissions-life.module').then(
        (m) => m.CommissionsLifeModule
      ),
  },
  {
    path: 'lastexpense',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'LastExpense' },
    loadChildren: () =>
      import('./lastexpense-policy/lastexpense-policy.module').then(
        (m) => m.LastexpensePolicyModule
      ),
  },
  {
    path: 'wallet-transactions',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./wallet-transactions/wallet-transactions.module').then(
        (m) => m.WalletTransactionsModule
      ),
  },
  {
    path: 'invoice-payments',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./invoice-payments/invoice-payments.module').then(
        (m) => m.InvoicePaymentsModule
      ),
  },
  {
    path: 'telemedicine',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./telemedicine-policy/telemedicine.module').then(
        (m) => m.TelemedicineModule
      ),
  },
  {
    path: 'referrals',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./referrals/referrals.module').then((m) => m.ReferralsModule),
  },
  {
    path: 'api-credentials',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./api-credentials/api-credentials.module').then(
        (m) => m.ApiCredentialsModule
      ),
  },
  {
    path: 'referral-thirdparty',
    loadChildren: () =>
      import('./referral-thirdparty/referral-thirdparty.module').then(
        (m) => m.ReferralThirdpartyModule
      ),
  },
  {
    path: 'gadget',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./gadget-policy/gadget-policy.module').then(
        (m) => m.GadgetPolicyModule
      ),
  },
  {
    path: 'gadget-claims',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./gadget-claim/gadget-claim.module').then(
        (m) => m.GadgetClaimModule
      ),
  },
  {
    path: 'leads',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./leads/leads.module').then((m) => m.LeadsModule),
  },
  {
    path: 'credit-life',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./credit-life/credit-life.module').then(
        (m) => m.CreditLifeModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
