<mat-tab-group>
  <mat-tab
    label="{{ quotesLabel }}"
    *ngIf="appService.isAdminOrThirdPartyOrPrimePartner()"
  >
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex justify-content-between">
              <h2
                class="text-mtek"
                *ngIf="appService.isAdmin(); else isThirdParty"
              >
                <i class="fa fa-users text-mtek icon-big"> </i>
                Domestic Quotes
              </h2>
              <ng-template #isThirdParty>
                <h2 class="text-mtek">
                  <i class="fa fa-2x fa-anchor text-mtek"></i> Domestic
                  Insurance Leads
                </h2>
              </ng-template>

              <div class="col-md-4 d-flex justify-content-end">
                <a
                  *ngIf="appService.hasPermission('admin-policy-create')"
                  routerLink="/domestic/manual-sales-domestic/create"
                  class="text-mtek bold-text mr-3"
                >
                  <i class="vsm-icon pe-7s-plus h6"></i>
                  <span class="aligned-with-icon h6"> Add Policy </span>
                </a>
                <a
                  *ngIf="
                    appService.hasPermission('admin-policy-download-report')
                  "
                  href="javascript:void(0)"
                  (click)="popDialogReport()"
                  class="text-mtek bold-text"
                >
                  <i class="vsm-icon pe-7s-cloud-download h6"></i>
                  <span class="aligned-with-icon h6"> Download Report</span>
                </a>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-12 mb-3">
                <app-aggregate-search
                  [searchParams]="searchParamsQuotes"
                  (searchResults)="showQuotesSearchResults($event)"
                  (InputEmpty)="resetQuoteData()"
                  (searchItem)="updateSearchInput($event)"
                >
                </app-aggregate-search>
              </div>
            </div>
            <div *ngIf="allQuoteDataLoaded == true">
              <app-domestic-list-table
                *ngIf="quoteRows?.length > 0; else noQuotes"
                [policies]="quoteRows"
              >
              </app-domestic-list-table>

              <app-pagination
                class="section2"
                *ngIf="!isQuoteSearchData"
                [skip]="QuotesPagination.skip"
                [limit]="QuotesPagination.limit"
                [dataLength]="dataQuoteLength"
                [dataLoading]="QuoteDataLoading"
                (newSkip)="updateQuoteSkip($event)"
              ></app-pagination>

              <app-pagination
                class="section2"
                *ngIf="isQuoteSearchData"
                [skip]="QuotesPagination.skip"
                [limit]="QuotesPagination.limit"
                [dataLength]="dataQuoteLength"
                [dataLoading]="QuoteDataLoading"
                (newSkip)="updateQuotesSearchSkip($event)"
              ></app-pagination>
            </div>
            <ng-template #noQuotes>
              <div class="alert alert-warning">
                There are no Domestic Quotes Yet.
              </div>
            </ng-template>

            <div class="col-md-12 text-center">
              <app-loader *ngIf="allQuoteDataLoaded == false"></app-loader>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="{{ policiesLabel }}">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex justify-content-between">
              <h2
                class="text-mtek"
                *ngIf="appService.isAdminOrPartner(); else isThirdPartySale"
              >
                <i class="fa fa-users text-mtek icon-big"></i> Domestic Policies
              </h2>
              <ng-template #isThirdPartySale>
                <h2 class="text-mtek">
                  <i class="fa fa-2x fa-anchor text-mtek"></i> Domestic
                  Insurance Sales
                </h2>
              </ng-template>

              <div class="col-md-4 d-flex justify-content-end">
                <a
                  *ngIf="appService.hasPermission('admin-policy-create')"
                  routerLink="/domestic/manual-sales-domestic/create"
                  class="text-mtek bold-text mr-3"
                >
                  <i class="vsm-icon pe-7s-plus h6"></i>
                  <span class="aligned-with-icon h6"> Add Policy </span>
                </a>
                <a
                  *ngIf="
                    appService.hasPermission('admin-policy-download-report')
                  "
                  href="javascript:void(0)"
                  (click)="popDialogReport()"
                  class="text-mtek bold-text"
                >
                  <i class="vsm-icon pe-7s-cloud-download h6"></i>
                  <span class="aligned-with-icon h6"> Download Report</span>
                </a>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-12 mb-3">
                <app-aggregate-search
                  [searchParams]="searchParamsPolicies"
                  (searchResults)="showPolicySearchResults($event)"
                  (InputEmpty)="resetPolicyData()"
                  (searchItem)="updateSearchInput($event)"
                ></app-aggregate-search>
              </div>
            </div>

            <div *ngIf="allPolicyDataLoaded == true">
              <app-domestic-list-table
                *ngIf="policyRows?.length > 0; else noPolicies"
                [policies]="policyRows"
              >
              </app-domestic-list-table>

              <app-pagination
                class="section2"
                *ngIf="!isPolicySearchData"
                [skip]="PoliciesPagination.skip"
                [limit]="PoliciesPagination.limit"
                [dataLength]="dataPolicyLength"
                [dataLoading]="PolicyDataLoading"
                (newSkip)="updatePolicySkip($event)"
              ></app-pagination>

              <app-pagination
                class="section2"
                *ngIf="isPolicySearchData"
                [skip]="PoliciesPagination.skip"
                [limit]="PoliciesPagination.limit"
                [dataLength]="dataPolicyLength"
                [dataLoading]="PolicyDataLoading"
                (newSkip)="updatePolicySearchSkip($event)"
              ></app-pagination>
            </div>

            <ng-template #noPolicies>
              <div class="alert alert-warning">
                There are no Domestic Policies Yet.
              </div>
            </ng-template>

            <div class="col-md-12 text-center">
              <app-loader *ngIf="allPolicyDataLoaded == false"></app-loader>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab
    label="COMMISSIONS"
    *ngIf="appService.hasPermission('admin-policy-view-commissions')"
  >
    <app-policy-commissions-list
      [product]="product"
    ></app-policy-commissions-list>
  </mat-tab>
  <!-- <mat-tab label="MANUAL SALES" *ngIf="appService.isAdmin()">
      <app-domestic-manual-sales></app-domestic-manual-sales>
    </mat-tab> -->
</mat-tab-group>
