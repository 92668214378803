<ul
  *ngIf="collapsed && data.items && data.items.length > 0"
  [@submenu]="
    expanded
      ? {
          value: 'visible',
          params: {
            transitionParams: '400ms cubic-bezier(0.86, 0, 0.07,1)',
            height: '*'
          }
        }
      : {
          value: 'hidden',
          params: {
            transitionParams: '400ms cubic-bezier(0.86, 0, 0.07,1)',
            height: '0'
          }
        }
  "
  class="sublevel-nav"
>
  <li *ngFor="let item of data.items" class="sublevel-nav-item">
    <a
      class="sublevel-nav-link"
      *ngIf="item.items && item.items.length > 0"
      (click)="handleClick(item)"
      [ngClass]="getActiveClass(item)"
    >
      <span class="sublevel-link-text" *ngIf="collapsed">{{
        item.policyName
      }}</span>
      <i
        *ngIf="item.items && collapsed"
        class="menu-collapsed-icon"
        [ngClass]="!item.expanded ? 'fa fa-angle-right' : 'fa fa-angle-down'"
      ></i>
    </a>
    <a
      class="sublevel-nav-link"
      *ngIf="!item.items || (item.items && item.items.length === 0)"
      [routerLink]="[item.link]"
      routerLinkActive="active-sublevel"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <span class="sublevel-link-text" *ngIf="collapsed">{{
        item.policyName
      }}</span>
    </a>
    <div *ngIf="item.items && item.items.length > 0">
      <app-sidebar-sublevel-menu
        [collapsed]="collapsed"
        [multiple]="multiple"
        [expanded]="item.expanded"
      ></app-sidebar-sublevel-menu>
    </div>
  </li>
</ul>
