import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppEnums } from 'src/app/enums/app.enum';
import { ApiResponse } from 'src/app/models/api-response.interface';
import { IUnderwriter } from 'src/app/models/underwriter.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import { IPaymentdetail } from '../payment-plan-partial/payment-plan-partial.component';

@Component({
  selector: 'app-register-external-payment-partial',
  templateUrl: './register-external-payment-partial.component.html',
  styleUrls: ['./register-external-payment-partial.component.scss'],
})
export class RegisterExternalPaymentPartialComponent implements OnInit {
  registerPaymentFormGroup: FormGroup;
  checkBankDetails: boolean = false;

  @Input() receivedId: string;
  @Input() insurer: string;
  @Input() product: string;
  @Input() paymentdetails?: IPaymentdetail;
  @Input() feduid: string;
  @Output() emitPaymentCompleteEvent = new EventEmitter<any>();
  showMpesaPhoneNumberField: boolean = false;
  selectedPolicy: any;
  underwriters: IUnderwriter[] = [];
  endDate: number = 0;
  paymentChannels = [
    { id: 'M-PESA', value: 'OFFLINE' },
    { id: 'Bank Deposit', value: 'OFFLINE' },
    { id: 'Bank Transfer', value: 'OFFLINE' },
    { id: 'Cash', value: 'OFFLINE' },
    { id: 'Card', value: 'OFFLINE' },
    { id: 'Cheque', value: 'OFFLINE' },
    { id: 'Other', value: 'OFFLINE' },
  ];

  isLoading: boolean = false;
  isWalletTransaction: boolean = false;
  constructor(
    public appService: AppService,
    private _formBuilder: FormBuilder,
    private router: Router
  ) {
    this.registerPaymentFormGroup = this._formBuilder.group({
      paymentChannel: ['', Validators.required],
      paidTo: ['', Validators.required],
      totalPaid: ['', Validators.required],
      reference: ['', Validators.required],
      description: ['', Validators.required],
      mpesaNumber: [''],
      projectedEndPaymentTimeStamp: [''],
    });
    this.getUnderwriterInsurer(AppEnums.MTEK_GUUID);
    this.watchFormValueChange();
  }

  ngOnInit(): void {
    if (this.insurer) {
      this.getUnderwriterInsurer(this.insurer);
    }
    if (this.paymentdetails) {
      if (this.paymentdetails.hasOwnProperty('bank')) {
        if (this.paymentdetails.bank === 'TRUSTGRO') {
          this.getUnderwriterInsurer(AppEnums.PartnerTrustgro);
        }
      }
    }
  }

  getUnderwriterInsurer(insurerId: string) {
    this.appService?.getCombinedUnderwriterThirdpartyFromCdn.subscribe(
      (allUnderwriters) => {
        this.underwriters.push(
          allUnderwriters.find((anUnderwriter) => {
            return anUnderwriter.insurer === insurerId;
          })
        );
      }
    );
  }

  watchFormValueChange() {
    this.registerPaymentFormGroup
      .get('paymentChannel')
      .valueChanges.subscribe((v) => {
        if (v === 'M-PESA') {
          this.showMpesaPhoneNumberField = true;
        } else {
          this.showMpesaPhoneNumberField = false;
        }
      });
  }

  registerPayment() {
    if (this.isWalletTransaction) {
      this.registerPaymentFormGroup = this._formBuilder.group({
        paymentChannel: ['Wallet', Validators.required],
        paidTo: ['NA', Validators.required],
        totalPaid: ['NA', Validators.required],
        reference: ['NA', Validators.required],
        description: ['NA', Validators.required],
        mpesaNumber: [''],
        projectedEndPaymentTimeStamp: [''],
      });
    }
    if (!this.registerPaymentFormGroup.valid) {
      const controls = this.registerPaymentFormGroup.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            'Field Required',
            name
          );
        }
      }
      return;
    }

    if (this.isWalletTransaction) {
      this.isLoading = true;
      let paymentId: string = '';
      const stagePaymentBody = {
        bank: 'N/A',
        cover: 'motor',
        customPerUnitRepaymentAmount: 0.0,
        _id: this.receivedId,
        isDownPayment: true,
        plan: 1,
      };

      this.appService
        .makePostRequest(
          `${environment.IPF_API_URL}/stagePayment`,
          stagePaymentBody
        )
        .pipe(switchMap((res) => this.submitWalletPayment(res)))
        .subscribe(
          (apiResponse) => {
            if (apiResponse.Status == 200) {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeSuccess,
                '',
                'Success'
              );
              this.emitPaymentCompleteEvent.emit(apiResponse);
            } else {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeWarning,
                apiResponse.Payload,
                'Warning'
              );
            }
          },
          (error) => {
            this.isLoading = false;
            this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              this.appService.processHttpErrors(error),
              'Error'
            );
          }
        );
    } else {
      let dt = this.registerPaymentFormGroup.get(
        'projectedEndPaymentTimeStamp'
      ).value;
      this.endDate = Date.parse(dt);
      const now = Date.now();
      if (this.endDate < now) {
        this.appService.showToastMessage(
          AppEnums.ToastTypeWarning,
          'Invalid Input',
          'Kindly pick an appropriate end payment date'
        );
        return;
      }

      this.isLoading = true;
      let body: any = {
        _id: this.receivedId,
        amount: Number(this.registerPaymentFormGroup.get('totalPaid').value),
        received_underscore_id: this.receivedId,
        broker: this.registerPaymentFormGroup.get('paidTo').value,
        tenantUUID: this.registerPaymentFormGroup.get('paidTo').value,
        insurer: this.insurer,
        currency: 'KES',
        feduid: this.appService.getSessionUser()?.feduid,
        id: this.receivedId,
        internalTenant: 1,
        ref: this.product.toLowerCase(),
        mobileNumber: this.registerPaymentFormGroup.get('mpesaNumber').value,
        transactionDescription:
          this.registerPaymentFormGroup.get('description').value,
        transactionRef: this.registerPaymentFormGroup.get('reference').value,
        transactionType: 'OFFLINE',
        projectedEndPaymentTimeStamp: this.endDate,
      };

      this.appService
        .makePostRequest(
          `${environment.PAYMENTS_API_URL}/initiatepayment`,
          body
        )
        .subscribe(
          (res) => {
            this.isLoading = true;
            if (res.Status === 200) {
              this.isLoading = false;
              this.appService.showToastMessage(
                AppEnums.ToastTypeSuccess,
                'Success',
                ''
              );
              this.emitPaymentCompleteEvent.emit(res);
              this.isLoading = false;
            }
          },
          (error) => {
            this.isLoading = true;
            this.appService.showToastMessage(
              AppEnums.ToastTypeError,
              'Error',
              this.appService.processHttpErrors(error)
            );
            this.isLoading = false;
          }
        );
    }
  }

  submitWalletPayment(res: ApiResponse): Observable<ApiResponse> {
    return this.appService.makePostRequest(
      `${environment.MPESA_API_URL}/adminInitiateWalletCheckout`,
      {
        _id: this.receivedId,
        product: 'motor',
        paymentId: res.Payload?.paymentRecord?._id,
        feduid: this.feduid,
      }
    );
  }

  onPaymentChannelChange(event: string) {
    if (event === 'Wallet') {
      this.isWalletTransaction = true;
    }
  }
}
