<div class="table-responsive-sm">
  <table class="table table-striped table-responsive-sm">
    <tr>
      <th scope="col">Date</th>
      <th scope="col">Name</th>
      <th scope="col">Underwriter</th>
      <th scope="col">Policy</th>
      <th scope="col">Amount</th>
      <th scope="col">Status</th>
    </tr>
    <tbody>
      <tr *ngFor="let card of payments" class="section-step" (click)="goToPaymentDetails(card._id)">
        <td class="p-3">{{ card?._timestamp | date: 'medium' }}</td>
        <td class="p-3">
          {{ card?.client?.userName }} {{ card?.client?.name }}
        </td>
        <td class="p-3">
          {{ card?.broker | underwriter | async }}
        </td>
        <td class="p-3">{{ card?.service?.transactionDescription }}</td>
        <td class="p-3">{{ card?.amount | currency: 'Ksh. ' }}</td>
        <td class="p-3">
          <span *ngIf="card?.status == 'SUCCESS'; else successReversed" class="text-success">{{ card?.status }}</span>

          <span *ngIf="card?.status == 'SUCCESS_REVERSED'; else pending" class="text-success">{{ card?.status }}</span>
          <span *ngIf="card?.status == 'PENDING'; else failed" class="text-warning">
            {{ card?.status }}<br />
          </span>

          <span *ngIf="card?.status == 'FAILED'; else noStatus" class="text-danger">
            {{ card?.status }}</span>

          <span *ngIf="card?.status == null">NA</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>