<div class="row" *ngIf="appService.isPartner()">
  <div class="col-md-12">
    <div class="alert alert-info" *ngIf="paymentdetails?.plan > 1">
      This is a purchase via payment plan and is being handled by MTEK.
    </div>
  </div>
</div>

<div class="row" *ngIf="appService.hasPermission('admin-policy-payment')">
  <div class="col-md-12">
    <div class="card mb-3">
      <div class="card-body">
        <h4 class="card-title text-uppercase mb-0 text-mtek d-inline">
          Record Policy Payment
        </h4>
        <div *ngIf="
            showAddPayment &&
              appService.hasPermission('admin-policy-add-payment');
            else noshowAddPayment
          ">
          <button class="btn btn-outline-danger float-right" (click)="toggleAddPayment()">
            Close
          </button>
          <hr />
          <app-register-external-payment-partial (emitPaymentCompleteEvent)="completePayment($event)"
            [receivedId]="selectedRecordId" [product]="product" [paymentdetails]="paymentdetails"
            [insurer]="selectedRecord?.insurer">
          </app-register-external-payment-partial>
        </div>
        <ng-template #noshowAddPayment>
          <button class="btn btn-outline-primary float-right" (click)="toggleAddPayment()">
            Add Payment
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="selectedRecord?.paymentdetails; else noPaymentInfo">
  <div class="col-md-9">
    <div *ngIf="appService.isAdmin()">
      <div *ngIf="paymentdetails?.bank === 'MORIENTAL-BANK'">
        <div class="card mb-3" *ngIf="paymentdetails?.plan > 1">
          <div class="card-body">
            <h4 class="card-title text-uppercase mb-0 text-mtek d-inline">
              Payment Plan Summary
            </h4>
            <hr />
            <div class="list-group section1 text-center">
              <div class="row">
                <!-- Payment Plan -->

                <div class="col text-info">
                  <h5 class="mb-1 d-block text-info bold-text">Plan</h5>
                  <h5>
                    <span class="text-center h6">
                      {{ paymentdetails?.plan | paymentPlan }}
                    </span>
                  </h5>
                </div>

                <!-- Interest Rate-->

                <div class="col text-info">
                  <h5 class="mb-1 d-block text-info bold-text">
                    Interest Rate
                  </h5>
                  <h5>
                    <span class="text-center h6">{{
                      paymentdetails?.interest | percent
                      }}</span>
                  </h5>
                </div>

                <!-- Down Payment | Principle -->

                <div class="col text-info">
                  <h5 class="mb-1 d-block text-info bold-text">Principle</h5>
                  <h5>
                    <span class="text-center h6">{{
                      paymentdetails?.principle | currency: ' '
                      }}</span>
                  </h5>
                </div>

                <!-- Interest -->

                <div class="col text-info">
                  <h5 class="mb-1 d-block text-info bold-text">Interest</h5>
                  <h5>
                    <span class="text-center h6">{{
                      paymentdetails?.interest * paymentdetails?.principle
                      | currency: ' '
                      }}</span>
                  </h5>
                </div>

                <!-- Payable Premium -->

                <div class="col text-info">
                  <h5 class="mb-1 d-block text-info bold-text">Payable</h5>
                  <div *ngIf="paymentdetails?.payable; else totalPayable">
                    <span class="text-center h6">
                      {{ paymentdetails?.payable | currency: ' ' }}</span>
                  </div>
                  <ng-template #totalPayable>
                    <span class="text-center h6">
                      {{ paymentdetails?.totalPayable | currency: ' ' }}</span>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="payment-records-header d-flex justify-content-between align-items-center">
          <h4 class="card-title text-uppercase mb-2 text-mtek d-inline">
            Payment Records
          </h4>
          <button class="btn btn-primary mb-2" *ngIf="
              appService.hasPermission('admin-policy-update-finance-plan') &&
              totalBalance > 0 &&
              paymentdetails?.financed
            " (click)="onUpdateFinancePlan()">
            Update Finance Plan
          </button>
        </div>
        <div *ngIf="paymentdetails?.plan > 1">
          <app-repayment-status [paymentdetails]="paymentdetails" [selectedRecordId]="selectedRecordId">
          </app-repayment-status>
        </div>
        <app-payment-log-entry [paymentLogs]="paymentLogs" [paymentdetails]="paymentdetails" [isLife]="isLife"
          [selectedRecord]="selectedRecord">
        </app-payment-log-entry>
      </div>
    </div>
    <div *ngIf="appService.isAdminOrThirdParty()">
      <div *ngIf="paymentdetails?.bank === 'TRUSTGRO'">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title text-uppercase mb-0 text-mtek">Loan Terms</h4>
            <div class="row p-3">
              <div class="col card card1 mb-5 bg-white rounded">
                <div class="card-body">
                  <div class="row">
                    <div class="col txt-label">
                      Lender <br />
                      <small>(bank)</small>
                    </div>
                    <div class="col txt-content">
                      {{ paymentdetails?.moreDetails?.bank }}
                    </div>
                  </div>
                  &nbsp;
                  <div class="row">
                    <div class="col txt-label">
                      Interest Rate <br />
                      <small>(percentage per plan period)</small>
                    </div>
                    <div class="col txt-content">
                      {{ paymentdetails?.moreDetails?.interest }}%
                    </div>
                  </div>
                  &nbsp;
                  <div class="row">
                    <div class="col txt-label">
                      Loan Period <br />
                      <small>(duration the loan should be repaid by)</small>
                    </div>
                    <div class="col txt-content">
                      {{ paymentdetails?.moreDetails?.paymentTenure || paymentdetails?.moreDetails?.tenureInMonths }}
                      {{ paymentdetails?.plan | paymentPlanNew }}
                    </div>
                  </div>
                  &nbsp;
                  <div class="row">
                    <div class="col txt-label">
                      Loan Amount <br />
                      <small>(Loan awarded)</small>
                    </div>
                    <div class="col txt-content">
                      {{
                      paymentdetails?.moreDetails?.loanAmount
                      | currency: 'Ksh.'
                      }}
                    </div>
                  </div>
                  &nbsp;

                  <div class="row">
                    <div class="col txt-label">
                      Repayment Unit Amount <br />
                      <small>(expected payment per plan)</small>
                    </div>
                    <div class="col txt-content">
                      {{
                      paymentdetails?.perUnitRepaymentAmount
                      | currency: 'Ksh.'
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col card mb-5 bg-white rounded">
                <div class="card-body">
                  <div class="row">
                    <div class="col txt-label">
                      Basic Payable Amount <br />
                      <small>(without interest)</small>
                    </div>
                    <div class="col txt-content">
                      {{
                      paymentdetails?.moreDetails?.amount | currency: 'Ksh.'
                      }}
                    </div>
                  </div>
                  &nbsp;
                  <div class="row">
                    <div class="col txt-label">
                      Interest Payable Amount <br />
                      <small>(total interest)</small>
                    </div>
                    <div class="col txt-content">
                      {{
                      paymentdetails?.moreDetails?.totalCostOfCredit
                      | currency: 'Ksh.'
                      }}
                    </div>
                  </div>
                  &nbsp;
                  <div class="row">
                    <div class="col txt-label">
                      Down Payment Amount <br />
                      <small>(deposit paid)</small>
                    </div>
                    <div class="col txt-content">
                      {{
                      paymentdetails?.moreDetails?.downPaymentPayable
                      | currency: 'Ksh.'
                      }}
                    </div>
                  </div>
                  &nbsp;
                  <div class="row">
                    <div class="col txt-label">
                      Total Monthly Repayments<br />
                      <small>(total payments minus downpayment)</small>
                    </div>
                    <div class="col txt-content">
                      {{
                      paymentdetails?.moreDetails?.totalMonthlyRepayments
                      | currency: 'Ksh.'
                      }}
                    </div>
                  </div>
                  <hr />

                  <div class="row">
                    <div class="col">
                      <span class="text-mtek bold-text"> Total Payments </span>
                      <br />
                      <small>(basic plus interest amount)</small>
                    </div>
                    <div class="col bold-text txt-content">
                      {{
                      paymentdetails?.moreDetails?.totalPayable
                      | currency: 'Ksh.'
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            &nbsp;

            <h4 class="card-title text-uppercase mb-0 text-mtek">
              <i class="fa fa-calendar-check-o text-mtek icon-big h5" aria-hidden="true"></i>
              &nbsp; Loan Payments
              <button class="btn btn-outline-primary float-right" (click)="toggleSchedulePayment()">
                Loan Schedule
              </button>
            </h4>

            <hr />

            <div class="card mb-5 bg-white rounded" *ngIf="showSchedulePayment">
              <div class="card-body">
                <h4 class="card-title text-uppercase mb-0 text-mtek d-inline">
                  {{ paymentdetails?.plan | paymentPlan }} Loan Schedule
                  <button class="btn btn-outline-danger float-right" (click)="toggleSchedulePayment()">
                    Close
                  </button>
                </h4>
                <hr />
                <div *ngIf="selectedRecordId">
                  <app-payment-schedule-table [receivedId]="selectedRecordId"
                    [sheduledPaymentPlans]="sheduledPaymentPlans">
                  </app-payment-schedule-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="card mb-3">
      <div class="card-body">
        <h4 class="card-title text-uppercase mb-0 text-mtek">PROMO DISCOUNT</h4>
        <hr />
        <div *ngIf="promocode; else noPromoCode">
          <div class="row">
            <div class="col-md-12">
              <h5 class="mb-1 d-block text-info bold-text">Date</h5>
              <p>
                <span class="text-center">
                  {{ promocode?._timestamp | date: 'medium' }}
                </span>
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <h5 class="mb-1 d-block text-info bold-text">Code</h5>
              <p>
                <span class="text-center">
                  {{ promocode?.code }}
                </span>
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <h5 class="mb-1 d-block text-info bold-text">Discount</h5>
              <p>
                <span class="text-center">
                  {{ promocode?.discount }}
                </span>
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <h5 class="mb-1 d-block text-info bold-text">Payable</h5>
              <p>
                <span class="text-center">
                  {{ promocode?.payable | currency: 'KES. ' }}
                </span>
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <h5 class="mb-1 d-block text-info bold-text">product</h5>
              <p>
                <span class="text-center">
                  {{ promocode?.product }}
                </span>
              </p>
            </div>
          </div>
        </div>

        <ng-template #noPromoCode>
          <div class="alert alert-warning">
            There are no Promo Discounts for this Purchase.
          </div>
        </ng-template>
      </div>
    </div>

    <div class="card mb-3">
      <div class="card-body">
        <h4 class="card-title text-uppercase mb-0 text-mtek">Bima Discount</h4>
        <hr />
        <div *ngIf="
            paymentdetails?.hasOwnProperty('bimaRedemption');
            else noBimaPoints2
          ">
          <div *ngIf="
              paymentdetails.bimaRedemption.hasOwnProperty('pointValue');
              else noBimaPoints
            ">
            <p>
              <span class="d-block text-muted">Points Value</span>
              <strong>{{ paymentdetails?.bimaRedemption?.pointValue }}</strong>
            </p>

            <p>
              <span class="d-block text-muted">Redeemed Points</span>
              <strong>{{
                paymentdetails?.bimaRedemption?.redeemedPoints
                }}</strong>
            </p>

            <p>
              <span class="d-block text-muted">Redeemed Value</span>
              <strong>{{
                paymentdetails?.bimaRedemption?.redeemedValue
                | currency: 'KES. '
                }}</strong>
            </p>
          </div>
          <ng-template #noBimaPoints>
            <div class="alert alert-warning">
              There are no Bima Discounts for this Purchase.
            </div>
          </ng-template>
        </div>
        <ng-template #noBimaPoints2>
          <div class="alert alert-warning">
            There are no Bima Discounts for this Purchase.
          </div>
        </ng-template>
      </div>
    </div>

    <div class="card" *ngIf="appService.hasPermission('admin-policy-update-finance-plan')">
      <div class="card-body">
        <h4 class="card-title text-uppercase mb-0 text-mtek">Finance Plan</h4>
        <hr />
        <div>
          <mat-slide-toggle #toggleBtn (change)="popAction($event)" [checked]="check">
            <div *ngIf="toggleBtn.checked === true">On</div>
            <div *ngIf="toggleBtn.checked === false">Off</div>
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #noPaymentInfo>
  <div class="alert alert-warning" role="alert">
    This policy record has no payment information.
  </div>
</ng-template>