import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexTitleSubtitle,
  ApexLegend,
  ApexFill,
  ApexResponsive,
} from 'ng-apexcharts';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { IpaymentStats } from 'src/app/models/payment.interface';
import { AppEnums } from 'src/app/enums/app.enum';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  legend: ApexLegend;
  fill: ApexFill;
  title: ApexTitleSubtitle;
};

export type ChartOptions2 = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
};

interface Irange {
  end_date: number;
  start_date: number;
}

@Component({
  selector: 'app-partner-payments-dashboard',
  templateUrl: './partner-payments-dashboard.component.html',
  styleUrls: ['./partner-payments-dashboard.component.scss'],
})
export class PartnerPaymentsDashboardComponent implements OnInit {
  paymentDataLoaded: boolean = false;
  paymentChartsDataLoaded: boolean = false;

  underwriterPayments: IpaymentStats[];
  uPayment: IpaymentStats;
  totalMpesaPayments: number = 0;
  totalCardPayments: number = 0;
  totalPayments: number = 0;

  paymentStatusSeries: number[] = [];
  paymentStatusLabels: string[] = [];

  cardPaymentSeries: number[] = [];
  mpesaPaymentSeries: number[] = [];

  coversArray: string[] = [];
  noPayments: boolean = false;

  selectedDateRange: Irange;

  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public chartOptions2: Partial<ChartOptions2>;

  constructor(private appService: AppService) {
    let todayStamp = Date.now();
    let today = new Date(todayStamp);
    let last30Days = new Date(Date.now() - 2592000000);

    let startDate =
      last30Days.getFullYear() +
      '-' +
      (last30Days.getMonth() + 1) +
      '-' +
      last30Days.getDate();

    let endDate =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate();

    this.selectedDateRange = {
      end_date: Date.parse(endDate),
      start_date: Date.parse(startDate),
    };
  }

  ngOnInit(): void {
    this.getPaymentsInfo();
  }

  updateRange(range) {
    this.selectedDateRange.start_date = Date.parse(range.start_date);
    this.selectedDateRange.end_date = Date.parse(range.end_date);
    this.paymentDataLoaded = false;
    this.paymentChartsDataLoaded = false;

    this.totalMpesaPayments = 0;
    this.totalCardPayments = 0;
    this.totalPayments = 0;

    this.paymentStatusSeries = [];
    this.paymentStatusLabels = [];

    this.cardPaymentSeries = [];
    this.mpesaPaymentSeries = [];

    this.coversArray = [];
    this.noPayments = false;
    this.getPaymentsInfo();
  }

  getPaymentsInfo() {
    let parner: string;
    if (this.appService.getSessionUser().partner != undefined) {
      parner = this.appService.getSessionUser().partner;
    }
    this.appService
      .makePostRequest(`${environment.PAYMENTS_API_URL}/paymentlogstats`, {
        pipeline: [
          {
            $match: {
              product: {
                $exists: true,
              },
              status: 'SUCCESS',
              tenantUUID: parner,
              $and: [
                { _timestamp: { $gte: this.selectedDateRange.start_date } },
                { _timestamp: { $lte: this.selectedDateRange.end_date } },
              ],
            },
          },
          {
            $group: {
              _id: {
                tenantUUID: '$tenantUUID',
                product: '$product',
              },
              count: {
                $sum: 1,
              },
              total: {
                $sum: '$amount',
              },
              mpesa: {
                $sum: {
                  $cond: [
                    {
                      $eq: ['$transactionType', 'MPESA'],
                    },
                    '$amount',
                    0,
                  ],
                },
              },
              card: {
                $sum: {
                  $cond: [
                    {
                      $eq: ['$transactionType', 'CARD'],
                    },
                    '$amount',
                    0,
                  ],
                },
              },
              offline: {
                $sum: {
                  $cond: [{
                    $eq: ["$transactionType", "OFFLINE"]
                  }, "$amount", 0
                  ]
                }
              }
            },
          },
          {
            $match: {
              _id: {
                $ne: 'None',
              },
            },
          },
          {
            $group: {
              _id: '$_id.tenantUUID',
              policies: {
                $push: {
                  policy: '$_id.product',
                  mpesaPayments: {
                    $sum: '$mpesa',
                  },
                  cardPayments: {
                    $sum: '$card',
                  },
                  offlinePayments: {
                    $sum: '$offline',
                  },
                  total: {
                    $sum: '$total',
                  },
                },
              },
              totalMpesaPayments: {
                $sum: '$mpesa',
              },
              totalCardPayments: {
                $sum: '$card',
              },
              totalOfflinePayments: {
                $sum: '$offline',
              },
              totalPayments: {
                $sum: '$total',
              },
            },
          },
        ],
      })
      .subscribe(
        (apiResponse) => {
          if (apiResponse.Status === 200) {
            if (apiResponse.Payload.length > 0) {
              this.underwriterPayments = apiResponse.Payload;
              this.uPayment = this.underwriterPayments[0];

              this.totalPayments = this.uPayment.totalPayments;
              this.totalMpesaPayments = this.uPayment.totalMpesaPayments;
              this.totalCardPayments = this.uPayment.totalCardPayments;

              this.paymentDataLoaded = true;

              this.uPayment.policies.forEach((cover) => {
                this.cardPaymentSeries.push(cover.cardPayments);
                this.mpesaPaymentSeries.push(cover.mpesaPayments);
                this.coversArray.push(cover.policy);
              });

              this.chartOptions = {
                series: [
                  {
                    name: 'CARD',
                    data: this.cardPaymentSeries,
                  },
                  {
                    name: 'MPESA',
                    data: this.mpesaPaymentSeries,
                  },
                ],
                chart: {
                  type: 'bar',
                  height: 350,
                  stacked: true,
                  toolbar: {
                    show: true,
                  },
                  zoom: {
                    enabled: true,
                  },
                },
                responsive: [
                  {
                    breakpoint: 480,
                    options: {
                      legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0,
                      },
                    },
                  },
                ],
                plotOptions: {
                  bar: {
                    horizontal: false,
                  },
                },
                title: {
                  text: 'Payments per Cover',
                  align: 'center',
                },
                xaxis: {
                  type: 'category',
                  categories: this.coversArray,
                },
                legend: {
                  position: 'right',
                  offsetY: 40,
                },
                fill: {
                  opacity: 1,
                },
              };

              this.chartOptions2 = {
                series: [this.totalCardPayments, this.totalMpesaPayments],
                chart: {
                  height: 320,
                  type: 'donut',
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        total: {
                          show: true,
                        },
                      },
                    },
                  },
                },
                legend: {
                  position: 'bottom',
                },
                title: {
                  text: 'Payment Methods',
                  align: 'center',
                },
                labels: ['CARD', 'MPESA'],
              };
              this.paymentChartsDataLoaded = true;
            } else {
              this.noPayments = true;
            }
          } else {
            this.noPayments = true;
          }
        },
        (err) => {
          this.noPayments = true;
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
        }
      );
  }
}
