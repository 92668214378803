import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'collectionPolicyName',
})
export class CollectionPolicyNamePipe implements PipeTransform {
  transform(collectionName: string): string {
    if (collectionName.startsWith('cover_')) {
      return collectionName.replace('cover_', '').trim();
    }
    if (collectionName.includes('_quote_request')) {
      return collectionName.replace('_quote_request', '').trim();
    }

    return collectionName;
  }
}
