import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AppEnums } from "src/app/enums/app.enum";
import { IPagination } from "src/app/models/pagination.interface";
import { AppService } from "src/app/services/app.service";
import { AddPaymentComponent } from "src/app/wallet-transactions/add-payment/add-payment.component";
import { environment } from "src/environments/environment";
import { WalletTransactionsSearchPartialComponent } from "../wallet-transactions-search-partial/wallet-transactions-search-partial.component";

@Component({
  selector: "app-client-wallet",
  templateUrl: "./client-wallet.component.html",
  styleUrls: ["./client-wallet.component.scss"],
})
export class ClientWalletComponent implements OnInit {
  @ViewChild(WalletTransactionsSearchPartialComponent)
  walletSearch: WalletTransactionsSearchPartialComponent;
  @Input() feduid: string;
  isLoading: boolean = false;
  userWalletBalance: number = 0;
  userWalletTransactions: any[] = [];
  walletPagination: IPagination = {
    limit: 10,
    skip: 0,
    sort: {
      _timestamp: -1,
    },
  };
  walletDataLength: number = 0;
  walletSearchData: boolean = false;
  searchItem: string = "";
  transactionsSearchParams = {};

  constructor(
    public appService: AppService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getUserWallet();
    this.getUserWalletTransactions();
  }

  getUserWallet() {
    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.MPESA_API_URL}/adminGetWallets`, {
        $or: [{ feduid: this.feduid }],
      })
      .subscribe(
        (res) => {
          this.isLoading = false;
          if (res.Status === 200) {
            let response = res.Payload;
            if (response.length > 0) {
              response.forEach((res) => {
                if (res.hasOwnProperty("amount")) {
                  this.userWalletBalance = res.amount++;
                }
              });
            }
            this.userWalletBalance;
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              "Something went wrong",
              "Warning"
            );
          }
        },
        (error) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            this.appService.processHttpErrors(error),
            "Error"
          );
        }
      );
  }

  getUserWalletTransactions() {
    this.isLoading = true;
    this.appService
      .makePostRequest(
        `${environment.MPESA_API_URL}/adminGetWalletTransactions`,
        {
          $or: [{ feduid: this.feduid }],
          pagination: {
            sort: this.walletPagination.sort,
            skip: this.walletPagination.skip,
            limit: this.walletPagination.limit,
          },
        }
      )
      .subscribe(
        (res) => {
          this.isLoading = false;
          if (res.Status === 200) {
            this.userWalletTransactions = res.Payload;
            if (this.userWalletTransactions.length > 0) {
              this.userWalletTransactions = this.userWalletTransactions.sort(
                (a, b) => {
                  return b._timestamp - a._timestamp;
                }
              );
            }
            this.walletDataLength = this.userWalletTransactions.length;
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              "Something went wrong",
              "Warning"
            );
          }
        },
        (error) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            this.appService.processHttpErrors(error),
            "Error"
          );
        }
      );
  }

  updateWalletSkip(newSkip) {
    if (newSkip) {
      this.walletPagination.skip = newSkip;
      this.getUserWalletTransactions();
    }
  }

  updateWalletSearchSkip(newSkip) {
    if (newSkip) {
      this.walletPagination.skip = newSkip;
      this.walletSearch.search(this.transactionsSearchParams);
    }
  }

  showTransactionSearchResults(results) {
    this.isLoading = true;
    this.walletDataLength = results.length;
    this.userWalletTransactions = results;
    this.userWalletTransactions = this.userWalletTransactions.sort((a, b) => {
      return b._timestamp - a._timestamp;
    });
    this.isLoading = false;
  }

  resetTransactionData() {
    this.getUserWalletTransactions();
  }

  updateSearchInput(item) {
    this.searchItem = item;
  }

  popAddPaymentModal() {
    const dialogRef = this.dialog.open(AddPaymentComponent, {
      width: "25%",
      data: {
        feduid: this.feduid,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getUserWallet();
      this.getUserWalletTransactions();
    });
  }

  getMotorPolicy() {
    this.router.navigate(["manual-sale-motor/create"], {
      queryParams: { feduid: this.feduid },
    });
  }
}
