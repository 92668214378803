<h2 mat-dialog-title>
    Get Declaration Report
</h2>
<div mat-dialog-content>
    <app-loader *ngIf="isLoading"></app-loader>
    <form [formGroup]="formGroupSendDeclarationReport">
        <mat-form-field appearance="fill">
            <mat-label>Send to</mat-label>
            <textarea matInput cdkTextareaAutosize formControlName="to" cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"></textarea>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
    <button mat-button color="primary" (click)="generateReport()">
        Get Report
    </button>
</div>