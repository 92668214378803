import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppService } from '../services/app.service';

@Pipe({
  name: 'user',
})
export class UserPipe implements PipeTransform {
  constructor(private appService: AppService) {}
  transform(feduid: string): Observable<string> {
    return this.appService
      .getUserDetailsByFeduid(feduid, ['_id', 'userName'])
      .pipe(
        map((aUser) => {
          return aUser;
        })
      );
  }
}
