import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppEnums } from 'src/app/enums/app.enum';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-declare-icea-risk-note',
  templateUrl: './declare-icea-risk-note.component.html',
  styleUrls: ['./declare-icea-risk-note.component.scss'],
})
export class DeclareIceaRiskNoteComponent implements OnInit {
  isLoading: boolean = false;
  policyDetail: { _id: string; emails: string };
  constructor(
    public appService: AppService,
    public dialogRef: MatDialogRef<DeclareIceaRiskNoteComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      product: string;
      _id: string;
      insurer: string;
    }
  ) {}

  ngOnInit(): void {}
  sendReport() {
    this.isLoading = true;
    this.appService.sendIceaRiskDeclaration(this.data._id).subscribe(
      (res) => {
        this.isLoading = false;
        let payload = res.Payload;
        let error_desc: any = '';
        if (payload.hasOwnProperty('error_desc')) {
          error_desc = payload.error_desc;
          if (Array.isArray(error_desc)) {
            error_desc.forEach((err) => {
              if (err.hasOwnProperty('error_code')) {
                const error_desc = err.error_desc;
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  '',
                  error_desc
                );
              }
            });
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              ``,
              error_desc
            );
          }
        } else {
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            ``,
            'Risk Note posted successfully'
          );
          this.dialogRef.close();
        }
      },
      (err) => {
        this.isLoading = false;
        this.appService.showToastMessage(
          AppEnums.ToastTypeError,
          '',
          this.appService.processHttpErrors(err)
        );
      }
    );
  }
}
