import { Component, Input, OnInit } from '@angular/core';
import { IUser } from 'src/app/models/user.interface';

@Component({
  selector: 'app-referrals-list',
  templateUrl: './referrals-list.component.html',
  styleUrls: ['./referrals-list.component.scss']
})
export class ReferralsListComponent implements OnInit {
  @Input() referralDetails: IUser[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
