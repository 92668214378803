<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<h2 mat-dialog-title>ADD PUSH NOTIFICATION</h2>
<div mat-dialog-content>
  <form
    [formGroup]="sendNotifications"
    (ngSubmit)="submit()"
    autocomplete="off"
  >
    <mat-form-field>
      <mat-label>Title</mat-label>
      <input matInput formControlName="title" placeholder="Title" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Message</mat-label>
      <textarea
        matInput
        formControlName="message"
        placeholder="Message"
      ></textarea>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Template</mat-label>
      <mat-select formControlName="template">
        <mat-option *ngFor="let template of templates" [value]="template.value">
          {{ template.id }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Intent</mat-label>
      <mat-select formControlName="intent">
        <mat-option *ngFor="let intent of intents" [value]="intent.value">
          {{ intent.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label class="text-info">Analytics Label</mat-label>
      <input matInput formControlName="analyticsLabel" placeholder="Anaytics Label" />
    </mat-form-field>
  </form>
  <button
    mat-button
    color="primary"
    (click)="submit()"
    [disabled]="!sendNotifications.valid"
  >
    Send
  </button>
  <button mat-button mat-dialog-close>Cancel</button>
</div>
