import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppEnums } from 'src/app/enums/app.enum';
import { ApiResponse } from 'src/app/models/api-response.interface';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-client-info-update-partial',
  templateUrl: './client-info-update-partial.component.html',
  styleUrls: ['./client-info-update-partial.component.scss'],
})
export class ClientInfoUpdatePartialComponent implements OnInit {
  @Input() receivedId: string;
  @Input() selectedQuoteId: string;
  @Input() product: string;
  @Input() nokData: any;
  @Input() startDate: any;
  @Input() dependents: any;
  @Input() dependantsAsIsInQuote: any;
  @Input() checkoutUpdate: any[];
  @Input() cardMemberPayload: any[];
  @Output() clientInfoEvent: EventEmitter<any> = new EventEmitter<any>();
  clientFormGroup: FormGroup;
  multiUploadFormGroup: FormGroup;
  allowedFileExtentions = ['jpg', 'jpeg', 'png', 'pdf'];
  azureFileLocation: string = 'manual-sales';
  activeFileName: string = '';
  activeFile: any;
  clientFiles: any[] = [
    { name: 'ID Card (Front)', value: 'idPhotoFront', suffix: '_ID_FRONT' },
    { name: 'ID Card (Back)', value: 'idPhotoBack', suffix: '_ID_BACK' },
    { name: 'KRA PIN Document', value: 'kraPhoto', suffix: '_KRA_PIN' },
    { name: 'Passport Document', value: 'passportPhoto', suffix: '_PASSPORT' },
  ];
  genders: any[] = [
    { name: 'Female', value: 'female' },
    { name: 'Male', value: 'male' },
  ];
  _tstamp: number = 0;
  currentRecord: any;
  questionsId: string;
  capturedGender: string;
  isLoading: boolean = false;
  capturedEmail: string;
  capturedUserName: string;
  capturedPhoneNumber: string;
  capturedKraPin: string;
  capturedKraPhoto: string;
  capturedIdNumber: string;
  capturedPassportNumber: string;
  capturedPassportPhoto: string;
  capturedIdPhotoBack: string;
  capturedIdPhotoFront: string;
  capturedPhysicalAddress: string;
  capturedPostalAddress: string;
  capturedPostalCode: string;
  constructor(
    private _formBuiler: FormBuilder,
    private appService: AppService,
    private router: Router
  ) {
    this._tstamp = Date.now();
    this.clientFormGroup = _formBuiler.group({
      email: ['', Validators.email],
      userName: [''],
      phoneNumber: [''],
      kraPin: [''],
      kraPhoto: [''],
      gender: [''],
      idNumber: [''],
      passportNumber: [''],
      passportPhoto: [''],
      idPhotoBack: [''],
      idPhotoFront: [''],
      physicaladdress: [''],
      postaladdress: [''],
      postalcode: [''],
    });
    this.multiUploadFormGroup = this._formBuiler.group({
      fileObj: [''],
    });
  }

  onFileObjChange(idx: number) {
    this.activeFile = this.clientFiles[idx];
    this.activeFileName = this._tstamp + this.activeFile.suffix;
  }

  uploadEvent($event) {
    switch (this.activeFile.value) {
      case 'idPhotoFront':
        this.clientFormGroup.patchValue({
          idPhotoFront: $event,
        });
        break;
      case 'idPhotoBack':
        this.clientFormGroup.patchValue({
          idPhotoBack: $event,
        });
        break;
      case 'kraPhoto':
        this.clientFormGroup.patchValue({
          kraPhoto: $event,
        });
        break;
      case 'passportPhoto':
        this.clientFormGroup.patchValue({
          passportPhoto: $event,
        });
        break;
      default:
        break;
    }
  }

  validateClient() {
    if (!this.clientFormGroup.valid) {
      const controls = this.clientFormGroup.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            'Field Required',
            name
          );
        }
      }
      return;
    }
    this.clientInfoEvent.emit(this.clientFormGroup.value);
  }

  setClientInformation() {
    this.convertGenderValue();
    this.checkForAvailableData();
    if (this.currentRecord != null) {
      if (this.currentRecord?.hasOwnProperty('moreQuestions')) {
        if (this.currentRecord.moreQuestions?.client != null) {
          this.clientFormGroup = this._formBuiler.group({
            email: [this.capturedEmail, Validators.email],
            userName: [this.capturedUserName],
            phoneNumber: [this.capturedPhoneNumber],
            kraPin: [this.capturedKraPin],
            kraPhoto: [this.capturedKraPhoto],
            gender: [
              this.capturedGender ||
                this.currentRecord.moreQuestions?.client?.gender,
            ],
            idNumber: [this.capturedIdNumber],
            passportNumber: [this.capturedPassportNumber],
            passportPhoto: [this.capturedPassportPhoto],
            idPhotoBack: [this.capturedIdPhotoBack],
            idPhotoFront: [this.capturedIdPhotoFront],
            physicaladdress: [this.capturedPhysicalAddress],
            postaladdress: [this.capturedPostalAddress],
            postalcode: [this.capturedPostalCode],
          });
        }
      }
    }
  }

  setGetQuoteUrl() {
    let microService = '';
    switch (this.product) {
      case 'motor':
        microService = `${environment.MOTOR_API_URL}`;
        this.getQuoteInformation('adminlistmotorquote', microService);
        break;
      case 'health':
        microService = `${environment.MEDICAL_API_URL}`;
        this.getQuoteInformation('adminlistquote', microService);
        break;
      default:
        return;
    }
  }

  convertGenderValue() {
    let gender = this.currentRecord.moreQuestions?.client?.gender;
    if (gender == 'Male') {
      this.capturedGender = 'male';
    } else if (gender == 'Female') {
      this.capturedGender = 'female';
    }
  }

  checkForAvailableData() {
    if (
      this.currentRecord.moreQuestions?.client?.email != null ||
      this.currentRecord?.email != null
    ) {
      this.capturedEmail =
        this.currentRecord.moreQuestions?.client?.email ||
        this.currentRecord?.email;
    }
    if (
      this.currentRecord.moreQuestions?.client?.userName != null ||
      this.currentRecord?.name != null
    ) {
      this.capturedUserName =
        this.currentRecord.moreQuestions?.client?.userName ||
        this.currentRecord?.name;
    }
    if (
      this.currentRecord.moreQuestions?.client?.phoneNumber != null ||
      this.currentRecord?.phoneNumber != null
    ) {
      this.capturedPhoneNumber =
        this.currentRecord.moreQuestions?.client?.phoneNumber ||
        this.currentRecord?.phoneNumber;
    }
    if (this.currentRecord.moreQuestions?.client?.kraPin != null) {
      this.capturedKraPin = this.currentRecord.moreQuestions?.client?.kraPin;
    }
    if (this.currentRecord.moreQuestions?.client?.kraPhoto != null) {
      this.capturedKraPhoto =
        this.currentRecord.moreQuestions?.client?.kraPhoto;
    }
    if (this.currentRecord.moreQuestions?.client?.idNumber != null) {
      this.capturedIdNumber =
        this.currentRecord.moreQuestions?.client?.idNumber;
    }
    if (this.currentRecord.moreQuestions?.client?.passportNumber != null) {
      this.capturedPassportNumber =
        this.currentRecord.moreQuestions?.client?.passportNumber;
    }
    if (this.currentRecord.moreQuestions?.client?.passportPhoto != null) {
      this.capturedPassportPhoto =
        this.currentRecord.moreQuestions?.client?.passportPhoto;
    }
    if (this.currentRecord.moreQuestions?.client?.idPhotoBack != null) {
      this.capturedIdPhotoBack =
        this.currentRecord.moreQuestions?.client?.idPhotoBack;
    }
    if (this.currentRecord.moreQuestions?.client?.idPhotoFront != null) {
      this.capturedIdPhotoFront =
        this.currentRecord.moreQuestions?.client?.idPhotoFront;
    }
    if (this.currentRecord.moreQuestions?.client?.physicaladdress != null) {
      this.capturedPhysicalAddress =
        this.currentRecord.moreQuestions?.client?.physicaladdress;
    }
    if (this.currentRecord.moreQuestions?.client?.postaladdress != null) {
      this.capturedPostalAddress =
        this.currentRecord.moreQuestions?.client?.postaladdress;
    }
    if (this.currentRecord.moreQuestions?.client?.postalcode != null) {
      this.capturedPostalCode =
        this.currentRecord.moreQuestions?.client?.postalcode;
    }
  }

  getQuoteInformation(endpoint: string, microService: string) {
    if (this.selectedQuoteId != null) {
      this.appService
        .makePostRequest(`${microService}/${endpoint}`, {
          _id: this.selectedQuoteId,
        })
        .pipe(switchMap((res) => this.getQuoteQuestions(res, microService)))
        .subscribe((apiResponse) => {
          if (apiResponse.Status === 200) {
            this.currentRecord = apiResponse.Payload;
            this.setClientInformation();
          } else {
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              apiResponse.Payload,
              "Could not process this quote's information"
            );
          }
          (error) => {
            this.appService.showToastMessage(
              AppEnums.ToastTypeError,
              '',
              this.appService.processHttpErrors(error)
            );
          };
        });
    }
  }

  getQuoteQuestions(
    res: ApiResponse,
    microService: string
  ): Observable<ApiResponse> {
    let q = res.Payload[0].questions_id;

    return this.appService.makePostRequest(
      `${microService}/admingetquestions`,
      {
        _id: q,
      }
    );
  }

  ngOnInit(): void {
    this.setGetQuoteUrl();
  }

  updateClientInfo() {
    this.isLoading = true;
    let client = this.clientFormGroup.value;
    let nok = this.nokData;
    let body = {
      _id: this.currentRecord?._id,
      update: {
        moreQuestions: {
          client: { ...client, ...nok },
          startDate: this.startDate,
          checkoutUpdate: this.checkoutUpdate,
          dependents: this.dependents,
          dependantsAsIsInQuote: this.dependantsAsIsInQuote,
          card: this.cardMemberPayload,
        },
      },
    };
    this.appService
      .makePostRequest(
        `${environment.MEDICAL_API_URL}/adminUpdateQuestionsGeneral`,
        body
      )
      .subscribe(
        (res) => {
          if (res.Status == 200) {
            this.isLoading = false;
            this.appService.showToastMessage(
              AppEnums.ToastTypeSuccess,
              'Update successful',
              'Success'
            );
            this.router.navigate([
              '/medical/list/medical-details',
              this.selectedQuoteId,
            ]);
          } else {
            this.isLoading = false;
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              'Update unsuccessful',
              'Something went wrong'
            );
          }
        },
        (error) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            this.appService.processHttpErrors(error),
            'Error'
          );
        }
      );
  }
}
