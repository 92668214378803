import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { AppEnums } from "src/app/enums/app.enum";
import { AppService } from "src/app/services/app.service";

@Component({
  selector: "app-wallet-transactions-search-partial",
  templateUrl: "./wallet-transactions-search-partial.component.html",
  styleUrls: ["./wallet-transactions-search-partial.component.scss"],
})
export class WalletTransactionsSearchPartialComponent implements OnInit {
  @Input() searchParams;
  @Input() feduid: string = "";
  @Output() searchResults = new EventEmitter<string>();
  @Output() InputEmpty = new EventEmitter<boolean>();
  @Output() searchItem = new EventEmitter<string>();

  searchForm: FormGroup;
  isSearching: boolean = false;
  searchQuery: {} = {};

  name = new FormControl("");

  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService
  ) {
    this.searchForm = this.formBuilder.group({
      inputItem: ["", [Validators.required]],
    });
  }

  ngOnInit(): void {}

  search(searchParams: any) {
    this.isSearching = true;
    const { inputItem } = this.searchForm.value;

    let cleanInputItem: any = inputItem;

    if (inputItem == "true") {
      cleanInputItem = true;
    }

    if (inputItem == "false") {
      cleanInputItem = false;
    }
    if (!inputItem) {
      this.isSearching = false;
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        "Missing Input",
        "Please enter your search item!"
      );
      return;
    }

    const fields = searchParams.searchFields.map((item) => {
      return { [item]: { $regex: cleanInputItem.trim(), $options: "i" } };
    });
    this.searchQuery = {
      $or: fields,
      feduid: this.feduid,
    };
    try {
      this.appService
        .makeSearchRequest(searchParams.url, {
          ...this.searchQuery,
        })
        .subscribe(
          (apiResponse) => {
            this.isSearching = false;
            apiResponse.Status === 200
              ? apiResponse.Payload.length === 0
                ? (this.appService.showToastMessage(
                    AppEnums.ToastTypeWarning,
                    "No results!",
                    "No results found. Try searching other options"
                  ),
                  this.searchResults.emit(apiResponse.Payload))
                : this.searchResults.emit(apiResponse.Payload)
              : this.appService.showToastMessage(
                  AppEnums.ToastTypeError,
                  "Something went wrong",
                  "Failed to get search results. Please try again"
                );
          },
          (err) => {
            this.isSearching = false;
            this.appService.showToastMessage(
              AppEnums.ToastTypeError,
              "Something went wrong",
              "Please try again"
            );
          }
        );
    } catch (ex) {
      this.isSearching = false;
      this.appService.showToastMessage(
        AppEnums.ToastTypeError,
        "Something went wrong",
        "Please try again"
      );
      throw new Error(ex.toString);
    }
  }

  onInputChange() {
    const { inputItem } = this.searchForm.value;

    this.searchItem.emit(inputItem);

    if (!inputItem) {
      this.InputEmpty.emit();
    }
  }
}
