<h2 mat-dialog-title>
    Declare Risk Note to {{ (data.insurer
    |underwriter | async) }}
</h2>
<div mat-dialog-content>
    <app-loader *ngIf="isLoading"></app-loader>
    <p>
        Submitting this report will result in posting this {{data.product}} policy directly into {{ (data.insurer
        |underwriter | async) }} core system.
    </p>
    <p>
        Do you want to proceed?
    </p>

</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>No, Cancel</button>
    <button mat-button color="danger" (click)="sendReport()">
        Yes, Submit Report
    </button>
</div>