<div class="card">
  <div class="card-body col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="d-flex justify-content-between">
          <h2 class="text-mtek">
            WALLET BALANCE :
            <span class="text-muted">{{
              userWalletBalance | currency : 'Ksh. '
            }}</span>
          </h2>
          <div class="col-md-4 d-flex justify-content-end">
            <button
              class="btn btn-outline-primary btn-small"
              (click)="popAddPaymentModal()"
            >
              Add Payment
            </button>
            <button
              class="btn btn-outline-primary btn-small ml-2"
              (click)="getMotorPolicy()"
            >
              Purchase Motor Policy
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-md-12 mb-3">
        <app-wallet-transactions-search-partial
          [searchParams]="transactionsSearchParams"
          [feduid]="feduid"
          (searchResults)="showTransactionSearchResults($event)"
          (InputEmpty)="resetTransactionData()"
          (searchItem)="updateSearchInput($event)"
        >
        </app-wallet-transactions-search-partial>
      </div>
      <div class="col-md-12 mt-2">
        <h4 class="card-title text-uppercase mb-0 text-mtek">
          Wallet Transactions
        </h4>
      </div>
      <div class="col-md-12">
        <div *ngIf="userWalletTransactions.length > 0; else noUserTransactions">
          <div class="table-responsive-sm">
            <table class="table table-striped table-responsive-sm">
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Transaction Type</th>
                <th scope="col">Amount</th>
                <th scope="col">Status</th>
                <th scope="col">Transaction Type</th>
                <th scope="col">Reference Number</th>
              </tr>

              <tbody>
                <tr *ngFor="let transaction of userWalletTransactions">
                  <td class="p-3">
                    {{ transaction?._timestamp | date }}
                  </td>
                  <td class="p-3">
                    {{ transaction?.transactionType }}
                  </td>
                  <td class="p-3">
                    {{ transaction?.amount | currency : 'Ksh. ' }}
                  </td>
                  <td class="p-3">
                    {{ transaction?.status }}
                  </td>
                  <td class="p-3">
                    {{ transaction?.transactionDirection }}
                  </td>
                  <td
                    class="p-3"
                    *ngIf="transaction?.MpesaReceiptNumber; else noReceipt"
                  >
                    {{ transaction?.MpesaReceiptNumber }}
                  </td>
                  <ng-template #noReceipt>
                    <td class="p-3">NA</td>
                  </ng-template>
                </tr>
              </tbody>
            </table>
            <app-pagination
              class="section2"
              *ngIf="!walletSearchData"
              [skip]="walletPagination.skip"
              [limit]="walletPagination.limit"
              [dataLength]="walletDataLength"
              [dataLoading]="isLoading"
              (newSkip)="updateWalletSkip($event)"
            ></app-pagination>

            <app-pagination
              class="section2"
              *ngIf="walletSearchData"
              [skip]="walletPagination.skip"
              [limit]="walletPagination.limit"
              [dataLength]="walletDataLength"
              [dataLoading]="isLoading"
              (newSkip)="updateWalletSearchSkip($event)"
            ></app-pagination>
          </div>
        </div>
        <ng-template #noUserTransactions>
          <div class="alert alert-warning" role="alert">
            There are no available wallet transactions.
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
