<h1 mat-dialog-title>Update Company Information</h1>
<div mat-dialog-content>
  <app-loader *ngIf="isLoading"></app-loader>
  <form [formGroup]="companyFormGroup">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label for="name">Name</label>
          <input
            class="form-control"
            type="text"
            formControlName="name"
            placeholder="Company Name"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="emailAddress">Email</label>
          <input
            class="form-control"
            type="email"
            formControlName="emailAddress"
            placeholder="Email Address"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="phoneNumber">Phone</label>
          <input
            class="form-control"
            type="phoneNumber"
            formControlName="phoneNumber"
            placeholder="Phone Number"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="kraPin">KRA PIN</label>
          <input
            class="form-control"
            type="text"
            formControlName="kraPin"
            placeholder="KRA PIN"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="physicaladdress">Physical Address</label>
          <input
            class="form-control"
            type="text"
            formControlName="physicaladdress"
            placeholder="Physical Address"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="postalAddress">Postal Address</label>
          <input
            class="form-control"
            type="text"
            formControlName="postalAddress"
            placeholder="Postal Address"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="postalCode">Postal Code</label>
          <input
            class="form-control"
            type="text"
            formControlName="postalCode"
            placeholder="Postal Code"
          />
        </div>
      </div>
      <div class="col-md-8">
        <div class="card">
          <div class="card-body">
            <form [formGroup]="companyMultiUploadFormGroup" class="mt-2">
              <div class="form-group">
                <label for="fileObj">Select File to Upload</label>
                <select
                  class="custom-select"
                  formControlName="fileObj"
                  (change)="onCompanyFileObjChange($event.target.value)"
                  id="fileObj"
                >
                  <option
                    *ngFor="let afileObj of companyFiles; let i = index"
                    [value]="i"
                  >
                    {{ afileObj.name }}
                  </option>
                </select>
              </div>
              <app-file-upload
                [allowedFileExtentions]="allowedFileExtentions"
                [azureFileLocation]="azureFileLocation"
                [azureFileName]="activeFileName"
                (emitFileUploadedEvent)="uploadCompanyDoc($event)"
              >
              </app-file-upload>
            </form>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
  <app-loader *ngIf="isMoreQuestionsObjectAvailable"></app-loader>
  <button
    mat-button
    color="primary"
    *ngIf="isMoreQuestionsObjectAvailable == false"
    (click)="updateCompanyInfo()"
  >
    Submit
  </button>
</div>
