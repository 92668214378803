import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AppEnums } from "src/app/enums/app.enum";
import { AppService } from "src/app/services/app.service";

@Component({
  selector: "app-collection-search",
  templateUrl: "./collection-search.component.html",
  styleUrls: ["./collection-search.component.scss"],
})
export class CollectionSearchComponent implements OnInit {
  @Input() searchParams;
  @Output() searchResults = new EventEmitter<string>();
  @Output() InputEmpty = new EventEmitter<boolean>();
  @Output() searchItem = new EventEmitter<string>();
  searchForm: FormGroup;
  isSearching: boolean = false;
  searchQuery: {} = {};

  name = new FormControl("");

  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService
  ) {
    this.searchForm = this.formBuilder.group({
      inputItem: ["", [Validators.required]],
    });
  }

  search() {
    this.isSearching = true;
    let searchValue = this.searchForm.get("inputItem").value;

    const fields = this.searchParams.searchFields.map((item) => {
      return { [item]: { $regex: searchValue, $options: "i" } };
    });

    this.appService
      .makeSearchRequest(this.searchParams.url, {
        pagination: this.searchParams.pagination,
        $or: fields,
      })
      .subscribe(
        (apiResponse) => {
          this.isSearching = false;
          apiResponse.Status === 200
            ? apiResponse.Payload.length === 0
              ? (this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  "No results!",
                  "No results found. Try searching other options"
                ),
                this.searchResults.emit(apiResponse.Payload))
              : this.searchResults.emit(apiResponse.Payload)
            : this.appService.showToastMessage(
                AppEnums.ToastTypeError,
                "Something went wrong",
                "Failed to get search results. Please try again"
              );
        },
        (err) => {
          this.isSearching = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            "Something went wrong",
            "Please try again"
          );
        }
      );
  }

  onInputChange() {
    const { inputItem } = this.searchForm.value;

    this.searchItem.emit(inputItem);

    if (!inputItem) {
      this.InputEmpty.emit();
    }
  }

  ngOnInit(): void {}
}
