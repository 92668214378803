<div class="container">
  <div class="row">
    <div class="col-md-3">
      <form [formGroup]="optionalForm" class="optional-form">
        <label class="search-by">Search by...</label>
        <select class="form-control" formControlName="name">
          <option
            *ngFor="let name of searchParams.searchFields"
            [value]="name.value"
          >
            {{ name.name | titlecase }}
          </option>
        </select>
      </form>
    </div>
    <div class="col-md form">
      <form
        [formGroup]="searchForm"
        (ngSubmit)="search(searchParams)"
        class="search-form"
      >
        <div class="col-lg-12 search-item-box">
          <div class="col-lg-12 d-flex form-group">
            <input
              placeholder="Search..."
              class="form-control"
              formControlName="inputItem"
              (input)="onInputChange()"
            />
            <div (click)="search(searchParams)" class="icon-container">
              <i class="fa fa-search"></i>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<app-loader class="search-loader" *ngIf="isSearching"></app-loader>
