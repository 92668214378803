<app-partner-payments-dashboard *ngIf="appService.isPartner()"></app-partner-payments-dashboard>
<div *ngIf="appService.isAdmin()">
  <app-date-range (dateRange)="updateRange($event)"> </app-date-range>
  <h4>{{selectedDateRange.start_date | date: 'fullDate'}} to {{selectedDateRange.end_date | date:'fullDate'}}</h4>
  <div *ngIf="totalPayments === 0 && allDataLoaded" class="alert alert-warning" role="alert">
    No new payments for the selected period. Please try another date range!
  </div>
  <div class="row">
    <div class="col-lg-3 card-container">
      <div class="card card-stats">
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase mb-0 text-mtek">
                Total payments
              </h5>
              <span *ngIf="allDataLoaded" class="">Ksh </span><span *ngIf="allDataLoaded" class="h5 mb-0">{{
                totalPayments | currency: ' ' }}</span>
              <app-loader *ngIf="!allDataLoaded"></app-loader>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-gradient-info text-white rounded-circle shadow">
                <i class="fa fa-users"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 card-container">
      <div class="card card-stats">
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase mb-0 text-success">
                Mpesa payments
              </h5>
              <span *ngIf="allDataLoaded" class="">Ksh </span><span *ngIf="allDataLoaded" class="h5 mb-0">{{
                totalMpesaPayments | currency: ' ' }}</span>
              <app-loader *ngIf="!allDataLoaded"></app-loader>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-gradient-success text-white rounded-circle shadow">
                <i class="fa fa-check-circle"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 card-container">
      <div class="card card-stats">
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase mb-0 text-warning">
                Card payments
              </h5>
              <span *ngIf="allDataLoaded" class="">Ksh </span><span *ngIf="allDataLoaded" class="h5 mb-0">{{
                totalCardPayments | currency: ' ' }}</span>
              <app-loader *ngIf="!allDataLoaded"></app-loader>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-gradient-warning text-white rounded-circle shadow">
                <i class="fa fa-credit-card"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 card-container">
      <div class="card card-stats">
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase mb-0 text-primary">
                Offline payments
              </h5>
              <span *ngIf="allDataLoaded" class="">Ksh </span><span *ngIf="allDataLoaded" class="h5 mb-0">{{
                totalOfflinePayments | currency: ' ' }}</span>
              <app-loader *ngIf="!allDataLoaded"></app-loader>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                <i class="fa fa-money"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <br />

  <div class="row charts-container">
    <div class="col-lg-8 text-center">
      <div class="card chart-container">
        <app-loader *ngIf="!chartsDataLoaded"></app-loader>
        <apx-chart *ngIf="chartsDataLoaded" [colors]="chartOptions.colors" [title]="chartOptions.title"
          [series]="chartOptions.series" [chart]="chartOptions.chart" [dataLabels]="chartOptions.dataLabels"
          [plotOptions]="chartOptions.plotOptions" [yaxis]="chartOptions.yaxis" [xaxis]="chartOptions.xaxis"
          [fill]="chartOptions.fill" [tooltip]="chartOptions.tooltip" [stroke]="chartOptions.stroke"
          [legend]="chartOptions.legend"></apx-chart>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card chart-container">
        <apx-chart *ngIf="chartsDataLoaded" [colors]="chartOptions4.colors" [title]="chartOptions4.title"
          [series]="chartOptions4.series" [chart]="chartOptions4.chart" [responsive]="chartOptions4.responsive"
          [labels]="chartOptions4.labels" [stroke]="chartOptions4.stroke" [fill]="chartOptions4.fill"
          [legend]="chartOptions4.legend"></apx-chart>
        <app-loader *ngIf="!chartsDataLoaded"></app-loader>
      </div>
    </div>
    <br />
  </div>
  <br />
</div>
