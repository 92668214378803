import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
})
export class DateRangeComponent implements OnInit {
  startDate: string;
  endDate: string;
  defaultPlaceholder: string = 'Default: Last 30 days';
  @Output() dateRange = new EventEmitter<{}>();
  @Input() defaultText?: string;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  constructor() {}

  ngOnInit(): void {
    if (this.defaultText) {
      this.defaultPlaceholder = this.defaultText;
    }
  }

  updateRate() {
    let start = this.range.value.start;
    let end = this.range.value.end;

    this.startDate =
      start.getFullYear() +
      '-' +
      (start.getMonth() + 1) +
      '-' +
      start.getDate();

    this.endDate =
      end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate();

    let range = Date.parse(this.endDate) - Date.parse(this.startDate);

    this.defaultPlaceholder =
      'Selected Range: ' + range / (60 * 60 * 24 * 1000) + ' Days';

    this.dateRange.emit({
      end_date: this.endDate,
      start_date: this.startDate,
    });
  }
}
